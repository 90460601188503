<template>
	<div>
		<Upload :disabled="disabled"  :before-upload="(file)=>handleUpload(file)" 
			action="">
			<Button :disabled="disabled" ref="uploadImg" @click="()=>upload()">上传图片</Button>
		</Upload>		
	</div>
</template>

<script>
	import * as qiniu from 'qiniu-js'
	export default{
		name:'qiniu',
		props:['qiniu_config',"disabled"],
		data() {
			return {
				file:'',//文件流,
				type:'',//
				img_type:['image/png','image/bmp','image/jpeg','image/jpg','image/gif'],//上传准入格式
				// qiniu_config:{},//表单状态数据
			}
		},
		methods: {
			
			async upload(file){
				console.log(file,'==============26')
				if(!file){
					return
				}
				// 文件类型限制
				if((this.img_type).indexOf(file.type)==-1){
					this.$mytoast_err('只能上传图片类型')
					return false
				}
				// 文件大小限制
				if(file.size>1024*1024){
					this.$mytoast_err('上传文件不可大于1M')
					return false
					
				}
				var observable = qiniu.upload(file, 'cms_resources/'+new Date().getTime(), this.qiniu_config.token, {}, {})
				let res_qiniu = await new Promise((resolve, reject) => {
					observable.subscribe({
						  next(res){
			
						  },
						  error(err){
							  
						  }, 
						  complete(res){
							  
							  resolve(res)
						  }
						}) // 上传开始
				})
				let url = this.qiniu_config.downloadurl+res_qiniu.key
				console.log(url,'=================57')
				this.$emit('set_url',url)
				
			},
			
			// 手动上传
			handleUpload(file){
			
				this.file = file;
				this.upload(file)
				return false;
				
			},
			
		},
	}
</script>

<style>
</style>
