<template>
    <div>
        <div style="text-align: right">
            <Button @click="() => handleCreate()" type="primary">新增</Button>
        </div>
        <Table @on-row-click="(payload) => (current_row_click = payload)" style="margin-top: 10px" border :columns="columns" :data="data6">
            <template slot-scope="{ row }" slot="logurl">
                <div style="width: 50px; height: 50px">
                    <img style="width: 100%; height: 100%" :src="row.logurl" alt="" />
                </div>
            </template>
            <template slot-scope="{ row }" slot="operation">
                <Button @click="() => handleEidt(row)" size="small" type="primary">编辑</Button>
                <Button @click="() => show_Modal_appointed_administrator(row)" style="margin-left: 10px" size="small">任命品牌管理员</Button>
            </template>
        </Table>
        <!-- 新增 -->
        <Modal :title="modalTitle" :mask-closable="false" v-model="Modal_CreateBrand">
            <Form :rules="ruleValidate" style="padding: 20px 40px 20px 20px" ref="form" :model="form" :label-width="140" label-position="left">
                <FormItem label="品牌名称：" prop="name">
                    <Input v-model="form.name" placeholder=""></Input>
                </FormItem>
                <FormItem label="品牌logo：" prop="logurl">
                    <Input v-model="form.logurl" placeholder=""></Input>
                </FormItem>
                <FormItem label="小程序appid：" prop="appid">
                    <Input v-model="form.appid" placeholder=""></Input>
                </FormItem>
                <FormItem label="接入模式：" prop="mode">
                    <Select :disabled="modalTitle == '编辑品牌'" @on-change="(value) => changeSelect(value)" v-model="form.mode" style="width: 200px">
                        <Option v-for="(item, index) in modeType" :value="item" :key="index">{{ item }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="授权商户号：" prop="subMchId">
                    <Input v-model="form.subMchId" placeholder=""></Input>
                </FormItem>
                <FormItem v-if="form.mode == '代开发'" label="peid：" prop="sid">
                    <Input v-model="form.peid" placeholder=""></Input>
                </FormItem>
                <FormItem v-if="form.mode == '云开发'" label="云环境ID：" prop="envId">
                    <Input v-model="form.envId" placeholder=""></Input>
                </FormItem>
                <FormItem v-if="form.mode == '云开发'" label="apitokenKey：" prop="authuid">
                    <Input v-model="form.authuid" placeholder=""></Input>
                </FormItem>
                <FormItem v-if="form.mode == '代开发' || form.mode == '服务商'" label="授权平台SID：" prop="sid">
                    <Input v-model="form.sid" placeholder=""></Input>
                </FormItem>
                <FormItem v-if="form.mode == '代开发' || form.mode == '服务商'" label="授权平台apikey：" prop="apikey">
                    <Input v-model="form.apikey" placeholder=""></Input>
                </FormItem>
                <FormItem label="到期时间：" prop="dueToTime">
                    <DatePicker v-model.object="form.dueToTime" placeholder="Select date" style="width: 200px" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="() => (Modal_CreateBrand = false)">返回</Button>
                <Button @click="() => handleSubmit()" type="primary">确定</Button>
            </div>
        </Modal>
        <!-- 任命品牌管理员 -->
        <Modal :mask-closable="false" width="800" v-model="Modal_appointed_administrator">
            <div slot="header">任命品牌管理员</div>

            <div>
                <div style="text-align: right">
                    <span style="width: 100px">输入任命信息：</span>
                    <Input @keyup.enter.native="() => do_search()" v-model="search_keyword" style="margin: 0 10px; width: 300px"></Input>
                    <Button @click="() => do_search()" type="primary">搜索</Button>
                </div>

                <div style="margin-top: 10px">
                    <h3>搜索用户</h3>
                    <Table :data="list_up" :columns="title_up">
                        <template slot-scope="{ row }" slot="operation">
                            <Button @click="() => appointed_to_cancel(row, '任命')" size="small" v-if="!row.admin" type="primary">任命</Button>
                        </template>
                    </Table>
                    <h3>品牌管理员列表</h3>
                    <Table style="margin-top: 20px" :data="list_bottom" :columns="title_bottom">
                        <template slot-scope="{ row }" slot="operation">
                            <Button @click="() => appointed_to_cancel(row, '罢免')" size="small" type="error">罢免</Button>
                        </template>
                    </Table>
                </div>
            </div>

            <div slot="footer">
                <Button @click="() => (Modal_appointed_administrator = false)">返回</Button>
                <Button type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { BrandAdmin_appoint, Get_BrandAdmin, searchUser, Set_BrandDate, Get_BrandDate, Get_BrandList, CreateBrand } from '../../../request/api.js';
export default {
    name: 'navbar',
    data() {
        return {
            modalTitle: '',
            search_keyword: '', //搜索输入框
            title_bottom: [],
            list_bottom: [],
            list_up: [],
            title_up: [],
            Modal_appointed_administrator: false, //任命品牌管理员弹窗
            current_row_click: '', //
            form: {},
            modeType: ['云开发', '代开发', '服务商'],
            ruleValidate: {
                appid: {
                    required: true,
                    trigger: 'blur',
                },
                sid: {
                    required: true,
                    trigger: 'blur',
                },
                apikey: {
                    required: true,
                    trigger: 'blur',
                },
                mode: {
                    required: true,
                    trigger: 'blur',
                },
                authuid: {
                    required: true,
                    trigger: 'blur',
                },
                dueToTime: {
                    required: true,
                    type: 'date',
                    trigger: 'blur',
                },
                envId: {
                    required: true,
                    trigger: 'blur',
                },
                logurl: {
                    required: true,
                    trigger: 'blur',
                },
                name: {
                    required: true,
                    trigger: 'blur',
                },
                subMchId: {
                    required: true,
                    trigger: 'blur',
                },
            },
            Modal_CreateBrand: false, //新增弹窗
            columns: [],
            data6: [],
        };
    },
    watch: {
        Modal_CreateBrand(value) {
            if (!value) {
                this.handleReset('form');
            }
        },
    },
    mounted() {
        this.flash();
    },
    methods: {
        /**
         * 表单重制
         */
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        /**
         * 点击编辑
         */
        async handleEidt(row) {
            this.modalTitle = '编辑品牌';
            this.current_row_click = row;
            await this.getEidtData(row.uid);
            this.Modal_CreateBrand = true;
        },
        /**
         * 点击新增
         */
        handleCreate() {
            this.modalTitle = '新增品牌';
            this.Modal_CreateBrand = true;
        },
        /**
         * 点击编辑按后钮获取数据
         */
        async getEidtData(branduid) {
            let { data: res } = await Get_BrandDate({
                branduid,
            });
            this.form = res;
        },
        /**
         * 接入模式切换
         */
        changeSelect(value) {
            switch (value) {
                case '云开发':
                    delete this.form.sid;
                    delete this.form.apikey;
                    break;
                default:
                    delete this.form.envId;
                    delete this.form.authuid;
                    break;
            }
        },
        /**
         * 提交表单
         */
        async handleSubmit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.form));
                    let pass_data = Object.assign(data, {
                        dueToTime: new Date(this.form.dueToTime).getTime() + '',
                    });
                    let res_data = '';
                    if (this.modalTitle == '新增品牌') {
                        res_data = await CreateBrand(pass_data);
                    }
                    if (this.modalTitle == '编辑品牌') {
                        pass_data['branduid'] = pass_data['uid'];
                        res_data = await Set_BrandDate(pass_data);
                    }
                    if (res_data.code == 200) {
                        this.Modal_CreateBrand = false;
                        this.flash();
                        this.$mytoast(this.modalTitle == '新增品牌' ? '新增成功' : '编辑成功');
                    }
                }
            });
        },
        // 任命/撤销
        async appointed_to_cancel(row, type) {
            let pass_data;

            if (type == '任命') {
                pass_data = {
                    branduid: this.current_row_click.uid, //品牌uid
                    useruid: row.uid, //用户UID
                    type: true, //true为任任命false为罢免
                };
            }

            if (type == '罢免') {
                pass_data = {
                    branduid: this.current_row_click.uid, //品牌uid
                    useruid: row.uid, //用户UID
                    type: false, //true为任任命false为罢免
                };
            }

            let res_data = await BrandAdmin_appoint(pass_data);

            if (res_data.code == 200) {
                this.$mytoast('修改成功');
                this.Modal_appointed_administrator = false;
            }
        },
        async show_Modal_appointed_administrator(row) {
            // 重置数据
            this.search_keyword = '';
            this.list_up = [];
            this.title_up = [];
            this.list_bottom = [];
            this.title_bottom = [];
            // 重置数据

            this.current_row_click = row;

            let pass_data = {
                branduid: this.current_row_click.uid,
            };

            let { data: res_data } = await Get_BrandAdmin(pass_data);
            res_data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
            });
            this.title_bottom = res_data.title;
            this.list_bottom = res_data.list;
            this.Modal_appointed_administrator = true;
        },
        // 执行搜索
        async do_search() {
            if (this.search_keyword == '') {
                this.$mytoast_err('请填写信息再提交');
                return;
            }
            let pass_data = {
                keyword: this.search_keyword,
                branduid: this.current_row_click.uid,
            };
            let { data: res_data } = await searchUser(pass_data);
            res_data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
            });
            this.title_up = res_data.title;
            this.list_up = res_data.list;
        },
        // 页面数据获取
        async flash() {
            let res_data = await Get_BrandList();
            res_data.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
            });
            res_data.data.title.forEach((item) => {
                if (item.key == 'logurl') {
                    item['slot'] = 'logurl';
                }
            });
            this.columns = res_data.data.title;
            this.data6 = res_data.data.list;
        },
    },
};
</script>
