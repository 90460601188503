<template>
  <div class="error404">
    <div class="error404-body-con">
      <el-card class="box-card">
        <div class="error404-body-con-title">4
        <span>0</span>4</div>
        <p class="error404-body-con-message">YOU&nbsp;&nbsp;LOOK&nbsp;&nbsp;LOST</p>
        <div class="error404-btn-con">
          <el-button size="large" style="width: 200px;cursor: pointer;" type="text" @click="goHome">返回登录页</el-button>
          <el-button size="large" style="width: 200px;margin-left: 40px;cursor: pointer;" type="primary" @click="backPage">返回上一页</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods: {
    backPage() {
      this.$router.go(-1)
    },
    goHome() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>
<style  scoped>
@keyframes error404animation {
  0% {
    transform: rotateZ(0deg);
  }
  20% {
    transform: rotateZ(-60deg);
  }
  40% {
    transform: rotateZ(-10deg);
  }
  60% {
    transform: rotateZ(50deg);
  }
  80% {
    transform: rotateZ(-20deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
.error404-body-con{
	width: 700px;
	height: 500px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.error404-body-con-title{
	text-align: center;
	font-size: 240px;
	font-weight: 700;
	color: #2d8cf0;
	height: 260px;
	line-height: 260px;
	margin-top: 40px;
}
.error404-body-con-title span{
	display: inline-block;
	color: #19be6b;
	font-size: 230px;
	animation: error404animation 3s ease 0s infinite alternate;
}
.error404-body-con-message{
	display: block;
	text-align: center;
	font-size: 30px;
	font-weight: 500;
	letter-spacing: 12px;
	color: #dddde2;
}
.error404-btn-con{
	text-align: center;
	padding: 20px 0;
	margin-bottom: 40px;
}

</style>
