import csvtojsonV2 from "csvtojson"
import { read, writeFileXLSX,utils } from "xlsx";
// 转base64编码
function base64(str) {
    return window.btoa(unescape(encodeURIComponent(str)))
  }
  // 生成xls
function createXls({ headers, data, worksheet }) {
    let str = '<tr>'
    for(let item in headers) {
      str += `<td>${headers[item]}</td>`
    }
    str += '</tr>'
    for(let i = 0; i < data.length; i++) { 
      str += '<tr>'
      for(let item in data[i]) {
        str += `<td style="mso-number-format:'\@';">${ data[i][item] + '\t'}</td>`
      }
      str += '</tr>'
    }
    const template = 
    `<html
      xmlns:o="urn:schemas-microsoft-com:office:office" 
      xmlns:x="urn:schemas-microsoft-com:office:excel" 
      xmlns="http://www.w3.org/TR/REC-html40"
    >
      <head>
        <meta charset="utf-8">
        <!--[if gte mso 9]>
          <xml>
            <x:ExcelWorkbook>
              <x:ExcelWorksheets>
                <x:ExcelWorksheet>
                  <x:Name>${worksheet}</x:Name>
                  <x:WorksheetOptions>
                    <x:DisplayGridlines/>
                  </x:WorksheetOptions>
                </x:ExcelWorksheet>
              </x:ExcelWorksheets>
            </x:ExcelWorkbook>
          </xml>
        <![endif]-->
      </head>
      <body>
        <table>${str}</table>
      </body>
    </html>`
    const uri = 'data:application/vnd.ms-excel;base64,'
    window.location.href = uri + base64(template)
}
  
  // 生成csv
function createCsv({ headers, data, worksheet }) {
	let title_key  = headers.map(item=>item.key)
    let str = ''
    for(let item in headers) {
      str += `${headers[item].title},`
    }
    str += '\n'
    for(let i = 0; i < data.length; i++ ) {
		let center = 0;
      for(let item in data[i]) {
		 if(title_key.length-1<center){
			 continue
		 }
        str += `${data[i][title_key[center]]}\t,`
		center++;
      }
      str += '\n'
    }
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str)
    let link = document.createElement('a')
    link.href = uri
    link.download = `${worksheet}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * 读取csv文件到网页
 * @param {*} file 上传的file格式文件
 * @param {*} options csv2json 的配置 默认配置header组和value组，可传入{}返回key value
 */

export async function readCsv2json(file,options={
         noheader:true,
					output: "csv",
				}){
          let data = await file.text()
          let res = await csvtojsonV2(options).fromString(data)
          let header = []
          if(options!={}){
            header = res.splice(0,1)
          }
          let list = res
          return {
            header,list
          }
}
/**
 * xls转json
 * @param {*} file 上传的文件
 */
export async function readXls2Json(file){
  const data = await file.arrayBuffer();
  /* data is an ArrayBufw b */
  const wb = read(data,{cellDates: true});
  const ws = wb.Sheets[wb.SheetNames[0]];
  console.log(ws)
  let tableData = utils.sheet_to_json(ws)
  tableData.shift()
  return tableData
}

  // 下载excel
export function download({worksheet=new Date(),headers,data,type='csv'}) {
    
  
    switch (type) {
      case 'xls':
        createXls({ headers, data, worksheet })
        break
      case 'csv':
        createCsv({ headers, data, worksheet })
        break
    }
}

// ---------表数据格式----------
// const data = [
//     {
//       sku: 2801010031508,
//       title: 'HUAWEI MateBook X Pro',
//       price: 9999
//     },
//     {
//       sku: 2801010028201,
//       title: 'HUAWEI MateBook 14',
//       price: 5888
//     },
//     {
//       sku: 2101010000413,
//       title: 'HUAWEI MateBook D 15',
//       price: 4099
//     }
//   ] // 模拟数据

// -------------------表头数据格式---------------
 // let  headers = [{key:'sku',title:"sku"}, {key:'price',title:"价格"}]
 
//   download({
//       'worksheet':'',
//       headers,
//       data
//   })