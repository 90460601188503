<template>
    <div>
        <Card style="width: 1050px">
            <Tabs ref="tabs" @on-click="tabClick" type="card" v-model="tabIndex">
                <TabPane name="会员权益" label="会员权益">
                    <div class="mb-5 text-right">
                        <Button @click="() => add()" type="primary">新建</Button>
                    </div>
                    <Table size="small" :columns="tableData.title" :data="tableData.list">
                        <template slot-scope="{ row, index }" slot="url">
                            <div class="p-2">
                                <img :src="row.url" class="w-48 h-24" />
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="operate">
                            <Button size="small" @click="() => edit(row)" type="primary" class="mr-2">编辑</Button>
                            <Poptip transfer confirm title="确定是否删除？" @on-ok="() => remove(row)">
                                <Button size="small" type="error">删除</Button>
                            </Poptip>
                        </template>
                    </Table>
                    <Card class="text-right">
                        <Page @on-change="(value) => fetchData(value)" :total="tableData.total" :pageSize="tableData.size"></Page>
                    </Card>
                </TabPane>
                <TabPane name="会员策略(草稿箱)" label="会员策略(草稿箱)">
                    <div class="mb-5 text-right">
                        <Button @click="popMemberPolicy('新建')" type="primary">新建</Button>
                    </div>

                    <Table height="500" size="small" :columns="tablePolicy.title" :data="tablePolicy.list">
                        <template slot-scope="{ row, index }" slot="operate">
                            <Button size="small" @click="() => popMemberPolicy('编辑', index)" type="primary" class="mr-2">编辑</Button>
                            <Poptip transfer confirm title="确定是否删除？" @on-ok="() => removeMemberPolicy(row, index)">
                                <Button size="small" type="error">删除</Button>
                            </Poptip>
                        </template>
                        <template slot-scope="{ row, index }" slot="benefitPoint">
                            <div>{{ row.benefitPoint.cashOffset }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="benefitPrice">
                            <div style="display: flex">
                                <div>购票积分:</div>
                                <div>{{ row.benefitPrice.ticket }}</div>
                            </div>
                            <div style="display: flex">
                                <div>卖品积分:</div>
                                <div>{{ row.benefitPrice.good }}</div>
                            </div>
                            <div style="display: flex">
                                <div>商城积分:</div>
                                <div>{{ row.benefitPrice.mall }}</div>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="mberPoint">
                            <div style="display: flex">
                                <div>电影票:</div>
                                <div>{{ row.mberPoint.ticket }}</div>
                            </div>
                            <div style="display: flex">
                                <div>卖品:</div>
                                <div>{{ row.mberPoint.good }}</div>
                            </div>
                            <div style="display: flex">
                                <div>商城:</div>
                                <div>{{ row.mberPoint.mall }}</div>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="benefitIncr">
                            <div v-for="(item, index) in row.benefitIncr">{{ policyConfig.filter((item1) => item1.value == item)[0].label }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="ensureCond">
                            <div v-for="(item1, index1) in row.ensureCond">
                                <div v-if="index1 > 0">或</div>
                                <div style="border: 1px solid #ccc; padding: 10px">
                                    <div v-for="(item2, index2) in item1">
                                        <span style="color: red" v-if="index2 > 0">且</span>{{ ruleConfig[item2.key] }}达到{{ item2.value }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="upgradeCond">
                            <div v-for="(item1, index1) in row.upgradeCond">
                                <div v-if="index1 > 0">或</div>
                                <div style="border: 1px solid #ccc; padding: 10px">
                                    <div v-for="(item2, index2) in item1">
                                        <span style="color: red" v-if="index2 > 0">且</span>{{ ruleConfig[item2.key] }}达到{{ item2.value }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Table>
                    <div class="mt-5 mb-5 text-right">
                        <Button @click="submitMemberpolicy" type="primary">提交</Button>
                    </div>
                </TabPane>
            </Tabs>
        </Card>
        <Modal :title="modelTitle" :closable="false" :maskClosable="false" v-model="show">
            <Form class="w-2/3" :label-width="100" ref="form" :model="form" :rules="rulevalidate">
                <FormItem prop="name" label="标题">
                    <Input v-model="form.name" />
                </FormItem>
                <FormItem prop="describe" label="描述">
                    <Input type="textarea" :rows="4" v-model="form.describe" />
                </FormItem>
                <FormItem prop="url" label="权益图片:">
                    <div style="display: flex; align-items: center">
                        <img alt="无数据" style="width: 200px; height: 100px; margin-right: 10px" :src="form.url" />
                        <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="(value) => (form.url = value)" />
                    </div>
                </FormItem>
            </Form>
            <div class="flex justify-end" slot="footer">
                <Button @click="cancel">取消</Button>
                <Button @click="() => submitAdd()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal width="900" style="display: flex" :title="modelTitle" :closable="false" :maskClosable="false" v-model="showCaogao">
            <div style="display: flex">
                <div style="flex: 1; padding-right: 10px">
                    <Form ref="formPolicy" :label-width="100" :model="formPolicy">
                        <FormItem prop="alias" label="别名">
                            <Input v-model="formPolicy.alias" />
                        </FormItem>
                        <FormItem prop="describe" label="描述">
                            <Input type="textarea" :rows="4" v-model="formPolicy.describe" />
                        </FormItem>
                        <FormItem prop="level" label="等级">
                            <Input type="number" v-model.number="formPolicy.level" />
                        </FormItem>
                        <FormItem prop="termOfVali" label="有效值">
                            <Input type="number" v-model.number="formPolicy.termOfVali" />
                        </FormItem>

                        <FormItem prop="benefitIncr" label="积分抵现">
                            <Select multiple v-model="formPolicy.benefitIncr">
                                <Option v-for="(item, index) in policyConfig" :value="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="benefitPoint.cashOffset" label="积分抵现">
                            <Input v-model="formPolicy.benefitPoint.cashOffset" />
                        </FormItem>
                        <FormItem prop="benefitPrice.ticket" label="电影票">
                            <Input v-model="formPolicy.benefitPrice.ticket" />
                        </FormItem>
                        <FormItem prop="benefitPrice.good" label="卖品">
                            <Input v-model="formPolicy.benefitPrice.good" />
                        </FormItem>
                        <FormItem prop="benefitPrice.mall" label="商城">
                            <Input v-model="formPolicy.benefitPrice.mall" />
                        </FormItem>
                        <FormItem prop="mberPoint.ticket" label="购票积分">
                            <Input v-model="formPolicy.mberPoint.ticket" />
                        </FormItem>
                        <FormItem prop="mberPoint.good" label="卖品积分">
                            <Input v-model="formPolicy.mberPoint.good" />
                        </FormItem>
                        <FormItem prop="mberPoint.mall" label="商城积分">
                            <Input v-model="formPolicy.mberPoint.mall" />
                        </FormItem>
                    </Form>
                </div>
                <div style="flex: 1">
                    <Card title="保级条件">
                        <Card v-for="(item, index) in formPolicy.ensureCond" style="display: flex">
                            <Form inline v-for="(item1, index1) in formPolicy.ensureCond[index]">
                                <FormItem>
                                    <Select style="width: 100px" v-model="item1.key">
                                        <Option value="recharge">充值</Option>
                                        <Option value="ticketAmount">票数</Option>
                                        <Option value="consumpAmount">消费额</Option>
                                    </Select>
                                </FormItem>
                                <FormItem>
                                    <Input v-model="item1.value" />
                                </FormItem>
                                <FormItem>
                                    <Button @click="() => addrelegation(index, index1)" type="error">-</Button>
                                </FormItem>
                            </Form>
                            <Button
                                v-if="formPolicy.ensureCond[index].length < 3"
                                long
                                @click="() => item.push({ key: '', value: '' })"
                                style="margin-top: 10px"
                                type="primary"
                                >+</Button
                            >
                        </Card>
                        <Button @click="() => formPolicy.ensureCond.push([{ key: '', value: '' }])" style="margin-top: 10px" long type="primary">+</Button>
                    </Card>
                    <Card title="升级条件">
                        <Card v-for="(item, index) in formPolicy.upgradeCond" style="display: flex">
                            <Form inline v-for="(item1, index1) in formPolicy.upgradeCond[index]">
                                <FormItem>
                                    <Select style="width: 100px" v-model="item1.key">
                                        <Option value="recharge">充值</Option>
                                        <Option value="ticketAmount">票数</Option>
                                        <Option value="consumpAmount">消费额</Option>
                                    </Select>
                                </FormItem>
                                <FormItem>
                                    <Input v-model="item1.value" />
                                </FormItem>
                                <FormItem>
                                    <Button @click="() => addrelegation1(index, index1)" type="error">-</Button>
                                </FormItem>
                            </Form>
                            <Button
                                v-if="formPolicy.upgradeCond[index].length < 3"
                                @click="() => item.push({ key: '', value: '' })"
                                style="margin-top: 10px"
                                type="primary"
                                long
                                >+</Button
                            >
                        </Card>
                        <Button @click="() => formPolicy.upgradeCond.push([{ key: '', value: '' }])" style="margin-top: 10px" long type="primary">+</Button>
                    </Card>
                </div>
            </div>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => (showCaogao = false)">取消</Button>
                <Button @click="() => addMemberPolicy()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    deleteBenefit,
    findManyBenefitIncr,
    editOrcreate,
    saveMembStraPlan,
    queryMembStradraft,
    OperationChart,
    getupTK,
    editOrCreate,
    queryBenefitItem,
    currentVersion,
} from '@/request/api';
import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue';
export default {
    name: 'affiliate_policy',
    components: {
        qiniuUploadBtn,
    },
    data() {
        return {
            tabIndex: '',
            modelTitle: '',
            show: false,
            tableData: '',
            form: {
                name: '',
                url: '',
                describe: '',
            },
            tablePolicy: {},
            rulevalidate: {},
            qiniu_config: {},
            showCaogao: false,
            formPolicy: {
                alias: '',
                benefitIncr: [],
                benefitPoint: { cashOffset: '' },
                benefitPrice: { ticket: '', good: '', mall: '' },
                brandId: '',
                createdAt: '',
                delete: true,
                describe: '',
                ensureCond: [],
                level: null,
                mberPoint: { ticket: '', good: '', mall: '' },
                mcode: '',
                termOfVali: null,
                type: '',
                updatedAt: '',
                upgradeCond: [],
                id: '',
                downgradeId: '67061e6b4e87831a4f0df916',
            },
            formPolicyNeed: {
                brandId: '8de7d7fc-6256-4dbc-afca-6704cca9f3aa',
                mcode: '44001631',
                type: '草稿',
            },
            editIndex: '', //草稿箱的编辑表格的当前索引
            ruleConfig: {
                recharge: '会员充值',
                ticketAmount: '购票数量',
                consumpAmount: '消费金额',
            },
            // formPolicy: {
            //     alias: 'LV1',
            //     benefitIncr: ['66f78bbc587982944b6364cb', '66f78bbc587982944b6364bj'],
            //     benefitPoint: { cashOffset: '0.05' },
            //     benefitPrice: { ticket: '1.2', good: '1.3', mall: '1.3' },
            //     brandId: '8de7d7fc-6256-4dbc-afca-6704cca9f3aa',
            //     createdAt: '2024-10-18T09:49:21.908Z',
            //     delete: true,
            //     describe: '11',
            //     ensureCond: [[{ key: 'recharge', value: '23.2' }]],
            //     level: 1,
            //     mberPoint: { ticket: '1.2', good: '1.3', mall: '1.3' },
            //     mcode: '44001631',
            //     termOfVali: 3,
            //     type: '草稿',
            //     updatedAt: '2024-10-18T10:08:54.356Z',
            //     upgradeCond: [[{ key: 'recharge', value: '300' }]],
            //     id: '67061e6b4e87831a4f0df915',
            // },

            policyConfig: [], //权益配置项
        };
    },
    created() {
        this.fetchData();
        this.to_getupTK();
        this.handleGetCurrentVersion();
    },
    watch: {
        show(value) {
            if (!value) {
                this.resetFeild('form');
            }
        },
        showCaogao(value) {
            if (!value) {
                this.formPolicy.ensureCond = [];
                this.formPolicy.upgradeCond = [];
                this.resetFeild('formPolicy');
            }
        },
    },
    methods: {
        /**
         * 查询当前版本
         */
        async handleGetCurrentVersion() {
            let res = await currentVersion({});
            if (res.errcode != 0) return;
        },
        addrelegation1(index, index1) {
            let list = this.formPolicy.upgradeCond[index].filter((item2, index2) => index2 != index1);
            this.formPolicy.upgradeCond[index] = list;
            this.formPolicy.upgradeCond = JSON.parse(JSON.stringify(this.formPolicy.upgradeCond.filter((item) => item.length > 0)));
        },
        /**
         * 添加保级条件
         */
        addrelegation(index, index1) {
            let list = this.formPolicy.ensureCond[index].filter((item2, index2) => index2 != index1);
            this.formPolicy.ensureCond[index] = list;
            this.formPolicy.ensureCond = JSON.parse(JSON.stringify(this.formPolicy.ensureCond.filter((item) => item.length > 0)));
        },
        /**
         * 获取权益配置项
         */
        async handlequeryBenefitItem() {
            let res = await queryBenefitItem({});
            if (res.errcode != 0) return;
            this.policyConfig = res.data;
        },
        /**
         * 删除会员权益按钮
         */
        async remove(value) {
            const { id } = value;
            await deleteBenefit({
                id,
            });
            this.fetchData(this.form.page);
        },
        /**
         * 弹出草稿箱的弹窗
         */
        popMemberPolicy(title, index) {
            if (title == '新建') {
            }
            if (title == '编辑') {
                this.formPolicy = JSON.parse(JSON.stringify(this.tablePolicy.list[index]));
                this.editIndex = index;
            }
            this.modelTitle = title;

            this.showCaogao = true;
        },
        /**
         * 移除草稿箱的单项
         */
        removeMemberPolicy(row, rowIndex) {
            let list = this.tablePolicy.list.filter((item, index) => index != rowIndex);
            this.tablePolicy.list = list;
        },
        /**
         * 会员策略(草稿箱)新建
         */
        addMemberPolicy() {
            //判断升级条件｜保级条件必须要有一项数据存在
            if (
                this.formPolicy.ensureCond.length > 0 &&
                this.formPolicy.ensureCond[0].length > 0 &&
                this.formPolicy.upgradeCond.length > 0 &&
                this.formPolicy.upgradeCond[0].length > 0
            ) {
                //判断保级条件和升级条件里面的条件是否唯一
                for (let i = 0; i < this.formPolicy.ensureCond.length; i++) {
                    let item = this.formPolicy.ensureCond[i];
                    let len1 = item.length;
                    let len2 = Array.from(new Set(item.map((item) => item.key))).length;
                    if (len1 != len2) {
                        //条件不唯一
                        this.$Message.warning('升级条件必须唯一');
                        return;
                    }
                }
                for (let i = 0; i < this.formPolicy.upgradeCond.length; i++) {
                    let item = this.formPolicy.upgradeCond[i];
                    let len1 = item.length;
                    let len2 = Array.from(new Set(item.map((item) => item.key))).length;
                    if (len1 != len2) {
                        //条件不唯一
                        this.$Message.warning('保级条件必须唯一');
                        return;
                    }
                }
                let data = { ...this.formPolicy };
                delete data.id;
                let rowData = {
                    ...data,
                };
                if (this.modelTitle == '新建') {
                    Object.assign(rowData, this.formPolicyNeed);
                    this.tablePolicy.list.push(rowData);
                    this.tablePolicy.list = JSON.parse(JSON.stringify(this.tablePolicy.list));
                }
                if (this.modelTitle == '编辑') {
                    this.tablePolicy.list[this.editIndex] = rowData;
                    this.tablePolicy.list = JSON.parse(JSON.stringify(this.tablePolicy.list));
                }
                this.showCaogao = false;
            } else {
                this.$Message.warning('升级条件和保级条件必须填写数据');
                return;
            }
        },
        /**
         * 会员策略(草稿箱)提交表格表单
         */
        async submitMemberpolicy() {
            let list = this.tablePolicy.list.map((item) => {
                let data = item;
                delete data.updatedAt;
                delete data.createdAt;
                return data;
            });
            let passdata = { item: list };

            let res = await saveMembStraPlan(passdata);
            if (res.errcode != 0) return;
            this.$Message.success('保存成功');
            this.handleDataQueryMembStradraft();
        },
        /**
         * 编辑 按钮
         */
        async edit(value) {
            this.form = JSON.parse(JSON.stringify(value));
            this.show = true;
            this.modelTitle = '编辑';
        },
        // 获取七牛云token
        async to_getupTK() {
            let { data: res_data } = await getupTK();
            this.qiniu_config = res_data.data;
        },
        async fetchData(page = 1) {
            let res = await findManyBenefitIncr({
                brandId: '123456789123456789123456',
                pagedata: {
                    page: page,
                    limit: 10,
                },
            });
            if (res.errcode != 0) return;
            res.data.title = [
                {
                    title: '标题',
                    key: 'name',
                },
                {
                    title: '描述',
                    key: 'describe',
                },
                {
                    title: '权益图片',
                    key: 'url',
                    slot: 'url',
                },
                {
                    title: '操作',
                    key: 'operate',
                    slot: 'operate',
                },
            ];

            this.tableData = res.data;
        },
        /**
         * 新增 按钮
         */
        add() {
            this.show = true;
            this.modelTitle = '新建';
        },
        /**
         * 弹窗 新建提交
         */
        async submitAdd() {
            let passdata = {
                name: this.form.name,
                describe: this.form.describe,
                url: this.form.url,
                brandId: '123456789123456789123456',
            };
            if (this.modelTitle == '新建') {
            }
            if (this.modelTitle == '编辑') {
                passdata.id = this.form.id;
            }
            let res = await editOrCreate(passdata);
            if (res.errcode != 0) return;
            this.show = false;
            this.fetchData();
        },
        /**
         * 弹窗取消 按钮
         */
        cancel() {
            this.resetFeild('form');
            this.show = false;
        },
        /**
         * 重置表单验证
         */
        resetFeild(name) {
            this.$refs[name].resetFields();
        },
        /**
         * 获取一行数据
         */
        async getRowData(value) {
            const { id } = value;
            let res = await OperationChart({
                exeType: '获取数据',
                id,
            });
            if (res.errcode != 0) return;
            this.form = res.data;
        },
        /**
         * 标签页切换
         */
        async tabClick(value) {
            if (value == '会员权益') {
            }
            if (value == '会员策略(草稿箱)') {
                this.handlequeryBenefitItem();
                this.handleDataQueryMembStradraft();
            }
        },

        /**
         * 获取草稿箱的数据
         */
        async handleDataQueryMembStradraft() {
            let res = await queryMembStradraft({});
            if (res.errcode != 0) return;
            let list = [];
            let title = [];
            list = res.data;
            list = res.data.map((item) => {
                let data = item;
                delete data.updatedAt;
                delete data.createdAt;
                return data;
            });
            title = [
                { title: '等级名称', key: 'alias' },
                { title: '权益id', key: 'benefitIncr', slot: 'benefitIncr' },
                { title: '积分抵现', key: 'benefitPoint', slot: 'benefitPoint' },
                { title: '价格权益', key: 'benefitPrice', slot: 'benefitPrice', width: 100 },
                { title: '描述', key: 'describe' },
                { title: '保级条件', key: 'ensureCond', slot: 'ensureCond', width: 150 },
                { title: '级别', key: 'level' },
                { title: '会员积分', key: 'mberPoint', slot: 'mberPoint', width: 100 },
                { title: '有效期', key: 'termOfVali' },
                { title: '升级条件', key: 'upgradeCond', slot: 'upgradeCond', width: 150 },
                {
                    title: '操作',
                    key: 'operate',
                    slot: 'operate',
                    width: 150,
                },
            ];
            this.tablePolicy = {
                title,
                list,
            };
        },
    },
};
</script>
