<template>
    <div>
        <div style="margin: 10px 0; text-align: right">
            <Button @click="() => (modal_add = true)" type="primary">添加角色</Button>
        </div>
        <!-- 表格 -->
        <Table stripe :columns="columns1" :data="role_list">
            <!-- 操作表头 -->
            <template slot-scope="{ row, index }" slot="operation">
                <div>
                    <Button @click="(pass_data) => edit(row)" size="small" type="info"> 编辑 </Button>
                    <Button @click="() => edit_del(row)" style="margin-left: 10px" size="small" type="error">删除</Button>
                </div>
            </template>
        </Table>
        <Modal v-model="modal_edit" :title="'角色权限管理-编辑-' + current_edit_row.name">
            <div style="display: flex; justify-content: space-between">
                <div slot="left" class="demo-split-pane">
                    <Card>
                        <Tree @on-select-change="(payload) => show_permissions_button(payload)" ref="edit_select_tree" :data="data2" show-checkbox></Tree>
                    </Card>
                </div>
                <div slot="right" class="demo-split-pane" style="width: 100%">
                    <Card>
                        <div>{{ permissions_button_data.pagetitle }}</div>
                        <Tree ref="treeRef_btn" :data="permissions_button_data.Buttonlist" show-checkbox></Tree>
                        <div style="text-align: right">
                            <Button @click="do_button_permission_to_submit()" type="success">应用</Button>
                        </div>
                    </Card>
                </div>
            </div>
            <div slot="footer" style="text-align: right">
                <Button @click="() => (modal_edit = false)">退出</Button>
                <Button @click="do_edit_role()" type="primary">提交</Button>
            </div>
        </Modal>
        <Modal v-model="modal_add" :title="'角色权限管理-添加角色'">
            <div>
                <span style="width: 100px; margin: 10px">角色名称</span>
                <Input v-model="role_name" style="width: 300px" placeholder="请输入角色名称"></Input>
            </div>
            <div slot="footer">
                <Button @click="() => (modal_add = false)">退出</Button>
                <Button @click="() => do_add_role()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
var branduid;
import {
    SetRoleButDate,
    GetPageButton,
    GetrolePageButton,
    DelRole,
    SetRoleMenu,
    GetRoleMenu,
    CreateRoles,
    GetRoles,
    update_menu,
    current_role_menu,
    quanbu_background_menu,
    role_management,
} from '../../request/api.js';

export default {
    name: 'navbar',
    data() {
        return {
            permissions_button_data: '', //当前选中的权限按钮
            modal_edit: false, //编辑对话框
            role_name: '', //输入框-角色名称
            modal_add: false, //添加角色弹窗
            role_list: [], //角色列表
            current_edit_list: [],
            current_edit_id: '', //当前编辑的id
            current_edit_row: '',
            modal1: false, //编辑对话框
            columns1: [
                {
                    title: 'id',
                    key: 'rolesuid',
                },
                {
                    title: '角色名称',
                    key: 'name',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                },
            ],
            data2: [],
        };
    },
    async mounted() {
        branduid = JSON.parse(localStorage.getItem('选中品牌1')).brand.uid;
        this.get_role_list();
    },
    methods: {
        // 按钮权限提交
        async do_button_permission_to_submit() {
            let butlist = await this.$refs.treeRef_btn.getCheckedNodes();
            butlist = butlist.map((item) => item.Butuid);
            let pass_data = {
                roleuid: this.current_edit_row.rolesuid,
                pageuid: this.permissions_button_data.pageuid,
                butlist: butlist,
            };

            let res_data = await SetRoleButDate(pass_data);

            if (res_data.code == 200) {
                // 回显按钮权限列表
                this.show_permissions_button(this.show_permissions_button_payload);
                this.$mytoast(res_data.msg);
            }
        },

        // 权限菜单页面点击 获取 页面按钮权限
        async show_permissions_button(payload) {
            this.show_permissions_button_payload = payload;
            this.routes_select_btn = payload[0].uid;
            // 非品牌
            if (!payload[0].hasOwnProperty('children')) {
                let pass_data = {
                    roleuid: this.current_edit_row.rolesuid,
                    pageuid: payload[0].uid,
                };

                let res_data = await GetrolePageButton(pass_data);

                if (res_data.code == 200) {
                    this.permissions_button_data = res_data.data.page ? res_data.data.page : '';
                }
            }
        },

        // 编辑角色删除
        async edit_del(row) {
            let pass_data = {
                branduid: branduid,
                roleuid: row.rolesuid,
            };

            let res_data = await DelRole(pass_data);

            if (res_data.code == 200) {
                let res = await this.get_role_list();
                if (res.code == 200) {
                    this.$mytoast('删除成功');
                }
            }
        },

        // 保存编辑的角色权限
        async do_edit_role() {
            let pagelist = this.$refs.edit_select_tree.getCheckedNodes();
            pagelist = pagelist.map((item) => item.uid);
            let pass_data = {
                roleuid: this.current_edit_row.rolesuid,
                pagelist: pagelist,
            };

            let res_data = await SetRoleMenu(pass_data);

            if (res_data.code == 200) {
                this.$mytoast('编辑成功');
                this.modal_edit = false;
            }
        },

        // 获取编辑的角色权限菜单数组
        async edit(row) {
            this.permissions_button_data = '';
            let pass_data = {
                roleuid: row.rolesuid,
            };

            let res_data = await GetRoleMenu(pass_data);

            this.current_edit_row = res_data.data;
            this.data2 = res_data.data.page;
            this.modal_edit = true;
        },

        // 获取角色列表
        async get_role_list() {
            let pass_data = {
                branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
            };

            let res_data = await GetRoles(pass_data);

            this.role_list = res_data.data;
        },

        // 保存添加的角色权限
        async do_add_role() {
            if (this.role_name == '') {
                this.$mytoast_err('请输入角色名称');
                return;
            }
            let pass_data = {
                branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid, //品牌Uid
                name: this.role_name, //角色名称
            };

            let res_data = await CreateRoles(pass_data);

            if (res_data.code == 200) {
                await this.get_role_list();
                this.modal_add = false;
            }
        },
    },
};
</script>

<style>
.demo-split {
    height: 1000px;
    border: 1px solid #dcdee2;
}

.demo-split-pane {
    padding: 10px;
}

.ivu-modal-body p {
    margin: 20px 0;
}
</style>
