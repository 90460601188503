<template>
    <div class=" p-10">
        <h1>抖音团购核销详情</h1>
        <Form  style="width: 600px;" :label-width="100">
            <Row>
                <Col :span="20">
                <FormItem label="团购订单号">
                    <Input disabled v-model="payCodeDetail.order_id" />
                </FormItem>
                </Col>
                <Col :span="4">
                <FormItem :label-width="10">
                    <Tag v-if="payCodeDetail.revoke">已撤销</Tag>
                    <Tag v-else color="cyan">已核销</Tag>
                </FormItem>
                </Col>
            </Row>
            <FormItem label="商品名称">
                <Input disabled v-model="payCodeDetail.sku_name" />
            </FormItem>
            <FormItem label="团购码">
                        <Input disabled v-model="payCodeDetail.origin_code" />
            </FormItem>
            <FormItem label="核销用户串码">
                <Row>
                    <Col :span="21">
                        <Input disabled v-model="payCodeDetail.openid" />
                     </Col>
                     <Col class=" text-right"  :span="3">
                        <Button :disabled="false" @click="()=>Mclip(payCodeDetail.openid)">复制</Button>
                    </Col>
                </Row>
                
            </FormItem>
            <FormItem label="核销时间">
                <Input disabled v-model="payCodeDetail.time" />
            </FormItem>
            <FormItem v-if="payCodeDetail.revoke" label="撤销原因">
                <Input disabled v-model="payCodeDetail.revoke_note" />
            </FormItem>
            <FormItem v-if="payCodeDetail.revoke" label="撤销时间">
                <Input disabled v-model="payCodeDetail.revoke_time" />
            </FormItem>

            <FormItem v-if="payCodeDetail.revoke" label="撤销人">
                <Input disabled v-model="payCodeDetail.revoke_name" />
            </FormItem>
        
        </Form>
        <div class=" text-right p-2">
            <Button type="primary" @click="exportTableData">导出</Button>
        </div>
        <Table :columns="tableData.title" :data="tableData.list"></Table>
        <Card >
            <div class="flex justify-between">
                <div  class=" text-red-500">{{payCodeDetail.revoke?payCodeDetail.isRelieve_res:""}}</div>
                <Page :page-size="tableData.size" :total="tableData.total" @on-change="fetchTableData"></Page>
            </div>
        </Card>
    </div>
</template>
<script>
import { GetGoosCashRecordInfo } from "@/request/api"
import { download } from "@/utils/table_download.js"
import { FormItem } from "view-design"
export default {
    name: "payCodeDetail",
    data() {
        return {
            id: "",
            payCodeDetail: "",
            tableData: {},
        };
    },
    created() {
        const { id } = this.$route.query;
        this.id = id;
        this.fetchTableData();
    },
    methods: {
        /**
         * 导出表格数据
         */
        async exportTableData() {
            let res = await GetGoosCashRecordInfo({
                id: this.id,
                page: 1,
                all: true
            });
            if (res.errcode != 0) return
            const { list, title } = res.data.givList;
            download({
                worksheet: "核销详情",
                headers: title,
                data: list
            })

        },
        /**
         * 获取核销记录详情
         */
        async fetchTableData(page = 1) {
            let res = await GetGoosCashRecordInfo({
                id: this.id,
                page,
                all: false
            });
            if (res.errcode != 0)
                return;
            this.payCodeDetail = res.data;
            res.data["time"] = new Date(res.data['time']).Format("yyyy-MM-dd hh:mm")
            this.tableData = res.data.givList;
        }
    },
    components: { FormItem }
}
</script>