<template>
	<div>
		<div style="display: flex;justify-content: end;">
			<!-- 线下订单统计查询 -->
			<div>
				<DatePicker v-model='value' type="date" placeholder="请选择查询日期" style="width: 200px"></DatePicker>
			</div>
			<!-- 查询   -->
			<div style="margin:0 10px ; ">
				<Button @click='order_get()' type='primary'>查询</Button>
			</div>
			<Button v-if="total" type="primary" size="mini" @click="exportData()">
				<Icon type="ios-download-outline"></Icon> 导出全部数据
			</Button>
		</div>
		<Table ref="table" stripe style="margin-top: 10px;" :columns="title" :data="list"></Table>
		<!-- 表格分页部分 -->
		<Card style='text-align: right;'>
			<Page :page-size="size" :total="total" @on-change='($)=>order_get($)' />
		</Card>
	</div>
</template>

<script>
	let mcode;
	import {
		download
	} from '@/utils/table_download.js'
	import {
		offlinePaymentOrders
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {

			return {
				title: [], //查询日期的表头
				list: [], //查询日期的数据集
				total: 0, //查询日期的总记录数
				size: 0, //查询日期的当前页最大记录数
				value: new Date(), //当前查询日期初始化 xxxx-xx-xx 当天日期
			}

		},
		mounted() {
			mcode = localStorage.getItem('mcode');
			// 获取查询日期商品销售明细表格数据
			this.order_get()

		},
		methods: {
			// 导出查询日期的全部数据
			async exportData() {

				let data = this.list
				let headers = this.title
				download({
					'worksheet': '线下订单统计',
					headers,
					data
				})
			},

			// 获取查询日期商品销售明细表格数据
			async order_get(page = 1) {
				// 表格初始化
				this.title = [];
				this.list = [];
				let day = this.$formatDate(Date.parse(this.value), "yyyy-MM-dd");
				let pass_data = {
					"mcode": mcode,
					'page': page,
					"size": 30,
					'day': day,
				}
				let res = await offlinePaymentOrders(pass_data)
				this.title = res.data.title; //查询日期的当前页表头
				this.list = res.data.datalist; //查询日期的当前页数据集
				this.total = res.data.total; //查询日期的总记录数
				this.size = res.data.size; //查询日期的最大数据集
			},

		},

	}
</script>
