<template>
	<div>
		<div style="text-align: right;margin-bottom: 10px;">
			<Button @click="()=>table_dowload_card('同步数据')"  type="primary">同步数据</Button>
			<Button  @click="()=>table_dowload_card()" style="margin-left: 10px;">导出数据</Button>
		</div>
		<Table :columns="title" :data="list">
			<template slot-scope="{row}" slot="operation" > 
				<Button @click="()=>Modifythesort(row)">修改排序</Button>
			</template>
			<template slot-scope="{row}" slot="show" >
				<i-Switch @on-change="()=>table_dowload_card('修改可视',row.code)" v-model="row.show"></i-Switch>
			</template>
		</Table>
		 <Card style="text-align: right;">
			 <Page @on-change="($)=>fetchData($)" :total="total" :pageSize="pageSize"/>
		 </Card>
		 <Modal :title="`${currentRow.name}`" :closable='false' :mask-closable='false' v-model="sortModal">
			 <Form :label-width="80">
			 	<FormItem label="排序">
			 		 <Input style="width: 300px;" v-model="currentRow.weight"></Input>
			 	</FormItem>
			</Form>
			 <div slot="footer">
				 <Button @click="()=>sortModal=false">取消</Button>
				 <Button @click="()=>table_dowload_card('修改排序',currentRow.code,currentRow.weight)" type="primary">确定</Button>
			 </div>
		 </Modal>
	</div>
</template>

<script>
	import { download } from '@/utils/table_download.js'
	import {PayType} from "@/request/api" 
	export default{
		name:"paytype",
		data(){
			return{
				currentRow:"",
				sortModal:false,
				list:[],
				title:[],
				total:0,
				pageSize:0
			}
		},
		async created() {
			this.fetchData()
		
		},
		methods:{
		   Modifythesort(row){
				this.currentRow=JSON.parse(JSON.stringify(row))
				this.sortModal=true
		   },
		   async table_dowload_card(type="获取数据",typecode,weight){
				let config =['修改登录','修改授权','修改密码']
				let pass_data={
					weight,
					typecode,
					"type": type, //['获取数据','同步数据','修改登录','修改授权','重置密码','修改密码']
					"all": true, //是否全部导出【获取数据】
				}
				let res =  await PayType(pass_data)
				if(res.errcode!=0) return
				if(config.indexOf(type)!=-1){
					this.$mytoast("操作成功")
				}
				if(type=="获取数据"){
					let headers = res.data.title
					let data  = res.data.list
					download({
					  'worksheet':'交易类型',
					  headers,
					  data
					})
				}
				if(type=='同步数据'||type=='修改排序'){
					this.sortModal=false
					this.fetchData()
				}
				
				
				
			},
		   
		   async fetchData(page=1){
				let pass_data={
					"type": "获取数据", //['获取数据','同步数据','修改登录','修改授权','重置密码','修改密码']
					"page":page, //分页页码，数字类型【获取数据】
					"all": false, //是否全部导出【获取数据】
				}
				let res =  await PayType(pass_data)
				res.data.title.push({
					key: "operation",
					title: "操作",
					slot:"operation"
				})
				res.data.title.forEach(item=>{
					if(item.key=="isauth"){
						item.slot="isauth"
					}
			
					if(item.key=="show"){
						item.slot="show"
					}
				})
				this.list=res.data.list
				this.title=res.data.title
				this.total=res.data.total
				this.pageSize=res.data.limit
				
			},
			
		}
		
	}
</script>

<style>
</style>