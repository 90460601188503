<template>
	<div style="position: relative;height: 100vh;">
		<!-- 内容主体 -->
		<div>
			<div style="text-align: right;margin: 20px auto;">
				<Button @click="pop_add" type="primary">添加</Button>
			</div>
			<!-- 添加按钮 弹出 -->
			<Modal title="添加按钮" :closable='false' :mask-closable='false' v-model="value8">
				<div>
					<Form style="padding-right: 30px;" :label-width="130">
						<FormItem label="基本充值量">
							<Input v-model="v1" />
						</FormItem>
						<FormItem label="赠送户充值量">
							<Input v-model="v2" />
						</FormItem>
						<FormItem label="积分">
							<Input v-model="v3" />
						</FormItem>
						<FormItem label="卖价">
							<Input v-model="v4" />
						</FormItem>
						<FormItem label="过期时间">
							<DatePicker v-model='timeDate' @on-change='timeDateClick' type="datetime"
								style="width: 200px"></DatePicker>
						</FormItem>
					</Form>
				</div>
				<div slot="footer" style="text-align: right;margin: 20px auto;">
					<Button @click="()=>value8=false">取消</Button>
					<Button @click="in_add" type="primary">添加</Button>
				</div>
			</Modal>
			<!-- 编辑按钮弹出 -->
			<Modal title='编辑按钮' width="800" :closable='false' :mask-closable='false' v-model="value9">
				<Form style="width:500px;" :label-width="130">
					<FormItem label="基本充值量">
						<Input v-model="value11" />
					</FormItem>
					<FormItem label="赠送户充值量">
						<Input v-model="value12" />
					</FormItem>
					<FormItem label="积分">
						<Input v-model="value13" />
					</FormItem>
					<FormItem label="卖价">
						<Input v-model="value14" />
					</FormItem>
					<FormItem label="过期时间">
						<div style="display: flex;">
							<DatePicker v-model='valuexiugai' @on-change='timeDateClick' type="datetime"
								style="width: 200px;margin-right: 10px;"></DatePicker>
							<Checkbox style="flex: 1;" v-model="single">是否无限期使用</Checkbox>
						</div>
					</FormItem>
				</Form>
				<div style="text-align: right;margin-bottom: 10px;">
					<Button @click="()=>Addeventtickets()">添加活动票券</Button>
				</div>
				<div>
					<Table :data='a_list' :columns='a_title'>
						<template slot-scope='{row}' slot='operation'>
							<Button @click="()=>to_disgoosbindingCoupon(row)" size='small' type='error'>解绑</Button>
						</template>
					</Table>
					<Card style='text-align: right;'>
						<Page :page-size='a_size*1' :total='a_total*1'></Page>
					</Card>
				</div>
				<div slot="footer">
					<Button @click="()=>value9=false">取消</Button>
					<Button@click="in_change" type="primary">确定</Button>
				</div>
			</Modal>
			<!-- 表格  -->
			<Table stripe @on-row-click='(payload)=>row_click(payload)' :columns="columns1" :data="data1">
				<!-- 表头操作 -->
				<template slot-scope="{ row, index }" slot="timeFormat">
					<div v-if="row.expiry== -1">
						无限期
					</div>
					<div v-if="row.expiry>0">
						{{formatDa(row.expiry)}}
					</div>
				</template>
				<!-- 表头操作 -->
				<template slot-scope="{ row, index }" slot="action">
					<div>
						<Button @click="()=>edit(row,index)" type="primary" size="small"
							style="margin-right: 5px">编辑</Button>
						<Poptip transfer confirm title="确定是否删除?" @on-ok="()=>del(row)">
							<div style="display: flex;">
								<Button type="error" size="small">删除</Button>
							</div>
						</Poptip>
					</div>
				</template>
			</Table>
			<!-- 弹窗区域 -->
			<div>
				<!-- 库存绑定弹窗 -->
				<Modal :closable='false' :mask-closable='false' width='900' v-model="is_show_kucunbangding">
					
					<div>
						<Form :label-width="100">
							<div style="display: flex;position: relative;">
								<div>
									<FormItem label="票券编号">
										<Input v-model="goosbindingCoupon_infos.apply" disabled style="width: 300px;" />
									</FormItem>
									<FormItem label="票券名称">
										<Input v-model="goosbindingCoupon_infos.applicationName" disabled style="width: 300px;" />
									</FormItem>
									<FormItem label="有效期-开始">
										<Input v-model="goosbindingCoupon_infos.satrt" disabled style="width: 300px;" />
									</FormItem>
									<FormItem label="有效期-结束">
										<Input v-model="goosbindingCoupon_infos.end" disabled style="width: 300px;" />
									</FormItem>
									<FormItem label="库存剩余张数">
										<Input v-model="goosbindingCoupon_infos.balanceTickets" disabled style="width: 300px;" />
									</FormItem>
									<FormItem label="规格">
										<Input v-model="goosbindingCoupon_infos.size" disabled style="width: 300px;" />
									</FormItem>
								</div>
								<div style="padding: 0 10px;">
									<FormItem label="模式">
										<Select v-model="goosbindingCoupon_infos.model" style="width: 200px;">
											<Option v-for="(item,index) in goosbindingCoupon_config" :value='index'>{{item}}
											</Option>
										</Select>
									</FormItem>
									<FormItem label="有效期天数">
										<Input v-model='goosbindingCoupon_infos.validity' style="width: 200px;" />
									</FormItem>
								</div>
							</div>
						</Form>
						<!-- 表格区域 -->
						<Table :data="inventory_bind_infos.list" :columns="inventory_bind_infos.title">
							<!-- 表格操作区域 -->
							<template slot-scope='{row}' slot="operation">
								<Button @click="()=>goosbindingCoupon_infos=row" size='small'
									type='primary'>选择</Button>
							</template>
						</Table>
						<div>
							<Card style='text-align: right;'>
								<Page @on-change="($)=>to_GetActivityCoupons({'id':current_id},$)"
									:total='inventory_bind_infos.total*1' :page-size='inventory_bind_infos.size*1'>
								</Page>
							</Card>
						</div>
						
					</div>
					<div slot='footer'>
						<Button @click="is_show_kucunbangding=false">返回</Button>
						<Button @click="()=>to_goosbindingCoupon()" type='primary'>确定</Button>
					</div>
				</Modal>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		bindingFreeTickets,
		goosbindingCoupon,
		GetgoosbindingCoupon,
		GetActivityCoupons,
		setoptions,
		setoptionsdef,
		deltapo,
		gettap,
		addtap,
		Schedul,
		SetgoosType,
		GetgoosType,
		SetgoosTypeVisual,
		DelgoosType,
		upimgfile,
		getimglist,
		GetgoosOneTow,
		AddgoosData,
		GetgoosList,
		GetgoosXData,
		SetgoosXData,
		UpgoosStock,
		UpgoosOnState,
		getbindingFreeTickets,
		PostmemberPrepaidPhoneButton,
		PostIdmemberPrepaidPhoneButton,
		getmemberPrepaidPhoneButton
	} from '@/request/api.js'
	import {
		getTimeFromZero
	} from '@/utils/index.js'
	export default {
		name: 'navbar',
		data() {

			return {
				current_id: '',
				goosbindingCoupon_config: {
					"1": '按模板有效期生成',
					"2": '按绑定时长生成有效期'
				},
				goosbindingCoupon_infos: '', //库存绑定-票券信息
				inventory_bind_infos: '',
				is_show_kucunbangding: false,
				a_total: '',
				a_size: '',
				a_title: [],
				a_list: [],
				current_select_row_data: {},
				v1: '',
				v2: '',
				v3: '',
				v4: '',
				single: true,
				valuexiugai: '',
				valuexiugai: '',
				border: [],
				index: 0, //当前选中的表格索引
				timeDate: '',
				timestamp: '',

				value1: '',
				value2: '',
				value3: '',
				value4: '',
				value5: '',
				value8: false,
				value9: false,
				value11: '',
				value12: '',
				value13: '',
				value14: '',
				value15: '',
				modal10: false,
				mPriceVal: '', //会员价格值
				nomPriceVal: '', //非会员价格值
				isEdit: false, //是否编辑
				columns1: [{
						title: '充值量',
						key: 'BASIC_CHARGE'
					},
					{
						title: '赠送量',
						key: 'GIVE_CHARGE',

					},
					{
						title: '赠送积分',
						key: 'INTEGRAL_CHARGE'
					},
					{
						title: '售卖价格',
						key: 'PRICE'
					},
					{
						title: '有效期',
						key: 'expiry',
						slot: "timeFormat"
					},
					{
						title: '操作',
						key: 'operation',
						slot: 'action',
					}
				],
				data1: []
			}

		},
		watch: {

			is_show_kucunbangding(val) {

				if (val) {
					this.goosbindingCoupon_infos = ''
				}

			},

			timestamp() {

				//如果用户选择了时间就 让勾选框取消
				if (this.timestamp) {
					this.single = false
				}

				//如果用户不选择时间就让  勾选框勾选
				if (!this.timestamp) {
					this.single = true
				}

			},

			// 2022-1-29
			current_select_tag() {
				this.to_gettap()
			},

			// 获取套餐选项组 
			async is_show_package_options_window(val) {

				this.group_infos = ''
				this.current_select_tag = ''
				if (val) {
					this.to_gettap()
				}

			},

			// 商品属性下拉菜单被选择时触发
			attribute_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.goosOneTow_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.attribute = _id;
			},

			// 用户点击添加按钮触发获取 【商品属性】 列表
			async is_show_type_goods_detail(is_show_drw) {

				if (is_show_drw) {
					let pass_data = {
						type: 1,
					};

					// 发动ajax 获取商品属性组或标签组
					let {
						data: res_data
					} = await GetgoosOneTow(pass_data);

					this.goosOneTow_data = res_data;
					// 获取商品标签组允许多个商品属性
					let pass_data1 = {
						type: 3,
					};
					// 发动ajax 获取商品属性组或标签组
					let {
						data: res_data1
					} = await GetgoosOneTow(pass_data1);
					console.log(res_data1);
					this.goosOneTow_data_tag = res_data1;

					let pass_data2 = {
						'mcode': localStorage.getItem('mcode')
					}
					let {
						data: res_data2
					} = await GetgoosType(pass_data2);
					this.type_data = res_data2

				}
			},

			// 商品属性下拉菜单那被选择时触发
			// 商品类型下拉菜单被选择时触发
			type_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.type_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.type = _id;
			},

		},
		computed: {

			formatDa() {
				return function(val) {
					return val > 0 ? this.$formatDate(val, "yyyy年MM月dd日 HH:mm:ss") : val;
				}
			}

		},
		mounted() {

			this.dataGet();

		},

		methods: {
			Addeventtickets(){
				this.is_show_kucunbangding=true
				let row = this.current_select_row_data
				this.to_GetActivityCoupons(row)
			},
			edit(row,index){
				this.current_select_row_data=row
				console.log(row,'==row')
				this.pop_edit(index)
			},
			row_click(payload) {

				this.current_select_row_data = payload
				// 回显需要编辑的表格行
				this.value11 = this.current_select_row_data['BASIC_CHARGE']
				this.value12 = this.current_select_row_data['GIVE_CHARGE']
				this.value13 = this.current_select_row_data['INTEGRAL_CHARGE']
				this.value14 = this.current_select_row_data['PRICE']

			},

			// 刷新首页 通过flashData改造 获取数据 展现列表
			async dataGet() {

				let {
					data: res
				} = await this.flashData()

				// 按钮保存到data里面
				res.map((item, index) => {
					res[index] = {
						isForever: '',
						...res[index]
					}
					res[index]['isForever'] = item.expiry > 0 ? true : false
				})
				this.data1 = res;

			},

			// 【ajax】 当前数据获取
			async flashData() {

				let mcode = localStorage.getItem('mcode');
				let pass_data = {
					"mcode": mcode,
					"type": "all"
				}

				let res1 = await getmemberPrepaidPhoneButton(pass_data);

				// 按钮保存到data里面
				this.data1 = [];
				return res1

			},

			// 内部编辑修改 表格单行  弹出 修改
			async in_change(item) {

				let value11 = this.value11;
				let value12 = this.value12;
				let value13 = this.value13;
				let value14 = this.value14;
				if (value11 && value12 && value13 && value14 && this.single || value11 && value12 && value13 &&
					value14 && this.timestamp) {
					// 如果当前用户勾选了无限期使用  则timestamp值为-1   如果用户没有勾选 则为timestamp
					let timestamp;
					if (this.border.length > 0) {
						timestamp = -1;
					} else {
						timestamp = this.timestamp
					}
					let data = {
						'BASIC_CHARGE': value11, //基本户充值量/shen
						'GIVE_CHARGE': value12, //赠送户充值量
						'INTEGRAL_CHARGE': value13, //积分量
						'PRICE': value14, //售卖售价 单位：分
						'mcode': localStorage.getItem('mcode'), //售卖键所属门店
						'expiry': this.single ? -1 : timestamp //售卖键有效期 （时间戳超过后不允许购买） -1无限期    
					}
					// 数据整合成功  发送请求  添加按钮
					//[ajax] 成功验证 发送郑家充值按钮

					let res = await PostIdmemberPrepaidPhoneButton(this.data1[this.index].id, data);

					this.dataGet()
					// 退出
					this.value9 = false;
					this.value11 = ''
					this.value12 = ''
					this.value13 = ''
					this.value14 = ''
					this.timestamp = ''
				} else {
					this.$Message.error('请正确输入信息');
				}

			},

			// [over] 删除会员充值按钮
			async del(item) {
				let BASIC_CHARGE = item.BASIC_CHARGE;
				let GIVE_CHARGE = item.GIVE_CHARGE;
				let INTEGRAL_CHARGE = item.INTEGRAL_CHARGE;
				let PRICE = item.PRICE;
				let expiry = item.expiry;
				//[ajax] 成功验证 发送郑家充值按钮
				let data = {
					'BASIC_CHARGE': BASIC_CHARGE, //基本户充值量/shen
					'GIVE_CHARGE': GIVE_CHARGE, //赠送户充值量
					'INTEGRAL_CHARGE': INTEGRAL_CHARGE, //积分量
					'PRICE': PRICE, //售卖售价 单位：分
					'mcode': localStorage.getItem('mcode'), //售卖键所属门店
					'expiry': '1' //售卖键有效期 （时间戳超过后不允许购买） -1无限期   0为删除 
				}

				// 数据整合成功  发送请求  添加按钮
				//[ajax] 成功验证 发送郑家充值按钮
				let res = await PostIdmemberPrepaidPhoneButton(item.id, data);

				this.dataGet()

			},

			// [over] 时间改变后的函数
			timeDateClick(e) {
				let time = new Date(e).getTime();
				this.timestamp = time
			},

			// [over] 添加商品
			async in_add() {

				let value11 = this.v1;
				let value12 = this.v2;
				let value13 = this.v3;
				let value14 = this.v4;
				let timestamp = this.timestamp
				// 如果当前用户有填项,阻止执行
				if (value11 && value12 && value13 && value14 && timestamp) {
					//[ajax] 成功验证 发送郑家充值按钮
					let data = {
						'BASIC_CHARGE': value11, //基本户充值量/shen
						'GIVE_CHARGE': value12, //赠送户充值量
						'INTEGRAL_CHARGE': value13, //积分量
						'PRICE': value14, //售卖售价 单位：分
						'mcode': localStorage.getItem('mcode'), //售卖键所属门店
						'expiry': this.timestamp //售卖键有效期 （时间戳超过后不允许购买） -1无限期    
					}

					// 数据整合成功  发送请求  添加按钮
					//[ajax] 成功验证 发送郑家充值按钮
					let res = await PostmemberPrepaidPhoneButton(data);

					// 退出
					this.value8 = false;
					this.dataGet()

				} else {
					this.$Message.error('请正确输入信息');
				}

			},

			//弹出添加商品页面
			pop_add() {

				this.v1 = ''
				this.v2 = ''
				this.v3 = ''
				this.v4 = ''
				this.timeDate = ''
				this.timestamp = ''
				this.value8 = true;

			},

			// 编辑卖品
			async pop_edit(index) {

				this.timeDate = ''
				this.timestamp = ''
				this.index = index
				// 弹出编辑卖品  弹层
				this.value9 = true;
				this.valuexiugai = ''
				this.current_id = this.current_select_row_data.id
				this.flash_modal()

			},

			async flash_modal() {

				let pass_data = {
					'cid': this.current_select_row_data.id
				}

				let res_data = await getbindingFreeTickets(pass_data)

				if (res_data.code == 200) {
					res_data.data.title.push({
						'key': 'operation',
						'title': '操作',
						'slot': 'operation'
					})
					this.a_title = res_data.data.title
					this.a_list = res_data.data.data
				}

			},

			// 库存绑定-绑定
			async to_goosbindingCoupon() {

				let pass_data = {
					"cid": this.current_id, //商品id
					"type": "绑定", //操作类型 ’绑定‘  ’解除绑定‘
					"uid": this.goosbindingCoupon_infos.uid, //type为绑定时必须传   要绑定的票券模板uid 
					"model": this.goosbindingCoupon_infos.model, //type为绑定时必须传  选择绑定的生成模式 1按模板有效期生成  2按绑定时长生成有效期
					"validity": this.goosbindingCoupon_infos.validity //type为绑定时必须传  且 model=2 是必须传 有效期 单位天
				}

				let res_data = await bindingFreeTickets(pass_data)

				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.is_show_kucunbangding = false
					this.flash_modal()
				}

			},

			// 库存绑定-解绑
			async to_disgoosbindingCoupon(row) {

				let pass_data = {
					"uid": row.uid, //商品id
					'cid': this.current_id,
					"type": "解除绑定", //操作类型 ’绑定‘  ’解除绑定‘
				}

				let res_data = await bindingFreeTickets(pass_data)

				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.flash_modal()
				}

			},

			// 获取套票绑定模板数据
			async to_GetgoosbindingCoupon(id) {

				let pass_data = {
					'id': id
				}

				let {
					data: res_data
				} = await GetgoosbindingCoupon(pass_data)

				res_data['model'] += ''
				this.goosbindingCoupon_infos = res_data

			},

			// 获取库存绑定弹窗数据
			async to_GetActivityCoupons(row, page = 1) {

				this.inventory_bind_infos = ''
				let pass_data = {
					'page': page,
					'mcode': localStorage.getItem('mcode')
				}

				let {
					data: res_data
				} = await GetActivityCoupons(pass_data)

				res_data.title.push({
					'key': 'operation',
					'title': '操作',
					'slot': 'operation'
				})
				res_data.list.forEach(item => {
					item['model'] = '1'
					item['validity'] = 0
				})
				this.inventory_bind_infos = res_data

				// 调用获取套票绑定模板数据 接口
				await this.to_GetgoosbindingCoupon(row.id)

			},

			// 获取弹窗套餐信息
			async to_gettap() {

				let pass_data = {
					'id': this.current_row_info.id,
					"mcode": localStorage.getItem('mcode')
				}

				let res_data = await gettap(pass_data)

				if (res_data.code = 200) {
					this.group_infos = ''
					res_data.data.options.forEach(item => {
						item['name'] = ''
					})
					this.group_infos = res_data.data
				}

			},

		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
