<template>
    <div class="p-10">
        <h1>消费记录</h1>
        <Row style="width: 700px">
            <Col span="8">
                <div class="flex">
                    <div>持卡人姓名:</div>
                    <div>{{ tableData.CardData.name }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>等级:</div>
                    <div>{{ tableData.CardData.levelName }}</div>
                </div>
            </Col>
            <Col span="8"></Col>
            <Col span="8">
                <div class="flex">
                    <div>卡号:</div>
                    <div>{{ tableData.CardData.cardNum }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>卡号:</div>
                    <div>{{ tableData.CardData.cardNum }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>绑定号码:</div>
                    <div>{{ tableData.CardData.phoneNum }}</div>
                </div>
            </Col>
            <Col :span="8">
                <div class="flex">
                    <div>余额:</div>
                    <div>{{ tableData.CardData.BASIC }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>赠送:</div>
                    <div>{{ tableData.CardData.GIVE }}</div>
                </div>
            </Col>
        </Row>
        <div class="flex">
            <div>绑定时间:</div>
            <div>{{ tableData.CardData.bindingTime }}</div>
        </div>

        <Tabs type="card" @on-click="(value) => changeTabs(value)" v-model="currentTab">
            <TabPane v-for="(item, index) in panneConfig" :label="item" :name="item" :index="index">
                <div class="mb-3 text-right">
                    <Button @click="() => tableDataExport()" size="small" type="primary">导出数据</Button>
                </div>
                <Table size="small" :columns="tableData.title" :data="tableData.list">
                    <template slot-scope="{ row, index }" slot="operate">
                        <Button @click="() => View(row)" type="primary" size="small">订单详情</Button>
                    </template>
                </Table>
                <Card class="text-right">
                    <Page @on-change="(value) => changePage(value)" :total="tableData.total" :pageSize="tableData.limit"></Page>
                </Card>
            </TabPane>
        </Tabs>
    </div>
</template>
<script>
import { MemCardInfo, GetMemberPayList, GetMemberPayInfoV, memberCardPayData } from '@/request/api';
import { download } from '@/utils/table_download.js';
export default {
    name: 'historyOrder',
    data() {
        return {
            order: '',
            tableData: {
                list: [],
            },
            tableRowData: '',
            modalTableData: '',
            CardID: '',
            panneConfig: ['消费记录', '消费记录-旧版'],
            currentTab: '消费记录',
        };
    },
    mounted() {
        let { CardID } = this.$route.query;
        if (CardID) {
            this.cardID = CardID;
            this.getTableData();
        }
    },
    methods: {
        /**
         * 表格内的查看
         */
        View(value) {
            const { _id, orderid } = value;
            switch (this.currentTab) {
                case '消费记录-旧版':
                    this.routeTo('orderFundDetail?id=' + orderid);
                    break;

                default:
                    this.routeTo('orderDetail?id=' + _id);
            }
        },
        /**
         * 切换标签页
         */
        async changeTabs(value) {
            this.currentTab = value;
            this.getTableData();
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        /**
         * 导出表格数据
         */
        async tableDataExport() {
            let res = await memberCardPayData({
                CardID: this.cardID,
                all: true,
                gettype: this.currentTab,
            });
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '消费记录',
                headers,
                data,
            });
        },
        /**
         * 翻页
         */
        changePage(value) {
            this.getTableData(value);
        },
        /**
         * 表格数据获取
         * @param {*} value
         * @param {*} page
         */
        async getTableData(page = 1) {
            this.tableData.list = [];
            this.tableData.title = [];
            let res = await memberCardPayData({
                CardID: this.cardID,
                page,
                all: false,
                gettype: this.currentTab,
            });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operate',
                slot: 'operate',
            });
            this.tableData = JSON.parse(JSON.stringify(Object.assign(this.tableData, res.data)));
        },
    },
};
</script>
