<template>
	<div>
		<div>
			<!-- 顶部搜索 -->
			<div style="text-align: right;">
				<Form inline>
					<FormItem>
						<Select v-model="currentMemberCardQuery" style="width: 150px;margin-right: 10px;text-align: left;">
							<Option v-for="item in memberCardQueryConfig" :value="item">{{item}}</Option>
						</Select>
					</FormItem>
					<FormItem>
						<Input v-model="number" placeholder="请输入卡号/手机号码/用户openid" style='width: 230px;'></Input>
					</FormItem>
					<FormItem>
						<Button @click="()=>to_handleMemberCardQuery()" style="margin:0 10px;" type='primary'>查询</Button>
					</FormItem>
					<FormItem>
						<Button @click="()=>table_dowload_card()">导出会员卡记录</Button>
					</FormItem>
				</Form>
			</div>
			<!-- 会员卡查询 -->
			<div style="margin-top: 10px;">
				<Table style="margin-top: 10px;" :columns='MemberCardQueryData.title' :data='MemberCardQueryData.list'>
					<template slot-scope='{row}' slot='operation'>
						<Button type="primary">操作</Button>
					</template>
				</Table>
				<Card>
					<div style="margin-top: 10px;text-align: right;">
						<Page :total='memberCardQuery_obj.total' @on-change="($)=>to_memberCardQuery($)"></Page>
					</div>
				</Card>
			</div>
		</div>
	</div>
</template>
<script>
	import { download } from '@/utils/table_download.js'
	import { memberCardQuery,memberCardConsumeQuery,handleMemberCardQuery } from '@/request/api.js'
	export default{
		name:'navbar',
		data() {
			return{
				currentMemberCardQuery:"全部",
				memberCardQueryConfig:['全部','cid','模糊搜索'],
				number:'',
				current_row:'',//当前点击查看消费记录的表格行
				memberCardQuery_obj:'',//会员卡数据
				memberCardConsumeQuery_obj:'',//消费记录表格数据
				MemberCardQueryData:""
			}
		},
		mounted(){
			this.to_handleMemberCardQuery()
		},
		methods: {
			async table_dowload_card(){
				let pass_data={
					"type": this.currentMemberCardQuery, //['全部','cid','模糊搜索'] 三选一
					"content": this.number, //搜索内容 类型未全部可以不填写
					"page": 1, //当前页码
					"all": true //是否获取全部数据
				}
				let res= await handleMemberCardQuery(pass_data)
				if (res.code != 200) return
				let headers = res.data.title
				let data  = res.data.list
				download({
				  'worksheet':'会员卡查询',
				  headers,
				  data
				})
			},
			async to_handleMemberCardQuery(){
				let pass_data={
					"type": this.currentMemberCardQuery, //['全部','cid','模糊搜索'] 三选一
					"content": this.number, //搜索内容 类型未全部可以不填写
					"page": 1, //当前页码
					"all": false //是否获取全部数据
				}
				let {data:res} = await handleMemberCardQuery(pass_data)
				res.title.push({
					key:"operation",
					title:"操作",
					slot:"operation"
				})
				this.MemberCardQueryData=res
			},
			// 导出消费记录
			async table_dowload(row){
				let pass_data = {
					"_id":row._id,
					"page":1,
					"all":1
				}
				let {data:res_data} = await memberCardConsumeQuery(pass_data) 
				let headers = res_data.title
				let data  = res_data.list
				download({
				  'worksheet':row.orderid+'',
				  headers,
				  data
				})
			},
			// 会员卡消费查询  查询消费记录
			async to_memberCardConsumeQuery(row,page=1){
				let pass_data = {
					"_id":row._id,
					"page":page,
				}
				let {data:res_data} = await memberCardConsumeQuery(pass_data)
				this.memberCardConsumeQuery_obj = res_data 
			},
		},
	}
</script>

