import axios from "axios";
import { request_config, response_config } from './request_response_config.js'

/**
 * 添加请求拦截器，在请求头中加token
 *
 */
axios.interceptors.request.use(
	config => {
		// 置协议头token 
		request_config(config)
		return config;
	},
	error => {
		return Promise.reject(error);
});


/**
 * 响应拦截器
 *
 */

axios.interceptors.response.use(
	(response) => {
		// 浏览器显示的状态码	
		response_config(response)
		return Promise.resolve(response);
	},
	// 服务器状态码不是200的情况
	(error) => {
		return Promise.reject(error);
	}
);
