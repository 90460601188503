<template>
	<div>
		<!-- 表格顶上操作区 创建票券模板 -->
		<div style="text-align: right;">
			<Button @click="()=>Createatickettemplate()" type='primary'>创建票券模板</Button>
		</div>
		<!-- 表格 -->
		<div style="margin-top: 10px;">
			<Table stripe @on-row-click='($)=>current_row_data=$' ref="table" :columns='title' :data='list'>
				<!-- 审批状态 -->
				<template slot-scope='{row}' slot="status">
					<div>
						<Tag color="warning" v-if='row.status==0'>{{row_state[row.status]}}</Tag>
						<Tag color="primary" v-if='row.status==1'>{{row_state[row.status]}}</Tag>
						<Tag color="success" v-if='row.status==2'>{{row_state[row.status]}}</Tag>
						<Tag color="error" v-if='row.status==3'>{{row_state[row.status]}}</Tag>
						<Tag color="default" v-if='row.status==4'>{{row_state[row.status]}}</Tag>
						<Tag color="default" v-if='row.status==5'>{{row_state[row.status]}}</Tag>
					</div>
				</template>
				<!-- 操作区域 -->
				<template slot-scope='{row}' slot='operation'>
					<div>
						<!-- 按钮权限控制 -->
						<span v-if="$my_checkBtn('to_view')">
							<Button v-if="[1,2,5].indexOf(row.status)!=-1" style="margin-right: 10px;"
								@click="()=>(is_show_to_view=true)&&(do_GetCouponDate(row))" type='primary'
								size='small'>查看</Button>
						</span>
						<span v-if="$my_checkBtn('download_coupon_code')">
							<Button v-if="([2].indexOf(row.status)!=-1)&&([1].indexOf(row.type)!=-1)"
								style="margin-right: 10px;" @click="()=>download_coupon_code_is_deriv(row)"
								type='warning' size='small'>下载券码</Button>
						</span>
						<span v-if="$my_checkBtn('imprest')">
							<Button v-if="([2].indexOf(row.status)!=-1)&&([2].indexOf(row.type)!=-1)"
								style="margin-right: 10px;" @click="()=>btn_imprest(row)" type='error'
								size='small'>备用金</Button>
						</span>
						<span v-if="$my_checkBtn('editor')">
							<Button v-if="[0,3].indexOf(row.status)!=-1" style="margin-right: 10px;"
								@click="()=>(pop_up_tag='编辑')&&(is_show_create_ticket_window=true)&&(do_GetCouponDate(row))"
								type='primary' size='small'>编辑</Button>
						</span>
						<span v-if="$my_checkBtn('submit')">
							<Button v-if="[0,3].indexOf(row.status)!=-1" style="margin-right: 10px;"
								@click="()=>examination_approval(row)" type='success' size='small'>提交</Button>
						</span>
						<span v-if="$my_checkBtn('agree')">
							<Poptip v-if="[1].indexOf(row.status)!=-1" transfer confirm title="是否确认同意?" @on-ok="()=>agree(row)">
								<Button v-if="[1].indexOf(row.status)!=-1" style="margin-right: 10px;" type='success' size='small'>同意</Button>
							</Poptip>
						</span>
						<span v-if="$my_checkBtn('reject')">
							<Poptip v-if="[1].indexOf(row.status)!=-1" transfer confirm title="是否确认驳回?" @on-ok="()=>reject(row)">
								<Button v-if="[1].indexOf(row.status)!=-1" style="margin-right: 10px;" type='error'size='small'>驳回</Button>
							</Poptip>
						</span>
					</div>
				</template>
			</Table>
		</div>
		<!-- 分页 -->
		<Card style='text-align: right;'>
			<Page @on-change="($)=>to_GetCouponlist($)" :page-size='size' :total='total'></Page>
		</Card>
		<!-- 弹窗区域 -->
		<!-- 备用金提示对话框 -->
		<Modal :closable='false' :mask-closable='false' v-model='is_show_imprest_toast'>
			<div slot='header'>
				备用金主提示
			</div>
			<p>
				此票券已被添加到备用金主: {{show_imprest_toast_info}}
			</p>
			<div slot='footer'>
				<Button @click="()=>is_show_imprest_toast=false">返回</Button>
				<Button @click="()=>is_show_imprest_toast=false" type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 备用金弹窗 -->
		<Modal :closable='false' :mask-closable='false' v-model='is_show_imprest'>
			<div slot='header'>
				添加到备用金主
			</div>
			<p>
				<span style="width: 100px;display: inline-block;">票券名称</span>
				<Input v-model="imprest_name" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="width: 100px;display: inline-block;">排序</span>
				<Input v-model="imprest_asc" style="width: 300px;"></Input>
			</p>
			<div slot='footer'>
				<Button @click="()=>is_show_imprest=false">返回</Button>
				<Button @click="()=>do_SetCouponaShotInLocker()" type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 查看按钮弹窗 -->
		<Modal :closable='false' :mask-closable='false' fullscreen width='1000' v-model='is_show_to_view'>
			<div>
				<p>
					<span style="display: inline-block;width: 150px;">票券名称</span>
					<Input disabled v-model="create_ticket_template.applicationName" style="width: 300px;"></Input>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">有效时间-开始/结束</span>
					<DatePicker disabled v-model='start_end_time' type="daterange" placeholder="请选择有效时间区间"
						style="width: 300px"></DatePicker>
				</p>
				<p style="display: flex;">
					<span style="display: inline-block;width: 150px;">创建类型</span>
					<Select disabled placeholder="请选择创建类型" style="width: 300px;"
						v-model="currently_selected_creat_ticket_types">
						<Option :value='key' v-for="(item,key) in ticket_type_configuration">{{item}}</Option>
					</Select>
				</p>
				<p style="display: flex;">
					<span style="display: inline-block;width: 150px;">票券兑换类型</span>
					<Select disabled placeholder="请选择票券兑换类型" style="width: 300px;"
						v-model="currently_selected_ticket_exchange_types">
						<Option :value='key' v-for="(item,key) in ticket_exchange_type_configuration">{{item}}</Option>
					</Select>
				</p>
				<!-- 选中电影票不显示 选中卖品显示 -->
				<div v-if="currently_selected_ticket_exchange_types==1">
					<p>
						<span style="width: 150px;display: inline-block;"></span>
						<RadioGroup v-model="type" @on-change="($)=>type=$">
							<Radio disabled label="1">通兑</Radio>
							<Radio disabled label="2">其他</Radio>
						</RadioGroup>
					</p>
					<!-- 当片全兑换类型为卖品的时候 -->
					<div v-if="(currently_selected_ticket_exchange_types==1)&&(type==2)" style="margin-top: 10px;">
						<span style="width: 150px;display: inline-block;">兑换卖品列表</span>
						<div style="display: inline-block;">
							<p style="width: 300px;">
								<Tag @on-close="()=>values=values.filter(it=>it.value!=item.value)"
									v-for="(item,index) in values">{{item.label}}</Tag>
							</p>
						</div>
					</div>
				</div>
				<p>
					<span style="display: inline-block;width: 150px;">票券类型</span>
					<Select disabled placeholder="请选择票券类型" style="width: 300px;"
						v-model="currently_selected_ticket_types">
						<Option :value='key' v-for="(item,key) in ticket_type">{{item}}</Option>
					</Select>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">优惠价格</span>
					<Input disabled placeholder="" ref='优惠价格1' type='number' v-model="totalAddPrice"
						style="width: 300px;"></Input>
					<!-- <Input   v-if="currently_selected_ticket_types=='兑换券'"   min="0" type='number'  ref="优惠价格" v-model="totalAddPrice" style="width: 300px;"></Input> -->
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">全部价格</span>
					<Input disabled placeholder="" type='number' v-model="current_look_infos.totalPrice"
						style="width: 300px;"></Input>
					<!-- <Input   v-if="currently_selected_ticket_types=='兑换券'"   min="0" type='number'  ref="优惠价格" v-model="totalAddPrice" style="width: 300px;"></Input> -->
				</p>
				<p v-if='currently_selected_creat_ticket_types==2'>
					<span style="display: inline-block;width: 150px;">是否生成套码</span>
					<i-Switch disabled ref='switch' v-model='create_ticket_template.bal'></i-Switch>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">成本价格</span>
					<Input disabled v-model="create_ticket_template.costPrice" type='number'
						style="width: 300px;"></Input>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">规格</span>
					<Input disabled v-model="create_ticket_template.size" type='number' style="width: 100px;"></Input>
					<span style="display: inline-block;width: 150px;margin-left: 10px;">张/套</span>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">申请数量</span>
					<Input disabled v-model="create_ticket_template.cassize" type='number'
						style="width: 100px;"></Input><span style="margin-left: 10px;">套</span>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">关联的企业微信OA单号</span>
					<Input disabled v-model="create_ticket_template.oaNo" style="width: 300px;"></Input>
				</p>
				<p>
					<span style="display: inline-block;width: 150px;">备注</span>
					<Input disabled v-model="create_ticket_template.note" style="width: 300px;"></Input>
				</p>
				<div v-if="currently_selected_creat_ticket_types==2||currently_selected_creat_ticket_types==3">
					<Card>
						<div style="text-align: right;">
							<Button @click="()=>dowload_modal_table_data()" type='primary'>导出所有数据</Button>
						</div>
						<Table stripe style="margin-top: 10px;" ref="table" :columns='modal_title' :data='modal_list'>
						</Table>
					</Card>
					<Card style='text-align: right;'>
						<Page @on-change='($)=>modal_table($)' :total='modal_total' :page-size='modal_size'></Page>
					</Card>
				</div>
			</div>
			<div slot='header'>
				查看票券订单
			</div>
			<div slot='footer'>
				<Button @click="()=>is_show_to_view=false">返回</Button>
				<Button disabled @click="()=>do_CouponCreat()" type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 创建 / 编辑 公用票券模板弹窗 -->
		<Modal :closable='false' :mask-closable='false' width='1200' v-model='is_show_create_ticket_window'>
			<Tabs type="card" @on-tab-remove="handleTabRemove">
				<TabPane label="基础设置" v-if="tab0">
					<Form :label-width="160">
						<FormItem label="票券名称">
							<Input v-model="create_ticket_template.applicationName" style="width: 300px;"></Input>
						</FormItem>
						<FormItem label="有效时间-开始/结束">
							<DatePicker v-model='start_end_time' type="daterange" placeholder="请选择有效时间区间"
								style="width: 300px"></DatePicker>
						</FormItem>
						<FormItem label="创建类型">
							<Select placeholder="请选择创建类型" style="width: 300px;"
								v-model="currently_selected_creat_ticket_types">
								<Option :value='key' v-for="(item,key) in ticket_type_configuration">{{item}}</Option>
							</Select>
						</FormItem>
						<FormItem label="票券兑换类型">
							<Select placeholder="请选择票券兑换类型" style="width: 300px;"
								v-model="currently_selected_ticket_exchange_types">
								<Option :value='key' v-for="(item,key) in ticket_exchange_type_configuration">{{item}}
								</Option>
							</Select>
						</FormItem>
						<!-- 选中电影票不显示 选中卖品显示 -->
						<div v-if="currently_selected_ticket_exchange_types==1">
							<p>
								<span style="width: 150px;display: inline-block;"></span>
								<RadioGroup v-model="type" @on-change="($)=>type=$">
									<Radio label="1">通兑</Radio>
									<Radio label="2">其他</Radio>
								</RadioGroup>
							</p>
							<!-- 当片全兑换类型为卖品的时候 -->
							<div v-if="(currently_selected_ticket_exchange_types==1)&&(type==2)"
								style="margin-top: 10px;">
								<div style="position: absolute;right: 10px;top: 80px;">
									<Transfer ref="穿梭" list-style="width:300px;height:530px" :data="list2"
										:target-keys="targetKeys2" filterable :filter-method="filterMethod"
										@on-change="handleChange2">
									</Transfer>
								</div>
							</div>
						</div>
						<FormItem label="票券类型">
							<Select placeholder="请选择票券类型" style="width: 300px;"
								v-model="currently_selected_ticket_types">
								<Option :value='key' v-for="(item,key) in ticket_type">{{item}}</Option>
							</Select>
						</FormItem>
						<FormItem label="优惠价格">
							<Input ref='优惠价格' type='number' v-model="totalAddPrice" style="width: 300px;"></Input>
						</FormItem>
						<FormItem v-if='currently_selected_creat_ticket_types==2' label="是否生成套码">
							<i-Switch ref='switch' v-model='create_ticket_template.bal'></i-Switch>
						</FormItem>
						<FormItem label="成本价格">
							<Input v-model="create_ticket_template.costPrice" type='number'
								style="width: 300px;"></Input>
						</FormItem>
						<FormItem label="规格">
							<Input v-model="create_ticket_template.size" type='number' style="width: 100px;"></Input>
							<span style="display: inline-block;width: 150px;margin-left: 10px;">张/套</span>
						</FormItem>
						<FormItem label="申请数量">
							<Input v-model="create_ticket_template.cassize" type='number' style="width: 100px;"></Input>
							<span style="margin-left: 10px;">套</span>
						</FormItem>
						<FormItem label="关联的企业微信OA单号">
							<Input v-model="create_ticket_template.oaNo" style="width: 300px;"></Input>
						</FormItem>
						<FormItem label="备注">
							<Input v-model="create_ticket_template.note" style="width: 300px;"></Input>
						</FormItem>
					</Form>
				</TabPane>
				<TabPane label="使用设置" v-if="tab1">
					<Form :label-width="100">
						<FormItem label="可用时间">
							<Select multiple placeholder="请选择可用时间" style="width: 300px;" v-model="time_available_value">
								<Option :value='item' v-for="(item,key) in time_available">{{item}}</Option>
							</Select>
						</FormItem>
						<FormItem>
							<template slot='label'>
								<span>使用说明<br>
									<span style="color: red;">(*顾客可见)</span></span>
							</template>
							<Input v-model="instructions" :rows="6" type="textarea" style="width: 500px;"></Input>
						</FormItem>
					</Form>
				</TabPane>
			</Tabs>

			<div slot='header'>
				{{pop_up_tag}}票券订单
			</div>
			<div slot='footer'>
				<Button @click="()=>is_show_create_ticket_window=false">返回</Button>
				<Button @click="()=>do_CouponCreat()" type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 插件下载表格 -->
		<Table v-show="false" ref='fake_table' :columns='fake_title' :data="fake_data"></Table>
	</div>
</template>

<script>
	let mcode;
	import {
		GetsellProduct,
		Getapplycode,
		GetCascodlog,
		SetCouponaShotInLocker,
		submitAudit,
		approval,
		GetCouponlist,
		CouponCreat,
		GetCouponDate,
		upCoupon
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {

			return {
				pop_up_tag: '', //创建 / 编辑 公用弹窗标记
				instructions: '', //使用说明
				tab0: true,
				tab1: true,
				time_available_value: ['周一', '周二', '周三', '周四', '周五', '周六', '周日', '法定节假日'], //当前可用时间绑定的日期
				time_available: ['周一', '周二', '周三', '周四', '周五', '周六', '周日', '法定节假日'], //可用时间
				rightData: [], //穿梭框的目的列表的数据集 是一个完整的数据集
				targetKeys2: [], //目的列表显示的数据集 是个集合的字段
				values: [], //编辑的时候保存的
				type: '', //单选 通兑（1）/其他（2） 变量
				list2: '', //穿梭框的数据集 源数据
				model20: [],
				options4: '', //兑换卖品列表标签数据集
				fake_data: '', //下载表格数据集
				fake_title: '', //下载表格表头
				modal_size: '', //查看弹窗的表格size
				modal_total: "", //查看弹窗的总数据集
				modal_title: [], //查看弹窗的表头
				modal_list: [], //查看弹窗的数据集
				current_row_data: '', //当前点击的表格行数据
				show_imprest_toast_info: '', //备用金提示对话框信息
				is_show_imprest_toast: false, //备用金提示对话框
				imprest_asc: '', //票券排序
				imprest_name: '', //票券名称
				is_show_imprest: false, //备用金对话框
				current_look_infos: '', //当前表格行处理数据集
				modal_dis_agree_row: '', //当前表格行处理数据集 驳回对话框提交的数据
				modal_agree: false, //同意对话框
				modal_dis_agree: false, //驳回对话框
				is_show_to_view: false, //查看对话框弹出
				row_state: {
					'0': '新建',
					'1': '审批中',
					'2': '已通过',
					'3': '驳回',
					'4': '禁止使用',
					'5': '生成中'
				}, //审批状态标签配置
				current_edit_infos: '', //当前选中的编辑数据
				totalAddPrice: '', //优惠价格
				currently_selected_ticket_types: '', //当前选中的票券类型
				ticket_type: {
					'团体票': '团体票',
					'VIP券': 'VIP券',
					'优惠券': '优惠券',
					'兑换券': '兑换券',
				}, //票券类型配置
				currently_selected_ticket_exchange_types: '',
				ticket_exchange_type_configuration: {
					'0': '电影票',
					'1': '卖品'
				},
				currently_selected_creat_ticket_types: '', //当前选中的票券创建类型
				ticket_type_configuration: {
					'1': '系统创建(预生成)',
					'2': '系统创建（按需生成）',
					'3': '系统创建（活动商品）',
				},
				start_end_time: [], //有效时间
				create_ticket_template: {
					"satrt": "", //有效时间-开始
					"end": "", //有效时间-结束
					"applicationName": "", //票券名称
					"type": '', //创建类型 1系统创建(预生成)  2系统创建（按需生成）
					"containSaleItemType": '', //票券兑换类型 0 电影票  1卖品
					"codetype": "", //票券类型 团体票 VIP券 优惠券  兑换券
					"totalAddPrice": '', //优惠价格  团体票 VIP券  自动为0   优惠券填写优惠金额需要带-     兑换券直接填写数字
					"bal": false, //type 为 2时 才需要选择  其他情况默认为false  是否生成套码
					"costPrice": '', //成本价格
					"creater": "", //创建人姓名
					"size": '', //规格每套多少张
					"cassize": '', //规格申请多少套
					"oaNo": "", //关联的企业微信OA单号
					'note': '',
					"mcode": "" //影院编码
				},
				is_show_create_ticket_window: false, //是否显示创建票券模板弹窗
				title: [],
				list: [],
				page: 0, //票券订单表格当前页码
				size: 0, //每次查询集合条数
				total: 0, //总集合次数
			}

		},

		mounted() {
			mcode = localStorage.getItem('mcode');
			// 获取当前票券订单
			this.to_GetCouponlist()
			//获取穿梭框的数据
			this.to_GetsellProduct()

		},

		watch: {

			// 监听当前是否生成套码 用户获取表格数据
			async currently_selected_creat_ticket_types(val) {

				// 获取表格数据
				if (val == 2 || val == 3) {
					this.modal_table()
				}

			},

			// 监听票券类型的变化
			totalAddPrice(val) {

				if (this.totalAddPrice == '') {
					return
				}
				if (this.currently_selected_ticket_types == '优惠券') {
					if (this.totalAddPrice > 0) {
						this.$refs.优惠价格.currentValue = ''
						this.$refs.优惠价格1.currentValue = ''
						this.totalAddPrice = ''
					}
				}
				if (this.currently_selected_ticket_types == '兑换券') {
					if (this.totalAddPrice < 0) {
						this.$refs.优惠价格.currentValue = ''
						this.$refs.优惠价格1.currentValue = ''
						this.totalAddPrice = ''
					}
				}

			},

			// 监听票券类型选中
			currently_selected_ticket_types(val) {

				this.$refs.优惠价格.disabled = false
				this.$refs.优惠价格1.disabled = false
				this.totalAddPrice = ''
				// 优惠价格自动为0
				if (val == '团体票' || val == 'VIP券') {
					this.$refs.优惠价格.disabled = true
					this.$refs.优惠价格1.disabled = true
					this.totalAddPrice = 0
					// 禁止用户修改优惠价格输入框
				}
				if (val == '优惠券') {
					this.$nextTick(() => {
						this.$refs.优惠价格.placeholder = '请输入负数'
						this.$refs.优惠价格1.placeholder = '请输入负数'
					})
				}
				if (val == '兑换券') {
					this.$nextTick(() => {
						this.$refs.优惠价格.placeholder = '请输入正数'
						this.$refs.优惠价格1.placeholder = '请输入正数'
					})
				}

			},

			// 监听操作 => 查看 数据初始化
			is_show_to_view(val) {

				if (!val) {
					this.current_look_infos = ''
					this.totalAddPrice = ''
					this.start_end_time = []
					this.currently_selected_creat_ticket_types = ''
					this.currently_selected_ticket_exchange_types = ''
					this.currently_selected_ticket_types = ''
					this.create_ticket_template = {
						"satrt": "", //有效时间-开始
						"end": "", //有效时间-结束
						"applicationName": "", //票券名称
						"type": '', //创建类型 1系统创建(预生成)  2系统创建（按需生成）
						"containSaleItemType": '', //票券兑换类型 0 电影票  1卖品
						"codetype": "", //票券类型 团体票 VIP券 优惠券  兑换券
						"totalAddPrice": '', //优惠价格  团体票 VIP券  自动为0   优惠券填写优惠金额需要带-     兑换券直接填写数字
						"bal": false, //type 为 2时 才需要选择  其他情况默认为false  是否生成套码
						"costPrice": '', //成本价格
						"creater": "", //创建人姓名
						"size": '', //规格每套多少张
						"cassize": '', //规格申请多少套
						"oaNo": "", //关联的企业微信OA单号
						"mcode": "", //影院编码
						'note': ''
					}
				}

			},

			// 监听创建票票券模板弹窗
			is_show_create_ticket_window(val) {

				if (!val) {
					this.time_available_value = ['周一', '周二', '周三', '周四', '周五', '周六', '周日',
						'法定节假日'
					] // 创建票券模板弹簧初始化 当前可用时间绑定的日期 数据
					this.targetKeys2 = [] //清空穿梭框右侧的数据
					this.rightData = []; //清空穿梭框的数据
					this.totalAddPrice = ''
					this.start_end_time = []
					this.currently_selected_creat_ticket_types = ''
					this.currently_selected_ticket_exchange_types = ''
					this.currently_selected_ticket_types = ''
					this.create_ticket_template = {
						"satrt": "", //有效时间-开始
						"end": "", //有效时间-结束
						"applicationName": "", //票券名称
						"type": '', //创建类型 1系统创建(预生成)  2系统创建（按需生成）
						"containSaleItemType": '', //票券兑换类型 0 电影票  1卖品
						"codetype": "", //票券类型 团体票 VIP券 优惠券  兑换券
						"totalAddPrice": '', //优惠价格  团体票 VIP券  自动为0   优惠券填写优惠金额需要带-     兑换券直接填写数字
						"bal": false, //type 为 2时 才需要选择  其他情况默认为false  是否生成套码
						"costPrice": '', //成本价格
						"creater": "", //创建人姓名
						"size": '', //规格每套多少张
						"cassize": '', //规格申请多少套
						"oaNo": "", //关联的企业微信OA单号
						"mcode": "", //影院编码
						'note': ''
					}
				}

			}

		},

		methods: {
		
			Createatickettemplate() {
				this.pop_up_tag = '创建'
				this.is_show_create_ticket_window = true
			},
			handleTabRemove(name) {
				this['tab' + name] = false;
			},

			// 穿梭框

			// 穿梭按钮点击时候触发的事件
			handleChange2(newTargetKeys) {

				this.targetKeys2 = newTargetKeys;
				this.$nextTick(() => {
					this.rightData = this.$refs.穿梭.rightData
				})

			},

			// 用于计算穿梭框的数量
			filterMethod(data, query) {
				return data.label.indexOf(query) > -1;
			},

			// 穿梭框
			// 获取穿梭框的数据
			async to_GetsellProduct() {

				let pass_data = {
					"mcode": [mcode]
				}

				let {
					data: res_data
				} = await GetsellProduct(pass_data)

				this.list2 = res_data

			},

			setDefaultOptions(options) {
				this.options4 = options;
			},

			remoteMethod4(query) {

				if (query !== '') {
					this.loading4 = true;
					setTimeout(() => {
						this.loading4 = false;
						this.options4 = this.list2.filter(item => item.label.indexOf(query) > -1);
					}, 200);
				} else {
					this.options4 = [];
				}

			},

			// 下载券码导出
			async download_coupon_code_is_deriv(row) {

				let pass_data = {
					'uid': row.uid
				}

				let {
					data: res_data
				} = await Getapplycode(pass_data)

				this.$refs.fake_table.exportCsv({
					filename: 'Custom_data',
					columns: res_data.title,
					data: res_data.list
				});

			},

			// 下载弹窗表格导出所有数据
			async dowload_modal_table_data() {

				let pass_data = {
					'uid': this.current_edit_infos.uid,
					'all': true
				}

				let {
					data: res_data
				} = await GetCascodlog(pass_data);

				this.$refs.table.exportCsv({
					filename: 'Custom_data',
					columns: res_data.title,
					data: res_data.list
				});

			},

			// 查看弹窗内的表格
			async modal_table(page = 1) {

				let pass_data = {
					'uid': this.current_edit_infos.uid,
					'page': page,
					'all': false
				}

				let {
					data: res_data
				} = await GetCascodlog(pass_data);

				this.modal_list = res_data.list
				this.modal_title = res_data.title
				this.modal_size = res_data.size
				this.modal_total = res_data.total

			},

			// 设置票券为备用金 
			async do_SetCouponaShotInLocker() {

				let pass_data = {
					"uid": this.current_row_data.uid,
					"name": this.imprest_name,
					"sort": this.imprest_asc
				}

				let res_data = await SetCouponaShotInLocker(pass_data)

				if (res_data.code == 200) {
					this.is_show_imprest = false
					this.to_GetCouponlist()
				}

			},

			// 场景值备用金按钮点击事件
			btn_imprest(row) {

				// 场景一 
				// 如果ShotInLocker有值就显示弹窗提示此票券已被添加到备用金主:xxx
				if (row.ShotInLocker != '') {
					this.show_imprest_toast_info = row.ShotInLocker
					this.is_show_imprest_toast = true
				}
				// 场景二
				// 如果ShotInLocker没有值就就显示提交对话框
				if (row.ShotInLocker == '') {
					this.is_show_imprest = true
					this.imprest_name = row.applicationName
					this.imprest_asc = 50
				}

			},

			// 同意
			async agree(row) {

				let pass_data = {
					"uid": row.uid, //票券订单uid
					'tag': true,
					"approval": JSON.parse(localStorage.getItem('选中品牌1')).user.name
				}

				let res_data = await approval(pass_data)

				if (res_data.code == 200) {
					this.$mytoast('执行成功')
					this.modal_agree = false
					this.to_GetCouponlist()
				}

			},

			// 驳回
			async reject(row) {

				let pass_data = {
					"uid": row.uid, //票券订单uid
					'tag': false,
					"approval": JSON.parse(localStorage.getItem('选中品牌1')).user.name
				}

				let res_data = await approval(pass_data)

				if (res_data.code == 200) {
					this.$mytoast('驳回成功')
					this.modal_dis_agree = false
					this.to_GetCouponlist()
				}

			},

			// 执行审批
			async examination_approval(row) {

				let pass_data = {
					"uid": row.uid, //票券订单uid
					"updater": JSON.parse(localStorage.getItem('选中品牌1')).user.name
				}

				let res_data = await submitAudit(pass_data)

				if (res_data.code == 200) {
					this.$mytoast('审批成功')
					this.to_GetCouponlist()
				}

			},

			// 获取需要编辑的 表格单行信息
			async do_GetCouponDate(row) {

				this.time_available_value = []
				let pass_data = {
					"uid": row.uid
				}

				let res_data = await GetCouponDate(pass_data)

				if (res_data.code == 200) {
					this.$refs.优惠价格1.currentValue = res_data.data.totalAddPrice
					this.start_end_time = []
					this.start_end_time[0] = new Date(res_data.data.satrt)
					this.start_end_time[1] = new Date(res_data.data.end)
					this.create_ticket_template.applicationName = res_data.data.applicationName
					this.currently_selected_creat_ticket_types = res_data.data.type + ''
					this.currently_selected_ticket_exchange_types = res_data.data.containSaleItemType + ''
					this.currently_selected_ticket_types = res_data.data.codetype
					this.create_ticket_template.bal = res_data.data.bal
					this.create_ticket_template.costPrice = res_data.data.costPrice
					this.create_ticket_template.size = res_data.data.size
					this.create_ticket_template.cassize = res_data.data.cassize
					this.create_ticket_template.oaNo = res_data.data.oaNo
					this.create_ticket_template.note = res_data.data.note
					this.current_edit_infos = row
					this.current_look_infos = res_data.data
					this.options4 = res_data.data.exchange
					this.rightData = res_data.data.exchange
					this.targetKeys2 = res_data.data.exchange ? res_data.data.exchange.map(item => item.value) : []
					this.type = res_data.data.forAll ? '1' : '2'
					this.values = res_data.data.exchange ? res_data.data.exchange : [];
					this.time_available_value = res_data.data.legalHoliday ? res_data.data.legalHoliday : [];
					this.instructions = res_data.data.instructions.join('\n')

				}

			},

			// 创建 / 编辑票券订单
			async do_CouponCreat(type) {

				type = this.pop_up_tag
				// 场景值一 创建票券
				let res_data;
				let pass_data = {
					"satrt": new Date(this.start_end_time[0]).getTime(), //有效时间-开始
					"end": new Date(this.start_end_time[1]).getTime(), //有效时间-结束
					"applicationName": this.create_ticket_template.applicationName, //票券名称
					"type": this.currently_selected_creat_ticket_types, //创建类型 1系统创建(预生成)  2系统创建（按需生成）
					"containSaleItemType": this.currently_selected_ticket_exchange_types, //票券兑换类型 0 电影票  1卖品
					"codetype": this.currently_selected_ticket_types, //票券类型 团体票 VIP券 优惠券  兑换券
					"totalAddPrice": this.totalAddPrice, //优惠价格  团体票 VIP券  自动为0   优惠券填写优惠金额需要带-     兑换券直接填写数字
					"bal": this.create_ticket_template.bal, //type 为 2时 才需要选择  其他情况默认为false  是否生成套码
					"costPrice": this.create_ticket_template.costPrice, //成本价格
					"creater": JSON.parse(localStorage.getItem('选中品牌1')).user.name, //创建人姓名
					"size": this.create_ticket_template.size, //规格每套多少张
					"cassize": this.create_ticket_template.cassize, //规格申请多少套
					"oaNo": this.create_ticket_template.oaNo, //关联的企业微信OA单号
					"mcode": mcode, //影院编码
					'note': this.create_ticket_template.note, //备注
					"legalHoliday": this.time_available_value, //可用时间
					"instructions": this.instructions ? this.instructions.split('\n') : [], //使用说明
				}
				if (type == '创建') {
					// 其他模式
					if (this.type == 2) {
						pass_data['exchange'] = this.rightData
					}
					// 通兑模式
					if (this.type == 1) {
						pass_data['forAll'] = true
					}

					res_data = await CouponCreat(pass_data)

				}
				// 场景二 编辑修改票券
				if (type == '编辑') {
					pass_data["uid"] = this.current_edit_infos.uid;
					pass_data["totalAddPrice"] = this.$refs.优惠价格1
						.currentValue; //优惠价格  团体票 VIP券  自动为0   优惠券填写优惠金额需要带-     兑换券直接填写数字
					// let center = this.model20.concat(this.values.map(item=>item.value));//搜索输入框和远端数据合并
					// 其他模式
					if (this.type == 2) {
						pass_data['exchange'] = this.rightData
					}
					// 通兑模式
					if (this.type == 1) {
						pass_data['forAll'] = true
					}

					// 最终接口调用
					res_data = await upCoupon(pass_data)

				}
				// 接口调用返回状态码判断
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.is_show_create_ticket_window = false
					this.to_GetCouponlist()
				}

			},

			// 获取当前票券订单
			async to_GetCouponlist(page = 1) {

				let pass_data = {
					'page': page,
					"mcode": [mcode]
				}

				let {
					data: res_data
				} = await GetCouponlist(pass_data)

				// 置入操作表头
				res_data.title.push({
					'key': 'operation',
					'title': '操作',
					'slot': 'operation',
					'width': '300'
				})
				// 新增审批状态插槽 形态 标签
				res_data.title.forEach(item => {
					if (item.key == "status") {
						item['slot'] = "status"
					}
				})
				this.title = res_data.title; //表头
				this.list = res_data.list; //数据集
				this.page = res_data.page; //当前页
				this.size = res_data.size; //每次查询集合记录数

			}

		}
	}
</script>
