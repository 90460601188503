<template>
	<div>
		<div v-if="is_show_fill" style="padding: 15px;">
			<Form ref="login_Info" :model="login_Info" :label-width="100" :rules="ruleInline">
				<FormItem   label="入驻影院名称">
					<Input disabled  v-model="login_Info.cname[0].cname" placeholder=""></Input>
				</FormItem>
				<FormItem   label="类型">
					<Input disabled v-model="login_Info.type"  placeholder=""></Input>
				</FormItem>
				<FormItem   label="小店logo">
					<div style="display: flex;">
						<div style="width: 100px;height: 100px;background-color: pink;">
							<img alt="无数据" style="width: 100%;height: 100%" :src="login_Info.icon" />
						</div>
						<div style="margin-left: 10px;">
							<qiniuUploadBtn  :qiniu_config="login_Info.qiun" @set_url="($)=>login_Info.icon=$"/>
						</div>
					</div>
				</FormItem>
				<FormItem prop="shopName"   label="小店名称">
					<Input  v-model="login_Info.shopName" placeholder=""></Input>
				</FormItem>
				<FormItem   label="状态描述">
					<Input  disabled v-model="login_Info.stateStr" placeholder=""></Input>
				</FormItem>
				<FormItem   label="入驻时间">
					<Input disabled  v-model="login_Info.inTime" placeholder=""></Input>
				</FormItem>
				
				<Alert type="success" show-icon>以下为结算信息，请认真填写</Alert>
				
				<FormItem prop="name"  label="姓名">
					<Input  v-model="login_Info.name" placeholder=""></Input>
				</FormItem>
				<FormItem  prop="phoneNumber"   label="手机号码">
					<Input  v-model="login_Info.phoneNumber" placeholder="" ></Input>
				</FormItem>
				<FormItem   prop="idCard"   label="身份证号码">
					<Input  v-model="login_Info.idCard" placeholder=""></Input>
				</FormItem>
				<FormItem prop="card"   label="银行卡开户行">
					<Input  v-model="login_Info.card" placeholder=""></Input>
				</FormItem>
				<FormItem   prop="cardNo"   label="银行卡号">
					<Input  v-model="login_Info.cardNo" placeholder=""></Input>
				</FormItem>
				<FormItem>
					<Button type="primary" @click="handleSubmit('login_Info')">提交</Button>
				</FormItem>
				
			</Form>
		</div>
		<div v-else style="position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%);">
			<Card style="padding: 20px;">
				<div >请输入密码验证</div>
				<Input style="margin-top: 10px;" v-model="check_pwd" type="password"></Input>
				<div style="text-align: right;margin-top: 10px;">
					<Button @click="()=>xx()" type='primary'>确认</Button>
				</div>
			</Card>
		</div>
		
		<!-- 对话框 -->
		<Modal v-model="is_show_ok_fail">
			<div v-if="is_show_ok">
				提交成功
			</div>
			<div v-else>
				提交失败
			</div>
			<div slot='footer'>
				<Button @click="()=>confirm()" type='primary'>确定</Button>
			</div>
		</Modal>
	
	</div>
</template>

<script>
	import { GetDisinDate,UpDisinDate } from "../../../request/api.js";
    import qiniuUploadBtn from '../../../components/qiniu_upload_btn.vue'
	export default{
			name:'navbar',
			components:{
			  qiniuUploadBtn
			},
			data() {
				return{
					is_show_ok_fail:false,
					is_show_ok:false,
					ruleInline: {
						cardNo: [
							{ required: true, message: '', trigger: 'blur' }
						],
						phoneNumber: [
							{ required: true, message: '', trigger: 'blur' }
						],
						idCard: [
							{ required: true, message: '', trigger: 'blur' }
						],
						card: [
							{ required: true, message: '', trigger: 'blur' }
						],
						shopName: [
							{ required: true, message: '', trigger: 'blur' }
						],
						name: [
							{ required: true, message: '', trigger: 'blur' },
						]
					},
					is_show_fill:false,
					check_pwd:'',//密码验证
					login_Info:{
						"mcode": [
						""
						], //门店编号
						"type": "", //类型
						"branduid": "", //品牌uid
						"level": 1, //等级
						"higherUid": "", //上级uid 001为门店（团长级分销商必须为001）
						"icon": "", //门店图标
						"shopName": "", //门店名称
						"openid": "", //分销员openid（不显示）
						"name": "", //分销商姓名
						"idCard": "", //分销商身份证号码
						"card": "", //分销商银行卡开户行
						"cardNo": "", //分销商银行卡号
						"phoneNumber": "", //分销商手机号码
						"state": 0, //分销商状态码
						"stateStr": "", //分销商状态码描述
						"inTime": "", //入驻时间
						"cname": [
								{
								"mcode": "", 
								 
								"cname": "", 
								 
								"asname": "" 
								 
								}
							]
						} //返回数据,//登录获取的信息
				}
			},
			methods:{
				
				confirm(){
					this.is_show_ok=false
					this.is_show_ok_fail=false
				},
				
				handleSubmit(name) {

					this.$refs[name].validate(async (valid) => {
						if (valid) {
							let pass_data = this.login_Info
							pass_data["uid"] = this.$route.query.uid 
							pass_data["pass"] = this.check_pwd
							 let res_data =  await UpDisinDate(pass_data)
							 if(res_data.code==200){
								 
								 this.is_show_ok=true
							 }else{
								 this.is_show_ok=false
							 }
							 this.is_show_ok_fail = true
						} else {
							this.$Message.error('Fail!');
						}
					})
				},
				
				 async xx(){
					
					let pass_data={
						"uid":this.$route.query.uid,
						"pass":this.check_pwd
					}
					
					let res_data = await GetDisinDate(pass_data)
					
					if(res_data.code==200){
						this.is_show_fill=true
						this.login_Info = res_data.data
					}
					console.log(res_data,'======')
					
				}
				
			}
	}
</script>

<style>
</style>
