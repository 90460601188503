<template>
	<div>
		<div>
			<div style="text-align: right;">
				<Button type='primary' @click="()=>Createthedealer()">创建分销商</Button>
			</div>
			<div style="margin-top: 10px;">
				<Table :columns="title" :data="list">
					<template slot-scope="{row}"  slot="operation">
						<Button style="margin-right: 10px;" @click="()=>view(row)" size='small'>查看</Button>
						<Button style="margin-right: 10px;" @click="()=>to_new_targe_page(row)" v-if="row.state==0" size='small'>填单</Button>
						<div style="margin-right: 10px;display: inline-block;" >
							<Poptip transfer  title="验证码" :content="row.pass" placement="right">
							  <Button  size='small'>验证码</Button>
							</Poptip>
						</div>
						<div v-if="row.state==2" style="display: inline-block;" >
							<Poptip transfer  title="分销码" placement="right">
								<div slot="content">
									<div style="width: 200px;height: 200px;">
										<img style="width: 100%;height: 100%;" :src="row.qcodeurl" alt="">
									</div>
								</div>
							  <Button style="margin-right: 10px;"  size='small'>分销码</Button>
							</Poptip>
						</div>
					</template>
				</Table>
			</div>
		</div>
		<!-- 弹框 -->
			<!-- 全屏弹窗 -->
			<Modal :closable='false' :mask-closable='false' v-if="modal_full_info" v-model="modal_full" fullscreen title="Fullscreen Modal">
				<div slot='header'>
					分销商详情
				</div>
				<div style="width: 100%;height: 100%;">
					<!-- 上半部分 -->
					<div style="overflow-y: scroll; width: 100%;height: 30%;border: 1px solid #000;display: flex;padding: 10px;font-weight: 700;">
						<div style="width: 30%;height: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;">
							<div style="position: fixed;display: flex;justify-content: center;align-items: center;flex-direction: column;">
								<div style="width: 100px;height: 100px;border-radius: 500px;overflow: hidden;">
									<img style="width: 100%;height: 100%;" :src="modal_full_info.icon" alt="">
								</div>
								<div style="font-weight: 700;font-size: 24px;">
									{{modal_full_info.shopName}}
								</div>
								<div>
									累计提现<span style="color: orange;">￥ {{ modal_full_info.amass}}</span>
								</div>
								<div style="text-align: center;" class="right_btn">
									<Button @click="()=>stop_or_start(2)" v-if="modal_full_info.state!=2" type='success'>启用</Button>
									<Button @click="()=>stop_or_start()" v-if="modal_full_info.state==2" type='error'>停用</Button>
									<Button @click="()=>edit()" type="primary">编辑</Button>
								</div>
								<div class="dw_fx" v-if="modal_full_info.state==2">
									<Button @click="()=>download_img()">下载分销码</Button>
								</div>
							</div>
						</div>
						<div style="width: 70%;height: 100%;">
							<div style="display: flex;justify-content: flex-start;">
								<p style="display: inline-block;">分销商ID {{modal_full_info.uid}} 【品牌:{{modal_full_info.branduid}}】</p>
							</div>
							<div style="display: flex;justify-content: flex-start;">
								<p style="width: 30%; display: inline-block;">影院编码 {{modal_full_info.cname[0].mcode}}</p>
								<p style="width: 30%; display: inline-block;">影院名称 {{modal_full_info.cname[0].cname}}</p>
								<p style="width: 30%; display: inline-block;">影院简称 {{modal_full_info.cname[0].asname}}</p>
							</div>
							<div style="display: flex;justify-content: flex-start;">
								<p style="width: 30%; display: inline-block;">分销商类型 {{modal_full_info.type}}</p>
								<p style="width: 30%; display: inline-block;">等级 {{modal_full_info.level}}</p>
								<p style="width: 30%;display: inline-block;">状态 {{modal_full_info.stateStr}}</p>
							</div>
							<div style="display: flex;justify-content: flex-start;">
								
								<p style="width: 30%;display: inline-block;">OpenId {{modal_full_info.openid}}</p>
								
								<p style="width: 30%;display: inline-block;">上级ID {{modal_full_info.higherUid}}</p>
							    <p style="width: 200px;display: inline-block;">入驻时间 {{modal_full_info.inTime}}</p>
							</div>
							<div style="display: flex;justify-content: flex-start;">
								<p style="width: 30%;display: inline-block;">分销商姓名 {{modal_full_info.name}}</p>
								<p style="width: 30%;display: inline-block;">分销商身份证号码 {{modal_full_info.idCard}}</p>
								<p style="width: 30%;display: inline-block;">分销商手机号码 {{modal_full_info.phoneNumber}} </p>
							</div>
							
							<div style="display: flex;justify-content: flex-start;">
								<p style="width: 30%;display: inline-block;">分销商银行开户行 {{modal_full_info.card}}</p>
								<p style="width: 30%;display: inline-block;">分销商银行卡号 {{modal_full_info.cardNo}}</p>
							</div>
						</div>
					</div>
					<!-- 下半部分 -->
					<div style="width: 100%;height: 70%;border: 1px solid #000;display: flex;padding: 10px;margin-top: 10px;">
						<Tabs value="name1">
							<TabPane label="分销商品" name="name1">
								<Select style="width: 300px;" v-model="drop_list_distribution_goods_value" filterable>
									<Option v-for="item in drop_list_distribution_goods" :value="item.value" :key="item.value">{{ item.label }}</Option>
								</Select>
								<Button @click="()=>bind()" style="margin-left: 10px;" type='primayr'>添加</Button>
								<Table :columns='distribution_goods_title' :data="distribution_goods_list">
									<template slot-scope="{row}" slot="placeObj">
										<div v-if="row.placeObj.commission[0].type=='固定金额'">
											<div>一级{{row.placeObj.commission[0].type}} {{row.placeObj.commission[0].val}}元</div>
											<div>二级{{row.placeObj.commission[1].type}} {{row.placeObj.commission[1].val}}元</div>
										</div>
										<div v-if="row.placeObj.commission[0].type=='百分比'">
											<div>一级{{row.placeObj.commission[0].type}} {{row.placeObj.commission[0].val}}%</div>
											<div>二级{{row.placeObj.commission[1].type}} {{row.placeObj.commission[1].val}}%</div>
										</div>
									</template>
									<template slot-scope="{row}" slot="operation">
										<Button @click="()=>(is_show_edit=true)&&(current_delet_info=row)" type='primary' size='small'>编辑</Button>
										<Poptip transfer confirm title="确定是否删除?" @on-ok="()=>delete_guid(row)">
											<div style="display: flex;">
												<Button style="margin-left: 10px;"  type='error' size='small'>删除</Button>
											</div>
										</Poptip>
									</template>
								</Table>
							</TabPane>
							<TabPane label="销售订单" name="name2">标签二的内容</TabPane>
						</Tabs>
					</div>
				</div>
				<div slot="footer">
					<Button @click="()=>modal_full=false">返回</Button>
				</div>
			</Modal>
			<!-- 全屏弹窗内的弹窗 -->
			<Modal :closable='false' :mask-closable='false' v-model="is_show_inner_modal">
				<Form :label-width="80">
					<FormItem label="类型">
						<Select style="width: 200px;" v-model="edit_info.type">
							<Option v-for="item in type_list_config" :value="item" :key='item'>{{item}}</Option>
						</Select>
					</FormItem>
					<FormItem label="等级">
						<Input v-model="edit_info.level" style="width: 200px;" type='number'></Input>
					</FormItem>
				</Form>
				<div slot="footer">
					<Button @click="()=>is_show_inner_modal=false">返回</Button>
					<Button @click="()=>to_updistype()" type='primary'>确定</Button>
				</div>
			</Modal>
			<Modal :closable='false' :mask-closable='false' v-model="is_show_createdIn">
				<Form :label-width="80">
					<FormItem label="上级uid">
						<Input v-model="higherUid" style="width: 200px;"></Input>
					</FormItem>
				</Form>
				<div  slot='footer'>
					<Button @click="()=>is_show_createdIn=false">返回</Button>
					<Button @click="()=>to_createdIn()" type='primary'>确定</Button>
				</div>
			</Modal>
			<Modal :closable='false' :mask-closable='false'  width="600"   v-model="is_show_edit">
				<Form :label-width="80">
					<FormItem label="商品名称">
						<Input disabled v-model="current_delet_info.name" style="width: 300px;" />
					</FormItem>
					<FormItem label="系统状态">
						<Input disabled  v-model="current_delet_info.onState" style="width: 300px;" />
					</FormItem>
					<FormItem label="小店可售">
						<i-Switch v-model="current_delet_info.active" true-value="激活"  false-value="未激活"></i-Switch>
					</FormItem>
					<FormItem label="库存模式">
						<Input disabled v-model="current_delet_info.isstock" style="width: 300px;" />
					</FormItem>
					<FormItem label="库存">
						<Input disabled v-model="current_delet_info.stock" style="width: 300px;"/>
					</FormItem>
					<FormItem label="售价">
						<Input v-model="current_delet_info.price" style="width: 300px;" type='number'/>
					</FormItem>
					<FormItem label="会员价">
						<Input  v-model="current_delet_info.memberPrice" style="width: 300px;" type='number' />
					</FormItem>
					<FormItem label="售卖时间">
						<Input disabled v-model="current_delet_info.addTime" style="width: 300px;" />
					</FormItem>
				</Form>
				
				<div slot='footer'>
					<Button @click="()=>is_show_edit=false">返回</Button>
					<Button @click="()=>save_edit()"  type="primary">确定</Button>
				</div>
			</Modal>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	let mcode;
	import { DisGetDateList,GetDisinDate_C,updis,updistype,createdIn,GetgoodsGiv,bindingGoods,GetbindingGoodslist,DelbindingGoods,SetbindingGoods } from '@/request/api.js'
	export default{
		name:'navbar',
		data() {
			return {
				is_show_edit:false,//编辑弹窗
				current_delet_info:'',
				is_show_delete:false,
				distribution_goods_list:[],
				distribution_goods_title:[],
				drop_list_distribution_goods_value:'',//分销产品
				drop_list_distribution_goods:[],//
				higherUid:'',
				is_show_createdIn:false,//创建分销商对话框是否显示
				editInfoCopy:{},
				edit_info:{
					type:'',
					level:''
				},
				type_list_config:['分销商','推广员','推广团长(未开放)'],
				is_show_inner_modal:false,//是否显示内部弹窗
				current_click_uid:'',//当前点击的表格行UID
				modal_full: false,
				title:[],//表头
				list:[],//数据集
				modal_full_info:'',//全屏弹窗数据
			}
		},
		async mounted() {
			mcode = localStorage.getItem('mcode')
			this.to_DisGetDateList()
			
			let pass_data = {
				"typeTag": "分销商品",
				"mcode":mcode
			}
			let {data:res_data} = await GetgoodsGiv(pass_data)
			this.drop_list_distribution_goods = res_data
		},
		watch:{
			async modal_full(val){
				if(val){
					this.flash()
				}
			}
		},
		methods:{
			edit(){
				this.edit_info= JSON.parse(JSON.stringify(this.editInfoCopy))
				this.is_show_inner_modal = true
			},
			reset(){
				this.drop_list_distribution_goods_value = ''
			},
			view(row){
				this.reset()
				this.current_click_uid = row.uid
				this.modal_full = true
			},
			Createthedealer(){
				this.higherUid = ''
				this.is_show_createdIn = true
			},
			async download_img(){
				let url =this.modal_full_info.qcodeurl+'?attname=1.png'
				window.open(url, '_blank');
			},
			// 保存编辑
			 async save_edit(){
				 let pass_data = {
					"uid": this.current_click_uid, //分销商uid
					"guid": this.current_delet_info.uid, //商品uid
					"active": (this.current_delet_info.active=='激活')?true:false, //是否激活
					"price": this.current_delet_info.price, //价格 0为默认系统价格
					"memberPrice":  this.current_delet_info.memberPrice //会员价 0为默认系统会员价
					}
					let res_data = await SetbindingGoods(pass_data)
					if(res_data.code==200){
						this.is_show_edit = false
						this.flash()
					}
			 },
			
			async delete_guid(row){
				let pass_data = {
					"uid": this.current_click_uid, 
					"guid": row.uid
					}
				let res_data = await DelbindingGoods(pass_data)
				if(res_data.code==200){
					this.is_show_delete=false
					this.flash()
				}
			},
			
			// 分销商品绑定
			async bind(){
				let pass_data = {
				"uid": this.current_click_uid, 
				"gid": this.drop_list_distribution_goods_value
				}
				let res_data = await bindingGoods(pass_data)
				if(res_data.code==200){
					this.flash()
				}
			},
			async to_createdIn(){
				let pass_data = {
					"mcode":[mcode],
					"higherUid":this.higherUid
				}
				let res_data = await createdIn(pass_data)
				if(res_data.code==200){
					this.is_show_createdIn=false
					this.$mytoast('创建成功')
				}
				this.to_DisGetDateList()
			},
			
			// 全屏弹窗 - 小弹窗确认
			async to_updistype(){
				let pass_data = {
					"uid": this.modal_full_info.uid, //分销商uid
					"type": this.edit_info.type, //类型 分销商 推广员推 广团长三选一
					"level": this.edit_info.level //等级必须大于0
					}
					let res_data = await updistype(pass_data)
					if(res_data.code==200){
						this.$mytoast('编辑成功')
						this.is_show_inner_modal=false
					}
					this.flash()
			},
			
			// 全屏弹窗数据获取
			async flash(){
				let pass_data = {
					"uid":this.current_click_uid
				}
				let {data : res_data} = await GetDisinDate_C(pass_data)
				this.edit_info.type = res_data.type
				this.edit_info.level = res_data.level
				this.modal_full_info = res_data
				this.editInfoCopy = JSON.parse(JSON.stringify(this.edit_info))
				let pass_data1 = {
					"uid": this.current_click_uid
				}
				let {data:res_data1} = await GetbindingGoodslist(pass_data1)
				res_data1.title.forEach(item=>{
					if(item.key=="placeObj"){
						item['slot'] = "placeObj",
						item['width'] = 200
					}
				})
				res_data1.title.push({
					title:'操作',
					key:'operation',
					slot:'operation',
					width:150
				})
				this.distribution_goods_title = res_data1.title
				this.distribution_goods_list = res_data1.list
			},
			
			// 停用或启用
			async stop_or_start(upval=1){
				let pass_data = {
					'uid':this.modal_full_info.uid,
					"upval":upval
				}
				let res_data = await updis(pass_data)
				if(res_data.code==200){
					if(upval==1){
						this.$mytoast('停用成功')
					}else{
						this.$mytoast('启用成功')
					}
				}
				this.flash()
			},
			
			// 跳转到新的标签页
			to_new_targe_page(row){
				window.open("/stay_single_fill_in?uid="+row.uid, '_blank');
			},
			
			async to_DisGetDateList(){
				let pass_data={
					"mcode":mcode,
					"page":1,
				}
				let { data : res_data} = await DisGetDateList(pass_data)
				res_data.title.push({
					title:'操作',
					key:'operation',
					slot:'operation',
					width:280
				})
				this.title = res_data.title
				this.list = res_data.list
			}
		},
	}
</script>

<style>
	.dw_fx .ivu-btn{
		width: 200px!important;
	}
	  .top,.bottom{
	        text-align: center;
	    }
	    .center{
	        width: 300px;
	        margin: 10px auto;
	        overflow: hidden;
	    }
	    .center-left{
	        float: left;
	    }
	    .center-right{
	        float: right;
	    }
	/* 修改默认按钮样式 */
	.right_btn .ivu-btn{
		width: 100px!important;
	}
	
	.pre_info span{
		border-left: 3px solid #ccc;
		font-weight: 700;
	}
</style>
