<template>
    <div>
        <div class="flex justify-between">
            <h1>{{ inlineForm.type }}</h1>
            <Form inline>
                <FormItem class="w-52" prop="">
                    <Select filterable v-model="inlineForm.type">
                        <Option v-for="(item, index) in directionCouponOrder" :key="index" :value="item.label">{{ item.value }}</Option>
                    </Select>
                </FormItem>
                <FormItem v-if="inlineForm.type.slice(0,1) == 'M'" class="w-52" prop="">
                    <Select v-model="inlineForm.Timetype">
                        <Option value="放映时间">放映时间</Option>
                        <Option value="交易时间">交易时间</Option>
                    </Select>
                </FormItem>
                <FormItem class="w-52" prop="">
                    <DatePicker
                        @on-change="(value) => (inlineForm.start = value)"
                        :value="inlineForm.start"
                        type="datetime"
                        format="yyyy-MM-ddTHH:mm"
                        placeholder="请输入开始时间"
                    ></DatePicker>
                </FormItem>
                <FormItem class="w-52" prop="">
                    <DatePicker
                        @on-change="(value) => (inlineForm.end = value)"
                        :value="inlineForm.end"
                        type="datetime"
                        format="yyyy-MM-ddTHH:mm"
                        placeholder="请输入结束时间"
                    ></DatePicker>
                </FormItem>
                <FormItem>
                    <Button @click="() => fetchData()" type="primary">查询</Button>
                </FormItem>
                <FormItem>
                    <Button @click="() => tableDownload()" type="primary">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div v-if="tableData" class="text-xl font-bold text-blue-500">{{ tableData.Note }}</div>
        <Table :columns="tableData.title" :data="tableData.list"></Table>
        <Card class="text-right">
            <Page @on-change="(value) => fetchData(value)" :total="tableData.total" :page-size="tableData.size"></Page>
        </Card>
    </div>
</template>
<script>
import { GetPresetValue, ComprehensiveReport } from '@/request/api';
import { download } from '@/utils/table_download.js';
export default {
    name: 'allInTable',
    data() {
        return {
            inlineForm: {
                Timetype: '交易时间',
                type: '',
                start: new Date().Format('yyyy-MM-dd') + 'T06:00',
                end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000).Format('yyyy-MM-dd') + 'T06:00',
                all: false,
            },
            directionCouponOrder: [],
            tableData: '',
        };
    },
    created() {
        this.init();
    },
    methods: {
        /**
         * 导出全部数据
         */
        async tableDownload() {
            let passdata = this.inlineForm;
            passdata['all'] = true;
            let res = await ComprehensiveReport(passdata);
            if (res.errcode != 0) return;
            let data = res.data.list;
            let headers = res.data.title;
            download({
                worksheet: '综合报表',
                headers,
                data,
            });
        },
        /**
         * 配置 获取
         */
        async init() {
            let res = await GetPresetValue({ type: '综合报表' });
            if (res.errcode != 0) return;
            if (res.data.length > 0) {
                this.inlineForm.type = res.data[0].value;
            }

            this.directionCouponOrder = res.data;
        },
        /**
         * 获取查询数据 表格
         */
        async fetchData(page = 1) {
            let passdata = this.inlineForm;
            passdata['page'] = page;
            passdata['all'] = false;
            let res = await ComprehensiveReport(passdata);
            if (res.errcode != 0) return;
            this.tableData = res.data;
        },
    },
};
</script>

