<template>
	<div>
		
		<div style="margin: 10px 0;text-align: right;">
			<Button @click="()=>is_show_add_user=true" type="primary">添加用户</Button>
		</div>

		<!-- 表格 -->
		<Table @on-row-click='(payload)=>current_select_row_data=payload' stripe :columns="columns1" :data="data1">

			<template slot-scope="{ row, index }" slot='state'>
				<!-- 用户启用，禁用操作 -->
				<div>
					<i-Switch @input='(payload)=>change_sitch(payload,row)' v-model="row.state" :true-value='true' :false-value='false' />
				</div>
			</template>
			<template slot-scope="{ row, index }" slot='title'>
				<div v-if="row.title">
					<Tag @on-close='()=>according_to_delete_dialog(item,row)' closable v-for="(item,index) in row.title">{{item.roleName}}</Tag>
				</div>
			</template>
			<!-- 操作 -->
			<template slot-scope="{ row, index }" slot='operation'>
				<div>
					<Button @click="()=>displays_edit_dialog=true" size='small' type="info">编辑</Button>
					<Button @click="()=>reset_pwd(row)" style="margin-left: 10px;" size='small' type="error">重置密码</Button>
				</div>
			</template>

		</Table>

		<Modal v-model='is_show_add_user' title='添加用户'>

			<p>
				<span style="width: 100px;display: inline-block;">账号：</span>
				<Input v-model="account" style="width: 300px;"></Input>

			</p>
			<p>
				<span style="width: 100px;display: inline-block;">用户名：</span>
				<Input v-model="username" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="width: 100px;display: inline-block;">密码：</span>
				<Input v-model="password" style="width: 300px;"></Input>
			</p>
			<p style="display: flex;align-items: center;">
				<span style="width: 100px;display: inline-block;">选择角色：</span>
				<Cascader :data="select_character_data" v-model="to_select_role"></Cascader>
			</p>
			<div slot='footer'>
				<Button @click="()=>is_show_add_user=false">取消</Button>
				<Button @click="()=>do_add_user()" type="primary">确定</Button>
			</div>

		</Modal>

		<Modal :title="'编辑用户信息-'+current_select_row_data.name" v-model='displays_edit_dialog'>

			<p>
				<span style="display: inline-block;width: 100px;">账号：</span>
				<Input disabled v-model="current_select_row_data.user" style="width: 300px;"></Input>
			</p>
			<p style="display: flex;">
				<span style="display: inline-block;width: 100px;"> 选中角色：</span>
				<Cascader style='width: 300px;' :data="select_character_data" v-model="to_select_role"></Cascader>
			</p>
			<div slot='footer'>
				<Button @click="()=>displays_edit_dialog=false">取消</Button>
				<Button @click="()=>submit_to_edit_character_information()" type="primary">确定</Button>
			</div>

		</Modal>

		<!-- 删除角色对话框 -->
		<Modal v-model='whether_to_delete_characters'>
			
			<div>
				是否删除
			</div>
			<div slot='footer'>
				<Button @click="()=>whether_to_delete_characters=false">取消</Button>
				<Button @click='()=>del_role()'  type='primary'>确定</Button>
			</div>
			
		</Modal>
		
	</div>
</template>

<script>
	
	import {
		md5
	} from '../../assets/js/md5.js'
	import {
		SetUserSRole,
		Set_UserForce,
		ResetPassword,
		GetUserList,
		CreateUser,
		GetStoreRoleDate,
		user_management
	} from '../../request/api.js'
	export default {
		name: 'navbar',
		data() {
			
			return {
				current_select_row_data:'',
				delete_select_item:'',
				whether_to_delete_characters: false, //是否显示删除角色对话框
				displays_edit_dialog: false, //显示编辑对话框
				select_character_data: [], //选择角色的级联数据
				username: '',
				account: '',
				password: '',
				whether_to_disable: 0,
				to_select_role: '',
				is_show_add_user: false, //是否显示添加用户对话框
				columns1: [{
						title: '账号',
						key: 'user'
					}, {
						title: '用户名',
						key: 'name'
					},
					{
						title: '角色',
						width: '300',
						key: 'title',
						slot: 'title',
						width:800
					},
					{
						title: '是否启用',
						key: 'state',
						slot: 'state'
					},
					{
						title: '创建时间',
						key: 'creation_time',

					},
					{
						title: '操作',
						key: 'operation',
						slot: 'operation'
					}
				],
				data1: [{
					'username': '123',
					'username': '123',
					'state': '123',
					'operation': '123',
				}]
			}
			
		},

		watch: {
			
			// 获取级联角色数据
			async is_show_add_user(val) {
				
				if (val) {
					this.to_select_role = [];
					let pass_data = {
						'branduid': JSON.parse(localStorage.getItem('选中品牌1')).brand.uid
					}

					let res_data = await GetStoreRoleDate(pass_data);
					
					this.select_character_data = res_data.data
				}
				
			},
			async displays_edit_dialog(val) {
				
				if (val) {
					this.to_select_role = [];
					let pass_data = {
						'branduid': JSON.parse(localStorage.getItem('选中品牌1')).brand.uid
					}
			
					let res_data = await GetStoreRoleDate(pass_data);
					
					this.select_character_data = res_data.data
				}
			}
			
		},

		mounted() {
			
			this.get_user_info()
			
		},

		methods: {
			
			// 显示删除对话框
			according_to_delete_dialog(item,row){
				
				this.current_select_row_data = row
				this.to_select_role = [
					item.mcode,
					item.roleuid
				]
				this.whether_to_delete_characters= true
				
			},
		
			// 删除角色
			async del_role(){
				
				let pass_data = {
					"useruid": this.current_select_row_data.uid, //用户uid
					"branduid": JSON.parse(localStorage.getItem('选中品牌1')).brand.uid, //品牌uid
					"mcode":this.to_select_role[0], //门店mcode
					"roleuid": this.to_select_role[1], //角色uid
					"type": "del" //操作类型 add添加权限角色 del删除权限角色
					}
					
				let res_data = await SetUserSRole(pass_data)
				
				if(res_data.code ==200){
					this.whether_to_delete_characters= false
						this.get_user_info()
					this.$mytoast(res_data.msg)
				}
				
			},
		
			// 编辑按钮 提交事件
			async submit_to_edit_character_information(){
				
				if(this.to_select_role==''){
					this.$mytoast_err('请选择角色')
					return
				}
				let pass_data = {
					"useruid": this.current_select_row_data.uid, //用户uid
					"branduid": JSON.parse(localStorage.getItem('选中品牌1')).brand.uid, //品牌uid
					"mcode":this.to_select_role[0], //门店mcode
					"roleuid": this.to_select_role[1], //角色uid
					"type": "add" //操作类型 add添加权限角色 del删除权限角色
					}
					
				let res_data = await SetUserSRole(pass_data)
				
				if(res_data.code ==200){
					this.displays_edit_dialog = false
						this.get_user_info()
					this.$mytoast(res_data.msg)
				}
				
			},
			
			async change_sitch(item,row){
				
				let pass_data = {
					'useruid':row.uid,
					"branduid":  JSON.parse(localStorage.getItem('选中品牌1')).brand.uid, 
					"inforce": item
					}
					
				let res_data = await Set_UserForce(pass_data)
				
				if(res_data.code==200){
					this.$mytoast(res_data.msg)
				}
				
			},
			
			// 重置密码
			async reset_pwd(row){
				
				let pass_data = {
					'useruid':row.uid
				}
				
				let res_data = await ResetPassword(pass_data)
				
				if(res_data.code == 200){
					this.$mytoast(res_data.msg)
				}
				
			},

			// 执行删除用户角色
			async to_delete_user_role() {

				let pass_data = {};
				
				let res_data = await xx();
				
				if (res_data.code == 200) {
					this.$mytoast('删除成功')
				}
				
			},

			// 添加用户表单提交
			async do_add_user() {
				
				let pass_data = {
					'user': this.account,
					'name': this.username,
					'pass': md5(this.password),
					'mcode': this.to_select_role[0],
					'branduid': JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
					'roleuid': this.to_select_role[1],
				}

				let res_data = await CreateUser(pass_data)

				if (res_data.code == 200) {
					this.is_show_add_user = false
					this.get_user_info()
					this.$mytoast('添加用户成功')
				}

			},

			// 获取用户信息
			async get_user_info() {
				
				let pass_data = {
					'branduid': JSON.parse(localStorage.getItem('选中品牌1')).brand.uid
				}
				
				// 用户管理页面
				let res_data = await GetUserList(pass_data)
				
				this.data1 = res_data.data
				
			}

		}
	}
</script>
