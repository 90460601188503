<template>
    <div>
       <Form>
            <FormItem prop="name">
                <Input v-model="form.name" />
            </FormItem>
            <FormItem prop="age">
                <Input v-model="form.age" />
            </FormItem>
        </Form>
        <div ref="test" style="width: 100px">123123</div>
        <Button @click="() => downloadImg(dataURL)">下载</Button>
        <div></div>
    </div>
</template>
<script>
import html2canvas from 'html2canvas';
export default {
    name: 'test_capture',
    data() {
        return {
            dataURL: '',
            form: {
                name: '',
                age: '',
            },
            rules: {
                name: { required: 'ture', trigger: 'blur', message: ' ' },
                age: { required: 'true', trigger: 'blur', message: ' ' },
            },
        };
    },
    mounted() {
        this.$refs['test'];
        html2canvas(this.$refs['test']).then((canvas) => {
            var url = canvas.toDataURL(); //这里上面不设值cors会报错
            this.dataURL = url;
        });
    },
    methods: {
        /**
         * 下载海报
         * @param {*} url 海报的地址
         */
        downloadImg(url) {
            var a = document.createElement('a'); //创建一个a标签 用来下载
            a.download = '注册二维码'; //设置下载的图片名称
            var event = new MouseEvent('click'); //增加一个点击事件
            //如果需要下载的话就加上这两句
            a.href = url; //此处的url为base64格式的图片资源
            a.dispatchEvent(event); //触发a的单击事件 即可完成下载
        },
    },
};
</script>
