<template>
	<div>

		<Button @click="btn1" type="primary">更新座位图</Button>
		<Button @click="btn2" type="dashed">更新POS端交易类型数据</Button> 
		<Button @click="btn3" type="success">更新POS端登录用户信息 </Button>
		<div>
			<pre>
				{{res}}
			</pre>
		</div>
	</div>
</template>

<script>
	import {
		setSeat_update,
		pos_update,
		poslogin_update
	} from '../../../request/api.js'
	export default {
		name: 'navbar',
		data(){
			
			return{
				res:''
			}
			
		},
		methods: {
			async btn1() {
				let baseURL = this.$store.state.baseURL;
				let res = await setSeat_update({
					baseURL
				})
				this.res = res
				console.log(res)
			},
			async btn2() {

					let baseURL = this.$store.state.baseURL;
					let res = await pos_update({
						baseURL
					})
					this.res = res
					console.log(res)
		
			},
			async btn3() {
				let baseURL = this.$store.state.baseURL;
				let res = await poslogin_update({
					baseURL
				})
				this.res = res
				console.log(res)
			}
		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
