import {
	get,
	post
} from "./http";

import ajax_config  from '../../url.json';
let baseUrl;

if (window.location.host.split(':')[0] == 'localhost') {
	baseUrl = ajax_config.devUrl; //开发环境
} else {
	baseUrl = ajax_config.runUrl; //生产环境
}

// 登录接口
const login = (pass_data) => post(baseUrl + "Min_Appcms/login", pass_data);



const Get_holdStores = () => get(baseUrl + "Min_Appcms/Get_holdStores");

// 设置门店
const Set_mcode = (pass_data) => post(baseUrl + "Min_Appcms/Set_mcode", pass_data);


// // 旧接口

const PostmemberPrepaidPhoneButton = (pass_data) => post(baseUrl + 'api/cloudData/SetMemberSell', pass_data);

const PostIdmemberPrepaidPhoneButton = (id, pass_data) => post(baseUrl + 'api/cloudData/SetMemberSell?id=' + id,
	pass_data);

const getmemberPrepaidPhoneButton = (pass_data) => get(baseUrl + 'api/cloudData/GetMemberSell',{
	params:pass_data
})

const filmPrice = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/backgroundSchedul',  pass_data)

// 强制更新
const filmPrice2 = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/backgroundSchedul', pass_data)

const setFilmPrices = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/setFilmPrices', pass_data);

const setSeat_render = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/UpsessionState', pass_data)

const setSeat_update = (pass_data) => get(baseUrl + 'api/GetseatingPlan', {
	params: pass_data
})

const pos_update = (pass_data) => get(baseUrl + 'api/GetPosPayType', {
	params: pass_data
})

const poslogin_update = (pass_data) => get(baseUrl + 'api/GetPosUser', {
	params: pass_data
})

const find_order = (pass_data) => get(baseUrl + 'api/cloudData/GetDataquery', {
	params: pass_data
})

const order_detail = (pass_data) => get(baseUrl + 'api/cloudData/GetOrderDatab', {
	params: pass_data
})

const coupons_detail = (pass_data) => get(baseUrl + 'api/cloudData/GetCouponsDatab', {
	params: pass_data
})

const yuangong_code = (pass_data) => get(baseUrl + 'api/cloudData/GetstaffPromotionCode', {
	params: pass_data
})

const offlinePaymentOrders = (pass_data) => get(baseUrl + 'api/cloudData/GetofflineData', {
	params: pass_data
})

const SetgoosType = (pass_data) => post(baseUrl + 'api/cloudData/SetgoosType', pass_data);

const GetgoosType = (pass_data) => get(baseUrl + 'api/cloudData/GetgoosType', {
	params: pass_data
})

const SetgoosTypeVisual = (pass_data) => post(baseUrl + 'api/cloudData/SetgoosTypeVisual', pass_data);

const DelgoosType = (pass_data) => post(baseUrl + 'api/cloudData/DelgoosType', pass_data);

const upimgfile = (mcode, pass_data) => post(baseUrl + 'api/cloudData/upimgfile?mcode=' + mcode, pass_data);

const getimglist = (pass_data) => get(baseUrl + 'api/cloudData/getimglist', {
	params: pass_data
})

const GetgoosOneTow = (pass_data) => get(baseUrl + 'api/cloudData/GetgoosOneTow', {
	params: pass_data
})

const AddgoosData = (pass_data) => post(baseUrl + 'api/cloudData/AddgoosData', pass_data);

const GetgoosList = (pass_data) => get(baseUrl + 'api/cloudData/GetgoosList', {
	params: pass_data
})

const GetgoosXData = (pass_data) => get(baseUrl + 'api/cloudData/GetgoosXData', {
	params: pass_data
})

const SetgoosXData = (pass_data_goods_obj, pass_data) => post(baseUrl + 'api/cloudData/SetgoosXData?id=' +
	pass_data_goods_obj.id + '&mcode=' + pass_data_goods_obj.mcode, pass_data);

const UpgoosStock = (pass_data) => post(baseUrl + 'api/cloudData/UpgoosStock', pass_data);

const UpgoosOnState = (pass_data) => post(baseUrl + 'api/cloudData/UpgoosOnState', pass_data);

const salesOrderSummary = (pass_data) => get(baseUrl + 'api/cloudData/salesOrderSummary', {
	params: pass_data
})


const cms_libraries = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/cms_libraries', {
	params: pass_data
})

const cms_uplibraries = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/cms_uplibraries', {
	params: pass_data
})

const cms_addMinprice = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/cms_addMinprice', pass_data);


// 退票申请
const GetOrderDatab = (pass_data) => get(baseUrl + 'api/cloudData/GetOrderDatab', {
	params: pass_data
})

// 退款
const OrderRefund = (pass_data) => post(baseUrl + 'api/cloudData/OrderRefund', pass_data);

// 获取门店设置数据 

const Get_StoreDate = (pass_data) => get(baseUrl + 'Min_Appcms/Get_StoreDate', {
	params: pass_data
})

// 设置门店数据
const Set_Store = (pass_data) => post(baseUrl + 'Min_Appcms/Set_Store', pass_data)

// 推送门店数据到小程序
const PushStore = (pass_data) => post(baseUrl + 'Min_Appcms/PushStore', pass_data)

// 创建页面 
const createPage = (pass_data) => post(baseUrl + 'Min_Appcms/createPage', pass_data)

// 获取顶级菜单
const GettopMenu = (pass_data) => get(baseUrl + 'Min_Appcms/GettopMenu', {
	params: pass_data
})

// 获取菜单列表 
const GetMenu = (pass_data) => get(baseUrl + 'Min_Appcms/GetMenu', {
	params: pass_data
})

// 设置可用菜单 
const SetMenu = (pass_data) => post(baseUrl + 'Min_Appcms/SetMenu', pass_data)

// 获取品牌角色列表 
const GetRoles = (pass_data) => get(baseUrl + 'Min_Appcms/GetRoles', {
	params: pass_data
})


// 创建品牌角色 
const CreateRoles = (pass_data) => post(baseUrl + 'Min_Appcms/CreateRoles', pass_data)

// 获取角色菜单
const GetRoleMenu = (pass_data) => get(baseUrl + 'Min_Appcms/GetRoleMenu', {
	params: pass_data
})

// 创建品牌角色 
const SetRoleMenu = (pass_data) => post(baseUrl + 'Min_Appcms/SetRoleMenu', pass_data)

// 删除品牌角色 
const DelRole = (pass_data) => post(baseUrl + 'Min_Appcms/DelRole', pass_data)

// 获取二级菜单
const GettwopMenu = (pass_data) => get(baseUrl + 'Min_Appcms/GettwopMenu', {
	params: pass_data
})

// 创建权限按钮 
const CreateButton = (pass_data) => post(baseUrl + 'Min_Appcms/CreateButton', pass_data)

// 获取页面权限按钮 
const GetPageButton = (pass_data) => get(baseUrl + 'Min_Appcms/GetPageButton', {
	params: pass_data
})

// 设置可用页面按钮
const SetPageButton = (pass_data) => post(baseUrl + 'Min_Appcms/SetPageButton', pass_data)

// 获取角色页面按钮 /获取角色页面按钮
const GetrolePageButton = (pass_data) => get(baseUrl + 'Min_Appcms/GetRoleBut', {
	params: pass_data
})

// 设置角色按钮数据 
const SetRoleButDate = (pass_data) => post(baseUrl + 'Min_Appcms/SetRoleButDate', pass_data)


// 获取品牌门店角色
const GetStoreRoleDate = (pass_data) => get(baseUrl + 'Min_Appcms/GetStoreRoleDate', {
	params: pass_data
})

// 创建用户 
const CreateUser = (pass_data) => post(baseUrl + 'Min_Appcms/CreateUser', pass_data)

// 获取品牌用户列表
const GetUserList = (pass_data) => get(baseUrl + 'Min_Appcms/GetUserList', {
	params: pass_data
})

// 重置用户密码
const ResetPassword = (pass_data) => post(baseUrl + 'Min_Appcms/ResetPassword', pass_data)

// 用户修改密码
const UserSetPassword = (pass_data) => post(baseUrl + 'Min_Appcms/UserSetPassword', pass_data)

// 品牌禁用启用用户
const Set_UserForce = (pass_data) => post(baseUrl + 'Min_Appcms/Set_UserForce', pass_data)

// 设置用户权限角色 
const SetUserSRole = (pass_data) => post(baseUrl + 'Min_Appcms/SetUserSRole', pass_data)

// 获取用户页面按钮搜全
const GetButMandate = (pass_data) => get(baseUrl + 'Min_Appcms/GetButMandate', {
	params: pass_data
})

// 管理员进入管理页
const Adminlogin = (pass_data) => get(baseUrl + 'Min_Appcms/Adminlogin', {
	params: pass_data
})

// 修改页面排序 
const SetPageSort = (pass_data) => post(baseUrl + 'Min_Appcms/SetPageSort', pass_data)

// 获取品牌列表
const Get_BrandList = (pass_data) => get(baseUrl + 'Min_Appcms/Get_BrandList', {
	params: pass_data
})

// 创建品牌
const CreateBrand = (pass_data) => post(baseUrl + 'Min_Appcms/CreateBrand', pass_data)

// 获取品牌数据
const Get_BrandDate = (pass_data) => get(baseUrl + 'Min_Appcms/Get_BrandDate', {
	params: pass_data
})

// 设置品牌数据
const Set_BrandDate = (pass_data) => post(baseUrl + 'Min_Appcms/Set_BrandDate', pass_data)

// 搜索用户 
const searchUser = (pass_data) => get(baseUrl + 'Min_Appcms/searchUser', {
	params: pass_data
})

// 获取品牌管理员列表 
const Get_BrandAdmin = (pass_data) => get(baseUrl + 'Min_Appcms/Get_BrandAdmin', {
	params: pass_data
})

// 品牌管理员任免设置 
const BrandAdmin_appoint = (pass_data) => post(baseUrl + 'Min_Appcms/BrandAdmin_appoint', pass_data)

// 是否品牌管理员 
const isBrandManager = (pass_data) => get(baseUrl + 'Min_Appcms/isBrandManager', {
	params: pass_data
})

// 品牌管理员进入 
const Brandmanagerlonin = (pass_data) => get(baseUrl + 'Min_Appcms/Brandmanagerlonin', {
	params: pass_data
})

// 获取品牌门店列表
const GetStore_list = (pass_data) => get(baseUrl + 'Min_Appcms/GetStore_list', {
	params: pass_data
})

// 添加品牌门店 
const addStore = (pass_data) => post(baseUrl + 'Min_Appcms/addStore', pass_data)

// 获取拉新数据 
const GetpullNewData = (pass_data) => get(baseUrl + 'Min_Appcms/GetpullNewData', {
	params: pass_data
})

// 获取电影票售票数据 
const ticketSalesData = (pass_data) => get(baseUrl + 'Min_Appcms/ticketSalesData', {
	params: pass_data
})

// 获取票券模板订单列表
const GetCouponlist = (pass_data) => post(baseUrl + 'Min_Appcms/GetCouponlist',  pass_data)

// 创建票券模板订单
const CouponCreat = (pass_data) => post(baseUrl + 'Min_Appcms/CouponCreat', pass_data)

// 获取票券模板订单数据
const GetCouponDate = (pass_data) => get(baseUrl + 'Min_Appcms/GetCouponDate', {
	params: pass_data
})

// 修改票券模板订单 
const upCoupon = (pass_data) => post(baseUrl + 'Min_Appcms/upCoupon', pass_data)
// 提交票券订单审批

const submitAudit = (pass_data) => post(baseUrl + 'Min_Appcms/submitAudit', pass_data)
// 票券模板订单审批接口 
const approval = (pass_data) => post(baseUrl + 'Min_Appcms/approval', pass_data)

// 设置票券为备用金 
const SetCouponaShotInLocker = (pass_data) => post(baseUrl + 'Min_Appcms/SetCouponaShotInLocker', pass_data)

// 票券订单 - 操作- 查看 -表格数据获取
const GetCascodlog = (pass_data) => get(baseUrl + 'Min_Appcms/GetCascodlog', {
	params: pass_data
})
// 获取批次券码
const Getapplycode = (pass_data) => get(baseUrl + 'Min_Appcms/Getapplycode', {
	params: pass_data
})

// 获取座位图
const PosSeatPlan = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/PosSeatPlan', {
	params: pass_data
})

// 添加套餐选项组
const addtap = (pass_data) => post(baseUrl + 'api/cloudData/addtap', pass_data)

// 获取套餐选项组 复
const gettap = (pass_data) => get(baseUrl + 'api/cloudData/gettap', {
	params: pass_data
})
// 删除分组或选项
const deltapo = (pass_data) => post(baseUrl + 'api/cloudData/deltapo', pass_data)

// 设置套餐选项默认
const setoptionsdef = (pass_data) => post(baseUrl + 'api/cloudData/setoptionsdef', pass_data)

// 设置套餐选项
const setoptions = (pass_data) => post(baseUrl + 'api/cloudData/setoptions', pass_data)
// 获取可用活动类票券订单
const GetActivityCoupons = (pass_data) => get(baseUrl + 'Min_Appcms/GetActivityCoupons', {
	params: pass_data
})

// 获取套票绑定模板数据
const GetgoosbindingCoupon = (pass_data) => get(baseUrl + 'api/cloudData/GetgoosbindingCoupon', {
	params: pass_data
})

// 库存绑定 - 解绑
const goosbindingCoupon = (pass_data) => post(baseUrl + 'api/cloudData/goosbindingCoupon', pass_data)

// 获取绑定数据列表
const getbindingFreeTickets = (pass_data) => get(baseUrl + 'api/cloudData/getbindingFreeTickets', {
	params: pass_data
})

// 绑定充值活动赠送券
const bindingFreeTickets = (pass_data) => post(baseUrl + 'api/cloudData/bindingFreeTickets', pass_data)

// 获取门店座位图列表 
const GetseatPlanList = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/GetseatPlanList', {
	params: pass_data
})

// 同步数据
const data_GetseatingPlan = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/GetseatingPlan', {
	params: pass_data
})

// 猫眼场次同步影厅底图 
const MaoyanseatPlan = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/MaoyanseatPlan', pass_data)
// 获取座位图底图渲染数据
const GetseatPlan = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/GetseatPlan', {
	params: pass_data
})
// 设置座位图渲染模式
const SetseatPlantype = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/SetseatPlantype', pass_data)

const addStrategy = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/addStrategy', pass_data)

const GetStrategyList = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/GetStrategyList', {
	params: pass_data
})
const SetStrategy = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/SetStrategy', pass_data)

const Gterecenttitle = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/Gterecenttitle', {
	params: pass_data
})

// 获取影厅名称 
const GteScreensList = (pass_data) => get(baseUrl + 'Min_Appcms/map/api/GteScreensList', {
	params: pass_data
})

// 获取卖品销售明细
const sellSalesData = (pass_data) => get(baseUrl + 'Min_Appcms/sellSalesData', {
	params: pass_data
})

// 会员查询
const memberQueryv1 = (pass_data) => get(baseUrl + 'Min_Appcms/memberQueryv1', {
	params: pass_data
})
const memberQuery = (pass_data) => get(baseUrl + 'Min_Appcms/memberQuery', {
	params: pass_data
})

// 会员订单详细数据
const memberOrderQuery = (pass_data) => get(baseUrl + 'Min_Appcms/memberOrderQuery', {
	params: pass_data
})
const memberOrderQueryv1 = (pass_data) => get(baseUrl + 'Min_Appcms/memberOrderQueryv1', {
	params: pass_data
})
// 会员卡查询
const memberCardQuery = (pass_data) => get(baseUrl + 'Min_Appcms/memberCardQuery', {
	params: pass_data
})
const memberCardQueryv1 = (pass_data) => get(baseUrl + 'Min_Appcms/memberCardQueryv1', {
	params: pass_data
})
// 获取七牛云上传tk
const getupTK = (pass_data) => get(baseUrl + 'Min_Appcms/GetQiniuyunToken', pass_data);

// 会员卡消费查询 
const memberCardConsumeQuery = (pass_data) => get(baseUrl + 'Min_Appcms/memberCardConsumeQuery', {
	params:pass_data
});
const memberCardConsumeQueryv1 = (pass_data) => get(baseUrl + 'Min_Appcms/memberCardConsumeQueryv1', {
	params:pass_data
});


// 获取兑换卖品列表
const GetsellProduct = (pass_data) => post(baseUrl + 'Min_Appcms/GetsellProduct', pass_data);

// 获取品牌配置 
const GetConfig = (pass_data) => get(baseUrl + 'Min_Appcms/GetConfig', {
	params:pass_data
});

// 设置品牌配置 
const SetConfig = (pass_data) => post(baseUrl + 'Min_Appcms/SetConfig', pass_data);
// 获取设备
const getEquipment = (pass_data) => get(baseUrl + 'Min_Appcms/getEquipment', {
	params:pass_data
});
const setEquipment = (pass_data) => get(baseUrl + 'Min_Appcms/setEquipment', {
	params:pass_data
});

const GetgoodsGiv = (pass_data) => get(baseUrl + 'api/cloudData/GetgoodsGiv', {
	params:pass_data
});

// 获取分销商基本数据列表
const DisGetDateList = (pass_data) => get(baseUrl + 'Min_Appcms/DisGetDateList', {
	params:pass_data
});
const GetDisinDate = (pass_data) => post(baseUrl + 'GetDisinDate', pass_data);

// 提交入驻单
const UpDisinDate = (pass_data) => post(baseUrl + 'UpDisinDate', pass_data);

// 获取分销商详细数据
const GetDisinDate_C = (pass_data) => get(baseUrl + 'Min_Appcms/GetDisinDate_C', {
	params:pass_data
});

const updis = (pass_data) => post(baseUrl + 'Min_Appcms/updis', pass_data);
// 编辑分销商类型等级
const updistype = (pass_data) => post(baseUrl + 'Min_Appcms/updistype', pass_data);

const createdIn = (pass_data) => post(baseUrl + 'Min_Appcms/createdIn', pass_data);

// 分销商绑定商品
const bindingGoods = (pass_data) => post(baseUrl + 'Min_Appcms/bindingGoods', pass_data);

// 获取分销商绑定商品列表
const GetbindingGoodslist = (pass_data) => get(baseUrl + 'Min_Appcms/GetbindingGoodslist', {
	params:pass_data
});

// 获取分销商绑定商品列表
const DelbindingGoods = (pass_data) => post(baseUrl + 'Min_Appcms/DelbindingGoods', pass_data);

// 设置绑定商品数据
const SetbindingGoods = (pass_data) => post(baseUrl + 'Min_Appcms/SetbindingGoods', pass_data);

// 创建营销活动
const ActivityCreate = (pass_data) => post(baseUrl + 'Min_Appcms/ActivityCreate', pass_data);

// 获取营销活动 
const ActivityGetList = (pass_data) => post(baseUrl + 'Min_Appcms/ActivityGetList',pass_data);

// 获取选项
const SelectionGet = (pass_data) => post(baseUrl + 'Min_Appcms/SelectionGet',pass_data);

// 获取营销活动内容
const GetActivityContent = (pass_data) => post(baseUrl + 'Min_Appcms/GetActivityContent',pass_data);

// 设置营销活动内容
const SetActivityContent = (pass_data) => post(baseUrl + 'Min_Appcms/SetActivityContent',pass_data);

// 管理系统
// 全局参数
// 扫码有礼添加礼品列表
const addActivityGivlist = (pass_data) => post(baseUrl + 'Min_Appcms/addActivityGivlist',pass_data);
// 扫码有礼删除礼品列表
const delActivityGivlist = (pass_data) => post(baseUrl + 'Min_Appcms/delActivityGivlist',pass_data);
// 设置营销活动状态
const SetState = (pass_data) => post(baseUrl + 'Min_Appcms/SetState',pass_data);

// 获取二维码
const getCodeGenerat = (pass_data) => post(baseUrl + 'Min_Appcms/getCodeGenerat',pass_data);

// POS交易用户功能
 const PayUser = (pass_data) => post(baseUrl + 'Min_Appcms/map/PayUser',pass_data);
 
 // POS交易类型功能
 const PayType = (pass_data) => post(baseUrl + 'Min_Appcms/map/apiv3/open/PayType',pass_data);

// 【过渡兼容】强制设置座位图底图
 const ForceSetseat = (pass_data) => post(baseUrl + 'Min_Appcms/map/api/ForceSetseat',pass_data);
 
 // 查找网售账户
 const GetNetSalesUser = (pass_data) => post(baseUrl + 'Min_Appcms/GetNetSalesUser',pass_data);

// 获取网售账户相关信息
 const GetNetSalesUserInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetNetSalesUserInfo',pass_data);
 
 // 查找会员卡
  const handleMemberCardQuery = (pass_data) => post(baseUrl + 'Min_Appcms/memberCardQuery',pass_data);
 
 // 创建票券申请订单
   const CouponOrderCreate = (pass_data) => post(baseUrl + 'Min_Appcms/CouponOrderCreate',pass_data);
 
 // 获取票券订单预设置值
    const CouponOrderPresetValue = (pass_data) => post(baseUrl + 'Min_Appcms/GetPresetValue',pass_data);
 const GetPresetValue = (pass_data) => post(baseUrl + 'Min_Appcms/GetPresetValue',pass_data);
 // 获取票券订单列表
     const CouponOrderList = (pass_data) => post(baseUrl + 'Min_Appcms/CouponOrderList',pass_data);
 
 // 获取票券订单详细数据
 const CouponOrderData = (pass_data) => post(baseUrl + 'Min_Appcms/CouponOrderData',pass_data);
 
 // 创建充值策略
 const MenCreateRecharge = (pass_data) => post(baseUrl + 'Min_Appcms/MenCreateRecharge',pass_data);
 
 // 获取充值策略列表
 const GetRechargeList = (pass_data) => post(baseUrl + 'Min_Appcms/GetRechargeList',pass_data);
 
 // 获取充值策略详情
 const GetRechargeInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetRechargeInfo',pass_data);
 
 // 编辑充值策略
 const SetRechargeInfo = (pass_data) => post(baseUrl + 'Min_Appcms/SetRechargeInfo',pass_data);
 
 // 获取员工推广码列表
  const GetEmCodeList = (pass_data) => post(baseUrl + 'Min_Appcms/GetEmCodeList',pass_data);
 
 // 编辑员工码
 const EditEmployeeCode = (pass_data) => post(baseUrl + 'Min_Appcms/EditEmployeeCode',pass_data);
 
 // 创建员工码
  const GenerateEmployeeCode = (pass_data) => post(baseUrl + 'Min_Appcms/GenerateEmployeeCode',pass_data);
  
  
  // 后台查找小程序用户
  const FindAppuser = (pass_data) => post(baseUrl + 'Min_Appcms/FindAppuser',pass_data);

// 编辑小程序用户数据
  const EditAppuser = (pass_data) => post(baseUrl + 'Min_Appcms/EditAppuser',pass_data);
  const GetGoodBaseList = (pass_data) => post(baseUrl + 'Min_Appcms/GetGoodBaseList',pass_data);
  
  const CreateGoodBase = (pass_data) => post(baseUrl + 'Min_Appcms/CreateGoodBase',pass_data);
  const EditGoodBase = (pass_data) => post(baseUrl + 'Min_Appcms/EditGoodBase',pass_data);
  const GoodGroupType = (pass_data) => post(baseUrl + 'Min_Appcms/GoodGroupType',pass_data);
  const MerGroupType = (pass_data) => post(baseUrl + 'Min_Appcms/MerGroupType',pass_data);
  const GetGoodsGatherList = (pass_data) => post(baseUrl + 'Min_Appcms/GetGoodsGatherList',pass_data);
  const CreateGoodsGather = (pass_data) => post(baseUrl + 'Min_Appcms/CreateGoodsGather',pass_data);
  const EditGoodsGather = (pass_data) => post(baseUrl + 'Min_Appcms/EditGoodsGather',pass_data);
 
 // 操作门店拓展数据
 const OperStoresExDate = (pass_data) => post(baseUrl + 'Min_Appcms/OperStoresExDate',pass_data);
 // 价格方案设置

 //const PriceInfo = (pass_data) => post(baseUrl + 'Min_Appcms/map/PriceInfo',pass_data);
const PriceInfo = (pass_data) => post(baseUrl + 'Min_Appcms/PriceInfo',pass_data);
 const SeatInfoUpDate = (pass_data) => post(baseUrl + 'Min_Appcms/map/SeatInfoUpDate',pass_data);
// 同步外部系统商品信息
 const MerDataSyn = (pass_data) => post(baseUrl + 'Min_Appcms/MerDataSyn',pass_data);
 // 获取商品信息
 const GetMerData = (pass_data) => post(baseUrl + 'Min_Appcms/GetMerData',pass_data);
 const EditMerData = (pass_data) => post(baseUrl + 'Min_Appcms/EditMerData',pass_data);
 const GetCouponRule = (pass_data) => post(baseUrl + 'Min_Appcms/GetCouponRule',pass_data);
 const QueryAppUser = (pass_data) => post(baseUrl + 'Min_Appcms/QueryAppUser',pass_data);
 const GetAppUserInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetAppUserInfo',pass_data);
 const GetMemCardList = (pass_data) => post(baseUrl + 'Min_Appcms/GetMemCardList',pass_data);
 const MemCardInfo = (pass_data) => post(baseUrl + 'Min_Appcms/MemCardInfo',pass_data);
 const GetMemberPayList = (pass_data) => post(baseUrl + 'Min_Appcms/map/GetMemberPayList',pass_data);
 const GetMemberPayInfoV = (pass_data) => post(baseUrl + 'Min_Appcms/map/GetMemberPayInfoV',pass_data);
 const UNCouponBind = (pass_data) => post(baseUrl + 'Min_Appcms/UNCouponBind',pass_data);
 const GetLvGroupList = (pass_data) => post(baseUrl + 'Min_Appcms/GetLvGroupList',pass_data);
 const LvGroup = (pass_data) => post(baseUrl + 'Min_Appcms/LvGroup',pass_data);
 const GetPlan = (pass_data) => post(baseUrl + 'Min_Appcms/map/GetPlan',pass_data);
 const NetPayTermGetList = (pass_data) => post(baseUrl + 'Min_Appcms/NetPayTermGetList',pass_data);
 const GetAddSetTerm = (pass_data) => post(baseUrl + 'Min_Appcms/GetAddSetTerm',pass_data);
 const SetTermExe = (pass_data) => post(baseUrl + 'Min_Appcms/SetTermExe',pass_data);
 const OperationChart = (pass_data) => post(baseUrl + 'Min_Appcms/OperationChart',pass_data);
 const GetFilmList = (pass_data) => post(baseUrl + 'Min_Appcms/map/GetFilmList',pass_data);
 const GetFeedbackListDataCms = (pass_data) => post(baseUrl + 'Min_Appcms/GetFeedbackListDataCms',pass_data);
 const ReplyToFeedbackCms = (pass_data) => post(baseUrl + 'Min_Appcms/ReplyToFeedbackCms',pass_data);
 const OrderList = (pass_data) => post(baseUrl + 'Min_Appcms/OrderList',pass_data);
 const GetOrserInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetOrserInfo',pass_data);
 const OrderRefundExe = (pass_data) => post(baseUrl + 'Min_Appcms/OrderRefundExe',pass_data);
 const GetRefundInList = (pass_data) => post(baseUrl + 'Min_Appcms/GetRefundInList',pass_data);
 const RefundReject = (pass_data) => post(baseUrl + 'Min_Appcms/RefundReject',pass_data);
 const CouponOrderExe = (pass_data) => post(baseUrl + 'Min_Appcms/CouponOrderExe',pass_data);
 const SetCmstPlan = (pass_data) => post(baseUrl + 'Min_Appcms/map/SetCmstPlan',pass_data);
 const GetTicketList = (pass_data) => post(baseUrl + 'Min_Appcms/GetTicketList',pass_data);
 const ComprehensiveReport = (pass_data) => post(baseUrl + 'Min_Appcms/ComprehensiveReport',pass_data);

 const CreateCouponRule = (pass_data) => post(baseUrl + 'Min_Appcms/CreateCouponRule',pass_data);
 const EditCouponRuleDetail = (pass_data) => post(baseUrl + 'Min_Appcms/EditCouponRuleDetail',pass_data);
 const memberCardPayData = (pass_data) => post(baseUrl + 'Min_Appcms/memberCardPayData',pass_data);
 const QuerycouponCode = (pass_data) => post(baseUrl + 'Min_Appcms/QuerycouponCode',pass_data);
 const AppUserRCard = (pass_data) => post(baseUrl + 'Min_Appcms/AppUserRCard',pass_data);
 const CreateActivity = (pass_data) => post(baseUrl + 'Min_Appcms/CreateActivity',pass_data);
 const GetActivitylist = (pass_data) => post(baseUrl + 'Min_Appcms/GetActivitylist',pass_data);
 const ActivityExe = (pass_data) => post(baseUrl + 'Min_Appcms/ActivityExe',pass_data);
 const  GetDYStoreInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetDYStoreInfo',pass_data);
 const  SetDYStoreInfo = (pass_data) => post(baseUrl + 'Min_Appcms/SetDYStoreInfo',pass_data);
 const  GetDyGoosList = (pass_data) => post(baseUrl + 'Min_Appcms/GetDyGoosList',pass_data);
 const  ExeDyGoos = (pass_data) => post(baseUrl + 'Min_Appcms/ExeDyGoos',pass_data);
 const  GetTiktokShopList = (pass_data) => post(baseUrl + 'Min_Appcms/GetTiktokShopList',pass_data);
 const  GetGoosCashRecordList = (pass_data) => post(baseUrl + 'Min_Appcms/GetGoosCashRecordList',pass_data);
 const  GetGoosCashRecordInfo = (pass_data) => post(baseUrl + 'Min_Appcms/GetGoosCashRecordInfo',pass_data);
 const  RevokeCash = (pass_data) => post(baseUrl + 'Min_Appcms/RevokeCash',pass_data); 
 const  SetDYStoreOpen = (pass_data) => post(baseUrl + 'Min_Appcms/SetDYStoreOpen',pass_data); 
 const BluePencilExe = (pass_data) => post(baseUrl + 'Min_Appcms/BluePencilExe',pass_data); 
 const GetTiktokShop = (pass_data) => post(baseUrl + 'Min_Appcms/GetTiktokShop',pass_data); 
 const GetCouponAppLog = (pass_data) => post(baseUrl + 'Min_Appcms/GetCouponAppLog',pass_data); 
 const CheckmanualEdit = (pass_data) => post(baseUrl + 'Min_Appcms/CheckmanualEdit',pass_data); 
 const ManualEdit = (pass_data) => post(baseUrl + 'Min_Appcms/ManualEdit',pass_data); 
 const DelMer = (pass_data) => post(baseUrl + 'Min_Appcms/DelMer',pass_data); 
 const OperationHoliday = (pass_data) => post(baseUrl + 'Min_Appcms/OperationHoliday',pass_data); 
 const OperationCustomer = (pass_data) => post(baseUrl + 'Min_Appcms/OperationCustomer ',pass_data); 
 const OperationRefund= (pass_data) => post(baseUrl + 'Min_Appcms/OperationRefund',pass_data); 
 const ThirdPartyOpenQuery = (pass_data) => post(baseUrl + 'Min_Appcms/ThirdPartyOpenQuery',pass_data); 
 const ThirdPartyOpenOrderRefund= (pass_data) => post(baseUrl + 'Min_Appcms/ThirdPartyOpenOrderRefund',pass_data); 
 const GiftTickets= (pass_data) => post(baseUrl + 'Min_Appcms/GiftTickets',pass_data); 
 const SetCouponState= (pass_data) => post(baseUrl + 'Min_Appcms/SetCouponState',pass_data); 
 const GetSysTicketList= (pass_data) => post(baseUrl + 'Min_Appcms/GetSysTicketList',pass_data); 
 const GetSysTicketRules= (pass_data) => post(baseUrl + 'Min_Appcms/GetSysTicketRules',pass_data); 
 const goLiveStore= (pass_data) => post(baseUrl + 'Min_Appcms/goLiveStore',pass_data); 
 const UpgetGlobal= (pass_data) => post(baseUrl + 'Min_Appcms/UpgetGlobal',pass_data); 
 const GetActivityGoogs= (pass_data) => post(baseUrl + 'Min_Appcms/GetActivityGoogs',pass_data); 
 const queryRefSessions= (pass_data) => post(baseUrl + 'Min_Appcms/queryRefSessions',pass_data); 
 const seqNoGetPayOrder= (pass_data) => post(baseUrl + 'Min_Appcms/seqNoGetPayOrder',pass_data); 
const refOrderCommit = (pass_data) => post(baseUrl + 'Min_Appcms/refOrderCommit',pass_data)
const OperationPurchase= (pass_data) => post(baseUrl + 'Min_Appcms/OperationPurchase',pass_data)
const querCancelPurch= (pass_data) => post(baseUrl + 'Min_Appcms/querCancelPurch',pass_data)
const OperationOrderLink= (pass_data) => post(baseUrl + 'Min_Appcms/OperationOrderLink',pass_data)

const findManyBenefitIncr = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/benefitIncr/findManyBenefitIncr',pass_data)
const editOrCreate = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/benefitIncr/editOrCreate',pass_data)
const saveMembStraPlan = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/membStra/saveMembStraPlan',pass_data)
const queryMembStradraft = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/membStra/queryMembStradraft',pass_data)
const deleteBenefit = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/benefitIncr/deleteBenefit',pass_data)
const queryBenefitItem = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/benefitIncr/queryBenefitItem',pass_data)
const currentVersion = (pass_data) => post(baseUrl + 'Min_Appcms/mapUserLevel/membStra/currentVersion',pass_data)





 

 const OperationNotice = (pass_data) => post(baseUrl+"Min_appcms/OperationNotice",pass_data)
export {
    login,
    Get_holdStores,
    Set_mcode,
    PostmemberPrepaidPhoneButton, //旧接口
    PostIdmemberPrepaidPhoneButton,
    getmemberPrepaidPhoneButton,
    filmPrice,
    setFilmPrices,
    setSeat_render,
    setSeat_update,
    pos_update,
    poslogin_update,
    find_order,
    order_detail,
    coupons_detail,
    yuangong_code,
    offlinePaymentOrders,
    SetgoosType,
    GetgoosType,
    SetgoosTypeVisual,
    DelgoosType,
    upimgfile,
    getimglist,
    GetgoosOneTow,
    AddgoosData,
    GetgoosList,
    GetgoosXData,
    SetgoosXData,
    UpgoosStock,
    UpgoosOnState,
    salesOrderSummary,
    cms_libraries,
    cms_uplibraries,
    cms_addMinprice,
    GetOrderDatab,
    OrderRefund,
    filmPrice2,
    Get_StoreDate,
    Set_Store,
    PushStore,
    createPage,
    GettopMenu,
    GetMenu,
    SetMenu,
    GetRoles,
    CreateRoles,
    GetRoleMenu,
    SetRoleMenu,
    DelRole,
    GettwopMenu,
    CreateButton,
    GetPageButton,
    SetPageButton,
    GetrolePageButton,
    SetRoleButDate,
    GetStoreRoleDate,
    CreateUser,
    GetUserList,
    ResetPassword,
    UserSetPassword,
    Set_UserForce,
    SetUserSRole,
    GetButMandate,
    Adminlogin,
    SetPageSort,
    Get_BrandList,
    CreateBrand,
    Get_BrandDate,
    Set_BrandDate,
    searchUser,
    Get_BrandAdmin,
    BrandAdmin_appoint,
    isBrandManager,
    Brandmanagerlonin,
    GetStore_list,
    addStore,
    GetpullNewData,
    ticketSalesData,
    GetCouponlist,
    CouponCreat,
    GetCouponDate,
    upCoupon,
    submitAudit,
    approval,
    SetCouponaShotInLocker,
    GetCascodlog,
    Getapplycode,
    PosSeatPlan,
    addtap,
    gettap,
    deltapo,
    setoptionsdef,
    setoptions,
    GetActivityCoupons,
    GetgoosbindingCoupon,
    goosbindingCoupon,
    getbindingFreeTickets,
    bindingFreeTickets,
    GetseatPlanList,
    data_GetseatingPlan,
    MaoyanseatPlan,
    GetseatPlan,
    SetseatPlantype,
    addStrategy,
    GetStrategyList,
    SetStrategy,
    Gterecenttitle,
    GteScreensList,
    sellSalesData,
    memberQuery,
    memberOrderQuery,
    memberCardQuery,
    getupTK,
    memberCardConsumeQuery,
    GetsellProduct,
    GetConfig,
    SetConfig,
    getEquipment,
    setEquipment,
    GetgoodsGiv,
    DisGetDateList,
    GetDisinDate,
    UpDisinDate,
    GetDisinDate_C,
    updis,
    updistype,
    createdIn,
    bindingGoods,
    GetbindingGoodslist,
    DelbindingGoods,
    SetbindingGoods,
    ActivityGetList,
    SelectionGet,
    GetActivityContent,
    ActivityCreate,
    addActivityGivlist,
    delActivityGivlist,
    SetActivityContent,
    SetState,
    getCodeGenerat,
    PayUser,
    PayType,
    ForceSetseat,
    GetNetSalesUser,
    GetNetSalesUserInfo,
    handleMemberCardQuery,
    CouponOrderCreate,
    CouponOrderPresetValue,
    CouponOrderList,
    CouponOrderData,
    MenCreateRecharge,
    GetRechargeList,
    GetRechargeInfo,
    SetRechargeInfo,
    GetPresetValue,
    GenerateEmployeeCode,
    EditEmployeeCode,
    GetEmCodeList,
    FindAppuser,
    EditAppuser,
    GetGoodBaseList,
    CreateGoodBase,
    EditGoodBase,
    GoodGroupType,
    CreateGoodsGather,
    GetGoodsGatherList,
    EditGoodsGather,
    memberCardConsumeQueryv1,
    memberOrderQueryv1,
    memberQueryv1,
    memberCardQueryv1,
    OperStoresExDate,
    PriceInfo,
    SeatInfoUpDate,
    MerGroupType,
    MerDataSyn,
    GetMerData,
    EditMerData,
    GetCouponRule,
    QueryAppUser,
    GetAppUserInfo,
    GetMemCardList,
    MemCardInfo,
    GetMemberPayList,
    GetMemberPayInfoV,
    UNCouponBind,
    GetLvGroupList,
    LvGroup,
    GetPlan,
    NetPayTermGetList,
    GetAddSetTerm,
    SetTermExe,
    OperationChart,
    GetFilmList,
    GetFeedbackListDataCms,
    ReplyToFeedbackCms,
    OrderList,
    GetOrserInfo,
    OrderRefundExe,
    GetRefundInList,
    RefundReject,
    SetCmstPlan,
    CreateCouponRule,
    EditCouponRuleDetail,
    CouponOrderExe,
    GetTicketList,
    ComprehensiveReport,
    memberCardPayData,
    QuerycouponCode,
    AppUserRCard,
    CreateActivity,
    GetActivitylist,
    ActivityExe,
    GetDYStoreInfo,
    SetDYStoreInfo,
    GetDyGoosList,
    ExeDyGoos,
    GetTiktokShopList,
    GetGoosCashRecordList,
    GetGoosCashRecordInfo,
    RevokeCash,
    SetDYStoreOpen,
    BluePencilExe,
    GetTiktokShop,
    GetCouponAppLog,
    CheckmanualEdit,
    ManualEdit,
    DelMer,
    OperationHoliday,
    OperationNotice,
    OperationCustomer,
    OperationRefund,
    ThirdPartyOpenQuery,
    ThirdPartyOpenOrderRefund,
    GiftTickets,
    SetCouponState,
    GetSysTicketList,
    GetSysTicketRules,
    goLiveStore,
    UpgetGlobal,
    GetActivityGoogs,
    queryRefSessions,
    seqNoGetPayOrder,
    refOrderCommit,
    OperationPurchase,
    querCancelPurch,
    OperationOrderLink,
    findManyBenefitIncr,
    editOrCreate,
    saveMembStraPlan,
    queryMembStradraft,
    deleteBenefit,
    queryBenefitItem,
    currentVersion,
};
