<template>
    <div>
        <div style="text-align: right; margin-bottom: 10px">
            <Select v-model="selectsearchvalue" style="width: 200px; text-align: left">
                <Option v-for="(item, index) in PresetValue.findtype" :value="item.value" :key="index">{{ item.label }}</Option>
            </Select>
            <Button type="primary" @click="() => handleRechargeList(1)" style="margin: 0 10px">查找</Button>
            <Button @click="() => Thenewstrategy()">新增策略</Button>
        </div>
        <Table stripe :columns="RechargeList.title" :data="RechargeList.list">
            <template slot-scope="{ row }" slot="operation">
                <Button @click="() => handleedit(row)" style="margin-right: 5px" type="primary" size="small">编辑</Button>
                <Poptip transfer confirm title="确定是否删除?" @on-ok="handledelete(row)">
                    <Button size="small" type="error">删除</Button>
                </Poptip>
            </template>
            <template slot-scope="{ row }" slot="show">
                <i-Switch @on-change="(value) => handleSetRechargeInfo(row, value)" v-model="row.show"></i-Switch>
            </template>
        </Table>
        <Card style="text-align: right">
            <Page @on-change="(value) => handleRechargeList(value)" :total="RechargeList.total" :page-size="RechargeList.size" />
        </Card>
        <Modal :closable="false" width="700" :mask-closable="false" v-model="addModal">
            <div>
                <Form ref="formValidate" :model="form" :rules="ruleValidate" :label-width="100">
                    <FormItem prop="type" label="策略类型">
                        <Select :disabled="type == '编辑'" v-model="form['type']" style="width: 300px">
                            <Option v-for="(item, index) in PresetValue.type" :value="item.value" :key="index">{{ item.label }} </Option>
                        </Select>
                    </FormItem>
                    <div style="display: flex">
                        <FormItem prop="basicCharge" label="充值量">
                            <Input type="number" v-model.number="form['basicCharge']" style="width: 100px"></Input>
                        </FormItem>
                        <FormItem prop="giveCharge" label="赠送量">
                            <Input type="number" v-model.number="form['giveCharge']" style="width: 100px"></Input>
                        </FormItem>
                    </div>
                    <FormItem prop="price" label="价格">
                        <Input type="number" v-model.number="form['price']" style="width: 300px"></Input>
                    </FormItem>
                    <FormItem prop="sort" label="排序">
                        <Input type="number" v-model.number="form['sort']" style="width: 300px"></Input>
                    </FormItem>
                    <FormItem prop="expiryTime" label="策略有效期">
                        <div style="display: flex; align-items: center">
                            <DatePicker
                                :disabled="datepicktimedisable"
                                :value="form.expiryTime"
                                @on-change="(value) => (form.expiryTime = value)"
                                type="date"
                                show-week-numbers
                                placement="bottom-end"
                                placeholder="请选择日期"
                                style="width: 200px; margin-right: 5px"
                            >
                            </DatePicker>
                            <Checkbox :value="datepicktimedisable" @on-change="(value) => handlecheckChange(value)">长期有效 </Checkbox>
                        </div>
                    </FormItem>
                    <Collapse>
                        <Panel>
                            高级设置
                            <div slot="content">
                                <FormItem label="包含或排除表">
                                    <Select v-model="form.exclude" style="width: 200px">
                                        <Option v-for="item in includList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem label="表">
                                    <Select v-model="form.excludeList" multiple style="width: 260px">
                                        <Option v-for="item in multipleExcludList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem label="新用户定义">
                                    <div style="display: flex">
                                        <DatePicker
                                            :disabled="datepickuserdisable"
                                            :value="form.allowBuyTime"
                                            @on-change="(value) => (form.allowBuyTime = value)"
                                            type="date"
                                            show-week-numbers
                                            placement="bottom-end"
                                            placeholder="请选择日期"
                                            style="width: 200px; margin-right: 5px"
                                        >
                                        </DatePicker>
                                        <Checkbox :value="datepickuserdisable" @on-change="(value) => handleusercheckChange(value)"> 所有用户可购</Checkbox>
                                    </div>
                                </FormItem>
                                <FormItem prop="quota" label="限购量">
                                    <div style="display: flex">
                                        <Input type="number" v-model.number="form['quota']" style="width: 300px; margin-right: 10px"></Input>
                                        <div style="color: #ccc">限购量0 为不限购</div>
                                    </div>
                                </FormItem>
                                <FormItem label="促销文字">
                                    <Input v-model="form['note']" style="width: 300px"></Input>
                                </FormItem>
                                <FormItem label="赠品组">
                                    <Select filterable v-model="GivListobj['giv']" style="width: 200px; margin-right: 5px">
                                        <Option v-for="(item, index) in PresetValue.GivList" :value="item.value" :key="index">
                                            {{ item.label }}
                                        </Option>
                                    </Select>
                                    <Input type="number" v-model="GivListobj.pcs" style="width: 100px; margin-right: 5px" />
                                    <Button @click="() => addgive()" type="primary">增加</Button>
                                </FormItem>
                                <FormItem v-show="false">
                                    <Checkbox v-model="form.Givinvalid">赠品库存不足或赠品下架时是否正常充值</Checkbox>
                                </FormItem>
                                <Alert show-icon>赠品组功能只对小程序充值订单生效，线下充值无效，设置赠品名称时尽量不要超过7个字</Alert>
                                <Table :columns="createtitle" :data="form['GivList']">
                                    <template slot-scope="{ row, index }" slot="operation">
                                        <Button @click="() => delegive(index)" type="error" size="small">删除</Button>
                                    </template>
                                    <template slot-scope="{ row }" slot="Note">
                                        <div class="text-green-300" v-if="row.Note == '正常'">{{ row.Note }}</div>
                                        <div v-else class="text-red-500">{{ row.Note }}</div>
                                    </template>
                                </Table>
                            </div>
                        </Panel>
                    </Collapse>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="() => (addModal = false)">取消</Button>
                <Button @click="() => handleMenCreateRecharge()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { MenCreateRecharge, GetRechargeList, GetRechargeInfo, SetRechargeInfo, GetPresetValue, GetLvGroupList } from '@/request/api';
export default {
    name: 'charged',
    data() {
        return {
            includList: [
                {
                    label: '包含',
                    value: 'true',
                },
                {
                    label: '排除',
                    value: 'false',
                },
            ],
            multipleExcludList: [],
            selectsearchvalue: '全部',
            type: '新增',
            datepickuserdisable: true,
            datepicktimedisable: true,
            GivListobj: {
                giv: '',
                pcs: '',
            },
            createtitle: [
                {
                    key: 'id',
                    title: 'ID',
                },
                {
                    key: 'name',
                    title: '赠品名称',
                },
                {
                    key: 'pcs',
                    title: '赠送数量',
                },
                {
                    key: 'Note',
                    title: '备注',
                    slot: 'Note',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                },
            ],
            ruleValidate: {
                type: {
                    required: true,
                    trigger: 'blur',
                },
                basicCharge: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
                giveCharge: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
                price: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
                GivList: {
                    required: true,
                    trigger: 'blur',
                },
                note: {
                    required: true,
                    trigger: 'blur',
                },
                sort: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
                quota: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
                allowBuyTime: {
                    required: true,
                    trigger: 'blur',
                },
            },
            PresetValue: [],
            formCopy: {},
            form: {
                type: '', //策略类型 ['通用充值','小程序充值','终端充值','活动素材'] 四选yi PresetValue.type
                basicCharge: '', //充值量
                giveCharge: '', //赠送量
                price: '', //价格 单位：元
                GivList: [], //赠送商品组
                note: '', //促销文字
                sort: 1,
                quota: 0,
                expiryTime: '',
                allowBuyTime: '',
                exclude: 'false',
                excludeList: [],
                Givinvalid: false,
            },
            addModal: false,
            RechargeList: '',
        };
    },

    created() {
        this.formCopy = JSON.parse(JSON.stringify(this.form));
        this.handleRechargeList();
        this.handleGetPresetValue();
        this.getMultipleExcludList();
    },
    watch: {
        addModal(value) {
            if (!value) {
                this.$refs['formValidate'].resetFields();
                this.form = {};
            }
        },
    },
    methods: {
        /**
         * 获取包含或排除列表字典
         */
        async getMultipleExcludList() {
            let res = await GetLvGroupList({
                all: true,
            });
            if (res.errcode != 0) return;
            let list = res.data.list.map((item, index) => {
                return {
                    label: item.levelName,
                    value: item.levelCode,
                };
            });
            this.multipleExcludList = list;
        },
        Thenewstrategy() {
            this.reset();
            this.addModal = true;
            this.type = '新增';
        },
        reset() {
            this.form = JSON.parse(JSON.stringify(this.formCopy));
            this.GivListobj = {
                giv: '',
                pcs: '',
            };
        },
        async handleedit(value) {
            this.reset();
            this.datepicktimedisable = true;
            this.datepickuserdisable = true;
            this.type = '编辑';
            let res = await GetRechargeInfo({ id: value.id });
            this.form = res.data;
            if (res.data.expiryTime) {
                this.datepicktimedisable = false;
            }
            if (res.data.allowBuyTime) {
                this.datepickuserdisable = false;
            }
            this.addModal = true;
        },
        async handledelete(value) {
            let passdata = {
                id: value.id,
            };
            passdata['settype'] = '删除策略';
            await SetRechargeInfo(passdata);
            this.handleRechargeList();
        },
        handleusercheckChange(value) {
            this.datepickuserdisable = false;
            if (value) {
                this.datepickuserdisable = true;
                this.form.allowBuyTime = '';
            }
        },
        handlecheckChange(value) {
            this.datepicktimedisable = false;
            if (value) {
                this.datepicktimedisable = true;
                this.form.expiryTime = '';
            }
        },
        handleMenCreateRecharge() {
            this.$refs['formValidate'].validate(async (valid) => {
                if (valid) {
                    let passdata = JSON.parse(JSON.stringify(this.form));
                    // 勾选长期有效 剔除expiryTime
                    if (this.datepicktimedisable) {
                        delete passdata['expiryTime'];
                    }
                    if (this.datepickuserdisable) {
                        delete passdata['allowBuyTime'];
                    }
                    let res;
                    if (this.type == '编辑') {
                        passdata['settype'] = '设置策略';
                        res = await SetRechargeInfo(passdata);
                    }
                    if (this.type == '新增') {
                        res = await MenCreateRecharge(passdata);
                    }

                    if (res.errcode != 0) return;
                    this.addModal = false;
                    this.handleRechargeList();
                }
            });
        },
        delegive(value) {
            this.form['GivList'].splice(value, 1);
        },
        addgive() {
            if (this.GivListobj.pcs <= 0) {
                this.$Message.error('数量不能小于0');
                return;
            }
            if (this.form['GivList'].length > 5) {
                this.$Message.error('添加数量不能超过5个');
                return;
            }
            if (this.GivListobj.giv == '' || this.GivListobj.pcs == '') return;
            const { giv, pcs } = this.GivListobj;
            let names = this.PresetValue.GivList.filter((item, index) => item.value == giv);

            this.form['GivList'].push({
                pcs,
                id: giv,
                Note: '',
                name: names[0].label,
            });
        },
        async handleSetRechargeInfo(row, value) {
            let passdata = {
                id: row.id,
            };
            passdata['settype'] = '状态设置';
            await SetRechargeInfo(passdata);
        },
        async handleGetPresetValue() {
            let res = await GetPresetValue({
                type: '充值策略',
            });
            if (res.errcode != 0) return;
            this.PresetValue = res.data;
        },
        async handleRechargeList(page = 1) {
            let res = await GetRechargeList({
                page,
                all: false, //是否导出全部
                type: this.selectsearchvalue, //类型 ['全部','通用充值','小程序充值','终端充值','活动素材'] 五选一
            });

            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width: 200,
            });
            res.data.title.forEach((item) => {
                if (item.key == 'show') {
                    item['slot'] = 'show';
                }
            });

            this.RechargeList = res.data;
        },
    },
};
</script>

<style></style>
