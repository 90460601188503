<template>
	<div>
		<div style="text-align: right;">
			<Form inline>
				<FormItem>
					<Select v-model="currentNetSalesUser" style="text-align: left;width: 150px;margin-right: 10px;">
						<Option :key="index" v-for="(item,index) in   NetSalesUserConfig" :value="item">{{item}}</Option>
					</Select>
				</FormItem>
				<FormItem>
					<Input placeholder="请输入手机号码" style="width: 300px;" v-model="phone"></Input>
				</FormItem>
				<FormItem>
					<Button @click="()=>to_GetNetSalesUser()" style="margin-left: 10px;" type='primary'>查询</Button>
				</FormItem>
				<FormItem>
					<Button @click="()=>table_dowload()" v-if="infos" style="margin-left: 10px;">导出全部数据</Button>
				</FormItem>
			</Form>
		</div>
		<!-- 号码信息 -->
		<div style="margin-top: 10px;">
			<Card>
				<pre class=" pl-32">
				账户ID:{{NetSalesUserInfo.ID}}
				微信名称:{{NetSalesUserInfo.nickName}}<span v-if="NetSalesUserInfo.realName">({{NetSalesUserInfo.realName}})</span>
				用户串码:{{NetSalesUserInfo.openid}}
				电话号码:{{NetSalesUserInfo.phone}}                   <div style="display: inline-block;"><Poptip v-if="NetSalesUserInfo.phonelist" placement="right" trigger="hover" title="曾用号码" :content="NetSalesUserInfo.phonelist.join('/n')"><Button type="primary">曾用号码</Button></Poptip></div>
			    创建时间:{{NetSalesUserInfo.creationTime}}
				当前影院:{{NetSalesUserInfo.mcode}}
				</pre>
				<div
					style="overflow: hidden; border-radius: 500px;  position: absolute;top: 20px;left: 50px;width: 100px;height: 100px;">
					<img style="width: 100%;height: 100%;"
						:src="NetSalesUserInfo.imgURl?NetSalesUserInfo.imgURl:require('../../../assets/images/no_img.png')">
				</div>
			</Card>
		</div>
		<!-- 详情查看 -->
		<div>
			<!-- 操作区域 -->
			<div>
				<Input v-show="false" @on-enter='()=>to_GetOrderDatab()' autofocus v-model="keyword"
					style="width: 300px;" placeholder="请输入订单号"></Input>
			</div>
			<div v-show="order.orderid" style="margin-top: 30px;">
				<Card>
					<!-- 电话号码 -->
					<div v-if="order.mobilePhone">
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">电话号码
						</p>
						<div style="padding-left: 20px;">
							{{order.mobilePhone}}
						</div>
					</div>
					<!-- 订单信息 -->
					<div>
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单信息
							<!-- order -->
						</p>
						<div style="padding-left: 20px;position: relative;">

							订单号 {{order.orderid}}
							<br> 订单名称
							{{order.orderName}}
							<br />
							订单描述 <span v-if="order.orderAbout" v-html="orderAbout(order.orderAbout)"></span>

							<br>

							订单状态 <Tag v-if='order.state' :color="state_color[order.state]">{{state[order.state]}}</Tag>

							<br>

							订单创建时间 {{$formatDate(order.createTime,"yyyy年MM月dd日 HH:mm")}}
							<br>
							支付类型 <Tag color="orange">{{order.pay}}</Tag>
							<br>
							订单价格 {{order.orderPrice/100}}元
							<br>
							实付金额 {{order.cash_fee/100}}元





							<div style="width: 200px ;height: 300px;position: absolute;top: -20px;right: 0;">
								<img style="width: 100%;height: 100%;" :src="order.imgurl">
							</div>
						</div>
					</div>
					<div v-if="order.qrCode!=''">
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">取货码
						</p>
						<div style="padding-left: 20px;">
							{{order.qrCode}}
						</div>
					</div>
					<div v-if="order.retime!=''">
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">退款信息</p>
						<div style="padding-left: 20px;">
							退款金额 <span style="color: red;">{{order.refund}}</span> 元
							<br>
							退款时间 {{order.retime}}
							<br>
							退款说明 {{order.retNote}}
							<br>
							退款人 {{order.retName}}
						</div>
					</div>
					<div>
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单备注
						</p>
						<div style="padding-left: 20px;">

							内部ID {{order._id}}

							<br />
							用户串码 {{order.openid}}
							<br>
							影院编号 {{order.mcode}}
							<br>
							{{order.Note}}

						</div>
					</div>
					<!-- 商品 票券信息 -->
					<div>
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">商品/票券信息
						</p>
						<div style="padding-left: 20px;">
						</div>
					</div>
				</Card>
			</div>
			<!-- 表格显示区域 -->
			<div style="margin-top: 30px;" v-for="item in order.gooslist">
				<Table stripe :columns="item.title" :data="item.list"></Table>
			</div>
			<div>
				<!-- 单笔退款弹出 -->
				<Modal v-model="modal1" title="退款">
					<p><span style="margin-right: 10px;">退款备注</span>
						<Input v-model="note_refund" style="width: 300px;" placeholder="请输入退款备注"></Input>
					</p>
					<div slot="footer">
						<Button @click="()=>modal1=false"> 取消</Button>
						<Button type='primary' @click="()=>to_refund('退款')"> 确定</Button>
					</div>
				</Modal>
				<!-- 全额退款弹出 -->
				<Modal v-model="modal2" title="退款订单">
					<p><span style="margin-right: 10px;">退款备注</span>
						<Input v-model="note_refund_all" style="width: 300px;" placeholder="请输入退款备注"></Input>
					</p>
					<div slot="footer">
						<Button @click="()=>modal2=false"> 取消</Button>
						<Button type='primary' @click="()=>to_refund('全部退款')"> 确定</Button>
					</div>
				</Modal>
			</div>
		</div>
		<Card>
			<Tabs type="card" @on-click="(value)=>changeTabs(value)" v-model="currentTab">
				<TabPane v-for="item in pannerConfig" :label="item" :name="item">
					<div style="text-align: right;margin-bottom: 10px;">
						<Button @click="()=>table_dowload_card()" v-if="NetSalesUserInfo.openid">导出数据</Button>
					</div>
					<Table :columns="NetSalesUserInfo.title" :data="NetSalesUserInfo.list">
						<template slot-scope="{row}" slot="promoCode">
							<img v-if="row.promoCode" style="width: 50px;height: 50px;" :src=row.promoCode alt="">
						</template>
					</Table>
					<div style="text-align: right;padding: 20px;">
						<Page @on-change="(value)=>to_GetNetSalesUserInfo({openid:NetSalesUserInfo.openid},currentTab,value)" :total="NetSalesUserInfo.total" :page-size="NetSalesUserInfo.size" />
					</div>
				</TabPane>
			</Tabs>
		</Card>
		<div style="height: 50px;width: 100vw;"> </div>
		<Modal width="1000" v-model="ModalNetSalesUser">
			<div style="padding: 50px 0;">
				<Table :data="NetSalesUserList" :columns="NetSalesUserTitle">
					<template slot-scope="{row}" slot="operation">
						<Button @click="()=>to_GetNetSalesUserInfo(row)">选择</Button>
					</template>
					<template slot-scope="{row}" slot="nickName">
						<div style="display: flex;">
							<img style="width: 30px;height: 30px;border-radius: 50%;" :src="row.imgURl" alt="">
							<div>
								{{row.nickName}}
							</div>
						</div>
					</template>
				</Table>
			</div>
			<div slot="footer">
			</div>
		</Modal>
	</div>

</template>

<script>
	import { download } from '@/utils/table_download.js'
	import {
		GetOrderDatab,
		OrderRefund,
		memberQuery,
		memberOrderQuery,
		GetNetSalesUser,
		GetNetSalesUserInfo
	} from '../../../request/api.js'
	export default {
		name: 'navbar',
		data() {

			return {
				currentTab:"绑定会员卡",
				NetSalesUserInfo: "",
				pannerConfig: ['绑定会员卡', '持有票券', '订单列表', '身份标识'],
				ModalNetSalesUser: false,
				NetSalesUserList: [],
				NetSalesUserTitle: [],
				currentNetSalesUser: "名称",
				NetSalesUserConfig: ['名称', '账户ID', '绑定号码', 'openId'],
				total: '', //查询总长度
				infos: '', //电话查询信息
				title: [], //
				list: [], //
				phone: '', //查询号码
				note_refund_all: '', //全额退款备注
				note_refund: '', //退款备注
				row_detail: {},
				modal2: false,
				modal1: false,
				state_color: {
					12: '',
					1: 'warning',
					2: 'primary',
					4: 'error',
					5: 'default',
					6: 'error'
				},
				state: {
					12: "全部", //
					1: '未支付', //黄色    <Tag color="warning">warning</Tag>
					2: "已支付", //蓝色<Tag color="primary">primary</Tag>
					4: "已退款", //红色  <Tag color="error">error</Tag>
					5: "已关闭", //灰色 <Tag color="default">default</Tag>
					6: '部分退款'
				},
				order: {}, //订单对象信息
				keyword: '', //订单号
			}

		},
		computed: {

			orderAbout() {
				return function(orderAbout) {
					return orderAbout.join(' ')
				}
			},

			order_state_tag() {
				return function(index) {
					return `<Tag color="${this.state_color[index]}">${this.state[index]}</Tag>`
				}
			}

		},
		methods: {
			async table_dowload_card(){
				let pass_data = {
					"openid": this.NetSalesUserInfo.openid, //网售账户对应的哦陪你的
					"type": this.currentTab, //['绑定会员卡','持有票券','订单列表','身份标识'] 四选一
					"all": true //是否获取全部数据
				}
				let res = await GetNetSalesUserInfo(pass_data)
				if (res.code != 200) return
				let headers = res.data.title
				let data  = res.data.list
				download({
				  'worksheet':'网售账号',
				  headers,
				  data
				})
			},
			changeTabs(value) {
				if (!this.NetSalesUserInfo.openid) return
				this.to_GetNetSalesUserInfo({
					openid: this.NetSalesUserInfo.openid
				}, value)
			},
			async to_GetNetSalesUserInfo(row, type = "绑定会员卡",page=1) {
				let pass_data = {
					"openid": row.openid, //网售账户对应的哦陪你的
					"type": type, //['绑定会员卡','持有票券','订单列表','身份标识'] 四选一
					"page": page, //当前页码
					"all": false //是否获取全部数据
				}
				let res = await GetNetSalesUserInfo(pass_data)
				if (res.code != 200) return
				res.data.title.forEach(item=>{
					if(item.key=="promoCode"){
						item.slot="promoCode"
					}
				})
				this.NetSalesUserInfo = res.data
				this.ModalNetSalesUser = false
			},
			async to_GetNetSalesUser() {
				if (!this.phone) return
				let {
					data: res
				} = await GetNetSalesUser({
					type: this.currentNetSalesUser,
					content: this.phone
				})
				// 直接发包
				if (res.list.length == 1) {
					this.currentTab="绑定会员卡"
					this.to_GetNetSalesUserInfo({
						openid: res.list[0].openid
					})
					return
				}
				res.title.push({
					key: "operation",
					title: "操作",
					slot: "operation",
				})
				res.title.forEach((item) => {
					if (item.key == "nickName") {
						item.slot = "nickName"
					}
				})

				this.ModalNetSalesUser = true
				this.NetSalesUserList = res.list
				this.NetSalesUserTitle = res.title

			},

			async table_dowload() {

				let pass_data = {
					openid: this.infos.openid,
					"page": 1,
					"all": 1
				}

				let {
					data: res_data
				} = await memberOrderQuery(pass_data)

				let headers = res_data.title
				let data = res_data.list.map(item => {
					return {
						"orderid": item.orderid,
						"orderName": item.orderName,
						"createTime": item.createTime,
						"state": item.state,
						"pay": item.pay,
						"cash_fee": item.cash_fee
					}
				})
				download({
					'worksheet': this.infos.phone + '',
					headers,
				 data
				})

			},



			async to_memberQuery(page = 1) {

				let pass_data = {
					"phone": this.phone,

				}

				let {
					data: res_data
				} = await memberQuery(pass_data)

				this.infos = res_data[0]
				let pass_data1 = {
					openid: res_data[0]['openid'],
					"page": page
				}
				let {
					data: res_data1
				} = await memberOrderQuery(pass_data1)
				this.total = res_data1.total
				res_data1.title.push({
					key: 'operation',
					title: '操作',
					slot: 'operation'
				})
				this.title = res_data1.title
				this.list = res_data1.list

			},


			refund_single(row) {

				this.modal1 = true
				this.row_detail = row

			},

			async to_refund(type) {

				if (type == '全部退款') {
					if (!this.note_refund_all) {
						this.$mytoast_err('请输入备注信息')
						return
					}
					let pass_data = {
						'retName': JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": true, //全部退款 优先级高于uidList
						"uidList": [], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund_all //退款备注
					}

					let res_data = await OrderRefund(pass_data);

					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal2 = false

				}
				if (type == '退款') {
					if (!this.note_refund) {
						this.$mytoast_err('请输入备注信息')
						return
					}

					let pass_data = {
						'retName': JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": false, //全部退款 优先级高于uidList
						"uidList": [this.row_detail], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund //退款备注
					}
					let res_data = await OrderRefund(pass_data);

					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal1 = false
				}

			},

			async to_GetOrderDatab() {

				if (!this.keyword) {
					this.$mytoast_err('请输入订单号查询')
					return
				}
				let pass_data = {
					'keyword': this.keyword
				}

				let res_data = await GetOrderDatab(pass_data);

				if (res_data.code == 200) {
					let center = JSON.parse(JSON.stringify(res_data.data))
					console.log(center)
					center.gooslist.forEach((item, index) => {
						center.gooslist[index].title.push({
							key: "operation",
							title: "操作",
							slot: 'operation'
						})
					})
					this.order = center
				} else {
					this.$mytoast_err(res_data.msg)
				}

			}

		},

	}
</script>
