<template>
	<div>
	<div style="text-align: right;">
		<Input style="width: 300px;"></Input>
		<Button style="margin-left: 10px;" type='primary'>查询</Button>
	</div>
	</div>
</template>

<script>
	export default{
		name:'navbar',
		data(){
			
			return{
				
			}
			
		}
	}
</script>

