<template>
    <div>
        <div style="text-align: right">
            <Button @click="() => (modal_add_store = true)" type="primary">添加品牌门店</Button>
            <Poptip confirm title="是否确认更新门店配置" @on-ok="updateConfig">
                <Button style="margin-left: 10px" type="primary">更新门店配置</Button>
            </Poptip>
        </div>
        <div style="margin-top: 10px">
            <Table :data="list" :columns="title">
                <template slot-scope="{ row }" slot="address">
                    <div style="width: 100px; height: 100px; padding: 20px">
                        <img alt="无数据" style="width: 100%; height: 100%" :src="row.address" />
                    </div>
                </template>
                <template slot-scope="{ row }" slot="operation">
                    <div>
                        <Button @click="() => handleEdit(row)" size="small" type="primary">编辑</Button>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="buff">
                    <div>
                        <!--<tag v-if="row.buff==-1" type='error' ><span style="color: #fff;">{{state[row.buff]}}</span> </tag>
						<tag v-if="row.buff==0" type='primary' ><span style="color: #fff;">{{state[row.buff]}}</span> </tag>
                        <tag v-if="row.buff==1" type='success' ><span style="color: #fff;">{{state[row.buff]}}</span> </tag>-->
                        <tag v-if="row.buff == -1" type="error"
                            ><span style="color: #fff">{{ state[row.buff] }}</span>
                        </tag>

                        <i-Switch v-else @on-change="changeState(row)" :disabled="row.buff == -1" :value="row.buff" :true-value="1" :false-value="0" />
                    </div>
                </template>
            </Table>
        </div>
        <Modal :mask-closable="false" v-model="modal_add_store">
            <!-- 弹窗内容区域 -->
            <div>
                <p>
                    <span style="display: inline-block; width: 100px">门店名字</span>
                    <Input v-model="add_name" style="width: 300px"></Input>
                </p>

                <p>
                    <span style="display: inline-block; width: 100px">门店logo图片</span>
                    <Input v-model="add_address" style="width: 300px"></Input>
                </p>
                <p>
                    <span style="display: inline-block; width: 100px">门店mcode</span>
                    <Input v-model="add_mcode" style="width: 300px"></Input>
                </p>
            </div>
            <div slot="header">添加门店</div>
            <div slot="footer">
                <Button @click="() => (modal_add_store = false)">返回</Button>
                <Button @click="() => add_brand_stores()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal :closable="false" :mask-closable="false" title="编辑门店弹窗" width="800" v-model="modal_edit_stores">
            <!-- 弹窗内容区域 -->
            <div v-if="formEidt.uid">
                <Tabs v-if="modal_edit_stores" @on-click="(value) => paneChange(value)" type="card">
                    <TabPane label="基本设置">
                        <Form
                            :rules="ruleValidate"
                            style="padding: 20px 40px 20px 20px"
                            ref="formEidt"
                            :model="formEidt"
                            :label-width="140"
                            label-position="left"
                        >
                            <FormItem label="门店名称(全称)：">
                                <Input disabled :value="formEidt.stores.name" style="width: 300px"></Input>
                            </FormItem>
                            <FormItem label="门店简称：">
                                <Input disabled :value="formEidt.stores.asName" style="width: 300px"></Input>
                            </FormItem>
                            <FormItem label="状态：">
                                <Tag v-if="formEidt.stores.buff === 0">审核中</Tag>
                                <Tag color="primary" v-if="formEidt.stores.buff === 1">生效</Tag>
                                <Tag color="error" v-if="formEidt.stores.buff === -1">审核不通</Tag>
                            </FormItem>
                            <FormItem label="影院专资编码：">
                                <span>{{ formEidt.stores.mcode }}</span>
                            </FormItem>
                            <FormItem label="门店服务器路径：" prop="stores.local">
                                <Input style="width: 300px" v-model="formEidt.stores.local"></Input>
                            </FormItem>
                            <FormItem label="门店客服电话：" prop="stores.phone">
                                <Input style="width: 300px" v-model="formEidt.stores.phone"></Input>
                            </FormItem>
                            <FormItem label="门店头像：" prop="stores.address">
                                <!-- <div>
									<div style="width: 100px;height: 100px;">
										<img alt="无数据" style="width: 100%;height: 100%;" :src="formEidt.stores.address">
									</div>
									<Input style="width: 300px;margin: 10px;" v-model="formEidt.stores.address"></Input>
								</div> -->
                                <div style="display: flex; align-items: center">
                                    <img alt="无数据" style="width: 100px; height: 100px; margin-right: 10px" :src="formEidt.stores.address" />
                                    <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="(value) => (formEidt.stores.address = value)" />
                                </div>
                            </FormItem>
                            <!-- <FormItem label="弹框图片：" prop="stores.src">
								<div>
									<div style="width: 100px;height: 100px;">
										<img alt="无数据" style="width: 100%;height: 100%;" :src="formEidt.stores.src">
									</div>
									<Input style="width: 300px;margin: 10px;" v-model="formEidt.stores.src"></Input>
								</div>
							</FormItem>
							<FormItem label="弹框跳转路径：" prop="stores.advert_navigate_url">
								<Input v-model="formEidt.stores.advert_navigate_url" style="width: 300px;"></Input>
							</FormItem> -->
                            <!-- <FormItem label="弹框图片：" prop="stores.BoxShows">
								<i-Switch v-model="formEidt.stores.BoxShows"></i-Switch>
							</FormItem>
							<FormItem label="提示文字+颜色：" >
								<div>
									<div>
										<Tag @on-close="(event,value)=>tagClose(value)"
											v-for="(item1,index1) in formEidt.stores.tips" type="border" closable
											:color="item1.colors" :name="item1">
											{{item1.value}}
										</Tag>
									</div>
									<Input v-model="color_title" style="width: 200px;" placeholder="请输入文字提示"></Input>
									<Select v-model="model_color_select" style="width: 100px;margin-left: 10px;">
										<Option :value="item.value" :key="item.value" v-for="item in color_list">{{item.label}}
										</Option>
									</Select>
									<Button @click="()=>add_color_tag()" style="margin-left: 10px;">提交</Button>
								</div>
							</FormItem> -->
                        </Form>
                        <div style="text-align: right; margin-top: 20px">
                            <Button @click="() => edit_store_brand()" type="primary">提交修改</Button>
                        </div>
                    </TabPane>
                    <TabPane label="拓展设置">
                        <!-- :rules="ruleValidateTuozhan" -->
                        <Form style="padding: 20px 40px 20px 20px" ref="formTuozhan" :model="formTuozhan" :label-width="140" label-position="left">
                            <FormItem label="拓展是否生效：">
                                <i-Switch v-model="formTuozhan.state"></i-Switch>
                            </FormItem>
                            <FormItem label="接入模式：">
                                <Select placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.version" style="width: 200px">
                                    <Option v-for="(item, index) in versionType" :value="item" :key="index">{{ item }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="门店编码：">
                                <Input placeholder="留空不填默认使用品牌配置" disabled v-model="formTuozhan.mcode"></Input>
                            </FormItem>
                            <FormItem label="专用APPID：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.appid"></Input>
                            </FormItem>
                            <FormItem label="接入模式：">
                                <Select
                                    placeholder="留空不填默认使用品牌配置"
                                    @on-change="(value) => changeSelect(value)"
                                    v-model="formTuozhan.mode"
                                    style="width: 200px"
                                >
                                    <Option v-for="(item, index) in modeType" :value="item" :key="index">{{ item }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem v-if="formTuozhan.mode == '云开发'" label="云环境ID：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.envId"></Input>
                            </FormItem>
                            <FormItem v-if="formTuozhan.mode == '云开发'" label="apitokenkey：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.authuid"></Input>
                            </FormItem>
                            <FormItem v-if="formTuozhan.mode == '代开发'" label="peid：">
                                <Input placeholder="" v-model="formTuozhan.peid"></Input>
                            </FormItem>
                            <FormItem v-if="formTuozhan.mode == '代开发' || formTuozhan.mode == '服务商'" label="授权系统sid：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.sid"></Input>
                            </FormItem>
                            <FormItem v-if="formTuozhan.mode == '代开发' || formTuozhan.mode == '服务商'" label="授权系统apikey：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.apikey"></Input>
                            </FormItem>

                            <FormItem label="专用商户号：">
                                <Input placeholder="留空不填默认使用品牌配置" v-model="formTuozhan.subMchId"></Input>
                            </FormItem>
                        </Form>
                        <div style="text-align: right; margin-top: 20px">
                            <Button @click="() => handleSubmit()" type="primary">提交修改</Button>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
            <div slot="footer">
                <Button @click="() => (modal_edit_stores = false)">取消</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue';
import { UpgetGlobal, goLiveStore, Set_Store, Get_StoreDate, addStore, GetStore_list, OperStoresExDate, getupTK } from '@/request/api.js';
export default {
    name: '品牌设置',
    components: { qiniuUploadBtn },
    data() {
        return {
            qiniu_config: {}, //表单状态数据
            add_mcode: '',
            state: {
                0: '待审批',
                1: '审批',
                '-1': '失效',
            },
            modeType: ['云开发', '代开发', '服务商'],
            versionType: ['正式版', '体验版', '开发版'],
            formEidt: {},
            ruleValidate: {
                'stores.uid': { required: true, trigger: 'blur' },
                'stores.asName': { required: true, trigger: 'blur' },
                'stores.phone': { required: true, trigger: 'blur' },
                'stores.address': { required: true, trigger: 'blur' },
                'stores.src': { required: true, trigger: 'blur' },
                'stores.advert_navigate_url': { required: true, trigger: 'blur' },
                'stores.BoxShows': { required: true, type: 'boolean', trigger: 'blur' },
                'stores.local': { required: true, trigger: 'blur' },
            },
            formTuozhan: {},
            settings: '', //点击编辑 弹出窗口  回显表单信息
            current_eidt_item: '',
            add_address: '',
            add_name: '',
            modal_edit_stores: false, //编辑门店弹窗
            modal_add_store: false, //添加门店弹窗
            title: [],
            list: [],
            color_title: '', //提示文字
            model_color_select: '',
            color_list: [
                {
                    value: 'red',
                    label: '红',
                },
                {
                    value: 'yellow',
                    label: '黄',
                },
                {
                    value: 'blue',
                    label: '蓝',
                },
                {
                    value: 'green',
                    label: '绿',
                },
                {
                    value: 'gray',
                    label: '灰',
                },
            ],
            tableRowMcode: '',
            branduid: '',
        };
    },
    mounted() {
        this.to_getupTK();
        this.flash();
    },
    methods: {
        /**
         * 重载配置
         */
        async updateConfig() {
            let res = await UpgetGlobal();
            if (res.code != 200) return;
            this.$Message.success('重载配置成功');
        },
        /**
         * 修改门店状态
         */
        async changeState(item) {
            await goLiveStore({
                branduid: this.branduid,
                mcode: item.mcode,
            });
            this.flash();
        },
        // 获取七牛云token
        async to_getupTK() {
            let { data: res_data } = await getupTK();
            this.qiniu_config = res_data.data;
        },
        /**
         * 关闭标签提示
         */
        tagClose(value) {
            this.formEidt.stores.tips = this.formEidt.stores.tips.filter((item, index) => value.value != item.value && value.colors != item.colors);
        },
        /**
         * 接入模式切换
         */
        changeSelect(value) {
            switch (value) {
                case '云开发':
                    delete this.formTuozhan.sid;
                    delete this.formTuozhan.apikey;
                    break;
                default:
                    delete this.formTuozhan.envId;
                    delete this.formTuozhan.authuid;
                    break;
            }
        },
        /**
         * 保存拓展设置修改
         */
        async handleSubmit() {
            console.log(this.formTuozhan, '==formTuozhan');
            let res = await OperStoresExDate({ type: '设置数据', ...this.formTuozhan, mcode: this.tableRowMcode });
            if (res.errcode != 0) return;
            this.flash();
            this.$mytoast('修改成功');
        },
        // 执行添加标签提示
        add_color_tag() {
            if (this.model_color_select == '' && this.color_title == '') {
                this.$mytoast_err('请正确填写');
                return;
            }
            // push标签到数组
            this.formEidt.stores.tips.push({
                colors: this.model_color_select,
                value: this.color_title,
            });
        },
        async handleOperStoresExDate(mcode) {
            let res = await OperStoresExDate({ type: '获取数据', mcode });
            console.log(res);
            if (res.errcode != 0) return;
            this.formTuozhan = res.data;
        },
        /**
         * 标签页切换
         */
        paneChange(value) {
            if (value == 1) {
                //获取拓展设置信息
                this.handleOperStoresExDate(this.tableRowMcode);
            }
        },
        /**
         * 编辑按钮取回数据
         */
        async editData(mcode) {
            this.tableRowMcode = mcode;
            let res_data = await Get_StoreDate({ mcode });
            if (res_data.code == 200) {
                this.formEidt = res_data.data[0];
            }
        },

        /**
         * 点击编辑按钮
         */
        async handleEdit(value) {
            const { mcode } = value;
            await this.editData(mcode);
            this.modal_edit_stores = true;
        },
        /**
         * 编辑基本设置保存
         */
        async edit_store_brand() {
            this.$refs['formEidt'].validate(async (valid) => {
                if (valid) {
                    const { uid, asName, phone, address, src, advert_navigate_url, BoxShows, tips, local } = this.formEidt.stores;
                    let pass_data = { uid, asName, phone, address, src, advert_navigate_url, BoxShows, tips, local };
                    let res_data = await Set_Store(pass_data);
                    if (res_data.code == 200) {
                        this.modal_edit_stores = false;
                        this.flash();
                        this.$mytoast('修改成功');
                    } else {
                        this.$mytoast_err('修改失败');
                    }
                } else {
                    this.$Message.error('Fail!');
                }
            });
        },
        /**
         * 添加品牌门店
         */
        async add_brand_stores() {
            if (this.add_address == '' || this.add_name == '' || this.add_mcode == '') {
                this.$mytoast_err('请填写信息');
                return;
            }
            let pass_data = {
                branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
                name: this.add_name,
                mcode: this.add_mcode,
                address: this.add_address,
            };

            let res_data = await addStore(pass_data);

            if (res_data.code == 200) {
                this.$mytoast('添加成功');
                this.modal_add_store = false;
                this.flash();
            }
        },
        /**
         * 刷新表格数据
         */
        async flash() {
            let pass_data = {
                branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
            };
            let res_data = await GetStore_list(pass_data);
            res_data.data.title.forEach((item) => {
                if (item.key == 'src') {
                    item.slot = 'src';
                }
                if (item.key == 'address') {
                    item.slot = 'address';
                }
                if (item.key == 'buff') {
                    item.slot = 'buff';
                }
            });
            res_data.data.title.push({
                key: 'operation',
                title: '操作',
                slot: 'operation',
            });
            if (res_data.code == 200) {
                this.title = res_data.data.title;
                this.list = res_data.data.list;
                this.branduid = res_data.data.branduid;
            }
        },
    },
};
</script>
