<template>
	<div>
		<div class=" flex justify-end">
			<Form inline>
				<FormItem prop="">
					<DatePicker 
						@on-change="(value)=>inlineForm.start=value"
						:value="inlineForm.start"
						type="datetime" 
						format="yyyy-MM-ddTHH:mm" 
						placeholder="请输入开始时间" 
						style="width: 200px"></DatePicker>
				</FormItem>
				<FormItem prop="">
					<DatePicker 
						@on-change="(value)=>inlineForm.end=value"
						:value="inlineForm.end"
						type="datetime" 
						format="yyyy-MM-ddTHH:mm" 
						placeholder="请输入结束时间" 
						style="width: 200px"></DatePicker>
				</FormItem>
				<FormItem>
					<Button @click="()=>fetchData()" type="primary">查询</Button>
				</FormItem>
				<FormItem>
					<Button @click="()=>tableDownload()" type="primary">导出</Button>
				</FormItem>
			</Form>
		</div>
		<Table stripe  :columns="title" :data="list"></Table>
		<Card class=" text-right">
			<Page :page-size="size" :total="total" @on-change='($)=>order_get($)' />
		</Card>
	</div>
</template>

<script>
	let mcode;
	import {
		download
	} from '@/utils/table_download.js'
	import {
		offlinePaymentOrders
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {

			return {
				inlineForm:{},
				title: [], //查询日期的表头
				list: [], //查询日期的数据集
				total: 0, //查询日期的总记录数
				size: 0, //查询日期的当前页最大记录数
				value: new Date(), //当前查询日期初始化 xxxx-xx-xx 当天日期
			}

		},
		mounted() {
			mcode = localStorage.getItem('mcode');
			// 获取查询日期商品销售明细表格数据
			this.order_get()

		},
		methods: {
			// 导出查询日期的全部数据
			async exportData() {

				let data = this.list
				let headers = this.title
				download({
					'worksheet': '线下订单统计',
					headers,
					data
				})
			},

			// 获取查询日期商品销售明细表格数据
			async order_get(page = 1) {
				// 表格初始化
				this.title = [];
				this.list = [];
				let day = this.$formatDate(Date.parse(this.value), "yyyy-MM-dd");
				let pass_data = {
					"mcode": mcode,
					'page': page,
					"size": 30,
					'day': day,
				}
				let res = await offlinePaymentOrders(pass_data)
				this.title = res.data.title; //查询日期的当前页表头
				this.list = res.data.datalist; //查询日期的当前页数据集
				this.total = res.data.total; //查询日期的总记录数
				this.size = res.data.size; //查询日期的最大数据集
			},

		},

	}
</script>
