<template>
	<div>
		
		<h1>当前状态：{{state}}</h1>
		<h3 style='text-align: center;display: flex;justify-content: center;'>
			<div style="display: flex;align-items: center;margin-right: 10px;">
				可售: <span style="display: inline-block;width: 20px;height: 20px;border: solid #ccc;"></span>
			</div>
			<div style="margin-right: 10px;display: flex;align-items: center;">
				选中: <span style="display: inline-block;width: 20px;height: 20px;border: solid green;"></span>
			</div>
			<div style="margin-right: 10px;display: flex;align-items: center;">
				已售: <span style="display: inline-block;width: 20px;height: 20px;background-color: red;"></span>
			</div>
			<div style="margin-right: 10px;display: flex;align-items: center;">
				坏座: <span style="display: inline-block;width: 20px;height: 20px;background-color: #ccc;"></span>
			</div>
			<div style="margin-right: 10px;display: flex;align-items: center;">
				留座: <span style="display: inline-block;width: 20px;height: 20px;background-color: orange;"></span>
			</div>
			<div style="margin-right: 10px;display: flex;align-items: center;">
				真实坏座: <span style="display: inline-block;width: 20px;height: 20px;background-color: #7c7cf8;"></span>
			</div>
		</h3>
		
			<canvas style="margin-top: 10px; border:1px solid;" width="1000" height="600" id="myCanvas"></canvas>
			
			<div style="text-align: right;position: absolute;top: 0;right: -300px;display: flex;flex-direction: column;">
				<div style="text-align: left;" v-if="modal_is_show_seat_management&&seat_info">
					
					<div v-if="seat_info.name">
						<span style="font-weight: 700;">| 影厅名</span><p>{{seat_info.name}}</p>
						<span style="font-weight: 700;">| 总座位数</span><p>{{seat_info.seatnum}}</p>
						<span style="font-weight: 700;">| 座位图渲染模式</span><p>{{seat_info.type}}</p>
						<span style="font-weight: 700;">| 类型</span><p>{{seat_info.uidtype}}</p>
					</div>
					<div v-if="seat_info.cinema">
						<span style="font-weight: 700;">| 影院</span><p>{{seat_info.cinema.cinemaName}}</p>
						<span style="font-weight: 700;">| 影厅名</span><p>{{seat_info.hall.hallName}}</p>
						<span style="font-weight: 700;">| 总座位数</span><p>{{seat_info.hall.seatnum}}</p>
						<span style="font-weight: 700;">| 座位图渲染模式</span><p>{{seat_info.hall.type}}</p>
						<span style="font-weight: 700;">| 影票名</span><p>{{seat_info.show.movieName}}</p>
						<span style="font-weight: 700;">| 类型</span><p>{{seat_info.show.dim}}</p>
						<span style="font-weight: 700;">| 语言</span><p>{{seat_info.show.lang}}</p>
						<span style="font-weight: 700;">| 放映时间</span><p>{{seat_info.show.showDate}} {{seat_info.show.showTime}}</p>
						<span style="font-weight: 700;">| 结束时间</span><p>{{seat_info.show.showDate}} {{seat_info.show.endTime}}</p>
					</div>
					
				</div>
			</div>
	
	</div>
</template>

<script>
	export default{
		name:'cp_seats',
		props:[
			'state',
			'seat_info',
			'modal_is_show_seat_management'
		],
		data(){
			return{
			}
		}
	}
</script>

<style>
</style>
