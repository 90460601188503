import store from '@/store/index.js';
import router from '@/router/index.js';

function formatNumber(n) {
	const str = n.toString();
	return str[1] ? str : `0${str}`;
}

export function localeDateStringtoTimestamp(dateString) {
	if (dateString) {
		if (dateString.split('上午').length == 2) {
			var _date = dateString.split('上午').join("");
			return new Date(_date)
		}
		if (dateString.split('下午').length == 2) {
			var _dateArray = dateString.split('下午');
			var ymd = _dateArray[0];
			var hms = _dateArray[1].split(":");
			hms[0] = parseInt(hms[0]) < 10 ? (parseInt(hms[0]) + 12) + '' : hms[0];
			return new Date(ymd + hms.join(':'))
		}
	}
}


export function getTimeFromZero(sec) {
	let hour = Math.floor(sec / 3600) 
	let min = 0
	if (sec % 3600 != 0) {
		min = (sec / 3600-hour) * 60
	}
	if (hour < 10) {
		hour = `0${hour}`
	}
	if (min < 10) {
		min = `0${min}`
	}
	return `${hour}:${min}`
}

export function formatTime(date, v) {
	let _v = v ? v : '-'
	if (!date.getFullYear) {
		date = new Date(date);
	}
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = '00';

	const t1 = [year, month, day].map(formatNumber).join(_v);
	const t2 = [hour, minute, second].map(formatNumber).join(':');

	return `${t1} ${t2}`;
}

export function formatDate(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t1 = [year, month, day].map(formatNumber).join('-')
	return `${t1}`;
}


export function formatDateYYYYMM(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t2 = [year, month].map(formatNumber).join('-')
	return `${t2}`;
}

export function formatDateYYYY(date) {
	if (typeof date !== 'object') {
		date = new Date(date);
	}
	const year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (month < 10) {
		month = '0' + month;
	}
	if (day < 10) {
		day = '0' + day;
	}
	const t3 = [year].map(formatNumber).join('')
	return `${t3}`;
}
/*
 * 获取倒计时
 * */
export function getTime(smallTime, bigTime) {
	let date1 = new Date(smallTime);
	let date2 = new Date(bigTime);

	let s1 = date1.getTime(),
		s2 = date2.getTime();
	let total = (s2 - s1) / 1000;
	let day = total / (24 * 60 * 60); //计算整数天数
	let afterDay = total - day * 24 * 60 * 60; //取得算出天数后剩余的秒数
	let hour = afterDay / (60 * 60); //计算整数小时数
	let afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60; //取得算出小时数后剩余的秒数
	let min = afterHour / 60; //计算整数分
	let afterMin = total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60; //取得算出分后剩余的秒数
	return {
		day: day > 0 ? day : 0,
		hour: hour > 0 ? hour : 0,
		min: min > 0 ? min : 0,
		afterMin: afterMin > 0 ? afterMin : 0,
	};
}

export function getFileType(fileName) {
	const imgReg = /\.jpg$|\.jpeg$|\.gif$|\.png$/i;
	const videoReg = /\.mp4$|\.rmvb$|\.flv$|\.avi$|\.mpeg$/i;
	const audioReg = /\.mp3$|\.cda$/i;

	let result = '';

	if (imgReg.test(fileName)) {
		result = '图片';
	} else if (videoReg.test(fileName)) {
		result = '视频';
	} else if (audioReg.test(fileName)) {
		result = '音频';
	} else {
		result = '文件';
	}

	return result;
}

function throttol(fn, threshold) {
	let timer;

	return function() {
		let args = [...arguments];
		if (!timer) {
			timer = setTimeout(() => {
				fn.apply(this, args);
				timer = null;
			}, threshold || 200);
		}
	};
}

export const log = throttol(() => {}, 2000);


export function formatJson(filterVal, jsonData) {
	return jsonData.map((v) => filterVal.map((j) => v[j]));
}

export function checkDate(s, e) {
	if (!s || !e) {
		return true;
	}
	// eslint-disable-next-line no-useless-escape
	const sDate = s.replace('/-/g', '/');
	// eslint-disable-next-line no-useless-escape
	const eDate = e.replace('/-/g', '/');
	if (sDate > eDate) {
		return false;
	}
	return true;
}

// 保留两位小数
export function returnFloat(number, bitNum) {
	const f_x = parseFloat(number);
	if (isNaN(f_x)) {
		return 0;
	}
	let s_x = number.toString();
	let pos_decimal = s_x.indexOf('.');
	//没有小数点的加上小数点
	if (pos_decimal < 0) {
		pos_decimal = s_x.length;
		if (bitNum != 0) {
			s_x += '.';
		} else {
			//没有小数点还要保留0位小数
			return s_x;
		}
	}
	if (s_x.length <= pos_decimal + bitNum) {
		while (s_x.length <= pos_decimal + bitNum) {
			s_x += '0';
		}
	} else {
		s_x = s_x.substring(0, pos_decimal + bitNum + 1);
	}
	return s_x;
}

// 科学计数法转换
export function fixNumber(x) {
	if (Math.abs(x) < 1.0) {
		let e = parseInt(x.toString().split('e-')[1]);
		if (e) {
			x *= Math.pow(10, e - 1);
			x = '0.' + new Array(e).join('0') + x.toString().substring(2);
		}
	} else {
		let e = parseInt(x.toString().split('+')[1]);
		if (e > 20) {
			e -= 20;
			x /= Math.pow(10, e);
			x += new Array(e + 1).join('0');
		}
	}
	return parseInt(x);
}


//优雅解决async/await的catch
//使用：const [err, data] = await awaitWrap(asyncFn())
export function awaitWrap(promise) {
	return promise
		.then(data => [null, data])
		.catch(err => [err, null])
}

//防抖
export const debounce = (fn, delay) => {
	let timer = null //借助闭包
	return function() {
		if (timer) {
			clearTimeout(timer)
		}
		timer = setTimeout(fn, delay) // 简化写法
	}
}

export const findValue = (obj,name,value) =>{
   if(Array.isArray(obj)){
       let _key = ''
    obj.forEach(el =>{
        if(el[name] == value){
            _key = el
        }
    })
    return _key
   } 
}

//时间格式换处理
export const dateFtt=(fmt,date)=>{  
	 var o = { 
	 "M+" : date.getMonth()+1,     //月份 
	 "d+" : date.getDate(),     //日 
	 "h+" : date.getHours(),     //小时 
	 "m+" : date.getMinutes(),     //分 
	 "s+" : date.getSeconds(),     //秒 
	 "q+" : Math.floor((date.getMonth()+3)/3), //季度 
	 "S" : date.getMilliseconds()    //毫秒 
	 }; 
	 if(/(y+)/.test(fmt)) 
	 fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length)); 
	 for(var k in o) 
	 if(new RegExp("("+ k +")").test(fmt)) 
	 fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length))); 
	 return fmt; 
	}
	
export const ToJudge = (arr,id) =>{
	return (arr.indexOf(id) >= 0)
}