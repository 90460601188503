<template>
    <div>
        <Card style="width: 1000px">
            <Tabs @on-click="tabClick" type="card">
                <TabPane name="基础信息" label="基础信息">
                    <div style="width: 800px">
                        <div v-for="(item, index) in settings">
                            <Form :label-width="120">
                                <FormItem label="门店名称(全称):">
                                    <Input disabled :value="item.stores.name" style="width: 300px"></Input>
                                </FormItem>
                                <FormItem label="门店简称:">
                                    <Input v-model="item.stores.asName" style="width: 300px"></Input>
                                </FormItem>
                                <FormItem label="状态:">
                                    <Tag v-if="item.stores.buff === 0">审核中</Tag>
                                    <Tag color="primary" v-if="item.stores.buff === 1">生效</Tag>
                                    <Tag color="error" v-if="item.stores.buff === -1">审核不通</Tag>
                                </FormItem>
                                <FormItem label="影院专资编码:">
                                    <span>{{ item.stores.mcode }}</span>
                                </FormItem>
                                <!-- <FormItem label="门店服务器路径:">
									<Input style="width:300px" v-model="item.stores.local"></Input>
								</FormItem> -->
                                <div class="flex">
                                    <FormItem v-show="false" label="在线客服:">
                                        <i-Switch v-model="item.stores.isCustomer"></i-Switch>
                                    </FormItem>
                                    <FormItem v-show="false" label="顾客发起退款:">
                                        <i-Switch v-model="item.stores.isRefund"></i-Switch>
                                    </FormItem>
                                    <FormItem label="意见反馈功能:">
                                        <i-Switch v-model="item.stores.isBackFe"></i-Switch>
                                    </FormItem>
                                </div>

                                <FormItem label="比价:">
                                    <i-Switch v-model="item.stores.tripartiteComparison"></i-Switch>
                                </FormItem>
                                <FormItem label="门店电话:">
                                    <Input style="width: 300px" v-model="item.stores.phone"></Input>
                                </FormItem>
                                <FormItem label="门店头像:">
                                    <div>
                                        <div style="display: flex; align-items: center">
                                            <img alt="无数据" style="width: 100px; height: 100px; margin-right: 10px" :src="item.stores.address" />
                                            <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="($) => (settings[index].stores.address = $)" />
                                        </div>
                                        <div>
                                            <Input :disabled="!touxiang" style="width: 300px; margin: 10px" v-model="item.stores.address"></Input>
                                            <Checkbox v-model="touxiang">自定义</Checkbox>
                                        </div>
                                    </div>
                                </FormItem>
                                <!-- <FormItem label="弹框图片:">
									<div>
										<div style="display: flex;align-items: center;">
											<img alt="无数据" style="width: 100px;height: 100px;margin-right: 10px;" :src="item.stores.src">
											<qiniuUploadBtn  :qiniu_config="qiniu_config"
												@set_url="($)=>settings[index].stores.src=$" />
										</div>
										<div>
											<Input :disabled="!tanchuang" style="width: 300px;margin: 10px;" v-model="item.stores.src"></Input>
											<Checkbox v-model="tanchuang">自定义</Checkbox>
										</div>
									</div>
								</FormItem>
								<FormItem label="弹框跳转路径:">
									<Input v-model="item.stores.advert_navigate_url" style="width: 300px;"></Input>
								</FormItem>
								<FormItem label="弹框是否显示:">
									<i-Switch v-model="item.stores.BoxShows"></i-Switch>
								</FormItem> -->
                                <FormItem label="小公告:">
                                    <div>
                                        <div>
                                            <Tag
                                                @on-close="() => Removingtextprompt(item1, index)"
                                                v-for="(item1, index1) in settings[index].stores.tips"
                                                type="border"
                                                closable
                                                :color="item1.colors"
                                            >
                                                {{ item1.value }}
                                            </Tag>
                                        </div>
                                        <Input v-model="color_title" style="width: 200px" placeholder="请输入小公告"></Input>
                                        <!-- <Select v-model="model_color_select" style="width: 100px;margin-left: 10px;">
											<Option :value="item.value" :key="item.value" v-for="item in color_list">{{item.label}}
											</Option>
										</Select> -->
                                        <Button @click="() => add_color_tag(index)" style="margin-left: 10px">添加</Button>
                                    </div>
                                </FormItem>
                                <FormItem label="城市:">
                                    <Input v-model="item.stores.city" style="width: 200px" placeholder="请输入城市"></Input>
                                </FormItem>
                                <FormItem label="门店是否可选:">
                                    <i-Switch v-model="item.stores.onLine_show"></i-Switch>
                                </FormItem>
                                <FormItem label="地址:">
                                    <Input type="textarea" v-model="item.stores.detailed_address" style="width: 400px" placeholder="请输入地址"></Input>
                                </FormItem>
                                <FormItem label="经度:">
                                    <Input v-model.number="item.stores.longitude" style="width: 200px" placeholder="请输入经度"></Input>
                                </FormItem>
                                <FormItem label="纬度:">
                                    <Input v-model.number="item.stores.dimension" style="width: 200px" placeholder="请输入纬度"></Input>
                                </FormItem>
                                <FormItem label="观影小提示:">
                                    <div style="display: flex">
                                        <div>
                                            <Input v-model="item.stores.view_tips" type="textarea" :rows="5" style="width: 300px"></Input>
                                        </div>
                                        <div>
                                            <span style="display: flex; align-items: center; margin-left: 10px">
                                                <i-Switch style="margin-right: 10px" v-model="item.stores.is_show_view_tips"> </i-Switch>
                                                <span>是否显示</span>
                                            </span>
                                        </div>
                                    </div>
                                </FormItem>
                            </Form>
                            <div style="text-align: right; margin-top: 20px">
                                <Button @click="() => do_Set_Store(item)" type="primary">提交修改</Button>
                                <Button v-if="false" style="margin-left: 10px" @click="() => do_PushStore(index)" type="error">同步到小程序</Button>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane name="节假日设置" label="节假日设置">
                    <div class="text-right">
                        <Form inline>
                            <FormItem>
                                <Button @click="holidayCreate" type="primary">新增</Button>
                            </FormItem>
                            <FormItem>
                                <Button @click="downloadHolidayTableData">导出</Button>
                            </FormItem>
                        </Form>
                    </div>
                    <Table size="small" :columns="holidayTableData.title" :data="holidayTableData.list">
                        <template slot="opreater" slot-scope="{ row }">
                            <Poptip transfer confirm title="确定要删除吗?" @on-ok="deleteHolidayTableRow(row)">
                                <Button type="error" size="small">删除</Button>
                            </Poptip>
                        </template>
                    </Table>
                    <Card class="text-right">
                        <Page @on-change="handleOperationHoliday" :page-size="holidayTableData.size" :total="holidayTableData.total"></Page>
                    </Card>
                </TabPane>
                <TabPane name="抖音来客" label="抖音来客">
                    <Row>
                        <Col span="10">
                            <Form :label-width="100" :rules="rulesDy" ref="dy" :model="dySettingData">
                                <FormItem prop="client_key" label="appid">
                                    <Input v-model="dySettingData.client_key" />
                                </FormItem>
                                <FormItem prop="client_key" label="secretid">
                                    <Input type="password" password v-model="dySettingData.client_secret" />
                                </FormItem>
                                <FormItem prop="account_id" label="账号id">
                                    <Input v-model="dySettingData.account_id" />
                                </FormItem>
                                <FormItem prop="store_id" label="店铺id">
                                    <Input v-model="dySettingData.store_id" />
                                </FormItem>
                                <FormItem prop="poi_name" label="门店名称">
                                    <Input disabled v-model="dySettingData.poi_name" />
                                </FormItem>
                                <FormItem prop="address" label="地址">
                                    <Input disabled v-model="dySettingData.address" />
                                </FormItem>
                                <FormItem prop="longitude" label="经度">
                                    <Input disabled v-model="dySettingData.longitude" />
                                </FormItem>
                                <FormItem prop="latitude" label="纬度">
                                    <Input disabled v-model="dySettingData.latitude" />
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col class="text-right" :span="10">
                            <Button @click="dySettingSet" type="primary">保存</Button>
                        </Col>
                    </Row>
                    <Form v-if="dySettingData._id">
                        <FormItem label="小程序抖音团购核销功能" :label-width="200">
                            <i-Switch @on-change="(value) => changePayCodeFunc(value)" v-model="dySettingData.isOpen"></i-Switch>
                        </FormItem>
                    </Form>
                </TabPane>
                <TabPane name="在线客服" label="在线客服">
                    <Form class="w-96" :label-width="120">
                        <FormItem label="微信小程序客服:">
                            <i-Switch v-model="customerConfig.isCustomer"></i-Switch>
                        </FormItem>
                        <FormItem label="企业微信客服:">
                            <i-Switch v-model="customerConfig.isWeCom"></i-Switch>
                        </FormItem>
                        <div v-show="customerConfig.isWeCom">
                            <FormItem label="小程序客服:">
                                <Select v-model="customerConfig.weComType" style="width: 264px">
                                    <Option :value="item.value" :key="item.value" v-for="item in customerConfig.weComTypeOptions">{{ item.label }} </Option>
                                </Select>
                            </FormItem>
                            <FormItem label="企业微信ID:">
                                <Input v-model="customerConfig.weComId" />
                            </FormItem>
                            <FormItem label="企业URL:">
                                <div v-if="customerConfig.weComType == '企微二维码'" style="display: flex; align-items: center">
                                    <img alt="无数据" style="width: 100px; height: 100px; margin-right: 10px" :src="customerConfig.weComUrl" />
                                    <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="(qiniuUrl) => (customerConfig.weComUrl = qiniuUrl)" />
                                </div>
                                <Input class="w-80" v-model="customerConfig.weComUrl" />
                            </FormItem>
                        </div>
                        <FormItem class="text-right">
                            <Button @click="submitCustomOnline" type="primary">提交</Button>
                        </FormItem>
                    </Form>
                </TabPane>
                <TabPane name="退款设置" label="退款设置">
                    <Form ref="ruleForm" style="width: 700px" :label-width="200" :model="ruleForm" :rules="ruleFormRules">
                        <FormItem label="用户每月退款次数:" prop="refundNum">
                            <Input type="number" class="w-80" v-model.number="ruleForm.refundNum" />
                        </FormItem>
                        <FormItem label="用户退款申请审核:" prop="refundAudit">
                            <i-Switch v-model="ruleForm.refundAudit"></i-Switch>
                        </FormItem>
                        <!--<FormItem label="自动退票:" prop="automaticRefund">
                            <Input type="number" class="w-80" v-model.number="ruleForm.automaticRefund" />
                        </FormItem>-->
                        <FormItem label="支持用户申请退票:" prop="isRefund">
                            <i-Switch v-model="ruleForm.isRefund"></i-Switch>
                        </FormItem>
                        <div v-if="cpSuppertTicket">
                            <h2>退票规则</h2>
                            <div class="flex justify-between">
                                <FormItem label="规则标准时间:" prop="refundTime">
                                    <Select v-model="ruleForm.refundTime" style="width: 264px">
                                        <Option value="开场时间">开场时间</Option>
                                        <Option value="结束时间">结束时间</Option>
                                    </Select>
                                </FormItem>
                                <Button @click="showRuleModal">添加规则</Button>
                            </div>
                            <Table size="small" :columns="ruleForm.refundListTitle" :data="ruleForm.refundList">
                                <template slot="isRefund" slot-scope="{ row }">
                                    <div>{{ row.isRefund ? '是' : '否' }}</div>
                                </template>
                                <template slot="opreater" slot-scope="{ row, index }">
                                    <Poptip transfer confirm title="确定要删除吗?" @on-ok="deleteRuleTableRow(index)">
                                        <Button type="error" size="small">删除</Button>
                                    </Poptip>
                                </template>
                            </Table>
                        </div>
                        <FormItem label="支持用户申请退卖品:" prop="isRefundMer">
                            <i-Switch v-model="ruleForm.isRefundMer"></i-Switch>
                        </FormItem>
                        <div v-if="cpSuppertGoods">
                            <FormItem label="退卖品时间单位:" prop="refundMerDay">
                                <Select v-model="ruleForm.refundMerDay" style="width: 264px">
                                    <Option value="天">天</Option>
                                    <Option value="小时">小时</Option>
                                    <Option value="分钟">分钟</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="卖品退货时间量:" prop="refundMerTime">
                                <Input type="number" class="w-80" v-model.number="ruleForm.refundMerTime" />
                            </FormItem>
                            <div class="flex items-center">
                                <FormItem label="退卖品手续费:" prop="merServiceCharge">
                                    <Input type="number" class="w-80" v-model.number="ruleForm.merServiceCharge" />
                                </FormItem>
                                <div class="ml-2">%按订单交易金额百分比</div>
                            </div>
                            <div class="flex items-center">
                                <FormItem label="退卖品百分比实际计算后手续费金额低于x免除手续费:" :label-width="400" prop="merServiceCharge_min">
                                    <Input type="number" class="w-80" v-model.number="ruleForm.merServiceCharge_min" />
                                </FormItem>
                                <div class="ml-2">元</div>
                            </div>
                        </div>

                        <FormItem label="积分抵扣:" prop="isIntegral">
                            <i-Switch v-model="ruleForm.isIntegral"></i-Switch>
                        </FormItem>
                        <div v-if="cpSuppertDi">
                            <div class="flex items-center">
                                <FormItem label="积分手续费兑换比例x积分抵扣1元手续费:" :label-width="300" prop="integral">
                                    <Input type="number" class="w-80" v-model.number="ruleForm.integral" />
                                </FormItem>
                                <div class="ml-2">(不足1元按1元计算)</div>
                            </div>
                            <FormItem label="积分兑换退款次数x积分可以兑换1次:" :label-width="300" prop="integraltoRNum">
                                <Input type="number" class="w-80" v-model.number="ruleForm.integraltoRNum" />
                            </FormItem>
                        </div>
                        <FormItem class="text-right">
                            <Button @click="submitRefundForm" type="primary">保存生效</Button>
                        </FormItem>
                    </Form>
                </TabPane>
                <TabPane name="限购设置" label="限购设置">
                    <Form ref="purchaseModel" style="width: 460px" :label-width="200" :model="purchaseForm">
                        <FormItem label="限购次数:" prop="quantity">
                            <InputNumber :min="1" style="width: 260px" type="number" v-model="purchaseForm.quantity" />
                        </FormItem>
                        <FormItem label="限购开关:" prop="xgswitch">
                            <i-Switch v-model="purchaseForm.xgswitch"></i-Switch>
                        </FormItem>
                        <FormItem label="限购策略:" prop="strategy">
                            <Select v-model="purchaseForm.strategy" style="width: 264px">
                                <Option :value="0">不允许购买</Option>
                                <Option :value="1">不允许会员价购买</Option>
                            </Select>
                        </FormItem>
                        <FormItem style="text-align: right" label="">
                            <Button @click="submitPurchaseForm" type="primary">确定</Button>
                        </FormItem>
                    </Form>
                    <h3 style="padding: 20px 0">限购白名单(在白名单的用户不被限购)</h3>
                    <Table size="small" :columns="cancelTable.title" :data="cancelTable.list">
                        <template slot="opreat" slot-scope="{ row }">
                            <!--@on-ok="deleteHolidayTableRow(row)"--->
                            <Poptip @on-ok="removeCancelData(row)" transfer confirm title="确定要操作吗?">
                                <Button type="error" size="small">移除</Button>
                            </Poptip>
                        </template>
                    </Table>
                    <Card class="text-right">
                        <Page @on-change="(value) => getCancelPurchData(value)" :total="cancelTable.total" :pageSize="10"></Page>
                    </Card>
                </TabPane>
            </Tabs>
        </Card>

        <Modal v-model="holidayModal" :closable="false" :mask-closable="false">
            <Form :label-width="100" ref="formHoliday" :model="formHoliday" :rules="formHolidayRule" class="w-96">
                <FormItem label="日期" prop="day">
                    <DatePicker
                        :value="formHoliday.day"
                        @on-change="(value) => (formHoliday.day = value)"
                        type="date"
                        placeholder="请选择日期"
                        style="width: 200px"
                    ></DatePicker>
                </FormItem>
                <FormItem label="日期描述" prop="desc">
                    <Input type="textarea" v-model="formHoliday.desc" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="holidayModal = false">取消</Button>
                <Button @click="createHolidayData" type="primary">确认</Button>
            </div>
        </Modal>

        <Modal v-model="ruleModal" :closable="false" :mask-closable="false">
            <Form ref="refundList" :label-width="120" :model="refundList" :rules="refundListRule" class="w-96">
                <FormItem label="成立条件:" prop="cond">
                    <Select v-model="refundList.cond" style="width: 264px">
                        <Option value="大于">大于</Option>
                        <Option value="小于">小于</Option>
                    </Select>
                </FormItem>
                <FormItem label="时间单位:" prop="daType">
                    <Select v-model="refundList.daType" style="width: 264px">
                        <Option value="小时">小时</Option>
                        <Option value="分钟">分钟</Option>
                    </Select>
                </FormItem>
                <FormItem label="时间:" prop="time">
                    <Input type="number" v-model.number="refundList.time" />
                </FormItem>
                <FormItem label="是否允许退款:" prop="isRefund">
                    <i-Switch v-model="refundList.isRefund"></i-Switch>
                </FormItem>
                <div class="flex items-center">
                    <FormItem label="手续费:" prop="serviceCharge">
                        <Input type="number" v-model.number="refundList.serviceCharge" />
                    </FormItem>
                    <div class="ml-2">元/张</div>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="ruleModal = false">取消</Button>
                <Button @click="addRule" type="primary">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {
    Get_StoreDate,
    Set_Store,
    PushStore,
    getupTK,
    GetDYStoreInfo,
    SetDYStoreInfo,
    SetDYStoreOpen,
    OperationHoliday,
    OperationCustomer,
    OperationRefund,
    OperationPurchase,
    querCancelPurch,
    EditAppuser,
} from '@/request/api.js';
import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue';
import { download } from '@/utils/table_download.js';
import { FormItem, Message } from 'view-design';
export default {
    name: 'navbar',
    components: {
        qiniuUploadBtn,
        FormItem,
    },
    data() {
        return {
            touxiang: false,
            tanchuang: false,
            qiniu_config: {}, //表单状态数据
            qj: {
                asdf: true,
            },
            settings: [], //门店设置
            select_colors: [], //选中的备注标签颜色
            color_title: '', //提示文字
            model_color_select: '',
            color_list: [
                {
                    value: 'red',
                    label: '红',
                },
                {
                    value: 'yellow',
                    label: '黄',
                },
                {
                    value: 'blue',
                    label: '蓝',
                },
                {
                    value: 'green',
                    label: '绿',
                },
                {
                    value: 'gray',
                    label: '灰',
                },
            ],
            dySettingData: {},
            rulesDy: -{
                client_key: { required: true, trigger: 'blur' },
                client_secret: { required: true, trigger: 'blur' },
                account_id: { required: true, trigger: 'blur' },
                store_id: { required: true, trigger: 'blur' },
            },
            holidayModal: false,
            holidayTableData: '',
            formHoliday: {
                day: '',
                desc: '',
            },
            formHolidayRule: {
                day: { required: 'true', message: ' ', trigger: 'blur' },
                desc: { required: 'true', message: ' ', trigger: 'blur' },
            },
            /**
             * 门店客服配置
             */
            customerConfig: {
                weComType: '',
                isCustomer: false,
                isWeCom: false,
                weComId: '',
                weComUrl: '',
                weComTypeOptions: [],
            },
            ruleModal: false,
            ruleForm: {
                refundNum: null, //用户每月退款次数
                refundAudit: false, //用户退款申请审核:
                automaticRefund: null, //自动退票 放映结束前X分钟未取票的自动退票   X应该大于等于30  小于30视为无效 (此功能不遵守退票规则)
                isRefund: false, //支持用户退票
                refundTime: '', //退票规则依据的时间
                refundList: [], //退票规则列表
                isRefundMer: false, //支持退卖品
                refundMerDay: null, //卖品退货时间单位
                refundMerTime: null, //卖品退货时间量
                merServiceCharge: null, //卖品退货手续费 交易金额百分比% 填写3相当于3%
                merServiceCharge_min: null, //卖品退货计算后手续费低于多少免除
                isIntegral: false, //是否开启积分抵扣手续费
                integral: null, //多少积分可以抵扣1元手续费不足一元按一元算
                integraltoRNum: null, //多少积分可以兑换一次订单退款次数
            },
            ruleFormRules: {
                refundNum: { required: true, trigger: 'blur', type: 'number', message: ' ' },
                refundAudit: { required: true, trigger: 'blur', message: ' ' },
                automaticRefund: { required: true, trigger: 'blur', type: 'number', message: ' ' },
                isRefund: { required: true, trigger: 'blur', message: ' ' },
                isRefundMer: { required: true, trigger: 'blur', message: ' ' },
                isIntegral: { required: true, trigger: 'blur', message: ' ' },
            },
            refundList: {
                cond: '', //退票规则-条件
                daType: '', //退票规则-时间单位
                time: null, //退票规则-时间量
                isRefund: false, //退票规则-支持退票
                serviceCharge: null, //退票规则-手续费
            },
            refundListRule: {
                cond: { required: true, trigger: 'blur', message: ' ' },
                daType: { required: true, trigger: 'blur', message: ' ' },
                time: { required: true, trigger: 'blur', type: 'number', message: ' ' },
                isRefund: { required: true, trigger: 'blur', type: 'boolean', message: ' ' },
                serviceCharge: { required: true, trigger: 'blur', type: 'number', message: ' ' },
            },
            purchaseForm: {
                quantity: '', //每天限购次数
                xgswitch: '', //true开启限购
                strategy: '', //0：不允许下单，1：不允许购会员票
            },
            cancelTable: {
                title: [],
                list: [],
                total: 0,
            },
        };
    },
    computed: {
        /**
         * 支持退票
         */
        cpSuppertTicket() {
            return this.ruleForm.isRefund;
        },
        /**
         * 支持退卖品
         */
        cpSuppertGoods() {
            return this.ruleForm.isRefundMer;
        },
        /**
         * 支持积分抵扣
         */
        cpSuppertDi() {
            return this.ruleForm.isIntegral;
        },
    },
    async mounted() {
        this.to_getupTK();
        await this.flash_data();
    },
    methods: {
        async removeCancelData(row) {
            let passdata = {
                appuserid: row._id,
                content: '后台操作移出',
                type: '恢复限购', //取消限购、恢复限购
            };
            let res = await EditAppuser(passdata);
            if (res.errcode != 0) return;
            this.getCancelPurchData();
        },
        /**
         * 设置会员限购
         */
        async submitPurchaseForm() {
            let passdata = this.purchaseForm;
            let res = await OperationPurchase(passdata);
            if (res.errcode != 0) return;
            Message.success('保存成功');
        },
        /**
         * 退款设置表单提交
         */
        async submitRefundForm() {
            let passdata = this.ruleForm;
            let res = await OperationRefund(passdata);
            if (res.errcode != 0) return;
            Message.success('保存成功');
        },
        /**
         * 添加规则表格行数据
         */
        addRule() {
            this.$refs['refundList'].validate(async (valid) => {
                if (valid) {
                    let passdata = Object.assign({}, this.refundList);
                    this.ruleForm.refundList.push(passdata);
                    this.ruleModal = false;
                }
            });
        },
        /**
         * 删除规则表格行数据
         */
        deleteRuleTableRow(rowIndex) {
            this.ruleForm.refundList = this.ruleForm.refundList.filter((item, index) => rowIndex != index);
        },
        /**
         * 显示添加规则弹窗
         */
        showRuleModal() {
            this.$refs['refundList'].resetFields();
            this.ruleModal = true;
        },
        /**
         * 提交在线客服的表单
         */
        async submitCustomOnline() {
            let data = this.customerConfig;
            let res = await OperationCustomer(data);
            if (res.errcode != 0) return;
            Message.success('保存成功');
        },
        /**
         * 下载节假日设置表格
         */
        async downloadHolidayTableData() {
            let res = await OperationHoliday({ exeType: '获取列表', all: true });
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '节假日设置',
                headers,
                data,
            });
        },
        /**
         * 新增节假日表格行
         */
        async createHolidayData() {
            let res = await OperationHoliday({ exeType: '添加数据', exeData: this.formHoliday });
            if (res.errcode != 0) return;
            this.handleOperationHoliday(this.holidayTableData.page);
            this.holidayModal = false;
        },
        /**
         * 删除节假日设置表格行
         */
        async deleteHolidayTableRow(value) {
            const { _id } = value;
            await OperationHoliday({ exeType: '删除数据', id: _id });
            this.handleOperationHoliday(this.holidayTableData.page);
        },
        /**
         * 弹窗节假日设置
         */
        holidayCreate() {
            this.$refs['formHoliday'].resetFields();
            this.holidayModal = true;
        },
        /**
         * 开启抖音核销功能
         */
        async changePayCodeFunc(value) {
            await SetDYStoreOpen({ id: this.dySettingData._id, isOpen: value });
            this.dySettingGet();
        },
        /**
         * 抖音配置获取
         */
        async dySettingGet() {
            let res = await GetDYStoreInfo({});
            if (res.errcode != 0) return;
            this.dySettingData = res.data;
        },
        /**
         * 抖音配置设置
         */
        dySettingSet() {
            this.$refs['dy'].validate(async (valid) => {
                if (valid) {
                    let { client_key, client_secret, account_id, store_id } = this.dySettingData;
                    let res = await SetDYStoreInfo({ client_key, client_secret, account_id, store_id });
                    if (res.errcode == 0) {
                        this.$Message.success('保存成功');
                    }
                    if (res.errcode != 0) return;
                    this.dySettingGet();
                }
            });
        },
        /**
         * 标签页切换
         */
        async tabClick(value) {
            if (value == '抖音来客') {
                this.dySettingGet();
            }
            if (value == '节假日设置') {
                this.handleOperationHoliday();
            }
            if (value == '在线客服') {
                let res = await OperationCustomer({});
                if (res.errcode != 0) return;
                this.customerConfig = res.data;
            }
            if (value == '退款设置') {
                let res = await OperationRefund({});
                if (res.errcode != 0) return;
                res.data.refundListTitle.forEach((item) => {
                    if (item.key == 'isRefund') {
                        item['slot'] = 'isRefund';
                    }
                });
                res.data.refundListTitle.push({
                    title: '操作',
                    key: 'opreater',
                    slot: 'opreater',
                });
                this.ruleForm = res.data;
            }
            if (value == '限购设置') {
                let res = await OperationPurchase({});
                if (res.errcode != 0) return;
                this.purchaseForm = res.data.purcRestData;
                this.getCancelPurchData();
            }
        },
        /**
            获取限购设置表格数据
        */
        async getCancelPurchData(page = 1) {
            let passdata = {
                all: true,
                page,
                size: 10,
            };
            let res = await querCancelPurch(passdata);
            if (res.errcode != 0) return;
            res.data.title.push({
                key: 'opreat',
                title: '操作',
                slot: 'opreat',
            });
            //获取表格数据
            this.cancelTable = res.data;
        },
        /**
         * 门店节假日获取
         */
        async handleOperationHoliday(page = 1) {
            let res = await OperationHoliday({ exeType: '获取列表', page, all: false });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'opreater',
                slot: 'opreater',
            });
            this.holidayTableData = res.data;
        },
        // 获取七牛云token
        async to_getupTK() {
            let { data: res_data } = await getupTK();
            this.qiniu_config = res_data.data;
        },
        Removingtextprompt(value, index) {
            let tips = this.settings[index].stores.tips;
            tips = tips.filter((item) => value.value != item.value);
            this.settings[index].stores.tips = tips;
        },
        // 首次刷新数据
        async flash_data() {
            this.settings = '';
            let pass_data = {
                mcode: localStorage.getItem('mcode'),
            };
            let res_data = await Get_StoreDate(pass_data);
            this.settings = res_data.data;
        },
        // 执行添加标签提示
        add_color_tag(index) {
            if (this.model_color_select == '' && this.color_title == '') {
                this.$mytoast_err('请正确填写');
                return;
            }
            // push标签到数组
            this.settings[index].stores.tips.push({
                colors: 'orange',
                value: this.color_title,
            });
        },
        // 提交修改按钮点击事件
        async do_Set_Store(item) {
            let pass_data = {
                uid: item.stores.uid, //门店uid
                asName: item.stores.asName, //门店简称
                phone: item.stores.phone, //门店电话
                address: item.stores.address, //文店头像
                src: item.stores.src, //弹框图片
                advert_navigate_url: item.stores.advert_navigate_url, //弹框地址
                BoxShows: item.stores.BoxShows, //是否弹框
                tips: item.stores.tips, //提示标签
                view_tips: item.stores.view_tips,
                is_show_view_tips: item.stores.is_show_view_tips,
                city: item.stores.city || '',
                onLine_show: item.stores.onLine_show || false,
                detailed_address: item.stores.detailed_address || '',
                longitude: item.stores.longitude || 0,
                dimension: item.stores.dimension || 0,
                isCustomer: item.stores.isCustomer || false,
                isRefund: item.stores.isRefund || false,
                isBackFe: item.stores.isBackFe || false,
                tripartiteComparison: item.stores.tripartiteComparison || false,
            };
            let res_data = await Set_Store(pass_data);
            if (res_data.code == 200) {
                this.flash_data();
                this.$mytoast('修改成功');
            } else {
                this.$mytoast_err('修改失败');
            }
        },

        // 同步到小程序点击事件
        async do_PushStore(index) {
            // 推送门店数据到小程序
            let pass_data = {
                branduid: this.settings[index].uid, //品牌uid
                mcode: localStorage.getItem('mcode'), //门店mcode编码
            };
            let res_data = await PushStore(pass_data);
            if (res_data.code == 200) {
                this.$mytoast(res_data.data.resultsOf);
            } else {
                this.$mytoast_err(res_data.msg);
            }
        },
    },
};
</script>
<style>
.ivu-form-item {
    margin-bottom: 10px;
}
</style>
