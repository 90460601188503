import ajax_config  from '../../url.json';
import { api_show_not_200 } from './lib/common.js'
let Auth  = ajax_config.Auth;// 准入令牌【约定】字符
let back_to_login_code = ajax_config.back_to_login_code;// 返回登录页【约定】状态码


/**
 *置token入请求协议头
 * @param {Object}  config [置入token的地址位置]
 */

export const request_config=(config)=>{
	if (localStorage.getItem(Auth)) {
		config.headers[Auth] = localStorage.getItem(Auth);
	}
	//为报表类接口延长超时功能
	if(config.url.indexOf('/ComprehensiveReport')>0){
		config.timeout = 60000
	}

}


/**
 * 响应码判断
 * @param {Object} response [判断该对象携带的响应码] 
 */
export const response_config=(response)=>{
	// 这个抛出到error
	if(response.status!=200){
		let err_msg = response.data.message
		throw new Error(err_msg);
	}else{
		if (response.data && response.data.code != 200&&response.data.errcode!=0) {
				console.log('================40',response.data.code,response.data)
			// -----后端返回的状态码 退出登录-----
			if (response.data.code==555) {
				window.location.replace('/')
			}else{
				// 错误消息提示
				let err_msg = response.data.msg
				api_show_not_200(err_msg)
			}
		}
	}
}

