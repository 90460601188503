<template>
	<div>
		<div>
			<!-- 操作区域 -->
			<div style="display: flex;justify-content: end;">
				<!-- 票券统计查询 起始时间-结束时间 -->
				<DatePicker v-model='value' type="datetimerange" placeholder="Select date and time"
					style="width: 300px">
				</DatePicker>
				<!-- 查询   -->
				<div style="margin:0 10px ; ">
					<Button @click='order_get()' type="primary">查询</Button>
				</div>
				<Button v-if="total" type="primary" size="mini" @click="exportData()">
					<Icon type="ios-download-outline"></Icon> 导出全部数据
				</Button>
			</div>
			<Table ref="table" stripe style="margin-top: 10px;" :columns="title" :data="list"></Table>
			<!-- 表格分页部分 -->
			<Card style='text-align: right;'>
				<Page :page-size="size" :total="total" @on-change='($)=>order_get($)' />
			</Card>
		</div>
	</div>
</template>

<script>
	let mcode;
	import {
		download
	} from '@/utils/table_download.js'
	import {
		coupons_detail
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {

			return {
				title: [], //查询日期的表头
				list: [], //查询日期的数据集
				total: 0, //查询日期的总记录数
				size: 0, //查询日期的当前页最大记录数
				value: [new Date(new Date().setHours(6, 0, 0)), new Date(new Date().setHours(30, 0,
				0))], //初始化当天6点到隔天6点的日期时间数据
			}

		},
		mounted() {
			// 获取查询日期商品销售明细表格数据
			this.order_get()
			mcode = localStorage.getItem('mcode')
		},
		methods: {
			// 导出查询日期的全部数据
			async exportData() {

				// 加载数据
				let pass_data = {
					"startTime": Date.parse(this.value[0]),
					"endTime": Date.parse(this.value[1]),
					"size": this.total,
					"mcode": mcode,
					"page": 1,
					"all": 1,
				}
				let {
					data: res
				} = await coupons_detail(pass_data)

				let data = res.datalist
				let headers = res.title
				download({
					'worksheet': '商品销售明细',
					headers,
					data
				})

			},
			// 获取查询日期商品销售明细表格数据
			async order_get(page = 1) {
				// 表格初始化
				this.title = [];
				this.list = [];
				let pass_data = {
					"startTime": Date.parse(this.value[0]),
					"endTime": Date.parse(this.value[1]),
					"mcode": mcode,
					'page': page,
					"size": 30
				}
				let res = await coupons_detail(pass_data)
				this.title = res.data.title; //查询日期的当前页表头
				this.list = res.data.datalist; //查询日期的当前页数据集
				this.total = res.data.total; //查询日期的总记录数
				this.size = res.data.size; //查询日期的最大数据集
			},

		},

	}
</script>
