<template>
	<div>
		<Card>
			<div style="text-align: right;margin: 20px auto;">
				<Button @click="show_type_add_btn" type="primary">添加</Button>
			</div>
			<Table draggable ref="table" stripe style="margin-top: 50px;" :columns="columns_type" :data="type_data">
				<!-- 前台是否显示 -->
				<template slot-scope="{ row, index }" slot="is_font_show">
					<div>
						<i-Switch v-model="row.displayTags" @on-change="type_change(row)" />
					</div>
				</template>
				<!-- 操作 -->
				<template slot-scope="{ row, index }" slot="operation">
					<div style="display: flex;">
						<div style="margin-right: 5px;">
							<Poptip transfer confirm title="确定是否删除?" @on-ok="()=>doDelgoosType(row)">
								<div style="display: flex;">
									<Button size="small" type="error">删除</Button>
								</div>
							</Poptip>
						</div>
						<div>
							<Button @click="on_cell_click(row)" size="small" type="primary">修改排序</Button>
						</div>
					</div>
				</template>
			</Table>
		</Card>
		<Modal :title="`类型${drow_type}`" :closable='false' :mask-closable='false' v-model="is_show_type_add">
			<Form :label-width="80">
				<FormItem label="类型名称">
					<Input :disabled="drow_type!='添加'" v-model="type.name" placeholder="" />
				</FormItem>
				<FormItem label="排序编号">
					<Input type="number" v-model="type.sortnum" placeholder="" />
				</FormItem>
				<FormItem label="图标路径">
					<Input v-model="type.iconurl" placeholder="" />
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="()=>is_show_type_add=false">取消</Button>
				<Button @click="add_type" type="primary">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import UploadImag from "@/components/UploadImag.vue";
	import {
		SetgoosType,
		GetgoosType,
		SetgoosTypeVisual,
		DelgoosType,
		GetgoosOneTow,
		AddgoosData,
		GetgoosList,
		SetgoosXData,
		UpgoosOnState
	} from "@/request/api.js";
	import {
		getTimeFromZero
	} from "@/utils/index.js";
	export default {
		name: "navbar",
		components: {
			UploadImag,
		},
		data() {
			return {
				current_id: '', //当前选中的id
				modify: [], //许可编辑修改的表单
				goosList_data: {}, //
				pass_data_goods_obj: {
					name: "", //
					attribute: "", //商品属性 通过GetgoosOneTow接口 type=1获取
					type: "", //商品类型
					tag: [], //商品标签组允许多个商品属性 通过GetgoosOneTow接口 type=2获取
					iconurl: "", //商品图片
					sortnum: "", //商品排序越大越靠前
					mcode: localStorage.getItem('mcode'), //影院编码
					describe: "", //商品描述
					details: "", //商品详情
					price: "", //商品原价
					memberPrice: "", //商品会员价
					timeSale: "", //开始售卖时间
					timeEnd: "", //结束售卖时间
				}, //商品添加对象
				pass_data_goods_obj_copy: {},
				drow_type: "", //抽屉动态显示文本  例子  点击添加按钮  xx添加；点击修改按钮 xx修改
				is_show_type_add: false,
				pass_data_type_obj: {
					id: "", //商品类型id，传此参数此接口为修改类型
					name: "", //类型名称
					iconurl: "", //图标路径
					mcode: localStorage.getItem('mcode'), //影院编码
					sortnum: "", //排序编号
				},
				columns_type: [{
						title: "类型id",
						key: "_id",
					},

					{
						title: "影院编码",
						key: "mcode",
					},
					{
						title: "类型名称",
						key: "name",
					},
					{
						title: "前台是否显示",
						key: "displayTags",
						slot: "is_font_show",
					},
					{
						title: "图标连接",
						key: "iconurl",
					},
					{
						title: "排序",
						key: "sortnum",
					},
					{
						title: "类型编号",
						key: "type",
					},

					{
						title: "操作",
						key: "operation",
						slot: "operation",
					},
				],
				type_data: [],
				type: {
					mcode: localStorage.getItem('mcode'), //影院编码
					"name": "",
					"iconurl": "",
					"sortnum": ""
				},
				typeCopy: {}
			};

		},
		created() {
			this.typeCopy = JSON.parse(JSON.stringify(this.type))
		},
		mounted() {
			this.pass_data_goods_obj_copy = JSON.parse(JSON.stringify(this.pass_data_goods_obj))
			// 获取商品类型列表
			this.doGetgoosType();
		},
		methods: {
			reset() {
				this.type = JSON.parse(JSON.stringify(this.typeCopy))
			},
			//获取 商品详情标签页 列表
			async doGetgoosList(page = 1) {
				let pass_data = {
					mcode: localStorage.getItem('mcode'),
					page: page,
				};
				let {
					data: res_data
				} = await GetgoosList(pass_data);
				res_data.titlelist.forEach((item, index) => {
					if (item.title == "商品标签") {
						item.slot = "tag";
						item.width = "200";
					}
					if (item.title == "上架") {
						item.slot = "onState";
					}
				});
				// 添加操作列
				res_data.titlelist.push({
					title: "操作",
					slot: "operation",
					'width': '150'
				});
				this.goosList_data = res_data;
			},
			// 添加按钮
			show_type_add_btn() {
				this.reset()
				this.is_show_type_add = true;
				this.drow_type = "添加";
			},
			// 单元格点击里面的按钮按数据
			on_cell_click(row_data) {
				// 分支从修改排序按钮出来的
				this.drow_type = "修改";
				this.is_show_type_add = true;
				this.type = JSON.parse(JSON.stringify(row_data))
				this.type.mcode = localStorage.getItem('mcode')
			},
			// 删除商品类型
			async doDelgoosType(row_data) {
				let pass_data = {
					id: row_data._id,
				};
				let res_data = await DelgoosType(pass_data);
				// 页面回显实时数据
				this.doGetgoosType();
			},
			async type_change(row_data) {
				let pass_data = {
					id: row_data._id, //商品类型id
					show: row_data.displayTags ? "1" : "-1", //-1不显示  1显示
				};
				// 设置商品类型可视状态  发送ajax
				let res_data = await SetgoosTypeVisual(pass_data);
				if (res_data.data.id) {
					this.$Message.success("更新成功");
				} else {
					this.$Message.error("更新失败");
				}
			},
			// 首次刷新查询
			async doGetgoosType() {
				let pass_data = {
					mcode: localStorage.getItem('mcode'),
				};
				let {
					data: res_data
				} = await GetgoosType(pass_data);
				this.type_data = res_data;
			},
			// 类型添加
			async add_type() {
				// 参数
				let pass_data = this.type;
				// 执行添加类型 ajax
				let {
					data: res_data
				} = await SetgoosType(pass_data);
				this.doGetgoosType();
				this.is_show_type_add = false;
			},
		},
	};
</script>

<style>
	.blue {
		background-color: #2d8cf0;
		color: #fff;
	}

	.show_type_add_class .ivu-drawer {
		width: 40vw !important;
	}

	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
