<template>
	<div>
		<div>
			
			<!-- 顶部搜索 -->
			<div style="text-align: right;">
				<Form inline>
					<FormItem>
						<Input v-model="number" placeholder="请输入卡号/手机号码/用户openid" style='width: 300px;'></Input>
					</FormItem>
					<FormItem>
						<Button @click="()=>to_memberCardQuery(1,number)" style="margin-left: 10px;">查询</Button>
					</FormItem>
					<FormItem>
						<Button @click="()=>table_dowload_card()">导出会员卡记录</Button>
					</FormItem>
				</Form>
			</div>
			<!-- 会员卡查询 -->
			<div style="margin-top: 10px;">
				<Table style="margin-top: 10px;" :columns='memberCardQuery_obj.title' :data='memberCardQuery_obj.list'>
					<template slot-scope='{row}' slot='operation'>
						
						<Button @click="()=>current_row=row&&to_memberCardConsumeQuery(row)" type='primary' size='small'>查看消费记录</Button>
						<Button @click="()=>table_dowload(row)" size='small' style="margin-left: 10px;">导出消费记录</Button>
					</template>
					
				</Table>
				<Card>
					<div style="margin-top: 10px;text-align: right;">
						<Page :total='memberCardQuery_obj.total' @on-change="($)=>to_memberCardQuery($)"></Page>
					</div>
				</Card>
			
			</div>
			<!-- 消费记录查询 -->
			<div style="margin-top: 10px;">
				
				<Table :columns='memberCardConsumeQuery_obj.title' :data='memberCardConsumeQuery_obj.list'>
				</Table>
				<Card>
					<div style="margin-top: 10px;text-align: right;">
						<Page :total='memberCardConsumeQuery_obj.total' @on-change="($)=>to_memberCardConsumeQuery($)"></Page>
					</div>
				</Card>
				
			</div>
			
		</div>
	</div>
</template>

<script>
	
	import { download } from '../../../utils/table_download.js'
	import { memberCardQueryv1,memberCardConsumeQueryv1 } from '../../../request/api.js'
	export default{
		name:'页面_会员卡查询',
		data() {
			
			return{
				number:'',
				current_row:'',//当前点击查看消费记录的表格行
				memberCardQuery_obj:'',//会员卡数据
				memberCardConsumeQuery_obj:'',//消费记录表格数据
			}
			
		},
		mounted(){
			
			this.to_memberCardQuery()
			
		},
		methods: {
			
			// 导出会员卡数据
			async table_dowload_card(){
				
				let pass_data = {
					"mcode":localStorage.getItem('mcode'),
					"page":1,
					"all":1
				}
				
				let {data:res_data} = await memberCardQueryv1(pass_data)
				
				let headers = res_data.title
				let data  = res_data.list
			    download({
				  'worksheet':'会员卡数据',
				  headers,
				  data
			    })
				
			},
			
			// 导出消费记录
			async table_dowload(row){
				
				let pass_data = {
					"_id":row._id,
					"page":1,
					"all":1
				}
				
				let {data:res_data} = await memberCardConsumeQueryv1(pass_data) 
				
				let headers = res_data.title
				let data  = res_data.list
				download({
				  'worksheet':row.orderid+'',
				  headers,
				  data
				})
				
			},
			
			// 会员卡消费查询  查询消费记录
			async to_memberCardConsumeQuery(row,page=1){
				
				let pass_data = {
					"_id":row._id,
					"page":page,
				}
				
				let {data:res_data} = await memberCardConsumeQueryv1(pass_data)
		
				this.memberCardConsumeQuery_obj = res_data 
				
			},
			
			// 会员卡查询
			async to_memberCardQuery(page=1,number){
				
				let pass_data = {
					"mcode":localStorage.getItem('mcode'),
					"page":page,
				}
				if(this.number){
					pass_data['number']=this.number
				}
				
				let {data:res_data} = await memberCardQueryv1(pass_data)
				
				res_data.title.push({
					key:'operation',
					title:'操作',
					slot:'operation',
					width:280
				})
				this.memberCardQuery_obj = res_data
				
			}
		
		},
	}
</script>

