<template>
    <div>
        <div class="mb-2 text-left">
            <Form ref="inlineForm" :model="inlineForm" :rules="inlineFormRules" inline>
                <FormItem label="单次订单查询" label-width="120" style="width: 300px" prop="orderid">
                    <Input v-model="inlineForm.orderid" placeholder="请输入订单号/取票码" />
                </FormItem>
                <FormItem>
                    <Button @click="() => search()">查找订单退款</Button>
                </FormItem>
            </Form>
            <Form inline>
                <FormItem label="批量订单查询" label-width="120" style="width: 320px" prop="orderid">
                    <DatePicker @on-change="handleDateChange" v-model="screeningDate" type="date" placeholder="选择查询日期" style="width: 200px"></DatePicker>
                </FormItem>
                <FormItem label="批量订单查询" label-width="120" style="width: 320px" prop="orderid">
                    <Cascader :data="Trading_session_list" v-model="seqNo"></Cascader>
                </FormItem>
                <FormItem>
                    <Button @click="() => handleSeqNoGetPayOrder()">批量订单查询</Button>
                </FormItem>
            </Form>
        </div>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="paytype">
                {{ row.paytype.join(' | ') }}
            </template>
            <template slot-scope="{ row, index }" slot="Goodstype">
                {{ row.Goodstype.join(' | ') }}
            </template>
            <template slot-scope="{ row, index }" slot="orderAbout">
                {{ row.orderAbout.join(' ') }}
            </template>
            <template slot-scope="{ row, index }" slot="opreate">
                <div class="flex">
                    <div class="mr-2">
                        <Button @click="() => routeTo('orderDetail?id=' + row._id)" size="small">查看</Button>
                    </div>
                    <div class="mr-2">
                        <Poptip transfer confirm title="是否确认退款?" @on-ok="() => handleOrderRefundExe(row)">
                            <Button size="small" type="primary">同意</Button>
                        </Poptip>
                    </div>
                    <div>
                        <Button @click="() => showRejectModal(row)" size="small" type="error">驳回</Button>
                    </div>
                </div>
            </template>
        </Table>
        <Card class="text-right">
            <Page @on-change="(value) => fetchData(value)" :total="tableData.total" :page-size="tableData.size"></Page>
        </Card>
        <Modal :closable="false" :maskClosable="false" v-model="show">
            <Form ref="form" :model="form" :rules="ruleValidate">
                <FormItem prop="content" label="驳回原因  (顾客在小程序端可查看驳回原因，请慎重填写)">
                    <Input type="textarea" v-model="form.content" />
                </FormItem>
            </Form>
            <div slot="footer">
                <div class="flex justify-end">
                    <Button @click="() => close()" class="mr-2">取消</Button>
                    <Button @click="() => reject()" type="primary">确定</Button>
                </div>
            </div>
        </Modal>
        <Modal width="1000" :mask-closable="false" :closable="false" v-model="orderRefundModal">
            <Table size="small" :columns="orderRefundtableData.title" :data="orderRefundtableData.list">
                <template slot-scope="{ row }" slot="operater">
                    <Button @click="() => routeTo('orderDetail?id=' + row._id)" size="small">详情</Button>
                </template>
            </Table>
            <div slot="footer" class="text-right">
                <Button @click="() => (orderRefundModal = false)">返回</Button>
            </div>
        </Modal>
        <Modal width="1000" :mask-closable="false" :closable="false" v-model="ModalPiOrder">
            <div style="height: 500px; overflow-y: scroll">
                <Table ref="selection" size="small" :columns="tableDataPiOrder.title" :data="tableDataPiOrder.list">
                    <template slot-scope="{ row }" slot="getState">
                        {{ row.getState ? '是' : '否' }}
                    </template>
                    <template slot-scope="{ row }" slot="markInRefund">
                        {{ row.markInRefund ? '是' : '否' }}
                    </template>
                    <template slot-scope="{ row }" slot="orderAbout">
                        <div v-for="(item, index) in row.orderAbout">
                            <div>{{ item }}</div>
                        </div>
                    </template>
                    <template slot-scope="{ row }" slot="payTime">
                        {{ new Date(row.payTime).Format('yyyy-MM-dd hh:mm') }}
                    </template>
                </Table>
            </div>
            <div>
                <Form :model="inlineFormRefund" :rules="inlineFormRulesRefund" inline>
                    <FormItem prop="refundReason" label="退款原因" label-width="120" style="width: 300px">
                        <Input v-model="inlineFormRefund.refundReason" style="width: 300px" />
                    </FormItem>
                </Form>
            </div>
            <div slot="footer" class="text-right">
                <Button @click="canclePiOrderModalShow">取消</Button>
                <Button @click="selectRefund" type="primary">选中退款</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetRefundInList, RefundReject, OrderRefundExe, OrderList, queryRefSessions, seqNoGetPayOrder, refOrderCommit } from '@/request/api';
export default {
    name: 'refundV3',
    data() {
        return {
            show: false,
            tableData: '',
            tableRowData: '',
            ruleValidate: {
                content: { required: true, message: '请输入驳回原因' },
            },
            form: {
                content: '',
            },
            inlineForm: {},
            inlineFormRules: {
                orderid: [{ required: true, message: ' ' }],
            },
            orderRefundtableData: '',
            orderRefundModal: false,
            screeningDate: '',
            Trading_session_list: [], //交易场次列表
            seqNo: '',
            ModalPiOrder: false,
            tableDataPiOrder: {
                title: [],
                list: [],
            },
            inlineFormRulesRefund: {
                refundReason: [{ required: true, message: ' ' }],
            },
            inlineFormRefund: {
                refundReason: '',
            },
        };
    },
    watch: {
        ModalPiOrder(value) {
            if (value) {
                this.inlineFormRefund.refundReason = '';
            }
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * 隐藏批量订单查询
         */
        canclePiOrderModalShow() {
            this.ModalPiOrder = false;
        },
        /**
         * 选中退[款
         */
        async selectRefund() {
            if (this.inlineFormRefund.refundReason.trim() == '') {
                this.$Message.warning('请输入退款原因');
                return;
            }
            let ids = this.$refs.selection.getSelection().map((item) => item._id);
            if (ids.length == 0) {
                this.$Message.warning('请选择订单');
                return;
            }
            let passdata = {
                refids: ids, //订单id
                content: this.inlineFormRefund.refundReason, //退款理由
            };
            let res = await refOrderCommit(passdata);
            if (res.errcode != 0) return;
            this.$Message.success('提交成功');
            this.ModalPiOrder = false;
            this.fetchData();
        },
        /**
         * 执行批量订单查询
         */
        async handleSeqNoGetPayOrder() {
            if (!this.screeningDate || !this.seqNo) {
                this.$Message.warning('请选择查询条件');
                return;
            }
            let res = await seqNoGetPayOrder({ seqNo: this.seqNo[1] });
            if (res.errcode != 0) return;
            res.data.title.unshift({
                type: 'selection',
                width: 60,
                align: 'center',
            });
            res.data.title.forEach((item) => {
                if (item.key == 'getState' || item.key == 'markInRefund' || item.key == 'orderAbout' || item.key == 'payTime') {
                    if (item.key == 'orderAbout') {
                        item['width'] = 300;
                    }
                    item['slot'] = item.key;
                }
            });
            res.data.list.forEach((item) => {
                item['_disabled'] = item.markInRefund;
            });
            this.tableDataPiOrder = res.data;

            //弹出装载表格的弹窗
            this.ModalPiOrder = true;
        },
        /**
         * 选择日期
         */
        async handleDateChange(value) {
            let res = await queryRefSessions({
                screeningDate: value,
            });
            if (res.errcode != 0) return;
            let data = res.data;
            let result = [];
            data.forEach((item) => {
                let row = {};
                row.value = item._id;
                row.label = item._id;
                row.children = [];
                item.sessions.forEach((item1) => {
                    row.children.push({
                        label: item1.showTime + '(' + item1.screenName + ')',
                        value: item1.seqNo,
                    });
                });
                result.push(row);
            });
            this.seqNo = '';
            this.Trading_session_list = result;
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        search() {
            this.$refs['inlineForm'].validate(async (valid) => {
                if (valid) {
                    let orderid = this.inlineForm.orderid.trim();
                    let res = await OrderList({
                        goodsType: ['电影票', '卖品套餐', '会员充值', '会员开卡'], //默认["电影票","卖品套餐","会员充值","会员开卡"] 多选项符合此数组成员内容即可
                        start: '2023-04-08T06:00', //开始时间
                        end: '2023-04-09T06:00', //结束时间
                        state: ['已支付', '已关闭'], //默认["已支付","未支付","已退款","已关闭"],多选项符合此数组成员内容即可
                        orderid: orderid, //订单号，传为当前门店下精确查找
                        openid: null, //用户相关订单
                        all: false, //是否全部导出
                        page: 1, //分页页码，数字类型
                    });
                    if (res.errcode != 0) return;
                    this.orderRefundModal = true;
                    res.data.title = [
                        {
                            key: 'orderid',
                            title: '单号',
                        },
                        {
                            key: 'orderName',
                            title: '订单名称',
                        },
                        {
                            key: 'orderAbout',
                            title: '商品描述',
                        },
                        {
                            title: '操作',
                            key: 'operater',
                            slot: 'operater',
                        },
                    ];
                    this.orderRefundtableData = res.data;
                }
            });
        },
        /**
         * 同意 按钮
         */
        async handleOrderRefundExe(value) {
            const { _id } = value;
            let passdata = {
                id: _id,
            };
            let res = await OrderRefundExe(passdata);
            if (res.errcode != 0) return;
            this.fetchData(this.tableData.page);
        },
        /**
         * 驳回弹窗 取消
         */
        close() {
            this.show = false;
            this.$refs['form'].resetFields();
        },
        /**
         * 驳回 按钮
         */
        showRejectModal(value) {
            this.tableRowData = value;
            this.show = true;
        },
        async reject() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let passdata = {
                        id: this.tableRowData._id,
                        content: this.form.content,
                    };
                    let res = await RefundReject(passdata);
                    if (res.errcode != 0) return;
                    this.show = false;
                    this.fetchData(this.tableData.page);
                    this.$refs['form'].resetFields();
                }
            });
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        /**
         * 表格数据获取
         */
        async fetchData(page = 1) {
            let res = await GetRefundInList({
                all: false,
                page,
            });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'opreate',
                slot: 'opreate',
                width: '200',
            });
            res.data.title.forEach((item, index) => {
                if (item.key == 'paytype') {
                    item['slot'] = 'paytype';
                }
                if (item.key == 'Goodstype') {
                    item['slot'] = 'Goodstype';
                }
                if (item.key == 'orderAbout') {
                    item['slot'] = 'orderAbout';
                }
            });
            this.tableData = res.data;
        },
    },
};
</script>

