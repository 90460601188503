<template>
    <div class="h-screen p-12" style="background-color: #efefef">
        <Card>
            <div v-if="detail" class="relative">
                <img class="absolute w-24 h-32 right-5 top-5" :src="detail.imgurl" />
                <div class="absolute bottom-0 right-5">
                    <div v-if="detail.state == '已支付'">
                        <Button @click="() => showRefundModal('仅退款')" class="ml-2" v-if="!detail.markInRefund" type="error">退款</Button>
                    </div>
                    <Poptip transfer confirm title="是否确认同意退单?" @on-ok="() => handleOrderRefundExeAgree()">
                        <Button v-if="detail.state == '退款中'" type="warning">同意退单</Button>
                    </Poptip>
                    <Button @click="() => (showRejectModal = true)" class="ml-2" v-if="detail.state == '退款中'" type="warning">撤销退单</Button>
                </div>
                <div class="mb-2">
                    <div class="pl-2 mb-2 font-bold" style="border-left: 4px solid #000">订单信息</div>
                    <div class="pl-7">
                        <div class="flex">
                            <div class="w-24">订单号:</div>
                            <div>{{ detail.orderid }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">订单名称:</div>
                            <div>{{ detail.orderName }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">订单描述:</div>
                            <div>{{ detail.orderAbout.join(' ') }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">订单状态:</div>
                            <Tag v-if="detail.state == '已支付'" color="success">{{ detail.state }}</Tag>
                            <Tag v-if="detail.state == '未支付'" color="primary">{{ detail.state }}</Tag>
                            <Tag v-if="detail.state == '已退款'" color="error">{{ detail.state }}</Tag>
                            <Tag v-if="detail.state == '已关闭'" color="default">{{ detail.state }}</Tag>
                            <Tag v-if="detail.state == '退款中'" color="warning">{{ detail.state }}</Tag>
                        </div>
                        <div class="flex">
                            <div class="w-24">交易时间:</div>
                            <div>{{ detail.payTime }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">支付类型:</div>
                            <div v-for="(item, index) in detail.paytype" :key="index">
                                <div class="mr-2">
                                    <Tag color="orange">{{ item }}</Tag>
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.state == '退款中' || detail.state == '已退款'">
                            <div class="flex">
                                <div class="w-24">支付金额:</div>
                                <div>{{ detail.orderPrice }}元</div>
                            </div>
                            <div class="flex">
                                <div class="w-24">手续费</div>
                                <div>{{ detail.refundService_fee }}元</div>
                            </div>
                        </div>

                        <div class="flex">
                            <div v-if="detail.state == '退款中' || detail.state == '已退款'" class="w-24">退款金额</div>
                            <div v-else class="w-24">支付金额:</div>
                            <div>{{ detail.cash_fee }}元</div>
                        </div>
                        <div v-if="detail.systemOrderid" class="flex">
                            <div class="w-24">外部订单号:</div>
                            <div>{{ detail.systemOrderid }}</div>
                        </div>
                    </div>
                </div>
                <div class="mb-2" v-if="detail.qrCode">
                    <div class="flex pl-2 mb-2 font-bold" style="border-left: 4px solid #000">
                        <div class="mr-3">取货码</div>
                        <div class="text-green-500" v-if="detail.getState">(已取)</div>
                        <div v-else>未取</div>
                    </div>
                    <div class="pl-7">
                        <div class="flex">
                            <div class="w-24">{{ detail.qrCode }}</div>
                        </div>
                    </div>
                </div>
                <div class="mb-2">
                    <div class="pl-2 mb-2 font-bold" style="border-left: 4px solid #000">订单备注</div>
                    <div class="pl-7">
                        <div class="flex">
                            <div class="w-24">用户手机:</div>
                            <div>{{ detail.userPhone }}</div>
                        </div>

                        <div class="flex">
                            <div class="w-24">用户串码:</div>
                            <div>{{ detail.openid }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">影院名称:</div>
                            <div>{{ detail.cname }}({{ detail.mcode }})</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">过期时间:</div>
                            <div>{{ detail.expiration }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">备注:</div>
                            <div>{{ detail.Note }}</div>
                        </div>
                    </div>
                </div>
                <div class="mb-2" v-if="detail.refereestype">
                    <div class="pl-2 mb-2 font-bold" style="border-left: 4px solid #000">操作信息</div>
                    <div class="pl-7">
                        <div class="flex">
                            <div class="w-24">操作类型:</div>
                            <div>{{ detail.refereestype }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">操作人:</div>
                            <div>{{ detail.refereesname }}</div>
                        </div>
                        <div class="flex">
                            <div class="w-24">提成金额:</div>
                            <div>{{ detail.royalty }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-2" v-if="detail.Reforder.length > 0">
                <div class="pl-2 mb-2 font-bold" style="border-left: 4px solid #000">退款单信息</div>
                <div class="pl-7">
                    <Table size="small" :columns="detail.ReforderTitle" :data="detail.Reforder">
                        <template slot-scope="{ row, index }" slot="state">
                            <Tag style="transform: scale(0.8)" v-if="row.state == '已退款'" color="error">{{ row.state }}</Tag>
                            <Tag style="transform: scale(0.8)" v-if="row.state == '退款中'" color="warning">{{ row.state }}</Tag>
                        </template>
                    </Table>
                </div>
            </div>
            <div class="flex" v-if="detail.state == '退款中' || detail.state == '已退款'">
                <div class="w-24">支付订单号</div>
                <div>{{ detail.yorderid }}</div>
            </div>
            <div class="mb-2" v-if="detail.Reforder.length > 0">
                <div class="pl-2 mb-2 font-bold" style="border-left: 4px solid #000">商品/票券信息</div>
                <div class="pl-7"></div>
            </div>
        </Card>
        <Card v-for="(item, index) in detail.gooditems" :key="index">
            <div class="flex items-center">
                <img class="h-24 w-18" :src="item.img" />
                <div class="flex-1 ml-5">
                    <div>{{ item.type }}</div>
                    <div v-if="item.type == '电影票'">
                        <div>{{ item.movieName }}</div>
                        <div>{{ item.showTime }}</div>
                        <div>{{ item.screenName }}</div>
                    </div>
                    <div>{{ item.name }}</div>
                </div>
                <div class="flex-1 ml-5">
                    <div v-if="item.couponApply">
                        <Tag color="orange">批次号:{{ item.couponApply }}</Tag>
                    </div>
                    <div v-if="item.couponNum">
                        <Tag color="blue">{{ item.couponName }}:{{ item.couponNum }}</Tag>
                    </div>
                    <div v-if="item.giv">
                        <Tag color="blue">赠品</Tag>
                    </div>
                    <div class="flex">
                        <div>支付金额:</div>
                        <div>{{ item.cash_fee }}元</div>
                    </div>
                </div>
                <div class="flex-1 ml-5">
                    <div v-if="item.state == '未支付'" class="text-gray-500">{{ item.state }}</div>
                    <div v-if="item.state == '未取'">{{ item.state }}</div>
                    <div v-if="item.state == '已取'" class="text-green-500">已取({{ item.pickupTime }})</div>
                    <div v-if="item.state == '已退货'" class="text-red-500">已退货</div>
                    <div v-if="item.state == '已取消'" class="text-gray-300">已取消</div>
                </div>
                <div class="flex-1 ml-5" v-if="item.state == '已退货'">
                    <div class="flex">
                        <div>退款金额</div>
                        <div>{{ item.ret_fee }}</div>
                    </div>
                    <div class="flex">
                        <div>退款时间</div>
                        <div>{{ item.retTime }}</div>
                    </div>
                    <div class="flex">
                        <div>退款订单</div>
                        <div>{{ item.Rorderid }}</div>
                    </div>
                </div>
            </div>
        </Card>
        <Modal :closable="false" :maskClosable="false" v-model="show">
            <Form ref="form" :model="form" :rules="ruleValidate">
                <FormItem prop="content" :label="`${isRgood ? '订单退单' : '退款'}原因  (顾客在小程序端可查看原因，请慎重填写)`">
                    <Input type="textarea" v-model="form.content" />
                </FormItem>
                <FormItem prop="isRgood">
                    <Checkbox v-model="form.isRgood">释放订单</Checkbox>
                </FormItem>
            </Form>
            <div slot="footer">
                <div class="flex justify-end">
                    <Button @click="() => close()" class="mr-2">取消</Button>
                    <Button @click="() => handleOrderRefundExe()" type="primary">确定</Button>
                </div>
            </div>
        </Modal>
        <Modal :closable="false" :maskClosable="false" v-model="showRejectModal">
            <Form ref="formReject" :model="formReject" :rules="ruleValidateReject">
                <FormItem prop="content" label="驳回原因  (顾客在小程序端可查看驳回原因，请慎重填写)">
                    <Input type="textarea" v-model="formReject.content" />
                </FormItem>
            </Form>
            <div slot="footer">
                <div class="flex justify-end">
                    <Button @click="() => closeReject()" class="mr-2">取消</Button>
                    <Button @click="() => handleOrderRefundExeBack()" type="primary">确定</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetOrserInfo, OrderRefundExe, RefundReject } from '@/request/api';
import { Checkbox } from 'view-design';
export default {
    name: 'orderDetail',
    data() {
        return {
            show: false,
            detail: '',
            form: {
                content: '',
                isRgood: true,
            },
            ruleValidate: {
                content: { required: true, message: '请输入原因' },
            },
            isRgood: '',
            id: '',
            formReject: {
                content: '',
            },
            ruleValidateReject: {
                content: { required: true, message: '请输撤销入原因' },
            },
            showRejectModal: false,
        };
    },
    created() {
        const { id } = this.$route.query;
        this.id = id;
        this.fetch(id);
    },
    methods: {
        closeReject() {
            this.showRejectModal = false;
            this.$refs['formReject'].resetFields();
        },
        /**
         * 撤销退单 提交
         */
        async handleOrderRefundExeBack() {
            this.$refs['formReject'].validate(async (valid) => {
                if (valid) {
                    let passdata = {
                        id: this.detail._id,
                        content: this.formReject.content,
                    };
                    let res = await RefundReject(passdata);
                    if (res.errcode != 0) return;
                    this.showRejectModal = false;
                    this.fetch(this.id);
                    this.$refs['formReject'].resetFields();
                }
            });
        },
        /**
         * 同意 按钮
         */
        async handleOrderRefundExeAgree() {
            const { _id } = this.detail;
            let passdata = {
                id: _id,
            };
            let res = await OrderRefundExe(passdata);
            if (res.errcode != 0) return;
            this.fetch(this.id);
        },
        close() {
            this.show = false;
            this.$refs['form'].resetFields();
        },
        /**
         * 订单退单/仅退款 按钮
         */
        showRefundModal(value) {
            switch (value) {
                case '仅退款':
                    this.isRgood = false;
                    this.show = true;
                    break;
            }
        },
        async handleOrderRefundExe() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    const { _id } = this.detail;
                    const { content, isRgood } = this.form;
                    let passdata = {
                        id: _id,
                        content,
                        isRgood,
                    };
                    let res = await OrderRefundExe(passdata);
                    if (res.errcode != 0) return;
                    this.show = false;
                    this.fetch(this.id);
                    this.$refs['form'].resetFields();
                }
            });
        },
        async fetch(id) {
            let res = await GetOrserInfo({ id });
            if (res.errcode != 0) return;
            res.data.ReforderTitle.forEach((item, index) => {
                if (item.key == 'state') {
                    item['slot'] = 'state';
                }
            });
            this.detail = res.data;
        },
    },
    components: { Checkbox },
};
</script>
