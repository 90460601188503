<template>
    <div>
        <div style="text-align: right; margin-bottom: 10px">
            <Button @click="() => showCreateModal()" type="primary">创建</Button>
            <Button @click="() => table_dowload()" style="margin-left: 10px">导出</Button>
        </div>
        <Table stripe :columns="CupOrderList.title" :data="CupOrderList.list">
            <template slot-scope="{ row }" slot="operation">
                <Button @click="() => showEditModal(row)" type="primary" size="small" style="margin-right: 10px">编辑</Button>
                <Poptip transfer confirm title="确定是否删除?" @on-ok="handleEditGoodBase(row)">
                    <Button type="error" size="small">删除</Button>
                </Poptip>
            </template>
        </Table>
        <Card style="text-align: right">
            <Page @on-change="(value) => handleCouponOrderList(value)" :page-size="CupOrderList.size" :total="CupOrderList.total"></Page>
        </Card>
        <Modal title="编辑" width="600" :mask-closable="false" :closable="false" v-model="editModal">
            <Form ref="formEdit" :model="formEdit" :label-width="100">
                <FormItem label="货物名称">
                    <Input disabled class="w_300" v-model="EditDesc.name"></Input>
                </FormItem>
                <FormItem label="货物类型">
                    <Select disabled class="w_300" v-model="EditDesc.type">
                        <Option v-for="(item, index) in PresetValue.cargotype" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem prop="purchas" label="供应商选择">
                    <Select class="w_300" multiple v-model="formEdit.purchas">
                        <Option v-for="(item, index) in PresetValue.purchas" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem label="状态">
                    <Select class="w_300" v-model="formEdit.state">
                        <Option v-for="(item, index) in stateList" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>
                <div style="display: flex">
                    <FormItem label="库存">
                        <Input disabled class="w_100" v-model="EditDesc.stock"></Input>
                    </FormItem>
                    <FormItem label="成本价">
                        <Input disabled class="w_100" v-model="EditDesc.ave"></Input>
                    </FormItem>
                </div>
                <div style="display: flex">
                    <FormItem label="基础单位选择">
                        <Select disabled class="w_100" v-model="EditDesc.unit">
                            <Option v-for="(item, unit) in PresetValue.units" :value="item" :key="item">
                                {{ item }}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="操作员">
                        <Input disabled class="w_100" v-model="EditDesc.upBy"></Input>
                    </FormItem>
                </div>

                <FormItem label="其他换算单位">
                    <div style="unit: flex">
                        <Select class="w_200" v-model="addObj.unit">
                            <Option v-for="(item, unit) in PresetValue.units" :value="item" :key="item">
                                {{ item }}
                            </Option>
                        </Select>
                        <Input type="number" v-model="addObj.val" style="width: 100px; margin-right: 5px" />
                        <Button @click="() => addAdcunit('edit')" type="primary">增加</Button>
                    </div>
                </FormItem>
            </Form>
            <Table :columns="createtitle" :data="formEdit['adcunit']">
                <template slot-scope="{ row, index }" slot="operation">
                    <Button @click="() => deleteAdcunit(index, 'edit')" type="error" size="small">删除</Button>
                </template>
            </Table>

            <div slot="footer">
                <Button @click="() => (editModal = false)">取消</Button>
                <Button @click="() => handleEditSave()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal title="创建" width="600" :mask-closable="false" :closable="false" v-model="createModal">
            <Form ref="form" :model="form" :label-width="120">
                <FormItem prop="name" label="货物名称">
                    <Input class="w_300" v-model="form.name"></Input>
                </FormItem>
                <FormItem prop="type" label="货物类型">
                    <Select class="w_300" v-model="form.type">
                        <Option v-for="(item, index) in PresetValue.cargotype" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem prop="purchas" label="供应商选择">
                    <Select class="w_300" multiple v-model="form.purchas">
                        <Option v-for="(item, index) in PresetValue.purchas" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem prop="unit" label="基础单位选择">
                    <Select class="w_300" v-model="form.unit">
                        <Option v-for="(item, unit) in PresetValue.units" :value="item" :key="item">
                            {{ item }}
                        </Option>
                    </Select>
                </FormItem>

                <FormItem label="其他换算单位">
                    <div style="unit: flex">
                        <Select class="w_200" v-model="addObj.unit">
                            <Option v-for="(item, unit) in PresetValue.units" :value="item" :key="item">
                                {{ item }}
                            </Option>
                        </Select>
                        <Input type="number" v-model="addObj.val" style="width: 100px; margin-right: 5px" />
                        <Button @click="() => addAdcunit()" type="primary">增加</Button>
                    </div>
                </FormItem>
            </Form>
            <Table :columns="createtitle" :data="form['adcunit']">
                <template slot-scope="{ row, index }" slot="operation">
                    <Button @click="() => deleteAdcunit(index)" type="error" size="small">删除</Button>
                </template>
            </Table>
            <div slot="footer">
                <Button @click="() => (createModal = false)">取消</Button>
                <Button @click="() => handleCreateGoodBase()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { GetGoodBaseList, CreateGoodBase, EditGoodBase, GetPresetValue } from '@/request/api';
import { download } from '@/utils/table_download.js';
export default {
    name: 'Inventorymanagement',
    data() {
        return {
            stateList: ['在售', '停售'],
            formEdit: {
                state: '',
                purchas: [], //供应商选择 ["总部采购","影院自采"] 多选
                adcunit: [], //其
            },
            form: {
                name: '', //货物名称
                type: '', //货物类型:["包材","原料","预包装"] 三选一
                purchas: [], //供应商选择 ["总部采购","影院自采"] 多选
                unit: '', //基础单位选择["克","个","瓶","张","条","包","桶","箱"]
                adcunit: [], //其
            },
            addObj: {
                unit: '',
                val: '',
            },
            createtitle: [
                {
                    key: 'unit',
                    title: '单位',
                },
                {
                    key: 'val',
                    title: '换算量',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                    width: 100,
                },
            ],
            CupOrderList: '',
            createModal: false,
            editModal: false,
            PresetValue: '',
            EditDesc: '',
        };
    },
    watch: {
        createModal(value) {
            if (!value) {
                this.$refs['form'].resetFields();
                this.reset();
            }
        },
        editModal(value) {
            if (!value) {
                this.$refs['formEdit'].resetFields();
                this.reset();
            }
        },
    },
    created() {
        this.handleGetPresetValue();
        this.handleGetGoodBaseList();
    },
    methods: {
        /**
         * 表格弹窗编辑-保存
         */
        async handleEditSave() {
            let passData = JSON.parse(JSON.stringify(this.formEdit));
            passData['gid'] = this.EditDesc._id;
            passData['edit_type'] = '修改属性';
            let res = await EditGoodBase(passData);
            if (res.errcode != 0) return;
            this.handleGetGoodBaseList();
            this.editModal = false;
        },
        /**
         * 表格删除
         */
        async handleEditGoodBase(value) {
            const { _id } = value;
            await EditGoodBase({
                gid: _id,
                edit_type: '删除',
            });
            this.handleGetGoodBaseList();
        },
        /**
         * 表格新增
         */
        async handleCreateGoodBase() {
            console.log(this.form, '==this.form');
            let res = await CreateGoodBase(JSON.parse(JSON.stringify(this.form)));
            if (res.errcode != 0) return;
            this.handleGetGoodBaseList();
            this.createModal = false;
        },
        reset() {
            this.addObj = {
                unit: '',
                val: '',
            };
            this.form.adcunit = [];
            this.formEdit.adcunit = [];
        },
        /**
         * 创建弹窗-删除
         */
        deleteAdcunit(value, type = 'create') {
            if (type == 'edit') {
                this.formEdit['adcunit'].splice(value, 1);
                return;
            }
            this.form['adcunit'].splice(value, 1);
        },
        /**
         * 创建弹窗-插入表格
         */
        addAdcunit(type = 'create') {
            if (this.addObj.val <= 0) {
                this.$Message.error('数量不能小于0');
                return;
            }
            if (this.addObj.val == '' || this.addObj.unit == '') return;
            if (type == 'edit') {
                this.formEdit.adcunit.push(JSON.parse(JSON.stringify(this.addObj)));
                return;
            }
            this.form.adcunit.push(JSON.parse(JSON.stringify(this.addObj)));
        },
        /**
         * 展开编辑弹窗
         */
        async showEditModal(row) {
            const { _id } = row;
            this.handleEditDesc(_id);
            this.editModal = true;
        },
        /**
         * 展开创建弹窗
         */
        async showCreateModal() {
            this.createModal = true;
        },
        /**
         * 获取编辑弹窗详情
         */
        async handleEditDesc(gid) {
            let res = await EditGoodBase({
                gid,
                edit_type: '获取详情',
            });
            if (res.errcode != 0) return;
            this.EditDesc = res.data;
            this.formEdit.state = res.data.state;
            this.formEdit.adcunit = res.data.adcunit;
            this.formEdit.purchas = res.data.purchas;
        },
        /**
         * 获取预设值
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({ type: '卖品管理' });
            if (res.errcode != 0) return;
            this.PresetValue = res.data;
        },
        /**
         * 分页
         */
        async handleGetGoodBaseList(page = 1) {
            let res = await GetGoodBaseList({
                page,
                all: false,
            });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width: 150,
            });
            this.CupOrderList = res.data;
        },
        /**
         * 表格导出
         */
        async table_dowload() {
            let res = await GetGoodBaseList({
                page: 1,
                all: true,
            });
            if (res.errcode != 0) return;

            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '库存管理',
                headers,
                data,
            });
        },
    },
};
</script>
<style>
.ivu-form-item {
    margin-bottom: 6px;
}
</style>
