<template>
	<div>
		
		<!-- 操作区域 -->
		<div style="text-align: right;">
			<Select v-model="select_type"  style="width: 200px;">
				<Option :key='index' v-for="(item,index) in type" :value='item.title'>{{item.title}}</Option>
			</Select>
			<Button @click="()=>to_GetConfig()">搜索</Button>
			<Button style="margin-left: 10px;" type='primary'>添加</Button>
		</div>
		<!-- 显示表格 -->
		<div style="margin-top: 10px;">
			<Table :columns='title' :data='list'>
				
				<template slot-scope="{row}" slot="operation">
					<div>
						<Button @click="()=>(select_cuid=row.uid)&&(is_edit_modal_show=true)" type="primary">编辑</Button>
						<Button @click="()=>is_delete=true" style="margin-left: 10px;">删除</Button>
					</div>
				</template>
				
			</Table>
		</div>
		
		<!-- 弹窗区域 -->
		<div>
			
			<!-- 编辑 -->
			<Modal width='800' :mask-closable='false' v-model="is_edit_modal_show">
				<div>
					<div>
						<span style="display:inline-block;width: 100px;">type</span>
						<Select v-model="select_type"  style="width: 200px;">
							<Option :key='index' v-for="(item,index) in type" :value='item.title'>{{item.title}}</Option>
						</Select>
					</div>
					<div style="margin-top: 10px;">
						<span style="display:inline-block;width: 100px;">key</span>
						<Input v-model="key_word" style="width: 200px;"> </Input>
					</div>
					<div style="margin-top: 10px;">
						<span style="display:inline-block;width: 100px;">添加字段</span>
						  <RadioGroup v-model="disabledGroup">
							<Radio label="数组" ></Radio>
							<Radio label="对象"></Radio>
						 </RadioGroup>
					</div>
					<div style="margin-top: 10px;display: flex;">
						<span style="display:inline-block;width: 100px;">添加</span>
					    <div v-if="disabledGroup=='对象'" style="display: flex;" >
							<Input v-model="object_ci_key" placeholder="请输入key" style="width:'100px'"></Input>
							<Input v-model="object_ci_value" placeholder="请输入value" style="width: '100px';margin-left: 20px;"></Input>
							<Button @click="()=>add()" style="margin-left: 10px;">添加</Button>
							<DatePicker  @on-change="($)=>object_ci_value=$" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择时间" style="width: 300px"></DatePicker>
						</div>
						<div v-if="disabledGroup=='数组'" style="display: flex;">
							<Input v-model="array_ci_value" ></Input>
							<Button @click="()=>add()" style="margin-left: 10px;">添加</Button>
							<DatePicker  @on-change="($)=>array_ci_value=$" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择时间" style="width: 300px"></DatePicker>
							
						</div>
					</div>
					<div style="margin-top: 10px;display: flex;">
						<span style="display:inline-block;width: 100px;">显示添加</span>
						<!-- 显示添加的值 对象-->
						<div v-if="disabledGroup=='对象'" >
							<pre>
{{object_ci}}
							</pre>
						</div>
						<!-- 显示添加的值 数组-->
						<div v-if="disabledGroup=='数组'" >
							<div v-for="item in array_ci">{{item}}</div>
						</div>
					</div>
				</div>
				<div slot="footer">
					<Button @click="()=>is_edit_modal_show=false">返回</Button>
					<Button @click="()=>to_SetConfig()" type='primary'>确认</Button>
				</div>
			</Modal>
					
			<!-- 删除 -->
			<Modal :mask-closable='false' v-model="is_delete">
				
				<div>确认是否删除</div>
				<div slot="footer">
					<Button @click="()=>is_delete=false">返回</Button>
					<Button @click="()=>to_delete()" type='primary'>确认</Button>
				</div>
			</Modal>
		
		</div>
		
	</div>
</template>

<script>
	import { GetConfig,SetConfig } from '../../request/api.js'
	export default{
		name:"navbar",
		data() {
			return {
				select_cuid:'',//当前操作的表格行
				key_word:'',//key
				array_ci_value:'',//绑定数组
				object_ci_key:'',//绑定对象key保存位置
				object_ci_value:'',//绑定对象value保存位置
				array_ci:[],//数组保存位置
				object_ci:{},//对象 key value保存位置
				disabledGroup:'数组',//
				is_edit_modal_show:false,//编辑弹窗是否显示
				is_delete:false,
				select_type:'活动时间',
				type:[
					{title:'活动时间'}
				],
				list:[],
				title:[],
			}
		},
		watch: {
			is_edit_modal_show(val) {
				if(!val){
					this.array_ci=[];
					this.array_ci_value = '';
					this.object_ci_key = '';
					this.object_ci_value = '';
					this.object_ci = {};
				}
			}
		},
		mounted(){
			this.to_GetConfig()
		},
		methods: {
			
			// 添加数组 或者 对象
			add(value,key){
				if(this.disabledGroup=='数组'){
				
					this.array_ci.push(this.array_ci_value)
				
				}
				if(this.disabledGroup=='对象'){
					let center = JSON.parse(JSON.stringify(this.object_ci));
					center[this.object_ci_key] = this.object_ci_value
					this.object_ci = center
				}
			},
			
			// 编辑提交
			async to_SetConfig(){
				
				let pass_data = {
				"branduid": JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
				"cuid": this.select_cuid, 
				"type": this.select_type, 
				"key": this.key_word, 

				}
				if(this.disabledGroup=="数组"){
					pass_data["ci"] = this.array_ci
				}
				if(this.disabledGroup=="对象"){
					pass_data["ci"] = this.object_ci
				}
				
				let res_data = await SetConfig(pass_data)
				if(res_data.code==200){
					this.to_GetConfig()
					this.$mytoast(res_data.msg)
					this.is_edit_modal_show = false 
				}
				
			},
			
			// 删除按钮
			to_delete(){
				console.log('del')
				this.is_delete = false
			},
			
			async to_GetConfig() {
				
				let pass_data = {
					"branduid":JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
					"type":this.select_type
				}
		
				let {data:res_data} = await GetConfig(pass_data)
				res_data.title.push({
					"title":'操作',
					"key":'operation',
					"slot":'operation'
				})
				this.title = res_data.title;
				this.list = res_data.list
			}
			
		},
		
	}
</script>

<style>
</style>
