<template>
    <div class=" p-10">
        <h1>历史订单</h1>
        <div class=" flex">
            <div class=" flex">
                <div>持卡人姓名:</div>
                <div>{{order.name}}</div>
            </div>
            <div class=" flex ml-10">
                <div>等级:</div>
                <div>{{order.levelName}}</div>
            </div>
        </div>
        <div class=" flex">
            <div>卡号:</div>
            <div>{{order.cardNum}}</div>
        </div>
        <div class=" flex">
            <div>物理卡号:</div>
            <div>{{order.phycardNum}}</div>
        </div>
        <div class=" flex">
            <div>绑定号码:</div>
            <div>{{order.phoneNum}}</div>
        </div>
        
        <div class=" flex justify-between">
            <div>
                <div>同步时间:</div>
                <div>{{order.synTime}}</div>
            </div>
            <Button @click="()=>tableDataExport()" size="small" type="primary">导出数据</Button>
        </div>
        <Table size="small" :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{row,index}" slot="operate">
                <Button @click="()=>showMore(row)" type="primary" size="small">订单详情</Button>
            </template>
        </Table>
        <Card class=" text-right">
            <Page @on-change="(value)=>changePage(value)" :total="tableData.total" :pageSize="tableData.limit"></Page>
        </Card>
        <Modal
            width=800
            :maskClosable="false"
            v-model="show"
            :title="'订单号:'+tableRowData.orderFormNum"
            >
            <Table size="small" :columns="modalTableData.title" :data="modalTableData.list"></Table>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>
<script>
import { MemCardInfo,GetMemberPayList,GetMemberPayInfoV } from "@/request/api"
import { download } from '@/utils/table_download.js'
export default{
    name:"historyOrder",
    data(){
        return{
            show:false,
            order:"",
            tableData:"",
            tableRowData:"",
            modalTableData:"",
        }
    },
    mounted(){
        let {CardID} = this.$route.query
        if(CardID){
            this.fetchData(CardID)
        }
    },
    methods:{
        /**
         * 导出表格数据
         */
        async tableDataExport(){
            const {ID,cardNum,phoneNum} = this.order
            let passdata = {
                ID:ID,
                CARD_NUM:cardNum,
                MOBILE_NUM:phoneNum,
                all:true
            }
            let res = await GetMemberPayList(passdata)
            if(res.errcode!=0) return
            let headers = res.data.title
            let data  = res.data.list
            download({
                'worksheet':'会员卡查询',
                headers,
                data
            })
        },
        /**
         * 订单详情按钮弹框
         */
        async showMore(value){
            this.tableRowData = value
            const { cardID,flowNum } = value
            let passdata = {
                ID:cardID,
                flowNum
            }
            let res = await GetMemberPayInfoV(passdata)
            if(res.errcode!=0) return
            this.modalTableData = res.data
            this.show = true
        },
        /**
         * 翻页
         */
        changePage(value){
            this.getTableData(this.order,value)
        },
        async fetchData(CardID){
            let passdata = {
                CardID,
                type:"获取"
            }
            let res = await MemCardInfo(passdata)
            if(res.errcode!=0) return
            this.order = res.data
            this.getTableData(res.data)
        },
        /**
         * 表格数据获取
         * @param {*} value 
         * @param {*} page 
         */
        async getTableData(value,page=1){
            const {ID,cardNum,phoneNum} = value
            let passdata = {
                ID:ID,
                CARD_NUM:cardNum,
                MOBILE_NUM:phoneNum,
                page,
                all:false
            }
            let res = await GetMemberPayList(passdata)
            if(res.errcode!=0) return
            res.data.title.push({
                title:"操作",
                key:"operate",
                slot:"operate"
            })
            this.tableData = res.data
        }
    }
}
</script>