<template>
    <div>
        <Form :model="inlineForm" ref="inlineForm" :rules="ruleInlineForm" class="flex justify-end" label-width="100" inline>
            <FormItem prop="" label="相关订单号">
                <Input style="width: 200px" v-model="inlineForm.orderid" />
            </FormItem>
            <FormItem prop="" label="反馈状态">
                <Select v-model="inlineForm.state" style="width: 200px">
                    <Option value="全部">全部</Option>
                    <Option value="跟进中">跟进中</Option>
                    <Option value="已完成">已完成</Option>
                    <Option value="已回复">已回复</Option>
                </Select>
            </FormItem>
            <FormItem label-width="0">
                <Button @click="() => fetchData()">查询</Button>
            </FormItem>
        </Form>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="oprate">
                <Button @click="() => edit(row)" size="small" type="primary">操作</Button>
            </template>
            <template slot-scope="{ row, index }" slot="state">
                <div v-if="row.state == '已回复'" class="text-green-500">{{ row.state }}</div>
                <div v-if="row.state == '跟进中'" class="text-red-500">{{ row.state }}</div>
                <div v-if="row.state == '已完成'">{{ row.state }}</div>
            </template>
        </Table>
        <Card class="text-right">
            <Page @on-change="(value) => fetchData(value)" :total="tableData.total" :pageSize="tableData.size"></Page>
        </Card>
        <Modal title="回复反馈" v-model="show" :maskClosable="false" :closable="false">
            <div class="flex">
                <div class="w-24">反馈单号</div>
                <div>{{ tableRowData.oN }}</div>
            </div>
            <div class="flex">
                <div class="w-24">影院</div>
                <div>{{ tableRowData.cname }}</div>
            </div>
            <div class="flex">
                <div class="w-24">状态</div>
                <div v-if="tableRowData.state == '已回复'" class="text-green-500">{{ tableRowData.state }}</div>
                <div v-if="tableRowData.state == '跟进中'" class="text-red-500">{{ tableRowData.state }}</div>
                <div v-if="tableRowData.state == '已完成'">{{ tableRowData.state }}</div>
            </div>
            <div class="flex">
                <div class="w-24">联系电话</div>
                <div>{{ tableRowData.phone }}</div>
            </div>
            <div class="flex">
                <div class="w-24 mr-1">相关订单号</div>
                <div class="flex mr-1">
                    <div class="mr-1">{{ tableRowData.orderid || '无' }}</div>
                    <div v-if="!tableRowData.isNew" class="text-red-500">(旧)</div>
                </div>
                <Button size="small" v-if="tableRowData.orderid" @click="View(tableRowData)">详情</Button>
            </div>
            <div v-if="tableRowData" class="h-48 mt-5 overflow-y-scroll">
                <Timeline>
                    <TimelineItem v-for="(item, index) in tableRowData.contentList" :key="index">
                        <div v-if="item.type == '反馈'" class="text-red-500">{{ item.type }}</div>
                        <div v-if="item.type == '回复'" class="text-green-500">{{ item.type }}</div>
                        <div>{{ item.content }}</div>
                        <div class="text-xs text-gray-300">{{ item.date }}</div>
                    </TimelineItem>
                </Timeline>
            </div>
            <Form ref="form" :rules="rule" :model="form">
                <FormItem prop="content">
                    <Input placeholder="请输入回复内容" v-model="form.content" :disabled="tableRowData.state == '已完成'" type="textarea" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Poptip confirm title="确认是否完成？已完成反馈单无法进行回复" @on-ok="() => overOrReply('确认完成')">
                    <Button type="error" v-if="tableRowData.state == '已回复'">已完成</Button>
                </Poptip>

                <Button class="ml-2" v-if="tableRowData.state != '已完成'" @click="() => overOrReply('回复')" type="primary">回复</Button>
                <Button @click="() => hideMe()">取消</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetFeedbackListDataCms, ReplyToFeedbackCms } from '@/request/api';
import { ifError } from 'assert';
export default {
    name: 'suggestion',
    data() {
        return {
            show: false,
            tableData: '',
            inlineForm: {
                state: '跟进中',
                orderid: '',
            },
            ruleInlineForm: {},
            tableRowData: '',
            form: {
                content: '',
            },
            rule: {
                content: [
                    { required: true, message: '必须输入内容' },
                    { min: 10, max: 200, message: '长度必须大于10小于200' },
                ],
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * 表格内的查看
         */
        View(value) {
            const { isNew, OID: _id, orderid } = value;
            if (isNew) {
                this.routeTo('orderDetail?id=' + _id);
                return;
            }
            this.routeTo('orderFundDetail?id=' + orderid);
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        /**
         * 隐藏弹窗
         */
        hideMe() {
            this.$refs['form'].resetFields();
            this.show = false;
        },
        /**
         * 已完成/回复 按钮
         */
        overOrReply(type) {
            if (type == '回复') {
                this.rule = {
                    content: [
                        { required: true, message: '必须输入内容' },
                        { min: 10, max: 200, message: '长度必须大于10小于200' },
                    ],
                };
            }
            if (type == '确认完成') {
                delete this.form.content;
            }
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    const { _id } = this.tableRowData;
                    let { content } = this.form;
                    let passdata = {
                        id: _id,
                        type,
                        content,
                    };
                    let res = await ReplyToFeedbackCms(passdata);
                    if (res.errcode != 0) return;
                    this.edit({ id: res.data._id });
                    this.fetchData(this.tableData.page);
                }
            });
        },
        /**
         * 操作按钮 点击
         */
        async edit(value) {
            this.rule = {};
            const { id } = value;
            let res = await ReplyToFeedbackCms({ id, type: '查询' });
            if (res.errcode != 0) return;
            this.tableRowData = res.data;
            this.show = true;
        },
        /**
         * 表格数据获取 按钮
         */
        async fetchData(page = 1) {
            const { state, orderid } = this.inlineForm;
            let passdata = {
                page,
                all: false,
                state,
                orderid,
            };
            let res = await GetFeedbackListDataCms(passdata);
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operate',
                slot: 'oprate',
            });
            res.data.title.forEach((item, index) => {
                if (item.key == 'state') {
                    item['slot'] = 'state';
                }
            });
            this.tableData = res.data;
        },
    },
};
</script>
