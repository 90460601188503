var MD5 = require('md5.js')
import axios from 'axios';
// if(process.env.NODE_ENV!="development"){
// 	setInterval(async() => {
// 	    let htmlValue = await getAppHtml()
// 	    let md5Value = bornMd5(htmlValue)
// 	    console.log(htmlValue?true:false,'==',md5Value)
// 	    if(md5Value){
// 	        //执行判断本地是否存在该md5记录
// 	        let localMd5value = localStorage.getItem("htmlMd5")
// 	        if(localMd5value){
// 	            //判断远程和本地的md5是否相等
// 	            if(md5Value!=localMd5value){
// 	                //需要弹窗 跳转 登录页面
// 	                alert("当前后台管理有更新，请重新登录")
// 	                window.location.replace('/')
// 	            }
// 	        }else{
// 	            //初始化md5html
// 	            saveLocalStorage("htmlMd5",md5Value)
// 	        }
// 	    }
// 	}, 10*1000);
// }
/**
 * 获取应用的html
 */
async function getAppHtml() {
    let instance = axios.create()
    let res = await instance.get("/")
    return res
}
/**
 * 生成一个md5值
 */
function bornMd5(value){
    try {
        let md5 = new MD5()
        let result = md5.update(value.data).digest('hex')
        return result
    } catch (error) {
        return ""
    }
}
/**
 * 保存在本地缓存
 */
function saveLocalStorage(key,value){
    localStorage.setItem(key,value)
}