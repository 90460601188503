<template>
	<div>
		
		<Tabs @on-click="TabPane_onclick" style="background-color: #fff;padding: 10px;border-radius: 5px;" type="card">
			<TabPane label="类型">
				<div style="text-align: right;margin: 20px auto;">
					<Button @click="show_type_add_btn" type="error">添加</Button>
				</div>

				<Table draggable  ref="table" stripe style="margin-top: 50px;"
					:columns="columns_type" :data="type_data">
					<!-- 前台是否显示 -->
					<template slot-scope="{ row, index }" slot="is_font_show">
						<div>
							<i-Switch v-model="row.displayTags" @on-change="type_change(row)" />
						</div>
					</template>
					<!-- 操作 -->
					<template slot-scope="{ row, index }" slot="operation">
						<div style="display: flex;">
							<div style="margin-right: 5px;">
								<Button size="small" @click="doDelgoosType(row)" type="error">删除</Button>
							</div>
							<div>
								<Button @click="on_cell_click(row)" size="small" type="primary">修改排序</Button>
							</div>
						</div>
					</template>
				</Table>
			</TabPane>
			<TabPane label="商品详情">
				<div style="text-align: right;margin: 20px auto;">
					<Button @click="show_goods_detail_add_btn" type="error">添加</Button>
				</div>

				<Table @on-cell-click='on_cell_click_goods' :columns="goosList_data.titlelist" :data="goosList_data.listdata">
					<!-- 表头操作 -->
					<template slot-scope="{ row, index }" slot="operation">
						<div style="display: flex;">
							<Button @click="edit_goods(row)" type="primary" size="small"
								style="margin-right: 5px">编辑</Button>
								
								<Button @click="()=>is_show_kuxiiugai=true" type="primary" size="small"
									style="margin-right: 5px">库存修改</Button>
						</div>
					</template>

					<!-- 商品标签 -->
					<template slot-scope="{ row, index }" slot="tag">
						<div>
							<Tag v-for="(item, index) in row.tag" color="primary">{{
                item
              }}</Tag>
						</div>
					</template>

					<!-- 是否上架 -->
					<template slot-scope="{ row, index }" slot="onState">
						<div>
							<i-Switch v-model="row.onState" @on-change="is_sale_change(row)"  />
						</div>
					</template>
				</Table>

				<div style="margin: 10px ; text-align: right;">
					<Page @on-change="(index) => this.doGetgoosList('', index)" :page-size="goosList_data.size"
						:total="goosList_data.tall" />
				</div>
			</TabPane>
		</Tabs>

		<!-- 类型标签页抽屉 -->
		<Drawer class="show_type_add_class" :title="`类型${drow_type}`" :closable="false" v-model="is_show_type_add">
			<div>
				<!-- //类型名称  name-->
				<div style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">类型名称</div>
						<Input v-model="pass_data_type_obj.name" placeholder="" />
					</div>
				</div>
				<!-- //排序编号 sortnum-->
				<div style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">排序编号</div>
						<Input type="number" v-model="pass_data_type_obj.sortnum" placeholder="" />
					</div>
				</div>
				<!-- //图标路径 iconurl-->
				<div style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">图标路径</div>
						<Input v-model="pass_data_type_obj.iconurl" placeholder="" />
					</div>
				</div>
				
				<Button @click="add_type" type="error">添加</Button>
		
			</div>

		
		</Drawer>

		<!-- 商品详情标签页抽屉 -->
		<Drawer  class="show_type_add_class" :title="`商品${drow_type}`" :closable="true" :mask-closable='false'
			v-model="is_show_type_goods_detail">
			<!-- 商品操作区 -->
			<div>
				<!-- //名称  name-->
				<div    style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品名称</div>
						
						<Input :disabled="modify.length!==0&&modify.indexOf('name')==-1" v-model="pass_data_goods_obj.name" placeholder="" />
					</div>
				</div>

				<!-- //商品属性  attribute-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品属性</div>


						<Select :disabled="modify.length!==0&&modify.indexOf('attribute')==-1"  v-model="pass_data_goods_obj.attribute" style="width:260px">
							<Option  v-for="item in goosOneTow_data" :value="item._id" :key="item.value">
								{{ item.name }}
							</Option>
						</Select>

					</div>
				</div>

				<!-- //商品类型  attribute-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品类型</div>
						<Select :disabled="modify.length!==0&&modify.indexOf('type')==-1" v-model="pass_data_goods_obj.type" style="width:260px">
							<Option v-for="item in type_data" :value="item._id" :key="item.value">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>

				<!-- //商品属性  tag-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品标签</div>
						<Select :disabled="modify.length!==0&&modify.indexOf('tag')==-1" v-model="pass_data_goods_obj.tag" multiple style="width:260px">
							<Option v-for="item in goosOneTow_data_tag" :value="item._id" :key="item.value">
								{{ item.name }}
							</Option>
						</Select>
					</div>
				</div>

				<!-- //商品排序  sortnum-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品排序</div>
						<Input :disabled="modify.length!==0&&modify.indexOf('sortnum')==-1" type="number" v-model="pass_data_goods_obj.sortnum" placeholder="" />
					</div>
				</div>

				<!-- //商品描述  describe-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品描述</div>
						<Input :disabled="modify.length!==0&&modify.indexOf('describe')==-1" v-model="pass_data_goods_obj.describe" placeholder="" />
					</div>
				</div>

				<!-- //商品详情  details-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品详情</div>
						<Input :disabled="modify.length!==0&&modify.indexOf('details')==-1" v-model="pass_data_goods_obj.details" placeholder="" />
					</div>
				</div>

				<!-- //商品原价  price-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品原价</div>
						<Input :disabled="modify.length!==0&&modify.indexOf('price')==-1" type="number" v-model="pass_data_goods_obj.price" placeholder="" />
					</div>
				</div>

			
				<!-- //商品会员价  memberPrice-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">商品会员价</div>
						<Input :disabled="modify.length!==0&&modify.indexOf('memberPrice')==-1" type="number" v-model="pass_data_goods_obj.memberPrice" placeholder="" />
					</div>
				</div>

				<!-- //选择开始时间  timeSale-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">开始时间</div>
						<DatePicker :disabled="modify.length!==0&&modify.indexOf('timeSale')==-1" :value='pass_data_goods_obj.timeSale' @on-change="DatePicker_change_bef"
							type="datetime" placeholder="选择日期和时间" style="width: 200px"></DatePicker>
					</div>
				</div>

				<!-- //选择结束时间  timeEnd-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">结束时间</div>
						<DatePicker :disabled="modify.length!==0&&modify.indexOf('timeEnd')==-1" :value='pass_data_goods_obj.timeEnd' @on-change="DatePicker_change_aft"
							type="datetime" placeholder="选择日期和时间" style="width: 200px"></DatePicker>
					</div>
				</div>

				<!-- //商品图片  imgurl-->
				<div  style="margin: 30px 0;">
					<div style="width:30vw;display: flex;align-items: center;">
						<div style="width: 100px;">选择图片</div>
						<div style="display: flex;">
							<div v-if="imgurl || pass_data_goods_obj.imgurl"
								style="width: 100px;height: 100px;margin-right: 50px;">

								<img style="width: 100%;height: 100%;"
									:src="pass_data_goods_obj.imgurl?pass_data_goods_obj.imgurl:imgurl" />
							</div>
							
							<Button :disabled="modify.length!==0&&modify.indexOf('iconurl')==-1" @click="modal_pic_show = true" type="primary">添加 / 选择图片</Button>
						</div>
					</div>
				</div>
				
				
			</div>

			<div style="text-align: right;margin: 20px auto;">
				<Button @click="goods_add" type="error">{{ drow_type }}商品</Button>
			</div>
		
		</Drawer>

		<!-- 库存修改 -->
		<Modal  width="500" v-model="is_show_kuxiiugai" title="修改库存">
			<div>
				<div style="display: flex;">
					<div @click="kucun_select='加'" :class="kucun_select=='加'?'blue':''" style="padding: 5px 10px;border: 1px solid #ccc;border-radius: 5px;margin-right: 5px;">增加</div>
					<div @click="kucun_select='减'" :class="kucun_select=='减'?'blue':''" style="padding: 5px 10px;border: 1px solid #ccc;border-radius: 5px;">减少</div>
					
				</div>
				<div style="margin-top: 10px;width: 300px;">
					<Input type="number" v-model="kucun_add_del" size="large" placeholder="请选择增加/减少后输入数字" />
				</div>
			</div>
			 <div slot="footer">
				 <Button   @click="do_count(1)"  >取消</Button>
				<Button type="primary" @click="do_count(2)" >确定</Button>
			</div>
		</Modal>

		<!--【组件】 图片上传/选择组件  复用此组件只需要添加一个变量  和一个 点击事件  需要注意缩略图 和 ajax传参-->
		<Modal width="800" v-model="modal_pic_show" title="选择 / 上传图片">
			<UploadImag @do_pick_pic="do_pick_pic" />
			<div slot="footer"></div>
		</Modal>
	
	</div>
</template>

<script>
	
	function test(a){
		this.a = a,
		this.c = function(){
			console.log(this.is_show_kuxiiugai)
		},
		// 空对象副本保存
		this.deal_mounted = (vue)=>{
			vue.pass_data_goods_obj_copy = JSON.parse(JSON.stringify(vue.pass_data_goods_obj)) 
		},
		// 时间格式化工具
		this.formatDate =function(source, format) {
							source = new Date(parseInt(source));
							const o = {
								//     'Y+': source.getFullYear(),
								'M+': source.getMonth() + 1, // 月份
								'd+': source.getDate(), // 日
								'H+': source.getHours(), // 小时
								'm+': source.getMinutes(), // 分
								's+': source.getSeconds(), // 秒
								'q+': Math.floor((source.getMonth() + 3) / 3), // 季度
								'f+': source.getMilliseconds() // 毫秒
							}
							if (/(y+)/.test(format)) {
								format = format.replace(RegExp.$1, (source.getFullYear() + '').substr(4 - RegExp.$1
									.length))
							}
							for (let k in o) {
								if (new RegExp('(' + k + ')').test(format)) {
									format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k])
										.substr(('' +
											o[k]).length)))
								}
							}
							return format
						}
						
	}
	
	let test007;
	import UploadImag from "../../../components/UploadImag.vue";
	import {
		Schedul,
		SetgoosType,
		GetgoosType,
		SetgoosTypeVisual,
		DelgoosType,
		upimgfile,
		getimglist,
		GetgoosOneTow,
		AddgoosData,
		GetgoosList,
		GetgoosXData,
		SetgoosXData,
		UpgoosStock,
		UpgoosOnState
	} from "../../../request/api.js";
	import {
		getTimeFromZero
	} from "../../../utils/index.js";
	export default {
		name: "navbar",
		components: {
			UploadImag,
		},
		data() {
			
			return {
				kucun_select:'',
				kucun_add_del:"",//跟库存有关
				is_show_kuxiiugai:false,//库存修改
				current_id:'',//当前选中的id
				modify:[],//许可编辑修改的表单
				goosList_data: {}, //
				imgurl: "", //用户展示图片
				goosOneTow_data_tag: [], //商品属性列表
				attribute_index: "", //商品属性索引下表
				goosOneTow_data: [], //获取商品属性组或标签组  商品属性数组
				pass_data_goods_obj: {
					name: "", //
					attribute: "", //商品属性 通过GetgoosOneTow接口 type=1获取
					type: "", //商品类型
					tag: [], //商品标签组允许多个商品属性 通过GetgoosOneTow接口 type=2获取
					iconurl: "", //商品图片
					sortnum: "", //商品排序越大越靠前
					mcode: localStorage.getItem('mcode'), //影院编码
					describe: "", //商品描述
					details: "", //商品详情
					price: "", //商品原价
					memberPrice: "", //商品会员价
					timeSale: "", //开始售卖时间
					timeEnd: "", //结束售卖时间
				}, //商品添加对象
				pass_data_goods_obj_copy:{},
				type_index: "", //商品类型索引下表
				modal_pic_show: false,
				drow_type: "",//抽屉动态显示文本  例子  点击添加按钮  xx添加；点击修改按钮 xx修改
				is_show_type_add: false,
				is_show_type_goods_detail: false,
				pass_data_type_obj: {
					id: "", //商品类型id，传此参数此接口为修改类型
					name: "", //类型名称
					iconurl: "", //图标路径
					mcode: localStorage.getItem('mcode'), //影院编码
					sortnum: "", //排序编号
				},
				columns_type: [{
						title: "类型id",
						key: "_id",
					},

					{
						title: "影院编码",
						key: "mcode",
					},
					{
						title: "类型名称",
						key: "name",
					},
					{
						title: "前台是否显示",
						key: "displayTags",
						slot: "is_font_show",
					},
					{
						title: "图标连接",
						key: "iconurl",
					},
					{
						title: "排序",
						key: "sortnum",
					},
					{
						title: "类型编号",
						key: "type",
					},

					{
						title: "操作",
						key: "operation",
						slot: "operation",
					},
				],
				type_data: [],
			};
			
		},
		watch: {
			
			// 商品属性下拉菜单被选择时触发
			attribute_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.goosOneTow_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.attribute = _id;
			},

			// 用户点击添加按钮触发获取 【商品属性】 列表
			async is_show_type_goods_detail(is_show_drw) {
				if (is_show_drw) {
					let pass_data = {
						type: 1,
					};
					// 发动ajax 获取商品属性组或标签组
					let {data:res_data} = await GetgoosOneTow(pass_data);
					this.goosOneTow_data = res_data;
					console.log("添加属性列表成功");

					// 获取商品标签组允许多个商品属性
					let pass_data1 = {
						type: 3,
					};
					// 发动ajax 获取商品属性组或标签组
					let {data:res_data1} = await GetgoosOneTow(pass_data1);
					console.log(res_data1);
					this.goosOneTow_data_tag = res_data1;
				}
			},

			// 商品属性下拉菜单那被选择时触发
			// 商品类型下拉菜单被选择时触发
			type_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.type_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.type = _id;
			},
			
		},
		mounted() {
			
			test.prototype = Object.create(this)
			test007 = new test()
			// 空对象副本保存
			this.pass_data_goods_obj_copy = JSON.parse(JSON.stringify(this.pass_data_goods_obj)) 
			// 获取商品类型列表
			this.doGetgoosType();
			
		},
		methods: {
			
			// 是否上架
			async is_sale_change(row){
				
				let pass_data = {
					'id': row.id, //商品类型id
					'mcode':localStorage.getItem('mcode'),
					'onState': row.onState ? "1" : "-1", //1表示上架    -1表示下架
				};
				// // 设置商品类型可视状态  发送ajax
				let {data:res_data} = await UpgoosOnState(pass_data);
				
				if (res_data.id) {
					this.$Message.success("更新成功");
				} else {
					this.$Message.error("更新失败");
				}
				
			},
			
			on_cell_click_goods(payload){
				this.current_id = payload.id
			},
			
			// 修改库存
			async do_count(type){
				
				if(type==1){
					// 取消按钮
					this.is_show_kuxiiugai=false
					return
				}
				if(!this.kucun_select || !this.kucun_add_del){
					this.$Message.error('请选择后正确输入')
				
				return	
				}
				let num;
				if(this.kucun_select=='加'){
					num ='+'+this.kucun_add_del
				}
				if(this.kucun_select=='减'){
					num ='-'+this.kucun_add_del
				}
				// 凑全数据
				let pass_data = {
					"id": this.current_id, //修改商品id
					"mcode": localStorage.getItem('mcode'), //所属门店
					"num": num //操作数量 可正  可负 但是修改后如果库存量低于0将会修改失败
					}
		
				// 执行发送动作
				
				let res_data = await UpgoosStock(pass_data)
				//获取商品列表
				this.doGetgoosList();
				this.is_show_kuxiiugai=false
				
			},
			
			// 商品修改编辑
			async edit_goods(item) {
				
				this.drow_type='编辑修改'
				let pass_data = {
					id: item.id,
					mcode: localStorage.getItem('mcode'),
				};
				// 后台获取商品信息
				let {data:res_data} = await GetgoosXData(pass_data);
				
				// 服务器返回的是时间戳 需要处理成date类型
				 res_data.goosdata.timeEnd=test007.formatDate(res_data.goosdata.timeEnd, "yyyy-MM-dd HH:mm:ss");
					res_data.goosdata.timeSale=test007.formatDate(res_data.goosdata.timeSale, "yyyy-MM-dd HH:mm:ss");
				
				this.pass_data_goods_obj = res_data.goosdata


				// 重新赋值给抽屉  抽屉显示
				this.is_show_type_goods_detail = true
				
				//保存当前点击编辑修改的id
				this.current_id = item.id
				// 许可编辑修改的表单项
				this.modify = res_data.modify

			},
			

			// 选项卡类型 商品详情点击事件
			TabPane_onclick(tab_data_index) {
			
				if (tab_data_index == 0) {
					this.goosList_data = "";
					// 类型
					// 获取商品类型列表
					this.doGetgoosType();
				}
				if (tab_data_index == 1) {
					//获取商品列表
					this.doGetgoosList();
				}
				
			},

			//获取 商品详情标签页 列表
			async doGetgoosList(page = 1) {
				
				let pass_data = {
					mcode: localStorage.getItem('mcode'),
					page: page,
				};
				
				let {data:res_data} = await GetgoosList(pass_data);

				res_data.titlelist.forEach((item, index) => {
					if (item.title == "商品标签") {
						item.slot = "tag";
						item.width = "200";
					}
					if (item.title == "上架") {
						item.slot = "onState";
					}
				});
				// 添加操作列
				res_data.titlelist.push({
					title: "操作",
					slot: "operation",
					'width':'150'
				});
				this.goosList_data = res_data;
				
			},

			// 添加商品 发送ajax----------
			async goods_add() {
			
				if(this.drow_type=="编辑修改"){
					// 执行修改操作
					
					let pass_data = {
						'id':this.current_id,
						'mcode':localStorage.getItem('mcode')
					}
					let end_pass_data={};
					// 整理传给服务器的字段数据
					this.modify.forEach(item=>{
						end_pass_data[item] = this.pass_data_goods_obj[item]
					})
					if(this.modify.indexOf('timeSale')!=-1){
						end_pass_data.timeSale = new Date(end_pass_data.timeSale)
							.getTime()
							.toString();
					}
					if(this.modify.indexOf('timeEnd')!=-1){
						end_pass_data.timeEnd = new Date(end_pass_data.timeEnd)
							.getTime()
							.toString();
					}
				
					// 执行修改接口
					let {data:res_data1} = await SetgoosXData(pass_data,end_pass_data)
					
					if(res_data1.id){
						// 修改成功抽屉消失
						this.is_show_type_goods_detail = false;
						// 刷新列表
						this.doGetgoosList();
					}else{
			
						this.$Message.error(res_data1.msg)
					}
					return
				}
				let pass_data = this.pass_data_goods_obj;
				pass_data.timeSale = new Date(pass_data.timeSale)
					.getTime()
					.toString();
					pass_data.timeEnd = new Date(pass_data.timeEnd)
						.getTime()
						.toString();
			
				let res_data = await AddgoosData(pass_data);
				
				if (res_data) {
					// 添加商品成功 抽屉消失
					this.is_show_type_goods_detail = false;
					// 刷新列表
					this.doGetgoosList();
				}
				
			},

			//end----------
			// 售卖开始时间和结束时间事件
			DatePicker_change_aft(paload_data) {
				this.pass_data_goods_obj.timeEnd = paload_data;
			},
			
			DatePicker_change_bef(paload_data) {
				this.pass_data_goods_obj.timeSale = paload_data;
			},

			// 商品详情标签页里的 抽屉- 弹窗子组件传到父组件的时间  点击选择图片
			do_pick_pic(item) {
				
				// 传参赋值初始化
				this.pass_data_goods_obj.iconurl = item.fileid;
				// 选中图片后的缩略图
				this.pass_data_goods_obj.imgurl = item.url;
				// 让弹窗消失
				this.modal_pic_show = false;
				
			},

			// 展示商品详情抽屉
			show_goods_detail_add_btn() {
				
				// 开放所有类型表单添加
				this.modify = [];
				this.pass_data_goods_obj = JSON.parse(JSON.stringify(this.pass_data_goods_obj_copy))
				this.is_show_type_goods_detail = true;
				this.drow_type = "添加";
				
			},
			
			// 添加按钮
			show_type_add_btn() {
				
				// 添加按钮点击 数据全部置空
				this.pass_data_type_obj.name = "";
				this.pass_data_type_obj.sortnum = "";
				this.pass_data_type_obj.iconurl = "";
				this.is_show_type_add = true;
				this.drow_type = "添加";
				
			},
		
			// 单元格点击里面的按钮按数据
			on_cell_click(row_data) {
		
				// 分支从修改排序按钮出来的
				this.drow_type = "修改";
				this.is_show_type_add = true;
				this.pass_data_type_obj = row_data;
				
			},
			
			// 删除商品类型
			async doDelgoosType(row_data) {
				
				let pass_data = {
					id: row_data._id,
				};
				
				let res_data = await DelgoosType(pass_data);
				
				// 页面回显实时数据
				this.doGetgoosType();
				
			},

			async type_change(row_data) {
				
				let pass_data = {
					id: row_data._id, //商品类型id
					show: row_data.displayTags ? "1" : "-1", //-1不显示  1显示
				};

				// 设置商品类型可视状态  发送ajax
				let res_data = await SetgoosTypeVisual(pass_data);

				if (res_data.data.id) {
					this.$Message.success("更新成功");
				} else {
					this.$Message.error("更新失败");
				}
				
			},
			
			// 首次刷新查询
			async doGetgoosType() {

				let pass_data = {
					mcode:localStorage.getItem('mcode'),
				};

				let {data:res_data} = await GetgoosType(pass_data);

				this.type_data = res_data;

			},
		
			// 类型添加
			async add_type() {
				
				// 参数
				let pass_data = this.pass_data_type_obj;

				// 执行添加类型 ajax
				let {data:res_data} = await SetgoosType(pass_data);
				
				this.doGetgoosType();
				this.is_show_type_add = false;
				
			},

		},
	};
</script>

<style>
	.blue{
		background-color: #2d8cf0;
		color: #fff;
	}
	.show_type_add_class .ivu-drawer {
		width: 40vw !important;
	}
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
