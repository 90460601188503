<template>
    <div>
        <div style="text-align: right; margin-bottom: 10px">
            <Form inline>
                <FormItem>
                    <Input style="width: 200px" v-model="queryKey" placeholder="请输入查询条件(批次号/名称)" />
                </FormItem>
                <FormItem>
                    <Button @click="() => handleCouponOrderList(1, queryKey)">查询</Button>
                </FormItem>
                <FormItem>
                    <Button v-if="$my_checkBtn('ticketSubmit')" @click="() => showCreateModal()" type="success">创建</Button>
                </FormItem>
                <FormItem>
                    <Button @click="exportCouponListData" type="primary">导出</Button>
                </FormItem>
            </Form>
        </div>
        <Table stripe :columns="CupOrderList.title" :data="CupOrderList.list">
            <template slot-scope="{ row, index }" slot="operation">
                <Tag style="scale: 0.8" v-if="row.isbluePencil" color="error">修订中</Tag>
                <Dropdown transfer @on-click="(value) => opreaterSwitch(value, row)" trigger="click">
                    <Button size="small" type="primary">
                        操作
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem name="查看">查看</DropdownItem>
                        <DropdownItem v-if="(row.status == '新建' || row.status == '驳回') && $my_checkBtn('ticketSubmit')" name="编辑">编辑</DropdownItem>
                        <DropdownItem v-if="row.status == '审批中' && $my_checkBtn('ticketConsent')" name="编辑">编辑</DropdownItem>
                        <DropdownItem name="提交审核" v-if="row.status == '新建' || row.status == '驳回'">提交审核</DropdownItem>
                        <DropdownItem v-if="row.status == '新建' || row.status == '驳回'" name="删除">删除</DropdownItem>
                        <DropdownItem v-if="row.status == '已通过' && !row.isbluePencil && $my_checkBtn('ticketSubmit')" name="修订">修订</DropdownItem>
                        <DropdownItem v-if="row.isbluePencil && $my_checkBtn('ticketConsent')" name="修订审批">修订审批</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </template>
        </Table>
        <Card class="text-right">
            <Page @on-change="(value) => handleCouponOrderList(value)" :page-size="CupOrderList.size" :total="CupOrderList.total"></Page>
        </Card>
        <Modal :mask-closable="false" :closable="false" fullscreen v-model="createModal">
            <div style="display: flex">
                <Card style="width: 500px; margin-right: 20px">
                    <Form
                        :disabled="
                            modalType == 'view' || modalType == 'xiudingshenpi' || (modalType == 'edit' && !(form.status == '新建' || form.status == '驳回'))
                        "
                        ref="form"
                        :model="form"
                        :rules="formRules"
                        style="width: 400px"
                        :label-width="80"
                    >
                        <FormItem prop="Name" label="票券名称">
                            <Input :disabled="modalType == 'xiuding'" v-model="form.Name"></Input>
                        </FormItem>
                        <FormItem prop="type" label="生成方式">
                            <Select :disabled="modalType == 'xiuding'" v-model="form.type">
                                <Option v-for="(item, index) in cupOrderPresetValue.type" :value="item.value" :key="item">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="expirType" label="过期方案">
                            <Select v-model="form.expirType">
                                <Option v-for="(item, index) in cupOrderPresetValue.expirType" :key="index" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="satrt" label="有效时间">
                            <div style="display: flex">
                                <DatePicker v-model="form.satrt" type="date"></DatePicker>
                                <div style="padding: 0 20px">-</div>
                                <DatePicker v-model="form.end" type="date"></DatePicker>
                            </div>
                        </FormItem>
                        <FormItem prop="ruleID" label="票券规则">
                            <Select :disabled="modalType == 'xiuding'" @on-change="(value) => changeCouponRule(value)" v-model="form.ruleID">
                                <Option v-for="(item, index) in cupOrderPresetValue.RuleList" :key="index" :value="item.value">
                                    {{ item.label }}
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="preparePay" label="预付单价">
                            <Input :disabled="form.codetype == 'VIP兑换券' || modalType == 'xiuding'" v-model="form.preparePay" type="number"></Input>
                        </FormItem>
                        <FormItem prop="total" label="申请数量">
                            <Input :disabled="form.type == '预制生成' && form.status == '已通过'" v-model="form.total" type="number"></Input>
                        </FormItem>
                        <FormItem prop="oaNo" label="OA单号">
                            <Input :disabled="modalType == 'xiuding'" v-model="form.oaNo" type="number"></Input>
                        </FormItem>
                        <div style="display: flex; align-items: center">
                            <div style="color: #ccc">预付金额:{{ price }}</div>
                        </div>
                        <FormItem prop="asname"></FormItem>
                        <FormItem prop="freeList"></FormItem>
                        <FormItem prop="onGift"></FormItem>
                        <FormItem prop="status"></FormItem>
                        <FormItem prop="end"></FormItem>
                        <FormItem prop="incomeCinameName"></FormItem>
                        <FormItem prop="payType"></FormItem>
                    </Form>
                </Card>
                <Card style="flex: 1">
                    <Form
                        :rules="formRules"
                        :disabled="
                            modalType == 'view' ||
                            modalType == 'xiuding' ||
                            modalType == 'xiudingshenpi' ||
                            (modalType == 'edit' && !(form.status == '新建' || form.status == '驳回'))
                        "
                        ref="couponRule"
                        :model="couponRule"
                        :label-width="100"
                        class="w-96"
                    >
                        <FormItem prop="" label="票券订单状态">
                            <div v-if="form.status == '已通过'" class="text-green-500">{{ form.status }}</div>
                            <div v-else>{{ form.status }}</div>
                        </FormItem>
                        <FormItem prop="" label="票券别名">
                            <Input placeholder="小程序前台显示名称" v-model="form.asname" />
                        </FormItem>
                        <FormItem prop="freeList" label="适用影院">
                            <Select v-model="form.freeList" multiple>
                                <Option v-for="(item, index) in cupOrderPresetValue.freeList" :value="item.value" :key="item">{{ item.label }} </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="incomeCinameName" label="入账影院">
                            <Input v-model="form.incomeCinameName" />
                        </FormItem>
                        <FormItem prop="payType" mutiple label="支付类型">
                            <Select v-model="form.payType" multiple>
                                <Option value="微信支付" :key="item">微信支付</Option>
                                <Option value="会员支付" :key="item">会员支付</Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="holiday" label="">
                            <Row>
                                <Col span="12">
                                    <Checkbox v-model="couponRule.holiday">节假日适用</Checkbox>
                                </Col>
                                <Col v-if="form.type == '按需生成'" span="12">
                                    <Checkbox v-model="form.onGift">多库存绑定</Checkbox>
                                </Col>
                                <!-- <Col v-if="form.type=='按需生成'" span="12">
									<Checkbox v-model="form.isCardPay">允许会员卡支付</Checkbox>
								</Col> -->
                            </Row>
                        </FormItem>
                        <!-- <FormItem prop="data" ></FormItem> -->
                    </Form>
                    <Table size="small" :columns="tableDataTitle" :data="couponRule.data">
                        <template slot-scope="{ row, index }" slot="containSaleItemType">
                            <div v-if="row.containSaleItemType == 0">影票</div>
                            <div v-if="row.containSaleItemType == 1">卖品</div>
                            <div v-if="row.containSaleItemType == 2">充值券</div>
                        </template>

                        <template slot-scope="{ row, index }" slot="totalAddPrice">
                            <p v-if="row.prohibit">禁止兑换</p>
                            <p v-else>{{ row.totalAddPrice}}</p>
                        </template>

                        <template slot-scope="{ row, index }" slot="extraPrice">
                            <p v-if="row.prohibit">禁止兑换</p>
                            <p v-else>{{ row.extraPrice}}</p>
                        </template>

                        <template slot-scope="{ row, index }" slot="screenLabel">
                            <div v-if="row.screenLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip transfer trigger="hover" :content="row.screenLabel.length > 0 ? row.screenLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="dateLabel">
                            <div v-if="row.dateLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip transfer trigger="hover" :content="row.dateLabel.length > 0 ? row.dateLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="dayLabel">
                            <div v-if="row.dayLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip transfer trigger="hover" :content="row.dayLabel.length > 0 ? row.dayLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                    </Table>
                </Card>
            </div>
            <Card v-if="form.status == '已通过' || form.status == '过期'">
                <Tabs @on-click="changeTabs" value="券码明细">
                    <TabPane label="券码明细" name="券码明细">
                        <div class="text-right">
                            <Button @click="() => exportCouponTableData()" type="primary">导出</Button>
                        </div>
                        <Table size="small" :columns="tableCouponList.title" :data="tableCouponList.list"></Table>
                        <Card class="text-right">
                            <Page
                                @on-change="(value) => handleGetTicketList(form._id, value)"
                                :total="tableCouponList.total"
                                :page-size="tableCouponList.size"
                            ></Page>
                        </Card>
                    </TabPane>
                    <TabPane v-if="form.isbluePencil" label="修订审批" name="修订审批">
                        <Alert show-icon>同意修订审批后申请单对应值将按以下修订值进行修订,如果修订信息有误，请撤销后重新提交修订内容</Alert>
                        <Form disabled class="w-96" :label-width="100">
                            <FormItem v-if="form.bluePencil.expirType" prop="" label="过期方案">
                                <Select v-model="form.bluePencil.expirType">
                                    <Option v-for="(item, index) in cupOrderPresetValue.expirType" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <FormItem v-if="form.bluePencil.satrt" prop="" label="有效时间">
                                <div style="display: flex">
                                    <DatePicker v-model="form.bluePencil.satrt" type="date"></DatePicker>
                                    <div style="padding: 0 20px">-</div>
                                    <DatePicker v-model="form.bluePencil.end" type="date"></DatePicker>
                                </div>
                            </FormItem>
                            <FormItem v-if="form.bluePencil.total" label="申请数量">
                                <Input v-model="form.bluePencil.total" type="number"></Input>
                            </FormItem>
                        </Form>
                        <div class="flex justify-end" v-if="modalType == 'xiudingshenpi'">
                            <Button class="mr-2" @click="() => rejectxdsp()" type="error">撤销修订</Button>
                            <Poptip transfer confirm title="此操作无法被撤销,是否同意修订?" @on-ok="() => resolvexdsp()" @on-cancel="cancel">
                                <Button type="success">同意修订</Button>
                            </Poptip>
                        </div>
                    </TabPane>
                    <TabPane label="操作日志" name="操作日志">
                        <Table size="small" :columns="tableDataLog.title" :data="tableDataLog.list"></Table>
                        <Card class="text-right">
                            <Page @on-change="handleGetCouponAppLog" :total="tableDataLog.total" :page-size="tableDataLog.siez"></Page>
                        </Card>
                    </TabPane>
                </Tabs>
            </Card>
            <div class="flex justify-end" slot="footer">
                <Button class="mr-2" @click="() => hiddenCreateModal()">取消</Button>
                <Poptip v-if="form.status == '审批中' && modalType == 'edit'" class="mr-2" confirm title="确认是否驳回？" @on-ok="() => reject()">
                    <Button type="error">驳回</Button>
                </Poptip>
                <Poptip v-if="form.status == '审批中' && modalType == 'edit'" confirm title="确认是否同意？" @on-ok="() => resolve()">
                    <Button type="success">同意</Button>
                </Poptip>

                <div v-if="modalType != 'xiudingshenpi'">
                    <Button
                        v-if="modalType != 'view' && modalType != 'xiuding' && form.status != '审批中'"
                        @click="() => handleCouponOrderCreate()"
                        type="primary"
                        >确定</Button
                    >
                    <Button v-if="modalType == 'xiuding' && form.status != '审批中'" @click="() => saveXiuding()" type="primary">确定</Button>
                </div>
            </div>
        </Modal>
        <Modal v-model="modalTableRowDelete" :closable="false" :mask-closable="false">
            <div>确认是否删除?</div>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => (modalTableRowDelete = false)">取消</Button>
                <div class="mr-2"></div>
                <Button @click="() => deleteTableRow()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal v-model="modalTableRowsubmit" :closable="false" :mask-closable="false">
            <div>确认是否提交审核?</div>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => (modalTableRowsubmit = false)">取消</Button>
                <div class="mr-2"></div>
                <Button @click="() => groupBtnSubmit()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {
    CouponOrderCreate,
    CouponOrderPresetValue,
    CouponOrderList,
    CouponOrderData,
    GetCouponRule,
    CouponOrderExe,
    GetTicketList,
    BluePencilExe,
    GetCouponAppLog,
} from '@/request/api';
import { download } from '@/utils/table_download.js';
import { FormItem } from 'view-design';
export default {
    name: 'Tickettoapplyfor',
    data() {
        return {
            queryKey: '',
            tableDataTitle: [
                {
                    key: 'containSaleItemType',
                    title: '兑换类型',
                    slot: 'containSaleItemType',
                    width: '100',
                    fixed: 'left',
                },
                {
                    key: 'ruleMerName',
                    title: '商品名称',
                    width: '200',
                    fixed: 'left',
                },
                {
                    key: 'priority',
                    title: '优先级',
                    width: '100',
                },
                {
                    key: 'totalAddPrice',
                    title: '优惠价',
                    width: '100',
                    slot: 'totalAddPrice',
                },
                {
                    key: 'extraPrice',
                    title: '加价',
                    width: '100',
                    slot: 'extraPrice',
                },
                {
                    key: 'dayLabel',
                    title: '适用星期',
                    width: '100',
                    slot: 'dayLabel',
                },
                {
                    key: 'dateLabel',
                    title: '禁用日期',
                    width: '100',
                    slot: 'dateLabel',
                },
                {
                    key: 'screenLabel',
                    title: '适用影厅',
                    width: '100',
                    slot: 'screenLabel',
                },
            ],
            form: {
                oaNo: '',
                Name: '',
                type: '',
                satrt: '',
                end: '',
                preparePay: '',
                total: '',
                mcode: '',
                freeList: [],
                ruleID: '',
                expirType: '',
                onGift: false,
                payType: ['微信支付', '会员支付'],
                asname: '',
            },
            formRules: {
                payType: { require: Array, min: 1, message: ' ' },
            },
            maipinduihuan: {
                title: [
                    {
                        key: 'cname',
                        title: '影院名称',
                    },
                    {
                        key: 'id',
                        title: 'gid',
                    },
                    {
                        key: 'name',
                        title: '商品名称',
                    },
                    {
                        key: 'billing',
                        title: '全部抵扣',
                    },
                    {
                        key: 'addprice',
                        title: '加价',
                    },
                    {
                        key: 'operation',
                        title: '操作',
                        slot: 'operation',
                    },
                ],
                list: [],
            },
            cupfilmTime: {
                title: [
                    {
                        key: 'daylabel',
                        title: '日期',
                    },
                    {
                        key: 'satrt',
                        title: '开始时段',
                    },
                    {
                        key: 'end',
                        title: '结束时段',
                    },
                    {
                        key: 'addprice',
                        title: '加价',
                    },
                    {
                        key: 'operation',
                        title: '操作',
                        slot: 'operation',
                    },
                ],
                list: [],
            },
            cupFilm: {
                title: [
                    {
                        key: 'id',
                        title: '影片编码',
                    },
                    {
                        key: 'name',
                        title: '影片名',
                    },
                    {
                        key: 'addprice',
                        title: '加价',
                    },
                    {
                        key: 'operation',
                        title: '操作',
                        slot: 'operation',
                    },
                ],
                list: [],
            },
            CupOrderList: '',
            createModal: false,
            cupOrderPresetValue: '',
            couponRule: {
                data: [],
                holiday: false,
            },
            tableCouponList: '',
            modalType: '',
            tableRowData: '',
            modalTableRowDelete: false,
            modalTableRowsubmit: false,
            tableDataLog: {},
        };
    },
    computed: {
        price() {
            return this.form.total * this.form.preparePay;
        },
    },
    watch: {
        'form.containSaleItemType': function (value) {
            if (value == '卖品券') {
                this.form.freeList = [];
            }
        },
        'form.codetype': function (value) {
            if (value == 'VIP兑换券') {
                this.form.preparePay = 0;
            }
        },
    },
    created() {
        this.handleCouponOrderList();
    },
    methods: {
        /**
         * 获取操作日志表格数据
         */
        async handleGetCouponAppLog(page = 1) {
            const { _id } = this.form;
            let res = await GetCouponAppLog({
                relationID: _id,
                page,
                all: false,
            });
            if (res.errcode != 0) return;
            this.tableDataLog = res.data;
        },
        /**
         * 切换标签页
         */
        async changeTabs(value) {
            switch (value) {
                case '操作日志':
                    this.handleGetCouponAppLog();
                    break;
            }
        },
        /**
         * 修订审批 同意
         */
        async resolvexdsp() {
            const { _id } = this.form;
            let res = await BluePencilExe({ _id, exeType: '同意修订' });
            if (res.errcode != 0) return;
            this.hiddenCreateModal();
        },
        async rejectxdsp() {
            const { _id } = this.form;
            let res = await BluePencilExe({ _id, exeType: '撤销修订' });
            if (res.errcode != 0) return;
            this.hiddenCreateModal();
        },
        /**
         * 修订保存
         */
        async saveXiuding() {
            const { satrt, end, expirType, apply, _id, total } = this.form;
            let res = await CouponOrderExe({
                exeType: '修订',
                apply: apply,
                _id: _id,
                bluePencil: {
                    total: total,
                    satrt: satrt,
                    end: end,
                    expirType: expirType,
                },
            });
            if (res.errcode != 0) return;
            this.hiddenCreateModal();
        },
        async exportCouponListData() {
            let res = await CouponOrderList({
                page: 1,
                all: true,
            });
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '票券',
                headers,
                data,
            });
        },
        /**
         * 同意 按钮
         */
        async resolve() {
            const { apply, _id } = this.tableRowData;
            let res = await CouponOrderExe({
                exeType: '同意',
                apply,
                _id,
            });
            if (res.errcode != 0) return;
            this.createModal = false;
            this.handleCouponOrderList();
        },
        /**
         * 驳回 按钮
         */
        async reject() {
            const { apply, _id } = this.tableRowData;
            let res = await CouponOrderExe({
                exeType: '驳回',
                apply,
                _id,
            });
            if (res.errcode != 0) return;
            this.createModal = false;
            this.handleCouponOrderList();
        },
        /**
         * 提交审核 按钮
         */
        async groupBtnSubmit() {
            const { apply, _id } = this.tableRowData;
            let res = await CouponOrderExe({
                exeType: '提审',
                apply,
                _id,
            });
            if (res.errcode != 0) return;
            this.modalTableRowsubmit = false;
            this.handleCouponOrderList();
        },
        /**
         * 表格行数据 删除
         */
        async deleteTableRow() {
            const { apply, _id } = this.tableRowData;
            let res = await CouponOrderExe({
                exeType: '删除',
                apply,
                _id,
            });
            if (res.errcode != 0) return;
            this.modalTableRowDelete = false;
            this.handleCouponOrderList();
        },
        /**
         * 操作 按钮组
         */
        async opreaterSwitch(value, row) {
            this.$refs['form'].resetFields();
            this.tableRowData = row;
            this.couponRule.data = [];
            switch (value) {
                case '编辑':
                    this.modalType = 'edit';
                    this.handleCouponOrderData(row);
                    break;
                case '查看':
                    this.modalType = 'view';
                    this.handleCouponOrderData(row);
                    break;
                case '提交审核':
                    this.modalTableRowsubmit = true;
                    break;
                case '删除':
                    this.modalTableRowDelete = true;
                    break;
                case '修订':
                    this.modalType = 'xiuding';
                    this.handleCouponOrderData(row);
                    break;
                case '修订审批':
                    this.modalType = 'xiudingshenpi';
                    this.handleCouponOrderData(row);
                    break;
            }
        },
        /**
         * 票券表格数据 导出
         */
        async exportCouponTableData() {
            let res = await GetTicketList({
                all: true,
                _id: this.form._id,
            });
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '票券',
                headers,
                data,
            });
        },
        /**
         * 隐藏创建的弹窗
         */
        hiddenCreateModal() {
            this.handleCouponOrderList();
            this.createModal = false;
            this.$refs['form'].resetFields();
            this.$refs['couponRule'].resetFields();
        },
        /**
         * 票券规则切换
         */
        async changeCouponRule(value) {
            if (!value) return;
            let res = await GetCouponRule({
                id: value,
                gettype: '获取详情',
            });
            if (res.errcode != 0) return;
            this.form.freeList = res.data.freeList;
            delete res.data.type;
            this.couponRule['incomeCinameName'] = res.data.incomeCinameName;
            this.couponRule = res.data;
        },
        /**
         * 获取表格数据 已生成票券
         */
        async handleGetTicketList(_id, page = 1) {
            let res = await GetTicketList({
                page,
                all: false,
                _id,
            });
            if (res.errcode != 0) return;
            this.tableCouponList = res.data;
        },
        // 查看表格行数据
        async handleCouponOrderData(value) {
            const { _id } = value;
            let res = await CouponOrderExe({
                exeType: '详情',
                _id,
                apply: value.apply,
            });
            if (res.errcode != 0) return;
            this.couponRule['data'] = res.data.ruleList;
            if (res.data.status == '已通过' || res.data.status == '过期') {
                this.handleGetTicketList(_id);
            }
            await this.handleCouponOrderPresetValue();
            this.form = res.data;
            this.cupFilm.list = res.data.Expand_movie;
            this.cupfilmTime.list = res.data.Expand_times;
            this.couponRule.holiday = this.form.holiday;
            this.createModal = true;
        },
        async handleCouponOrderCreate() {
            let passData = JSON.parse(JSON.stringify(this.form));
            passData['satrt'] = new Date(passData['satrt']).getTime();
            passData['end'] = new Date(passData['end']).getTime();
            this.cupOrderPresetValue.RuleList.forEach((item, index) => {
                if (this.form.ruleID == item.value) {
                    passData['ruleName'] = item.label;
                }
            });
            delete this.couponRule['_id'];
            const { holiday, data } = this.couponRule;
            passData['holiday'] = holiday;
            passData['data'] = data;
            if (!this.form._id) {
                delete passData['_id'];
            }
            passData.freeList = this.form.freeList;
            let res = await CouponOrderCreate(passData);
            if (res.errcode != 0) return;
            this.$Message.success(res.msg);
            this.handleCouponOrderList();
            this.createModal = false;
            this.$refs['form'].resetFields();
            this.$refs['couponRule'].resetFields();
        },
        async showCreateModal() {
            delete this.form._id;
            this.modalType = 'add';
            await this.handleCouponOrderPresetValue();
            this.createModal = true;
            this.$refs['form'].resetFields();
            this.$refs['couponRule'].resetFields();
            this.couponRule.data = [];
        },
        async handleCouponOrderPresetValue() {
            let res = await CouponOrderPresetValue({ type: '票券订单' });
            if (res.errcode != 0) return;
            this.cupOrderPresetValue = res.data;
        },
        async handleCouponOrderList(page = 1, queryKey = '') {
            let res = await CouponOrderList({
                page,
                all: false,
                queryKey,
            });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
            });
            this.CupOrderList = res.data;
        },
        async table_dowload(type = '获取数据', typecode, weight) {
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '交易类型',
                headers,
                data,
            });
        },
    },
    components: { FormItem },
};
</script>

<style></style>
