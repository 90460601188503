<template>
	<div>


		<Modal draggable v-model="modal_menu" title="添加权限菜单" @on-ok="ok">
			
			<p>
				<span style="display: inline-block;width: 130px;">菜单标题</span>
				<Input v-model="title" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="display: inline-block;width: 130px;">路由英文名</span>
				<Input v-model="name" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="display: inline-block;width: 130px;">该路由是否禁用</span>
				<i-Switch v-model="state" :true-value='1' :false-value='0' />

			</p>
			<p style="display: flex;">
				<span style="display: inline-block;width: 130px;">上级路由</span>

				<Select v-model="routes_select" style="width: 300px;">
					<Option :value="item.uid" v-for="item in routes">{{item.title}}</Option>
				</Select>

			</p>
			<!-- 自定义按钮操作对话框的显示与隐藏 -->
			<div slot="footer">
				<Button @click='()=>modal_menu=false'>取消</Button>
				<Button @click="()=>ok()" type="primary">确定</Button>
			</div>

		</Modal>

		<Modal draggable v-model="modal_btn" title="添加按钮权限" @on-ok="ok">

			<p>
				<span style="display: inline-block;width: 130px;">按钮标题</span>
				<Input v-model="title_btn" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="display: inline-block;width: 130px;">路由英文名</span>
				<Input v-model="name_btn" style="width: 300px;"></Input>
			</p>
			<p>
				<span style="display: inline-block;width: 130px;">该路由是否禁用</span>
				<i-Switch v-model="state_btn" :true-value='1' :false-value='0' />

			</p>
			<p style="display: flex;">
				<span style="display: inline-block;width: 130px;">上级路由</span>
				<Select disabled v-model="routes_select_btn" style="width: 300px;">
					<Option :value="item.uid" v-for="item in routes_btn">{{item.title}}</Option>
				</Select>
			</p>
			<!-- 自定义按钮操作对话框的显示与隐藏 -->
			<div slot="footer">
				<Button @click='()=>modal_btn=false'>取消</Button>
				<Button @click="()=>do_CreateButton()" type="primary">确定</Button>
			</div>

		</Modal>

		<div style="width: 100%;">

			<div class="demo-split">
				<Split v-model="split1">
					
					<div slot="left" class="demo-split-pane">
						<Card>
							
							<div style="margin: 10px 0;text-align: right;">
								<div>
									<Button @click="()=>modal_menu=true" type="primary">添加权限菜单</Button>
								</div>
								<div v-if="is_show_sort" style="margin-top: 10px;display: flex;justify-content: flex-end;">
									<div style="">
										<div>
											<span style="width: 100px;">请输入排序：</span>
											<Input v-if="current_select_menu" v-model="current_select_menu.sort" type="number" style="width: 100px;"></Input>
										</div>
										<div>
											<span style="width: 100px;">请输入图标：</span>
											<Input v-if="current_select_menu" v-model="current_select_menu.icon"  style="width: 100px;"></Input>
										</div>
									</div>
									<Button @click="()=>sort_btn()" type='primary' style="margin-left: 10px;">提交</Button>
								</div>
							</div>
							<Tree @on-select-change='(payload)=>show_permissions_button(payload)' ref='treeRef' :data="data2" show-checkbox></Tree>
							<div style="margin-top: 10px;text-align: right;">
								<Button @click="()=>set_tick()" type='primary'>提交</Button>
							</div>
							
						</Card>
					</div>
					
					<div slot="right" class="demo-split-pane">
						<Card >
							
							<div v-if="permissions_button_data" style="margin: 10px 0;display: flex;justify-content: space-between;align-items: center;">
								<div>
									{{permissions_button_data.pagetitle}}
								</div>
								<div >
									<Button @click="()=>modal_btn=true" type="primary">添加按钮权限</Button>
								</div>
							</div>
							<Tree ref='treeRef_btn' :data="permissions_button_data.Buttonlist" show-checkbox></Tree>
							<div v-if="permissions_button_data" style="margin-top: 10px;text-align: right;">
								<Button @click="()=>do_button_permission_to_submit()" type='primary'>提交</Button>
							</div>
							
						</Card>
					</div>
					
				</Split>
			</div>

		</div>

	</div>
</template>

<script>
	
	import {
		SetPageSort,
		SetPageButton,
		GetPageButton,
		CreateButton,
		GettwopMenu,
		SetMenu,
		GetMenu,
		GettopMenu,
		createPage,
		quanbu_background_menu,
		add_new_menu
	} from '../../request/api.js'
	export default {
		name: 'navbar',
		data() {
			
			return {
				current_select_menu:'',//当前选中的菜单
				is_show_sort:false,//是否显示排序表单
				show_permissions_button_payload:'',
				permissions_button_data:'',//当前选中的权限按钮
				split1: 0.5,
				modal_btn: false, //添加按钮权限弹窗
				title_btn: '', //按钮标题
				name_btn: '',
				state_btn: 0,
				routes_select_btn: '',
				routes_btn: [],
				title: '', //菜单标题
				name: '', //路由英文名
				state: 1, //该路由是否禁用
				pid: '', //上级路由
				is_menu: 1, //是否是后台菜单
				routes_select: '', //选中的上级路由
				routes: [], //上级路由
				modal_menu: false, //添加权限菜单弹窗
				data2: []
			}

		},
		mounted() {
			
			this.flash()
			
		},
		watch: {
			
			async modal_btn(val) {
				
				if (val) {
					// 加载一级菜单
					let res_data = await GettwopMenu()
					this.routes_btn = res_data.data
				}
				
			},

			async modal_menu(val) {
				
				if (val) {
					this.routes_select = ''
					// 加载顶级菜单
					let res_data = await GettopMenu()
					this.routes = res_data.data
				}

			}
			
		},
		methods: {

			// 修改排序 图标
			async sort_btn(){
				
				let pass_data = {
					"pageuid": this.current_select_menu.uid, //菜单UID
					"sort":this.current_select_menu.sort, //排序数字 越大越靠前
					"icon":this.current_select_menu.icon //排序数字 越大越靠前
					}
					
				let res_data = await SetPageSort(pass_data)
				
				if(res_data.code==200){
						this.flash()
					this.$mytoast('修改成功')
				}
				
			},
			
			// 按钮权限提交
			async do_button_permission_to_submit(){
				
				let butlist = await this.$refs.treeRef_btn.getCheckedNodes()
				
				butlist = butlist.map(item => item.Butuid)
				let pass_data ={
				"pageuid": this.permissions_button_data.pageuid, 
				"butlist": butlist
				}
					
				let res_data = await SetPageButton(pass_data);
				
				if (res_data.code == 200) {
					// 回显按钮权限列表
					this.show_permissions_button(this.show_permissions_button_payload)
					this.$mytoast(res_data.msg)
				}
				
			},
			
			// 权限菜单页面点击 获取 页面按钮权限
			async show_permissions_button(payload){
				
				this.current_select_menu = payload[0]
				// 显示排序提交表单
				this.is_show_sort = true
				this.show_permissions_button_payload = payload
				this.routes_select_btn =payload[0].uid
				// 非品牌
				if(!payload[0].hasOwnProperty('children')){
					let pass_data = {
						'pageuid':payload[0].uid
					}
					
					let res_data = await GetPageButton(pass_data)
					
					if (res_data.code == 200) {
						this.permissions_button_data = res_data.data
					}
				}
				
			},
			
			// 添加按钮权限-确定
			async do_CreateButton() {

				let pass_data = {
					"pageuid": this.routes_select_btn, //二级页面uid
					"title": this.title_btn, //按钮标题
					"key": this.name_btn, //按钮key键
					"disable": this.state_btn //是否禁用
				}
				if (this.routes_select_btn === '' || this.title_btn === '' || this.name_btn === '' || this
					.state_btn === '') {
					this.$mytoast_err('请正确填写')
					return
				}
				
				let res_data = await CreateButton(pass_data)
				
				if (res_data.code == 200) {
					this.$Message.success(res_data.msg)
					// 回显按钮权限列表
					this.show_permissions_button(this.show_permissions_button_payload)
					this.modal_btn = false
				}
				
			},

			async set_tick() {
				
				let res_data = await this.$refs.treeRef.getCheckedNodes()
				res_data = res_data.map(item => item.uid)
				
				let res = await SetMenu(res_data)
				
				if (res.msg == 'ok') {
					this.$mytoast('提交成功')
					this.flash()
				} else {
					this.$mytoast_err('提交失败')
				}

			},

			async ok() {

				let pass_data = {
					title: this.title, //菜单标题
					name: this.name, //路由英文名
					disable: this.state, //该路由是否禁用
					topRout: this.routes_select, //上级路由
				}
				if (!this.title || !this.name) {
					this.$Message.error('请正确填写菜单！')
					return
				}
				// 执行添加权限菜单接口

				let res_data = await createPage(pass_data)

				if (res_data.code == 200) {
					this.$Message.success(res_data.msg)
					this.flash()
					this.modal_menu = false
				}

			},

			async flash() {
				
				let pass_data = {
					type: 1
				}
				
				let res_data = await GetMenu(pass_data)

				if (res_data.code != 200) {
					this.$mytoast_err(res_data.msg + '')
					return
				}
				this.data2 = res_data.data

			}

		}

	}
</script>

<style>
	
	.demo-split {
		height: 700px;
		border: 1px solid #dcdee2;
	}

	.demo-split-pane {
		padding: 10px;
	}

	.ivu-modal-body p {
		margin: 20px 0;
	}
	
</style>
