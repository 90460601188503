<template>
    <div :style="`height: 100vh;`">
        <!-- 后台面板功能 -->
        <div style="display: flex">
            <!-- 侧边菜单导航 -->
            <div id="menu" style="min-width: 190px; overflow: hidden; height: 100vh; overflow-y: scroll; overflow-x: hidden">
                <!-- 门店logo和店名 -->
                <div
                    :style="`z-index: 999;  overflow: hidden;  color: #fff;width: 190px;background-color: #000;display: flex;align-items: center;justify-content: center;height: 60px;`"
                >
                    <div @click="() => $router.push('/home')" style="cursor: pointer; display: flex; align-items: center">
                        <div :style="`border-radius: 1000px;overflow: hidden;height: 40px;`">
                            <img style="height: 100%" :src="logo_img" alt="" />
                        </div>
                        <div style="margin-left: 10px; font-size: 20px">
                            {{ selected_stores_name }}
                        </div>
                    </div>
                </div>

                <!-- 侧边菜单 -->
                <!-- 此处必须使用v-if判断以下菜单数据是否到达，为了菜单中的name与当前路由页面的路径一直，也为了active-name与当前路由页面之一 -->
                <Menu
                    @on-select="($) => save_router_nav($)"
                    v-show="background_menu"
                    style="height: calc(100vh - 60px); width: 100%"
                    theme="dark"
                    :active-name="$route.path"
                >
                    <Submenu :name="item.title" v-for="(item, index) in background_menu">
                        <template slot="title">
                            <Icon :type="item.icon" />
                            {{ item.title }}
                        </template>
                        <MenuItem
                            :ref="'/' + itemchild.name"
                            v-for="(itemchild, indexchild) in item.child"
                            :to="'/' + itemchild.name"
                            :name="'/' + itemchild.name"
                        >
                            {{ itemchild.title }}
                        </MenuItem>
                    </Submenu>
                </Menu>
            </div>
            <!-- 内容区域 -->
            <div :style="`position: relative;height:100vh; z-index: 99;;overflow-x: hidden;width: 100%;`">
                <!-- 导航页右侧 -->
                <div>
                    <!--场景一 品牌选择页 => 进入系统 -->
                    <div
                        v-if="$mytype() == 'admin'"
                        style="
                            z-index: 999;
                            position: fixed;
                            top: 0;
                            right: 0;
                            left: 190px;
                            padding: 0 10px;
                            justify-content: space-between;
                            height: 60px;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                        "
                    >
                        <!-- 退出系统 -->
                        <div :style="`z-index: 10;display: flex;justify-content: flex-end;width: 100%;`">
                            <div>
                                用户：{{ username }} <Button style="margin: 0 10px" @click="reset_new_pwd = true" type="error">修改密码</Button>
                                <Button @click="() => logout()" type="primary">退出</Button>
                            </div>
                        </div>
                    </div>
                    <!--场景二 品牌选择页 => 进入品牌管理 -->
                    <!--场景三 选中品牌 => 选中门店 => 确认 -->
                    <!-- 同比新增门店选择功能 -->
                    <div
                        v-if="$mytype() != 'admin'"
                        style="
                            z-index: 999;
                            position: fixed;
                            top: 0;
                            right: 0;
                            left: 190px;
                            padding: 0 10px;
                            justify-content: space-between;
                            height: 60px;
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                        "
                    >
                        <!-- 退出系统 -->
                        <div :style="`z-index: 10;display: flex;justify-content: space-between;width: 100%;`">
                            <div>
                                <span>当前门店：{{ select_stores }}</span>
                                <Button @click="() => $router.push('/select_stores')" style="margin: 0 10px">选择门店</Button>
                            </div>
                            <div>
                                用户：{{ username }} <Button style="margin: 0 10px" @click="reset_new_pwd = true" type="error">修改密码</Button>
                                <Button @click="() => logout()" type="primary">退出</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 后台内容显示区域 -->
                <div class="bd" style="height: 100vh; padding-top: 75px; overflow-y: scroll; padding: 100px 30px 0 30px; background-color: #efefef">
                    <div v-if="$route.path == '/home'">
                        <h1 style="font-weight: 700">欢迎使用影惠云后台管理系统</h1>
                    </div>
                    <router-view />
                </div>
            </div>
        </div>
        <!-- 密码修改对话框 -->
        <Modal v-model="reset_new_pwd" title="修改密码">
            <p>
                <span style="display: inline-block; width: 100px">旧密码：</span>
                <Input type="password" style="width: 300px" v-model="old_pwd"></Input>
            </p>
            <p>
                <span style="display: inline-block; width: 100px">新密码：</span>
                <Input type="password" style="width: 300px" v-model="new_pwd"></Input>
            </p>
            <p>
                <span style="display: inline-block; width: 100px">新密码确认：</span>
                <Input type="password" style="width: 300px" v-model="new_pwd_copy"></Input>
            </p>
            <div slot="footer">
                <Button @click="() => (reset_new_pwd = false)">返回</Button>
                <Button @click="() => do_reset_new_pwd()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { UserSetPassword } from '../request/api.js';
import { md5 } from '../assets/js/md5.js';
export default {
    name: 'homePage',
    data() {
        return {
            new_pwd_copy: '', //iput新密码确认
            reset_new_pwd: false, //控制修改密码对话框显示与隐藏
            old_pwd: '', //input旧密码
            new_pwd: '', //input新密码
            open_menu: '', //打开的菜单
            select_stores: '', //当前门店
            background_menu: [], //侧边导航菜单
        };
    },
    computed: {
        /**
         * 门店名称
         */
        selected_stores_name() {
            let data = JSON.parse(localStorage.getItem('选中品牌1'));
            if (data && data.brand) {
                return data.brand.name;
            }
            return '';
        },
        /**
         * 门店图片
         */
        logo_img() {
            let data = JSON.parse(localStorage.getItem('选中品牌1'));
            if (data && data.brand) {
                return data.brand.logurl;
            }
            return '';
        },
        /**
         * 用户真实姓名
         */
        username() {
            let data = JSON.parse(localStorage.getItem('选中品牌1'));
            if (data && data.brand && data.user) {
                return data.user.name;
            }
            return '';
        },
    },
    async mounted() {
        try {
            // 实现侧边导航栏记忆功能,自动展开和高亮
            let active_route = localStorage.getItem('active_route');
            this.background_menu = '';
            this.background_menu = JSON.parse(localStorage.getItem('选中品牌1')).nat;

            this.$nextTick(() => {
                this.$refs[active_route][0].$parent.opened = true;
                this.$refs[active_route][0].active = true;
            });
            if (localStorage.getItem('选中门店')) {
                this.select_stores = JSON.parse(localStorage.getItem('选中门店'))[0].cname;
            }
            if (!localStorage.getItem('mcode')) {
                this.$router.push('/home');
            }
        } catch (e) {
            /* handle error */
        }
    },
    methods: {
        // 保存侧边导航路由
        save_router_nav(val) {
            localStorage.setItem('active_route', val);
        },

        async do_reset_new_pwd() {
            if (this.new_pwd == '' || this.new_pwd_copy == '' || this.old_pwd == '') {
                this.$mytoast_err('请正确填写密码');
                return;
            }
            if (this.new_pwd_copy != this.new_pwd) {
                this.$mytoast_err('新密码填写不一致');
                return;
            }
            let pass_data = {
                Password: md5(this.old_pwd), //旧密码
                newPassword: md5(this.new_pwd), //新密码
            };

            let res_data = await UserSetPassword(pass_data);
            if (res_data.code == 200) {
                this.reset_new_pwd = false;
                this.$mytoast(res_data.msg);
            }
        },

        // 退出登录
        logout() {
            localStorage.clear();
            this.$router.push('/login');
        },
    },
};
</script>
<style type="text/css">
.bd::-webkit-scrollbar {
    width: 0;
}
</style>
