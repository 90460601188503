<template>
	<div style="z-index: 99999;">

		<!-- 选择门店 -->
		<div style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;background-color: #efefef;">
		</div>

		<div style="position: absolute;top: 10px;right: 10px;z-index: 99;">
			<Button @click="()=>to_home_page_sys()" v-if="$route.query.type==1" style="z-index: 99999;">进入系统</Button>
			<Button style="z-index: 99;margin-left: 10px;" @click="()=>$router.back(-1)" type='primary'>返回</Button>
		</div>

		<div style="width: 80vw;margin: 50px auto;">

			<Card style='z-index: 99;'>
				<!-- 标签页 -->
				<h2>请选择品牌</h2>
				
				<div @click="()=>click_on_brand(index)" v-for="(item,index) in store_list"
					style="text-align: center;width: 100px;cursor: pointer;">
					<div 
						:style="`width: 100px;height: 100px;border: 3px solid ${current_select_brand===index?'gold':'#fff'};border-radius: 500px;overflow: hidden;`">
						<img style="width: 100%;height: 100%;" :src="item.logurl">
					</div>
					

				</div>

				<div v-if="store_list[current_select_brand]" style="margin-top: 30px;">
					<h2>请选择门店</h2>
					<div style="margin-top: 30px;display: flex;flex-wrap: wrap;">
						<div @click="()=>(current_select_shop=index)"
							v-for="(item,index) in store_list[current_select_brand].stores"
							style=" display: flex;margin-right: 10px;">
							<div  v-if='store_list[current_select_brand].endt<=0'>
								<h1>您的品牌授权已过期，请联系授权</h1>
								<h3 style="color: red;">*到期后平台(购票、查询、会员充值)功能将无法使用*</h3>
							</div>
							
							<div v-else>
								<Card  v-if="store_list[current_select_brand].endt>0"
									:style="` height: 80px;line-height: 40px;padding: 0 10px;font-size: 700;cursor: pointer;border: 3px solid ${current_select_shop===index?'gold':'#fff'};`">
									<div @click="()=>date_process(store_list[current_select_brand])" style="align-items: center;display: flex;">
										<img style="width: 30px;height: 30px;" :src="item.address"> {{item.cname}}
									</div>
								</Card>
							</div>
							
						</div>
					</div>
				</div>

				<div v-if="store_list[current_select_brand]" style="margin-top: 50px;text-align: right;">
					<Button @click="()=>to_home_page_brand()" v-if="current_select_brand_manager>=0" type='error'>进入品牌管理</Button>
					<Button v-if='store_list[current_select_brand].endt>0'  style="margin-left: 10px;" @click="()=>to_home_page()" type='primary'>  确定</Button>
				</div>

			</Card>

		</div>
		
		<!-- 弹窗 -->
		<div>
			
			<Modal v-if='store_list[current_select_brand]' :closable='false' :mask-closable='false' v-model='due_to_dialog'>
				<div>
					<h1>您的品牌授权已不足{{store_list[current_select_brand].endt}}天，请联系授权</h1>
					<h3 style="color: red;">*到期后平台(购票、查询、会员充值)功能将无法使用*</h3>
				</div>
				<div slot='footer'>
					
					<Button type='primary' @click="()=>due_to_dialog=false" :disabled="whether_pop_up_confirmation">确定{{(time==0?'':`(${time})`)}}</Button>
				</div>
			</Modal>
			
		</div>
	</div>
</template>

<script>
	import {
		Brandmanagerlonin,
		isBrandManager,
		Adminlogin,
		Get_holdStores,
		Set_mcode
	} from '../../../request/api.js'
	export default {
		name: "navbar",
		data() {
			
			return {
				current:'',
				time:10,
				current_day:'',
				due_to_dialog:false,//到期对话框
				whether_pop_up_confirmation:true,//到期确认按钮
				current_select_brand_manager: 0,
				current_select_shop: '', //选中的门店
				current_select_brand: '', //选中的品牌下保存的门店
				select_stores: '',
				modal11: true,
				store_list: [], //门店列表
			}
			
		},

		watch:{
			
			due_to_dialog(val){
				if(!val){
					this.whether_pop_up_confirmation=true
				}
			}
			
		},
		async mounted() {

			// 执行获取门店
			let { data: res_data } = await Get_holdStores()
			
			this.store_list = res_data.hold_list
			
		},
		methods: {
			
			test(val){
				let arr = '';
				this.store_list.forEach((item,index)=>item.stores.forEach((itemin,index)=>itemin.uid==val.uid?(arr=item):(arr=false)))
				this.current_day=arr.endt
				this.current = arr
			},
			
			// 到期提醒
			date_process(item){
	
				if(0<item.endt&&item.endt<=3){
					this.due_to_dialog=true
					let time=10
					let center =  setInterval(item=>{
						time--;
						this.time=time
						if(time==0){
							clearInterval(center)
						}
					}, 1000)
					setTimeout(()=>{
						this.whether_pop_up_confirmation=false
					},10000)
				}
				
			},
			
			// 进入品牌管理
			async to_home_page_brand() {
				
				let pass_data ={
					branduid:this.store_list[this.current_select_brand].branduid
				}
				
				let res_data = await Brandmanagerlonin(pass_data)
				
				localStorage.setItem('mcode', res_data.data.stores.mcode)
				localStorage.setItem('选中品牌1', JSON.stringify(res_data.data))
				this.$Message.success('门店设置成功！')
				this.$router.push('/home')
			
			},
			
			async click_on_brand(index) {
				
				this.current_select_brand = index
				// this.store_list[index]
				let pass_data = {
					branduid: this.store_list[index].branduid
				}
				
				let res_data = await isBrandManager(pass_data)
				
				if (res_data.code == 200) {
					this.current_select_brand_manager = res_data.data.manager
				}

			},
			
			async to_home_page(type) {
				
				let mcode = this.store_list[this.current_select_brand].stores[this.current_select_shop].mcode
				if (this.current_select_shop === '') {
					this.$mytoast_err('请选择门店')
					return
				}
				let pass_data = {
					"branduid": this.store_list[this.current_select_brand].branduid,
					"mcode": mcode
				}

				let res_data = await Set_mcode(pass_data)

				if (res_data.code != 200) {
					this.$mytoast_err(res_data.data.msg)
					return
				}
				if (res_data && res_data.data.stores.mcode == mcode) {
					localStorage.setItem('mcode', mcode)
					let center = this.store_list[this.current_select_brand].stores
					center = center.filter(item => item.mcode == mcode)
					localStorage.setItem('选中门店', JSON.stringify(center))
					// localStorage.setItem('选中品牌', JSON.stringify(this.store_list[this.current_select_brand]))
					localStorage.setItem('选中品牌1', JSON.stringify(res_data.data))

					this.select_stores = center[0].cname
					this.$Message.success('门店设置成功！')
					this.$router.push('/home')
				} else {
					this.$mytoast_err('门店设置失败！')
				}

			},
		
			// 进入系统
			async to_home_page_sys() {

				let res_data = await Adminlogin()
				
				localStorage.setItem('mcode', res_data.data.stores.mcode)
				localStorage.setItem('选中品牌1', JSON.stringify(res_data.data))
				this.$Message.success('门店设置成功！')
				this.$router.push('/home')

			}
		
		}

	}
</script>

<style>
	h2 {
		margin: 20px 0;
	}
</style>
