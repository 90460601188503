<template>
    <div>
        <div style="text-align: right">
            <Button type="primary" @click="() => (activity_create_modal = true)">创建活动</Button>
            <div class="mt-3 text-right">
                <Page @on-change="(value) => to_ActivityGetList(value)" show-total size="small" :page-size="CardInfo.size" :total="CardInfo.total"></Page>
            </div>
        </div>
        <div style="margin-top: 10px">
            <Row>
                <Col style="padding: 20px" v-for="(item, index) in CardInfo.list" :key="index" span="8">
                    <Card>
                        <Row>
                            <Col class="mb-3" span="24">
                                <Tag color="success">活动ID:{{ item.activityID }}</Tag>
                            </Col>
                            <Col class="mb-3" span="7">活动名称</Col>
                            <Col class="mb-3" span="17">{{ item.name }}</Col>
                            <Col class="mb-3" span="7">发起人</Col>
                            <Col class="mb-3" span="17">{{ item.incomeCinameName }}</Col>
                            <Col class="mb-3" span="7">活动类型</Col>
                            <Col class="mb-3" span="17">
                                <Tag color="blue">{{ item.type }}</Tag>
                            </Col>
                            <Col class="mb-3" span="7">开始时间</Col>
                            <Col class="mb-3" span="17">{{ item.start }}</Col>
                            <Col class="mb-3" span="7">结束时间</Col>
                            <Col class="mb-3" span="17">{{ item.end }}</Col>
                            <Col class="mb-3" span="7">开启活动</Col>
                            <Col class="mb-3" span="17">
                                <i-Switch @on-change="() => to_SetState(item, '开关')" v-model="CardInfo.list[index].state"></i-Switch>
                            </Col>
                            <Col class="mb-2" span="7">操作员</Col>
                            <Col class="mb-2" span="17">{{ item.modifier }}</Col>
                        </Row>
                        <div style="position: absolute; top: 10px; right: 10px">
                            <Button size="small" @click="() => handleEdit(item)">编辑</Button>
                            <Poptip transfer confirm title="确定是否删除?" @on-ok="() => to_SetState(item, '删除')">
                                <div style="display: flex">
                                    <Button type="error" style="margin-left: 10px" size="small">删除</Button>
                                </div>
                            </Poptip>
                        </div>
                        <div style="text-align: right">
                            <Button @click="() => to_getCodeGenerat(item)" size="small" type="primary" v-if="!item.qrcode">获取推广码</Button>
                            <Poptip v-else title="Title" placement="top">
                                <Button>查看推广码</Button>
                                <div slot="title">
                                    <i>{{ item.name }}</i>
                                </div>
                                <div slot="content">
                                    <img style="width: 150px; height: 150px" :src="item.url + item.qrcode" alt="" />
                                </div>
                            </Poptip>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
        <Modal
            v-if="editData.content"
            :closable="false"
            :mask-closable="false"
            @on-visible-change="() => resetFieldsHandle('formItem_edit')"
            width="800"
            v-model="active_edit_modal"
        >
            <div style="padding: 40px 0 0 0">
                <Form ref="formItem_edit" :model="formItem_edit" :label-width="130" :rules="ruleItem">
                    <Row class="w-96">
                        <Col :span="24">
                            <FormItem :label-width="80" label="活动名称">
                                <Input disabled v-model="editData.name" />
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem :label-width="80" label="适用门店">
                                <Select multiple v-model="editData.freeList">
                                    <Option v-for="item in holdStores_option.stores" :value="item.mcode">{{ item.cname }} </Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col :span="24">
                            <FormItem :label-width="80" label="活动时间">
                                <Row>
                                    <Col span="11">
                                        <DatePicker
                                            type="datetime"
                                            placeholder="开始时间"
                                            :value="editData.start"
                                            @on-change="(value) => (editData.start = value)"
                                        >
                                        </DatePicker>
                                    </Col>
                                    <Col span="2" style="text-align: center">-</Col>
                                    <Col span="11">
                                        <DatePicker
                                            type="datetime"
                                            placeholder="结束时间"
                                            :value="editData.end"
                                            @on-change="(value) => (editData.end = value)"
                                        ></DatePicker>
                                    </Col>
                                </Row>
                            </FormItem>
                        </Col>
                        <Col :span="18">
                            <FormItem :label-width="80" label="限制时间">
                                <DatePicker :disabled="editData.content.day" type="datetime" placeholder="限制时间" v-model="editData.content.time">
                                </DatePicker>
                            </FormItem>
                        </Col>
                        <Col class="pl-2" style="display: flex; align-items: center" :span="6">
                            <Checkbox v-model="editData.content.day">每天可领</Checkbox>
                        </Col>
                        <Col span="24">
                            <FormItem :label-width="80" label="领取上限">
                                <Input style="width: 200px" v-model="editData.content.getmax"></Input>
                            </FormItem>
                        </Col>
                    </Row>

                    <Alert show-icon>有效期如果为0 则使用票券模板限定有效期，否则则按获得时间+有效期自动生成</Alert>
                    <Row type="flex" justify="center" align="middle">
                        <Col span="8">
                            <FormItem prop="givName"></FormItem>
                            <FormItem :label-width="80" prop="givID" label="礼品选择">
                                <Select
                                    @on-select="($) => (formItem_edit.givName = $.label) && (formItem_edit.givID = $.value)"
                                    style="text-align: left; width: 200px"
                                    v-model="formItem_edit.givID"
                                >
                                    <Option v-for="item in SelectionGet_option" :value="item.value">{{ item.label }} </Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span="7">
                            <FormItem :label-width="100" prop="givNum" label="赠送量">
                                <Input type="number" v-model.number="formItem_edit.givNum"></Input>
                            </FormItem>
                        </Col>
                        <Col span="7">
                            <FormItem prop="expirationDate" :label-width="80" label="有效期">
                                <Input type="number" v-model.number="formItem_edit.expirationDate"></Input>
                            </FormItem>
                        </Col>
                        <Col class="pl-2" span="2">
                            <Button size="small" type="primary" @click="() => to_addActivityGivlist()">添加</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div v-if="editData.content">
                <Table size="small" :columns="editData.content.givTitle" :data="editData.content.giv">
                    <template slot-scope="{ row, index }" slot="opreater">
                        <Button @click="() => to_delActivityGivlist(index)" size="small" type="error">删除</Button>
                    </template>
                </Table>
            </div>
            <div class="flex justify-between" slot="footer">
                <div class="text-left">
                    <div>备注:{{ editData.Note }}</div>
                    <div class="flex text-xs text-blue-500">
                        <div class="mr-2">操作员:{{ editData.modifier }}</div>
                        <div>修改时间:{{ editData.Time }}</div>
                    </div>
                </div>
                <div>
                    <Button @click="() => save()" type="primary">保存</Button>
                    <Button @click="() => (active_edit_modal = false)">返回</Button>
                </div>
            </div>
        </Modal>
        <Modal :closable="false" :mask-closable="false" @on-visible-change="() => resetFieldsHandle('formItem')" v-model="activity_create_modal">
            <div style="max-width: 430px">
                <Form ref="formItem" :model="formItem" :label-width="130" :rules="ruleItem">
                    <FormItem prop="name" label="活动名称">
                        <Input v-model="formItem.name"></Input>
                    </FormItem>
                    <FormItem prop="freeList" label="适用门店">
                        <Select multiple v-model="formItem.freeList">
                            <Option v-for="item in holdStores_option.stores" :value="item.mcode">{{ item.cname }} </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="活动时间">
                        <Row>
                            <Col span="11">
                                <DatePicker type="datetime" placeholder="开始时间" v-model="formItem.start"> </DatePicker>
                            </Col>
                            <Col span="2" style="text-align: center">-</Col>
                            <Col span="11">
                                <DatePicker type="datetime" placeholder="结束时间" v-model="formItem.end"></DatePicker>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem prop="type" label="活动类型">
                        <Select v-model="formItem.type">
                            <Option v-for="item in type_option" :value="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                    <FormItem prop="Note" label="备注">
                        <Input v-model="formItem.Note"></Input>
                    </FormItem>
                    <FormItem prop="start"></FormItem>
                    <FormItem prop="end"></FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="() => (activity_create_modal = false)">取消</Button>
                <Button @click="() => to_ActivityCreate()" type="primary">确认</Button>
            </div>
        </Modal>
        <!--扫码购物弹出框-->
        <Modal :closable="false" :mask-closable="false" width="800" v-model="activeShopingScan">
            <div style="max-width: 430px">
                <Form ref="formItemShopingScan" :model="formItem" :label-width="130" :rules="ruleItemShopingItem">
                    <FormItem prop="name" label="活动名称">
                        <Input disabled v-model="formItemShopingScan.name"></Input>
                    </FormItem>
                    <FormItem prop="freeList" label="适用门店">
                        <Select multiple v-model="formItemShopingScan.freeList">
                            <Option v-for="item in holdStores_option.stores" :value="item.mcode">{{ item.cname }} </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="活动时间">
                        <Row>
                            <Col span="11">
                                <DatePicker type="datetime" placeholder="开始时间" v-model="formItemShopingScan.start"> </DatePicker>
                            </Col>
                            <Col span="2" style="text-align: center">-</Col>
                            <Col span="11">
                                <DatePicker type="datetime" placeholder="结束时间" v-model="formItemShopingScan.end"></DatePicker>
                            </Col>
                        </Row>
                    </FormItem>
                    <FormItem v-if="formItemShopingScan.content" label="有效时间">
                        <Input type="number" v-model="formItemShopingScan.content.time"></Input>
                    </FormItem>
                    <FormItem prop="start"></FormItem>
                    <FormItem prop="end"></FormItem>
                </Form>
            </div>
            <div style="text-align: right">
                <Button @click="showMeAddToShopingScanTable" type="primary">添加</Button>
            </div>
            <Table
                style="margin-top: 10px"
                v-if="formItemShopingScan.content"
                size="small"
                :columns="formItemShopingScan.content.goosTitle"
                :data="formItemShopingScan.content.goosList"
            >
                <template slot-scope="{ row, index }" slot="onState">
                    <div v-if="row.onState == '1'" style="color: green">上架</div>
                    <div v-if="row.onState == '0'" style="color: gray">未上架</div>
                    <div v-if="row.onState == '-1'" style="color: red">删除</div>
                </template>
                <template slot-scope="{ row, index }" slot="opreater">
                    <Button @click="handleDeleteGoodsTableList(row)" size="small" type="error">删除</Button>
                </template>
            </Table>

            <div class="flex justify-between" slot="footer">
                <div class="text-left">
                    <div>备注:{{ formItemShopingScan.Note }}</div>
                    <div class="flex text-xs text-blue-500">
                        <div class="mr-2">操作员:{{ formItemShopingScan.modifier }}</div>
                        <div>修改时间:{{ formItemShopingScan.Time }}</div>
                    </div>
                </div>
                <div>
                    <Button @click="() => confirmShopingScan()" type="primary">保存</Button>
                    <Button @click="hideMeShopingScan">返回</Button>
                </div>
            </div>
        </Modal>
        <Modal :closable="false" :mask-closable="false" width="800" v-model="addToShopingScanTableModal" title="活动商品">
            <div style="display: flex; justify-content: flex-end">
                <Form ref="formItemShopingScan" :model="formItem" inline>
                    <FormItem prop="name" label="">
                        <Input v-model="searchName" style="width: 150px" />
                    </FormItem>
                    <FormItem prop="name" label="">
                        <Button @click="handleGetActivityGoogs(formItemShopingScan._id, searchName)" style="margin-left: 10px">查询</Button>
                    </FormItem>
                </Form>
            </div>

            <Table
                style="margin-top: 10px"
                v-if="formItemShopingScan.content"
                size="small"
                :columns="addToShopingScanTableData.title"
                :data="addToShopingScanTableData.list"
            >
                <template slot-scope="{ row, index }" slot="onState">
                    <div v-if="row.onState == '1'" style="color: green">上架</div>
                    <div v-if="row.onState == '0'" style="color: gray">未上架</div>
                    <div v-if="row.onState == '-1'" style="color: red">删除</div>
                </template>

                <template slot-scope="{ row, index }" slot="opreater">
                    <Button :disabled="cpAddDisable(row)" @click="addToShopingScanTable(row)" size="small" type="primary">添加</Button>
                </template>
            </Table>
            <div slot="footer">
                <div style="text-align: right">
                    <Button @click="hideMeAddToShopingScanTable">返回</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import {
    SetState,
    ActivityCreate,
    Get_holdStores,
    ActivityGetList,
    SelectionGet,
    GetActivityContent,
    addActivityGivlist,
    delActivityGivlist,
    SetActivityContent,
    getCodeGenerat,
    CreateActivity,
    GetPresetValue,
    GetActivitylist,
    ActivityExe,
    GetActivityGoogs,
} from '@/request/api';
import { Checkbox, Page } from 'view-design';
export default {
    name: 'activity_codes',
    data() {
        return {
            types_option: ['每天', '自定义'],
            current_edit_row: {
                content: {
                    getmax: '',
                    type: '',
                    time: '',
                },
            },
            SelectionGet_option: '',
            formItem_edit: {
                givID: '',
                givName: '',
                givType: '票券',
                givNum: '',
                expirationDate: '',
            },
            active_edit_modal: false,
            CardInfo: '',
            holdStores_option: [],
            ruleItem: {
                apply: {
                    required: true,
                    trigger: 'blur',
                },
                num: {
                    required: true,
                    trigger: 'blur',
                },
                time: {
                    required: true,
                    trigger: 'blur',
                },
                name: {
                    required: true,
                    trigger: 'blur',
                },
                start: {
                    required: true,
                    trigger: 'blur',
                    type: 'date',
                },
                end: {
                    required: true,
                    trigger: 'blur',
                    type: 'date',
                },
                type: {
                    required: true,
                    trigger: 'blur',
                },
                freeList: {
                    required: true,
                    trigger: 'blur',
                    type: 'array',
                },
                givNum: {
                    required: true,
                    trigger: 'blur',
                    type: 'number',
                },
                expirationDate: {
                    required: true,
                    trigger: 'blur',
                    type: 'number',
                },
                givID: {
                    required: true,
                    trigger: 'blur',
                },
                givName: {
                    required: true,
                    trigger: 'blur',
                },
            },
            term_option: [
                {
                    name: '大于或等于',
                    value: '$gte',
                },
                {
                    name: '等于',
                    value: '$ne',
                },
            ],
            type_option: [],
            createType_option: ['品牌活动', '门店活动'],
            formItem: {
                name: '',
                start: '',
                end: '',
                type: '扫码有礼',
                freeList: [],
                Note: [],
            },
            activity_create_modal: false,
            editData: {
                name: '',
                freeList: [],
            },
            activeShopingScan: false,
            formItemShopingScan: {
                name: '',
                start: '',
                end: '',
                freeList: [],
            },
            ruleItemShopingItem: {
                name: {
                    required: true,
                    trigger: 'blur',
                },
                start: {
                    required: true,
                    trigger: 'blur',
                },
                end: {
                    required: true,
                    trigger: 'blur',
                },
                freeList: {
                    required: true,
                    trigger: 'blur',
                    type: 'array',
                },
            },
            addToShopingScanTableModal: false,
            addToShopingScanTableData: {
                title: [],
                list: [],
            },
            searchName: '', //活动商品的搜索框
        };
    },
    computed: {
        cpAddDisable() {
            return (row) => {
                //如果activity为true 则禁止用户点击，反之可以点击
                //如果 formItemShopingScan.content.goosList下的列表存在 row._id，那么也是禁止点击，反之可以点击
                let rule = row.activity;
                if (this.formItemShopingScan.content) {
                    let result = this.formItemShopingScan.content.goosList.find((item) => item._id == row._id);
                    rule = rule && result;
                }
                return rule;
            };
        },
    },
    created() {
        this.to_Get_holdStores();
        this.to_ActivityGetList();

        this.handleGetPresetValue();
    },

    methods: {
        /**
         * 获取活动商品
         */
        async handleGetActivityGoogs(activityID, name = '') {
            let passdata = {
                activityID,
                name,
                page: 1,
            };
            let res = await GetActivityGoogs(passdata);
            if (res.errcode != 0) return;
            for (let i = 0; i < res.data.title.length; i++) {
                if (res.data.title[i].key == 'onState') {
                    res.data.title[i]['slot'] = 'onState';
                }
            }
            for (let i = 0; i < res.data.list.length; i++) {
                //如果下面的表格有存在的_id ,那么就状态禁止
                let result = this.formItemShopingScan.content.goosList.find((item) => item._id == res.data.list[i]._id);

                if (result) {
                    res.data.list[i].activity = true;
                }
            }
            res.data.title.push({
                key: 'opreater',
                title: '操作',
                slot: 'opreater',
            });
            this.addToShopingScanTableData = res.data;
        },
        /**
         * 隐藏扫码购物添加表格数据的弹窗
         */
        hideMeAddToShopingScanTable() {
            this.addToShopingScanTableModal = false;
        },
        /**
         * 添加到扫码购物的弹窗表格数据
         */
        addToShopingScanTable(row) {
            //this.formItemShopingScan.content.goosList push进去row数据
            row.activity = !row.activity;
            this.formItemShopingScan.content.goosList.push(row);
        },
        /**
         * 显示扫码购物添加表格数据的弹窗
         */
        showMeAddToShopingScanTable() {
            this.addToShopingScanTableModal = true;
            this.handleGetActivityGoogs(this.formItemShopingScan._id);
        },
        /**
         * 隐藏扫码购物弹窗
         */
        hideMeShopingScan() {
            this.activeShopingScan = false;
        },
        /**
         * 删除扫码扫码购物弹窗表格的单项
         */
        handleDeleteGoodsTableList(row) {
            let tableList = this.formItemShopingScan.content.goosList.filter((item) => item._id != row._id);
            this.formItemShopingScan.content.goosList = tableList;
        },
        /**
         * 获取编辑扫码购物的弹窗数据
         */
        async handleGetShopingScanData() {},
        /**
         * 编辑弹窗弹出
         */
        handleEdit(item) {
            if (item.type == '扫码购物') {
                this.activeShopingScan = true;
                this.handleActivityExe(item);
            }
            if (item.type == '扫码有礼') {
                this.active_edit_modal = true;
                this.to_GetActivityContent(item);
            }
        },
        /**
         * 扫码购物弹窗确认事件
         */
        async confirmShopingScan() {
            let passdata = {
                exetype: '编辑',
                activityData: this.formItemShopingScan,
                activityID: this.formItemShopingScan._id,
                time: this.formItemShopingScan.time,
            };
            let res = await ActivityExe(passdata);
            if (res.errcode != 0) return;
            this.activeShopingScan = false;
            this.to_ActivityGetList(this.CardInfo.page);
        },
        async handleGetPresetValue() {
            let res = await GetPresetValue({
                type: '推广码',
            });
            if (res.errcode != 0) return;
            this.type_option = res.data;
        },
        async to_getCodeGenerat(card) {
            let passdata = {
                exetype: '生成推广码',
                activityID: card.activityID,
            };
            await ActivityExe(passdata);

            this.to_ActivityGetList(this.CardInfo.page);
        },
        /**
         * 开关 或 删除
         */
        async to_SetState(card, type) {
            let passdata = {
                exetype: type,
                activityID: card.activityID,
            };
            await ActivityExe(passdata);

            this.to_ActivityGetList(this.CardInfo.page);
        },
        /**
         * 编辑保存
         */
        async save() {
            let passdata = {
                exetype: '编辑',
                activityData: this.editData,
                activityID: this.editData._id,
            };
            let res = await ActivityExe(passdata);
            if (res.errcode != 0) return;
            this.active_edit_modal = false;
            this.to_ActivityGetList(this.CardInfo.page);
        },
        async to_delActivityGivlist(index) {
            this.editData.content.giv.splice(index, 1);
        },
        async to_addActivityGivlist() {
            this.$refs['formItem_edit'].validate(async (valid) => {
                if (valid) {
                    console.log(this.formItem_edit);
                    this.editData.content.giv.push(JSON.parse(JSON.stringify(this.formItem_edit)));
                    this.$refs['formItem_edit'].resetFields();
                }
            });
        },
        /**
         * 活动操作
         */
        async handleActivityExe(row) {
            const { activityID } = row;
            let res = await ActivityExe({
                exetype: '详情',
                activityID,
            });
            if (res.errcode != 0) return;
            if (typeof res.data.content.givTitle == 'array') {
                res.data.content.givTitle.push({
                    key: 'opreater',
                    title: '操作',
                    slot: 'opreater',
                });
            }
            res.data.start = new Date(res.data.start).Format('yyyy-MM-dd hh:mm:ss');
            res.data.end = new Date(res.data.end).Format('yyyy-MM-dd hh:mm:ss');
            if (row.type == '扫码购物') {
                for (let i = 0; i < res.data.content.goosTitle.length; i++) {
                    if (res.data.content.goosTitle[i].key == 'onState') {
                        res.data.content.goosTitle[i]['slot'] = 'onState';
                    }
                }
                res.data.content.goosTitle.push({
                    key: 'opreater',
                    title: '操作',
                    slot: 'opreater',
                });

                this.formItemShopingScan = res.data;
            }
            if (row.type == '扫码有礼') {
                res.data.content.givTitle.push({
                    key: 'opreater',
                    title: '操作',
                    slot: 'opreater',
                });

                this.editData = res.data;
            }
        },
        async to_GetActivityContent(row) {
            this.handleActivityExe(row);
            this.to_SelectionGet();
        },
        async to_SelectionGet() {
            let res = await GetPresetValue({
                type: '获取赠券',
            });
            if (res.errcode != 0) return;
            this.SelectionGet_option = res.data;
        },
        async to_ActivityGetList(page = 1) {
            let pass_data = {
                gettype: '推广码',
                page,
            };
            let res = await GetActivitylist(pass_data);
            if (res.errcode != 0) return;
            this.CardInfo = res.data;
        },
        resetFieldsHandle(name) {
            this.$nextTick(() => {
                this.$refs[name].resetFields();
            });
        },
        async to_Get_holdStores() {
            let { data: res_data } = await Get_holdStores();
            this.holdStores_option = res_data.hold_list[0];
        },
        async to_ActivityCreate() {
            this.$refs.formItem.validate(async (valid) => {
                if (valid) {
                    let pass_data = JSON.parse(JSON.stringify(this.formItem));
                    if (pass_data.createType == '门店活动') {
                        pass_data.mcode = [localStorage.getItem('mcode')];
                    }
                    pass_data['start'] = new Date(pass_data['start']).getTime();
                    pass_data['end'] = new Date(pass_data['end']).getTime();
                    let res = await CreateActivity(pass_data);
                    if (res.errcode != 0) {
                        return;
                    }
                    this.$refs['formItem'].resetFields();
                    this.activity_create_modal = false;
                    this.to_ActivityGetList();
                }
            });
        },
    },
    components: { Checkbox },
};
</script>
