<template>
	<div>
		<!-- 图片上传/选择组件 -->
		
			<div style="text-align: right;margin: 20px auto;">
				<Upload :headers='headers' :max-size="350" :action="goods_pic_action" 
					:on-success='on_pic_upload_success' :show-upload-list="false">
					<Button>上传图片</Button>
				</Upload>
			</div>
		
			<Tabs @on-click='tab_pic_change' type="card">
				<TabPane label="总部">
					<Row>
						<Col style="text-align: center;margin-top: 20px;" v-for="(item,index) in pic_data.resdata"
							span="6">
						<div @click="do_pick_pic(item)" style="height: 100px;cursor: pointer;">
							<img style="width: 100px;height: 100px;" :src="item.url">
						</div>
						</Col>
					</Row>
				</TabPane>
				<TabPane label="单影院">
					<Row>
						<Col style="text-align: center;margin-top: 20px;" v-for="(item,index) in pic_data.resdata"
							span="6">
						<div @click="do_pick_pic(item)" style="height: 100px;">
							<img style="width: 100px;height: 100px;" :src="item.url">
						</div>
						</Col>
		
					</Row>
				</TabPane>
			</Tabs>
			
			<div style="margin-top: 10px;text-align: right;">
				<Page @on-change="(index)=>this.dogetimglist('',index)" :page-size='8' :total="pic_data.total" />
			</div>
			
	</div>
</template>

<script>
	
	import {getimglist} from '../request/api.js'
	export default{
		name:'UploadImag',
		data(){
			return {
				select_mcode: '001',
				pic_data: {}, //图片数据列表
				headers: {
					'cms_token': localStorage.getItem('cms_token')
				},
				goods_pic_action: '//'+this.$uploadImg+'api/cloudData/upimgfile?mcode=001',
				
			}
		},
		created() {
			
				this.dogetimglist()
				
		},
	
		methods:{
			
			// 子传父参数用户赋值图片
			do_pick_pic(item){
				this.$emit('do_pick_pic',item)
			},
			
			// 上传图片选项卡切换事件
			tab_pic_change(index) {
				
				// 上传总部的图片
				if (index == 0) {
					// goods_pic_action = 
					this.select_mcode = '001'
					this.dogetimglist()
					this.goods_pic_action = '//'+this.$uploadImg+'api/cloudData/upimgfile?mcode=001'
				}
				// 上传单影院的图片
				if (index == 1) {
					this.select_mcode = localStorage.getItem('mcode')
					this.dogetimglist()
					this.goods_pic_action = '//'+this.$uploadImg+'api/cloudData/upimgfile?mcode=' + localStorage.getItem('mcode')
				}
			
			},
			
			// 上传图片成功的回调函数
			on_pic_upload_success() {
				this.dogetimglist(1)
			},
			
			// 获取图片组 
			async dogetimglist(type, page = 1) {
				
				let pass_data = {
					'mcode': this.select_mcode,
					'page': page
				}

				let {data:res} = await getimglist(pass_data);
				
				if (type == 1 && res.resdata) {
					this.$Message.success('上传图片成功')
				}
				this.pic_data = ''
				this.pic_data = res

			},
			
		}
	}
</script>

<style>
</style>
