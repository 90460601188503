<template>
    <div>
        <Form :label-width="120">
            <FormItem label="文案:">
                <Input v-model="orderLink.copywriting" style="width: 300px"></Input>
            </FormItem>
            <FormItem label="是否开启:">
                <i-Switch v-model="orderLink.isItEnabled"></i-Switch>
            </FormItem>
            <FormItem label="广告图片:">
                <div>
                    <div style="display: flex; align-items: center">
                        <img alt="无数据" style="width: 100px; height: 100px; margin-right: 10px" :src="orderLink.descUrl" />
                        <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="($) => (orderLink.descUrl = $)" />
                    </div>
                    <!-- <div>
                        <Input :disabled="!touxiang" style="width: 300px; margin: 10px" v-model="item.stores.address"></Input>
                        <Checkbox v-model="touxiang">自定义</Checkbox>
                   </div>-->
                </div>
            </FormItem>
            <FormItem label="按钮类型:" prop="">
                <Select v-model="orderLink.buttonType" style="width: 264px">
                    <Option value="NotDispButt">静默</Option>
                    <Option value="popWindow">弹窗</Option>
                </Select>
            </FormItem>
            <FormItem label="按钮文字:">
                <Input v-model="orderLink.buttonText" style="width: 300px"></Input>
            </FormItem>
            <FormItem v-if="orderLink.buttonType != 'NotDispButt'" label="内容路径:">
                <div>
                    <div style="display: flex; align-items: center">
                        <img alt="无数据" style="width: 100px; height: 100px; margin-right: 10px" :src="orderLink[orderLink.buttonType].url" />
                        <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="($) => (orderLink[orderLink.buttonType].url = $)" />
                    </div>
                    <!-- <div>
                        <Input :disabled="!touxiang" style="width: 300px; margin: 10px" v-model="item.stores.address"></Input>
                        <Checkbox v-model="touxiang">自定义</Checkbox>
                   </div>-->
                </div>
            </FormItem>

            <FormItem v-if="orderLink.buttonType != 'NotDispButt'" label="内容描述:">
                <Input v-model="orderLink[orderLink.buttonType].describe" style="width: 300px"></Input>
            </FormItem>

            <FormItem>
                <Button @click="submit" type="primary">保存</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue';
import { OperationOrderLink, getupTK } from '@/request/api.js';
import { Message } from 'view-design';

export default {
    name: '',
    components: {
        qiniuUploadBtn,
    },
    data() {
        return {
            orderLink: '',
            qiniu_config: {}, //表单状态数据
        };
    },
    mounted() {
        this.to_getupTK();
        this.getOperationOrderLinkData();
    },
    methods: {
        async submit() {
            let passdata = this.orderLink;
            let res = await OperationOrderLink(passdata);
            if (res.errcode != 0) return;
            Message.success('保存成功');
        },
        // 获取七牛云token
        async to_getupTK() {
            let { data: res_data } = await getupTK();
            this.qiniu_config = res_data.data;
        },
        /**
         * 获取订单外链配置数据
         */
        async getOperationOrderLinkData() {
            let res = await OperationOrderLink({});
            if (res.errcode != 0) return;
            this.orderLink = res.data;
        },
    },
};
</script>
