<template>
    <div>
        <div>
            <div class="text-right">
                <Form inline>
                    <!-- <FormItem>
                        <Select v-model="currentMemberCardQuery" style="width: 150px; margin-right: 10px; text-align: left">
                            <Option v-for="item in memberCardQueryConfig" :value="item">{{ item }}</Option>
                        </Select>
                    </FormItem> -->
                    <FormItem>
                        <Input v-model="number" placeholder="卡号/物理卡号/手机号" style="width: 280px"></Input>
                    </FormItem>
                    <FormItem>
                        <Button @click="() => to_handleMemberCardQuery()" style="margin: 0 10px" type="primary">查询</Button>
                    </FormItem>
                    <FormItem>
                        <Button @click="() => table_dowload_card()">导出会员卡记录</Button>
                    </FormItem>
                </Form>
            </div>
            <div class="mt-5">
                <Table :columns="MemberCardQueryData.title" :data="MemberCardQueryData.list">
                    <template slot-scope="{ row }" slot="operation">
                        <Dropdown @on-click="(value) => dropDownMenuSelect(row, value)" trigger="click" transfer>
                            <Button size="small" type="primary">
                                操作
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem name="修改资料">修改资料</DropdownItem>
                                <DropdownItem name="消费记录">消费记录</DropdownItem>
                                <DropdownItem name="历史消费" v-if="row.ID != 0">历史消费</DropdownItem>
                                <DropdownItem name="冻结" v-if="row.status != '冻结'">冻结</DropdownItem>
                                <DropdownItem name="解冻" v-if="row.status != '正常'">解冻</DropdownItem>
                                <DropdownItem name="复制用户串码">复制用户串码</DropdownItem>
                                <DropdownItem name="操作记录">操作记录</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
                <Card>
                    <div style="margin-top: 10px; text-align: right">
                        <Page
                            :total="MemberCardQueryData.total"
                            :page-size="MemberCardQueryData.size"
                            @on-change="(value) => to_handleMemberCardQuery(value)"
                        ></Page>
                    </div>
                </Card>
            </div>
        </div>
        <Modal :maskClosable="false" v-model="modalEditData" title="编辑会员卡资料" @on-ok="modalEditDataOk" @on-cancel="modalEditDataCancel">
            <Form :label-width="80" ref="formEditData" :model="formEditData" :rules="tableRowData.ID != 0 ? rulesformEditData2 : rulesformEditData">
                <FormItem label="卡号" prop="cardNum">
                    <div class="w-40">
                        <Input disabled v-model="formEditData.cardNum" />
                    </div>
                </FormItem>
                <FormItem label="等级" prop="levelCode" class="relative">
                    <Select :disabled="tableRowData.ID != 0" v-model="formEditData.levelCode" style="width: 150px; margin-right: 10px; text-align: left">
                        <Option v-for="item in CpMemLv" :value="item.value">{{ item.label }}</Option>
                    </Select>
                    <div v-if="tableRowData.ID != 0" class="absolute top-0 text-red-500 right-10">外部系统会员卡暂不支持等级</div>
                </FormItem>
                <FormItem label="绑定号码" prop="phoneNum">
                    <div class="w-40">
                        <Input v-model="formEditData.phoneNum" />
                    </div>
                </FormItem>
                <FormItem label="姓名" prop="name">
                    <div class="w-40">
                        <Input v-model="formEditData.name" />
                    </div>
                </FormItem>
                <FormItem label="身份证" prop="creditNum">
                    <div class="w-40">
                        <Input v-model="formEditData.creditNum" />
                    </div>
                </FormItem>
            </Form>
        </Modal>
        <Modal width="1000" v-model="opreateRecordModal" :mask-closable="false" :closable="false">
            <div class="mb-2 text-right">
                <Button @click="() => exportOpreateRecord()">导出全部数据</Button>
            </div>
            <Table :columns="opreateRecordTableData.title" :data="opreateRecordTableData.list"></Table>
            <Card class="text-right">
                <Page
                    @on-change="(value) => fetchTableDataOpreateRecord(value)"
                    :page-size="opreateRecordTableData.size"
                    :total="opreateRecordTableData.total"
                ></Page>
            </Card>
            <div slot="footer">
                <Button @click="() => (opreateRecordModal = false)">返回</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { download } from '@/utils/table_download.js';
import {
    GetPresetValue,
    memberCardQuery,
    memberCardConsumeQuery,
    handleMemberCardQuery,
    GetMemCardList,
    MemCardInfo,
    memberCardPayData,
} from '@/request/api.js';
export default {
    name: 'navbar',
    data() {
        return {
            tableRowData: '',
            rulesformEditData: {
                cardNum: { required: true, trigger: 'blur' },
                levelName: { required: true, trigger: 'blur' },
                phoneNum: { required: true, trigger: 'blur' },
                name: { required: true, trigger: 'blur' },
            },
            rulesformEditData2: {
                cardNum: { required: true, trigger: 'blur' },
                levelName: { required: true, trigger: 'blur' },
            },
            formEditData: {
                cardNum: '',
                levelName: '',
                phoneNum: '',
                name: '',
                creditNum: '',
            },
            modalEditData: false,
            currentMemberCardQuery: '全部',
            memberCardQueryConfig: ['全部', '卡号', '物理卡号', '绑定号码'],
            number: '',
            current_row: '', //当前点击查看消费记录的表格行
            memberCardQuery_obj: '', //会员卡数据
            memberCardConsumeQuery_obj: '', //消费记录表格数据
            MemberCardQueryData: '',
            opreateRecordModal: false,
            opreateRecordTableData: '',
            memLv: [], //会员等级配置 !=0 cx
        };
    },
    computed: {
        CpMemLv() {
            let data = this.memLv.filter((item, index) => (this.tableRowData.ID != 0 ? item._id == '晨星' : item._id != '晨星'));
            if (data.length > 0) {
                return data[0].options || [];
            } else {
                return [];
            }
        },
    },
    mounted() {
        this.to_handleMemberCardQuery();
        this.getMemLv();
    },
    methods: {
        /**
         * 会员等级配置获取
         */
        async getMemLv() {
            let res = await GetPresetValue({ type: '充值策略' });
            if (res.errcode) return;
            this.memLv = res.data.memLv;
        },
        /**
         * 导出操作记录
         */
        async exportOpreateRecord() {
            const { CardID } = this.tableRowData;
            let res = await memberCardPayData({
                CardID,
                gettype: '操作日志',
                all: true,
            });
            if (res.errcode != 0) return;
            download({
                worksheet: '操作记录',
                headers: res.data.title,
                data: res.data.list,
            });
        },
        /**
         * 操作记录获取 在弹窗中
         */
        async fetchTableDataOpreateRecord(page = 1) {
            const { CardID } = this.tableRowData;
            let res = await memberCardPayData({
                CardID,
                gettype: '操作日志',
                page,
                all: false,
            });
            if (res.errcode != 0) return;
            this.opreateRecordTableData = res.data;
        },
        async showModalEditData() {
            const { CardID } = this.tableRowData;
            let passdata = {
                type: '获取',
                CardID,
            };
            let res = await MemCardInfo(passdata);
            if (res.errcode != 0) return;
            this.tableRowData = res.data;
            this.formEditData = res.data;
            this.modalEditData = true;
        },
        async handleSubmit(name) {
            let valid = await this.$refs[name].validate();
            return valid ? true : false;
        },
        /**
         * 弹窗修改数据确认按钮
         */
        async modalEditDataOk() {
            let valid = await this.handleSubmit('formEditData');
            if (valid) {
                const { _id, phoneNum, name, creditNum, levelCode } = this.formEditData;
                let passdata = {
                    type: '编辑',
                    CardID: _id,
                    MobileNum: phoneNum,
                    UserName: name,
                    CreditNum: creditNum,
                    levelCode,
                };
                let res = await MemCardInfo(passdata);
                if (res.errcode != 0) return;
                this.modalEditData = false;
                this.$Message.success('修改成功');
                this.to_handleMemberCardQuery();
            }
        },
        /**
         * 冻结/解冻操作
         */
        async frezz(state) {
            const { CardID } = this.tableRowData;
            let type = state ? '冻结' : '正常';
            let passdata = {
                type,
                CardID,
            };
            let res = await MemCardInfo(passdata);
            if (res.errcode != 0) return;
            this.modalEditData = false;
            this.$Message.success((state ? '冻结' : '解冻') + '成功');
            this.to_handleMemberCardQuery();
        },
        /**
         * 弹窗修改数据取消按钮
         */
        modalEditDataCancel() {
            this.modalEditData = false;
        },
        /**
         * 操作下拉选择框选中
         */
        dropDownMenuSelect(row, value) {
            const { openid, CardID } = row;
            this.tableRowData = row;
            switch (value) {
                case '修改资料':
                    this.showModalEditData();
                    break;
                case '消费记录':
                    window.open('/costRecord?CardID=' + CardID);
                    break;
                case '历史消费':
                    window.open('/historyOrder?CardID=' + CardID);
                    break;
                case '冻结':
                    this.frezz(true);
                    break;
                case '解冻':
                    this.frezz(false);
                    break;
                case '复制用户串码':
                    this.Mclip(openid);
                    break;
                case '操作记录':
                    this.opreateRecordModal = true;
                    this.fetchTableDataOpreateRecord();
                    break;
            }
        },
        async table_dowload_card() {
            let pass_data = {
                querytype: this.currentMemberCardQuery, //['全部','cid','模糊搜索'] 三选一
                content: this.number, //搜索内容 类型未全部可以不填写
                page: 1, //当前页码
                all: true, //是否获取全部数据
            };
            let res = await GetMemCardList(pass_data);
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '会员卡查询',
                headers,
                data,
            });
        },
        async to_handleMemberCardQuery(page = 1) {
            let querytype = this.currentMemberCardQuery;
            let number = this.number;
            let pass_data = {
                querytype: querytype,
                content: number, //搜索内容 类型未全部可以不填写
                page: page, //当前页码
                all: false, //是否获取全部数据
            };
            let { data: res } = await GetMemCardList(pass_data);
            res.title.push({
                key: 'operation',
                title: '更多',
                slot: 'operation',
            });
            this.MemberCardQueryData = res;
        },
        // 导出消费记录
        async table_dowload(row) {
            let pass_data = {
                _id: row._id,
                page: 1,
                all: 1,
            };
            let { data: res_data } = await memberCardConsumeQuery(pass_data);
            let headers = res_data.title;
            let data = res_data.list;
            download({
                worksheet: row.orderid + '',
                headers,
                data,
            });
        },
        // 会员卡消费查询  查询消费记录
        async to_memberCardConsumeQuery(row, page = 1) {
            let pass_data = {
                _id: row._id,
                page: page,
            };
            let { data: res_data } = await memberCardConsumeQuery(pass_data);
            this.memberCardConsumeQuery_obj = res_data;
        },
    },
};
</script>
