<template>
    <div>
        <div style="text-align: right">
            <Form inline>
                <FormItem>
                    <Input placeholder="请输入票券名称" v-model="formInline.name" />
                </FormItem>
                <FormItem>
                    <Input placeholder="请输入批次号" v-model="formInline.apply" />
                </FormItem>
                <FormItem>
                    <Button @click="() => fetchData()" type="primary">查询</Button>
                </FormItem>
                <FormItem>
                    <Button @click="() => handleOpreate('add')" type="primary">创建</Button>
                </FormItem>
            </Form>
        </div>
        <div>
            <Table :columns="tableData.title" :data="tableData.list">
            
                <template slot-scope="{ row, index }" slot="operate">
                    <div class="flex">
                        <Button @click="() => handleOpreate('view', row)" size="small">查看</Button>
                        <div class="mr-2"></div>
                        <Button type="primary" @click="() => handleOpreate('edit', row)" size="small">编辑</Button>
                    </div>
                </template>

            </Table>
        </div>
        <Card style="text-align: right">
            <Page :pageSize="tableData.size" :total="tableData.total" @on-change="(value) => fetchData(value)"></Page>
        </Card>
        <Modal v-model="show" :closable="false" :maskClosable="false" :title="showTypeDirectory[showType]" width="1200">
            <Row>
                <Col span="8">
                    <Form :disabled="showType == 'view'" :label-width="100" ref="form" :model="form">
                        <FormItem prop="apply" label="规则编号">
                            <Input disabled v-model="form.apply" />
                        </FormItem>
                        <FormItem prop="name" label="规则名称">
                            <Input v-model="form.name" />
                        </FormItem>
                        <FormItem prop="holiday" label=" ">
                            <Checkbox v-model="form.holiday">节假日</Checkbox>
                        </FormItem>
                        <FormItem prop="freeList" label="适用门店">
                            <Select multiple v-model="form.freeList">
                                <Option v-for="(item, index) in freeList" :value="item.value" :key="index">{{ item.label }} </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="dayLabel" label="适用星期">
                            <Select placeholder="全部适用" multiple v-model="form.dayLabel">
                                <Option v-for="(item, index) in daylabeDictionry" :value="item.value" :key="index">{{ item.label }} </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="screenLabel" label="适用影厅">
                            <Select placeholder="全部适用" multiple v-model="form.screenLabel">
                                <Option v-for="(item, index) in screenLabel" :value="item.value" :key="index">{{ item.label }} </Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="dateLabel" label="禁用日期">
                            <Row v-if="form && form.dateLabel && form.dateLabel.length > 0">
                                <Col span="18">
                                    <DatePicker
                                        @on-change="(value) => changeDisDate(value, index)"
                                        format="yyyy-MM-dd"
                                        v-for="(item, index) in form.dateLabel"
                                        :key="index"
                                        type="date"
                                        v-model="form.dateLabel[index]"
                                    >
                                    </DatePicker>
                                </Col>
                                <Col class="text-right" span="6">
                                    <div v-for="(item, index) in form.dateLabel" :key="index">
                                        <Button v-if="index == 0" @click="() => addDateLabel()" type="primary">+</Button>
                                        <Button v-if="index != 0" @click="() => removeDateLabel(index)" type="error">-</Button>
                                    </div>
                                </Col>
                            </Row>
                        </FormItem>
                        <FormItem prop="time" label="时段范围">
                            <Row>
                                <Col span="18">
                                    <Select @on-change="(value) => timeRangChange(value)" transfer v-model="timeSelectValue">
                                        <Option v-for="item in timeSelectList" :value="item.value" :key="item.value">
                                            {{ item.label }}
                                        </Option>
                                    </Select>
                                </Col>
                                <Col :span="6" class="text-right">
                                    <Button v-if="timeSelectValue == '自定义'" @click="() => timeRangePush()" type="primary">+</Button>
                                </Col>
                            </Row>
                        </FormItem>
                        <template v-for="(item, index) in form.time">
                            <div v-if="timeSelectValue == '自定义'" :key="index" style="display: flex">
                                <div style="width: 100px; text-align: right; padding-right: 12px; display: flex; justify-content: center; align-items: center">
                                    <div>范围</div>
                                </div>
                                <div style="display: flex">
                                    <FormItem :label-width="0" label="" prop="startDate">
                                        <TimePicker
                                            :value="form.time[index].start"
                                            @on-change="(value) => (form.time[index].start = value)"
                                            transfer
                                            type="time"
                                            placeholder="开始时间"
                                            style="width: 120px"
                                        ></TimePicker>
                                    </FormItem>
                                    <FormItem style="margin-left: 10px" :label-width="0" label="" prop="endDate">
                                        <TimePicker
                                            :value="form.time[index].end"
                                            @on-change="(value) => (form.time[index].end = value)"
                                            transfer
                                            type="time"
                                            placeholder="结束时间"
                                            style="width: 120px"
                                        ></TimePicker>
                                    </FormItem>
                                </div>
                                <Button
                                    @click="() => timeRangeRemove(index)"
                                    v-if="timeSelectValue == '自定义'"
                                    style="margin-left: 10px"
                                    type="error"
                                    icon="md-remove"
                                ></Button>
                            </div>
                        </template>

                        <FormItem prop="type" label="规则类型">
                            <Input disabled v-model="form.type" />
                        </FormItem>
                        <FormItem v-if="form && form.synCinameName" prop="synCinameName" label="同步影院">
                            <Input disabled v-model="form.synCinameName" />
                        </FormItem>
                        <FormItem prop="synTime" label="最后修改">
                            <Input disabled v-model="form.synTime" />
                        </FormItem>
                        <FormItem prop="incomeCinameName" label="入账影院">
                            <Input disabled v-model="form.incomeCinameName" />
                        </FormItem>
                    </Form>
                </Col>
                <Col class="pl-2" span="16">
                    <div class="flex justify-between pb-5">
                        <div class="font-bold">适用规则</div>
                        <Button :disabled="showType == 'view'" @click="() => (showAddRuleModal = true)" size="small" type="primary">添加规则</Button>
                    </div>
                    <Table size="small" :columns="tableDataTitle" :data="form.data">
                        <template slot-scope="{ row, index }" slot="containSaleItemType">
                            <div v-if="row.containSaleItemType == 0">影票</div>
                            <div v-if="row.containSaleItemType == 1">卖品</div>
                            <div v-if="row.containSaleItemType == 2">充值券</div>
                        </template>

                        <template slot-scope="{ row, index }" slot="totalAddPrice">
                            <p v-if="row.prohibit">禁止兑换</p>
                            <p v-else>{{ row.totalAddPrice}}</p>
                        </template>

                        <template slot-scope="{ row, index }" slot="extraPrice">
                            <p v-if="row.prohibit">禁止兑换</p>
                            <p v-else>{{ row.extraPrice}}</p>
                        </template>

                        <template slot-scope="{ row, index }" slot="screenLabel">
                            <div v-if="row.screenLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip trigger="hover" :content="row.screenLabel.length > 0 ? row.screenLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="dateLabel">
                            <div v-if="row.dateLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip trigger="hover" :content="row.dateLabel.length > 0 ? row.dateLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="dayLabel">
                            <div v-if="row.dayLabel == null">默认</div>
                            <div v-if="row.screenLabel != null">
                                <Poptip trigger="hover" :content="row.dayLabel.length > 0 ? row.dayLabel.join('|') : '默认'">
                                    <Button size="small">查看</Button>
                                </Poptip>
                            </div>
                        </template>
                        <template slot-scope="{ row, index }" slot="opreate">
                            <Button @click="() => removeTableRule(index)" size="small" type="error">删除</Button>
                        </template>
                    </Table>
                </Col>
            </Row>

            <div class="flex justify-end" slot="footer">
                <Button @click="() => hiddenMe()" class="mr-2">取消</Button>
                <Button :disabled="showType == 'view'" @click="() => submit()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal v-model="showAddRuleModal" :maskClosable="false" :closable="false">
            <Form :label-width="100" class="w-96" ref="ruleTableForm" :model="ruleTableForm" :rules="ruleTableFormValidate">
                <FormItem prop="priority" label="优先级">
                    <Input placeholder="输入的范围必须在1-99" type="number" v-model.number="ruleTableForm.priority" />
                </FormItem>
                <FormItem prop="containSaleItemType" label="兑换类型">
                    <Select @on-change="(value) => changeSelectType(value)" v-model="ruleTableForm.containSaleItemType">
                        <Option value="影票">影票</Option>
                        <Option value="卖品">卖品</Option>
                    </Select>
                </FormItem>
                <FormItem prop="dayLabel" label="适用星期">
                    <Row>
                        <Col span="16">
                            <Select :disabled="ruleTableFormConfig.dayLabel" placeholder="默认" multiple v-model="ruleTableForm.dayLabel">
                                <Option v-for="(item, index) in daylabeDictionry" :value="item.value" :key="index">{{ item.label }} </Option>
                            </Select>
                        </Col>
                        <Col class="text-right" span="8">
                            <Checkbox v-model="ruleTableFormConfig.dayLabel">默认</Checkbox>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem prop="screenLabel" label="适用影厅">
                    <Row>
                        <Col span="16">
                            <Select :disabled="ruleTableFormConfig.screenLabel" placeholder="默认" multiple v-model="ruleTableForm.screenLabel">
                                <Option v-for="(item, index) in screenLabel" :value="item.value" :key="index">{{ item.label }} </Option>
                            </Select>
                        </Col>
                        <Col class="text-right" span="8">
                            <Checkbox v-model="ruleTableFormConfig.screenLabel">默认</Checkbox>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem prop="dateLabel" label="禁用日期">
                    <Row>
                        <Col span="12">
                            <DatePicker
                                :disabled="ruleTableFormConfig.dateLabel"
                                @on-change="(value) => (ruleTableForm.dateLabel[index] = value)"
                                format="yyyy-MM-dd"
                                v-for="(item, index) in ruleTableForm.dateLabel"
                                :key="index"
                                type="date"
                                :value="ruleTableForm.dateLabel[index]"
                            >
                            </DatePicker>
                        </Col>
                        <Col class="text-right" span="6">
                            <div v-for="(item, index) in ruleTableForm.dateLabel" :key="index">
                                <Button :disabled="ruleTableFormConfig.dateLabel" v-if="index == 0" @click="() => addRuleDateLabel()" type="primary">+</Button>
                                <Button v-if="index != 0" @click="() => removeRuleDateLabel(index)" type="error">-</Button>
                            </div>
                        </Col>
                        <Col class="text-right" span="6">
                            <Checkbox v-model="ruleTableFormConfig.dateLabel">默认</Checkbox>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem prop="ruleMerName" label="兑换商品">
                    <Row>
                        <Col span="16">
                            <Select :disabled="ruleTableFormConfig.ruleMerName" v-model="ruleTableForm.ruleMerKey" filterable placeholder="不限"
                            :remote-method="remoteMethod1"
                            :loading="loading1"
                            >
                                <Option v-for="(item, index) in duihuanDictionary" :value="item.value" :key="index"> {{ item.label }}</Option>
                            </Select>
                        </Col>
                        <Col class="text-right" span="8">
                            <Checkbox v-model="ruleTableFormConfig.ruleMerName">不限</Checkbox>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem prop="totalAddPrice" label="优惠金额">
                    <Row>
                        <Col span="16">
                            <Input type="number" v-model.number="ruleTableForm.totalAddPrice" :disabled="ruleTableForm.prohibit"  :placeholder="ruleTableForm.prohibit?'禁止兑换':''" />
                        </Col>
                        <Col class="text-right" span="8">
                            <Checkbox v-model="ruleTableForm.prohibit" :disabled="prohibitDisabled"  @on-change="(value) => changeProhibit(value)" >禁兑</Checkbox>
                        </Col>
                    </Row>
                   
                </FormItem>
                <FormItem prop="extraPrice" label="优惠后加价">
                    <Input :placeholder="ruleTableForm.prohibit?'禁止兑换':'输入范围必须在0-999'" type="number" v-model.number="ruleTableForm.extraPrice" :disabled="ruleTableForm.prohibit" />
                </FormItem>
            </Form>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => cancelAddRule()">取消</Button>
                <div class="mr-2"></div>
                <Button @click="() => addtoTableRule()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetCouponRule, GetPresetValue, SeatInfoUpDate, GetFilmList, GetMerData, EditCouponRuleDetail, CreateCouponRule } from '@/request/api';
export default {
    name: 'couponRule',
    data() {
        return {
            showAddRuleModal: false,
            formInline: {
                name: '',
                apply: '',
            },
            tableData: '',
            daylabeDictionry: [],
            freeList: [],
            screenLabel: [],
            show: false,
            timeSelectValue: '全时段范围',
            timeSelectList: [
                {
                    value: '全时段范围',
                    label: '全时段范围',
                },
                {
                    value: '自定义',
                    label: '自定义',
                },
            ],
            form: {
                holiday: true,
                data: [],
                dateLabel: [''],
                time: [],
            },
            showType: '',
            showTypeDirectory: {
                view: '查看',
                edit: '编辑',
                add: '创建',
            },
            tableDataTitle: [
                {
                    key: 'containSaleItemType',
                    title: '兑换类型',
                    slot: 'containSaleItemType',
                    fixed: 'left',
                    width: '100',
                },
                {
                    key: 'ruleMerName',
                    title: '商品名称',
                    fixed: 'left',
                    width: '200',
                },
                {
                    key: 'priority',
                    title: '优先级',
                    width: '100',
                },
                {
                    key: 'totalAddPrice',
                    title: '优惠价',
                    width: '100',
                    slot: 'totalAddPrice',
                },
                {
                    key: 'extraPrice',
                    title: '加价',
                    width: '100',
                    slot: 'extraPrice',
                },

                {
                    key: 'dayLabel',
                    title: '适用星期',
                    width: '100',
                    slot: 'dayLabel',
                },
                {
                    key: 'dateLabel',
                    title: '禁用日期',
                    width: '100',
                    slot: 'dateLabel',
                },
                {
                    key: 'screenLabel',
                    title: '适用影厅',
                    width: '100',
                    slot: 'screenLabel',
                },
                {
                    key: 'opreate',
                    title: '操作',
                    slot: 'opreate',
                    width: '100',
                },
            ],
            duihuanDictionary: [],
            ruleTableForm: {
                priority: '',
                containSaleItemType: '',
                dayLabel: '',
                screenLabel: '',
                dateLabel: [''],
                ruleMerName: '',
                totalAddPrice: '',
                extraPrice: '',
            },
            ruleTableFormConfig: {
                dayLabel: true,
                screenLabel: true,
                dateLabel: true,
                ruleMerName: true,
            },
            ruleTableFormValidate: {
                priority: [
                    { required: true, message: ' ', type: 'number' },
                    { min: 1, max: 99, message: ' ', type: 'number' },
                ],
                containSaleItemType: { required: true, message: ' ' },
                totalAddPrice: { required: true, message: ' ', type: 'number' },
                extraPrice: [
                    { required: true, message: ' ', trigger: 'blur', type: 'number' },
                    { min: 0, max: 999, type: 'number', message: ' ' },
                ],
            },
            loading1:false,
        };
    },
    created() {
        this.init();
    },
    //计算属性
    computed: {
        /**禁止兑换可选 */
        prohibitDisabled(){
            if(this.ruleTableForm.containSaleItemType==="影票" && this.ruleTableFormConfig.ruleMerName===false){
                return false;
            }else{
                this.ruleTableForm.prohibit=false;
                this.changeProhibit(this.ruleTableForm.prohibit)
                return true;
            }
            
        }
    },
    methods: {
        /**
         * 弹窗日期选择框 被改变
         */
        changeDisDate(value, index) {
            this.form.dateLabel[index] = value;
        },
        /**
         * 时段范围切换
         */
        timeRangChange(value) {
            if (value == '自定义') {
                this.form.memberPrice = '';
            }
        },
        /**
         * 移除时段范围
         */
        timeRangeRemove(value) {
            this.form.time = this.form.time.filter((item, index) => index != value);
        },
        /**
         * 插入时段范围
         */
        timeRangePush() {
            //如果当前的time不存在，初始化一个time字段
            if (!this.form.hasOwnProperty('time')) {
                this.form.time = [];
                this.form = JSON.parse(JSON.stringify(this.form));
            }
            if (this.form.time.length >= 5) {
                this.$Message.warning('数量不能超过5个');
                return;
            }
            this.form.time.push({
                start: '',
                end: '',
            });
        },
        /**
         * 编辑提交
         */
        async submit() {
            if (!this.form.hasOwnProperty('time')) {
                this.form.time = [];
            }
            let passdata = this.form;
            let res;
            if (this.showType == 'edit') {
                res = await EditCouponRuleDetail(passdata);
            }
            if (this.showType == 'add') {
                res = await CreateCouponRule(passdata);
            }
            if (res.errcode != 0) return;
            this.fetchData();
            this.show = false;
            this.$refs['form'].resetFields();
            this.form.data = [];
            this.form.dateLabel = [''];
            this.timeSelectValue = '全时段范围';
        },
        /**
         * 删除表格行 添加规则下的表格
         */
        removeTableRule(index) {
            this.form.data.splice(index, 1);
        },
        cancelAddRule() {
            this.showAddRuleModal = false;
            this.$refs['ruleTableForm'].resetFields();
            this.initRuleModalForm();
        },
        /**
         * 插入到添加规则的按钮下 表格
         */
        addtoTableRule() {
            this.$refs['ruleTableForm'].validate(async (valid) => {
                if (valid) {
                    let passdata = JSON.parse(JSON.stringify(this.ruleTableForm));
                    if (passdata.containSaleItemType == '影票') {
                        passdata.containSaleItemType = '0';
                    }
                    if (passdata.containSaleItemType == '卖品') {
                        passdata.containSaleItemType = '1';
                    }
                    passdata['couponAmount'] = '1';
                    passdata['useGiv'] = null;
                    let CheckboxList = this.ruleTableFormConfig;
                    for (let key in CheckboxList) {
                        if (CheckboxList[key]) {
                            if (key == 'ruleMerName') {
                                passdata[key] = '不限';
                                passdata['ruleMerKey'] = '不限';
                            } else {
                                passdata[key] = null;
                            }
                        } else {
                            if (key == 'ruleMerName') {
                                console.log(this.duihuanDictionary,"============================");
                                this.duihuanDictionary.forEach((item, index) => {
                                    if (this.ruleTableForm.ruleMerKey == item.value) {
                                        passdata['ruleMerKey'] = item.value;
                                        passdata['ruleMerName'] = item.label;
                                    }
                                });
                            }
                        }
                    }

                    this.form.data.push(passdata);
                    console.log(passdata);
                    this.showAddRuleModal = false;
                    this.$refs['ruleTableForm'].resetFields();
                    this.initRuleModalForm();
                }
            });
        },
        /**
         * 初始化添加规则弹窗
         */
        initRuleModalForm() {
            this.ruleTableForm = {
                priority: '',
                containSaleItemType: '',
                dayLabel: '',
                screenLabel: '',
                dateLabel: [''],
                ruleMerName: '',
                totalAddPrice: '',
                extraPrice: '',
            };
            this.ruleTableFormConfig = {
                dayLabel: true,
                screenLabel: true,
                dateLabel: true,
                ruleMerName: true,
            };
        },
        /**
         * 添加规则按钮的 弹窗 增加日期的选择框
         */
        addRuleDateLabel() {
            this.ruleTableForm.dateLabel.push('');
        },
        /**
         * 添加规则按钮的 弹窗 增加加少日期的选择框
         */
        removeRuleDateLabel() {
            this.ruleTableForm.dateLabel.splice(index, 1);
        },
        /**
         * 兑换类型 配置获取 选择框 切换
         */
        async changeSelectType(value) {
            this.ruleTableForm.ruleMerKey="";
            this.duihuanDictionary = [];
            switch (value) {
                case '影票':
                    // let res = await GetFilmList({ count: 50 });
                    // if (res.errcode != 0) return;
                    // let list = [];
                    // res.data.forEach((item, index) => {
                    //     list.push({
                    //         value: item.movieCode,
                    //         label: item.movieName,
                    //     });
                    // });
                    //替换为远程搜索接口
                    this.ruleTableFormConfig.ruleMerName = true;
                    break;
                case '卖品':
                    
                    this.ruleTableFormConfig.ruleMerName = true;
                    let res1 = await GetMerData({
                        gettype: '选项表',
                        onGift: '非赠品',
                        all: true,
                    });
                    if (res1.errcode != 0) return;

                    let list1 = [];
                    res1.data.list.forEach((item, index) => {
                        list1.push({
                            value: item.synMerUid,
                            label: item.name,
                        });
                    });
                    this.duihuanDictionary = list1;
                    break;
            }
        },
        /**
         * 减少日期的选择框
         */
        removeDateLabel(index) {
            this.form.dateLabel.splice(index, 1);
        },
        /**
         * 增加日期的选择框
         */
        addDateLabel() {
            this.form.dateLabel.push('');
        },
        /**
         * 查看/编辑弹窗 隐藏
         */
        hiddenMe() {
            this.show = false;
            this.timeSelectValue = '全时段范围';
            this.form.data = [];
            this.form.dateLabel = [''];
            this.$refs['form'].resetFields();
        },
        /**
         * 操作的 按钮
         */
        async handleOpreate(value, row) {
            switch (value) {
                case 'view':
                    this.getTableRow(row);
                    this.showType = 'view';
                    this.show = true;
                    break;
                case 'edit':
                    this.getTableRow(row);
                    this.showType = 'edit';
                    this.show = true;
                    break;
                case 'add':
                    this.form = {
                        holiday: true,
                        data: [],
                        dateLabel: [''],
                        time: [],
                    };
                    this.showType = 'add';
                    this.show = true;
                    break;
            }
        },
        /**
         * 获取表格行的数据
         */
        async getTableRow(value) {
            const { _id } = value;
            let res = await GetCouponRule({ gettype: '获取详情', id: _id });
            if (res.errcode != 0) return;
            this.form = res.data;
            if (this.form.time && this.form.time.length > 0) {
                this.timeSelectValue = '自定义';
            } else {
                this.timeSelectValue = '全时段范围';
            }
        },
        /**
         * 获取配置 初始化
         */
        async init() {
            this.handleGetPresetValue();
            this.handleSeatInfoUpDate();
        },
        /**
         * screenLabel 配置获取
         */
        async handleSeatInfoUpDate() {
            let res = await SeatInfoUpDate({
                type: '获取列表',
            });
            if (res.errcode != 0) return;
            let listSelect = [];
            res.data.list.forEach((item, index) => {
                listSelect.push({
                    label: item.screenName,
                    value: item.screenCode,
                });
            });
            this.screenLabel = listSelect;
        },
        /**
         * freeList和daylabel 配置获取
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({ type: '票券订单' });
            if (res.errcode != 0) return;
            const { daylabel, freeList } = res.data;
            let day = [];
            for (let i = 0; i < daylabel.length; i++) {
                day.push({
                    label: daylabel[i],
                    value: daylabel[i],
                });
            }
            this.daylabeDictionry = day || [];
            this.freeList = freeList || [];
        },
        /**
         * 获取票券规则
         */
        async fetchData(page = 1) {
            let passData = JSON.parse(JSON.stringify(this.formInline));
            passData['gettype'] = '获取列表';
            passData['page'] = page;
            let res = await GetCouponRule(passData);
            if (res.errcode != 0) return;
            res.data.title.push({
                key: 'operate',
                title: '操作',
                slot: 'operate',
            });
            this.tableData = res.data;
        },
        /**
         * 远程搜索影片
         */
        remoteMethod1(query){
            console.log(this.ruleTableForm.containSaleItemType,"=================")
            if(this.ruleTableForm.containSaleItemType==="影票"){
                if (query !== '' && query.length>=1) {
                    const nameStr = query.replace(/^\s+/, '');
                    this.loading1 = true;
                  	fetch('https://gzxh.ruiziyou.com/conf/film/queryFilmsOption', {
                      method: 'POST', // 指定请求方法
                      headers: {
                        'Content-Type': 'application/json', // 指定内容类型
                      },
                      body: JSON.stringify({"year":"2024","name":nameStr}), // 请求体数据
                    }).then( async (response)=>{
                      const {data}= await response.json()
                      this.loading1 = false;
                      this.duihuanDictionary =  data
                })
            }else{
                this.duihuanDictionary = []
            }
        }},
        /**禁止兑换规则 */
        changeProhibit(value){
            console.log(value,"====================changeProhibit")
            if(value){
                this.ruleTableForm.totalAddPrice=999
                this.ruleTableForm.extraPrice=999
            }else{
                this.ruleTableForm.totalAddPrice=""
                this.ruleTableForm.extraPrice=""
            }

        }
    },
};
</script>
