<template>
	<div>
		<div style="text-align: right;">
			<Button @click="( )=>to_data_GetseatingPlan()" type='primary'>数据同步</Button>
			<Button @click="(is_show_cats_eye_for=true)" style="margin-left: 10px;">猫眼获取</Button>
		</div>
		<!-- 表格 -->
		<div style="margin-top: 10px;">
			<Table :data='list' :columns='title'>
				<template slot-scope='{row}' slot='operation'>
					<Button @click="()=>view(row)" type='primary' size='small'>查看</Button>
					<Button style="margin-left: 10px;" @click="()=>(currentCell=row)&&(is_show_modify_rendering_type=true)&&(select_model=currentCell.type)" type='primary' size='small'>渲染模式</Button>
				</template>
			</Table>
		</div>
		<!-- 渲染模式 对话框 -->
		<Modal :closable='false' :mask-closable='false' v-model='is_show_modify_rendering_type'>
			<Form :label-width="80">
				<FormItem label="渲染模式">
					<Select style="width: 300px;" v-model='select_model'>
						<Option :value='key' v-for="(item,key) in select_model_List">{{item}}</Option>
					</Select>
				</FormItem>
			</Form>	
			<div slot='footer'>
				<Button @click="()=>is_show_modify_rendering_type=false">返回</Button>
				<Button @click="()=>to_SetseatPlantype()" type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 查看 对话框 -->
		<Modal :closable='false' fullscreen  v-model='modal_is_show_seat_management'>
			<div v-if="seat_info">
				<div style="position: relative;width: 80vw;height: 80vh;">
					<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
						<seatingplan @changeview="view" :state='state' :seat_info='seat_info' :modal_is_show_seat_management='modal_is_show_seat_management' />
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: left;width:1000px;justify-content: space-between;">
					<div>
						<Button :type="state=='正常'?'primary':'default'" @click="()=>standard_for_guest('正常')">标记正常</Button>
						<Button :type="state=='坏座'?'primary':'default'" @click="()=>standard_for_guest('坏座')">标记坏座</Button>
						<Button :type="state=='真实坏座'?'primary':'default'" @click="()=>standard_for_guest('真实坏座')">真实坏座</Button>
					</div>
					<Button @click="()=>submit_guest()" type="primary" style="margin-left: 10px;" >提交保存</Button>
				</div>
				{{abc}}
			</div>
			<div slot='footer'>
				<Button @click="()=>modal_is_show_seat_management=false">返回</Button>
			</div>
		</Modal>
		<!-- 猫眼获取 对话框 -->
		<Modal :closable='false' :mask-closable='false' v-model='is_show_cats_eye_for'>
			<Form :label-width="80">
				<FormItem label="场次编号">
					<Input v-model='event_id' style="width: 300px;"></Input>
				</FormItem>
			</Form>	
			<div slot='footer'>
				<Button @click="()=>is_show_cats_eye_for=false">返回</Button>
				<Button @click="()=>to_MaoyanseatPlan()"  type='primary'>确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	let arr =[];
	import seatingplan from '@/components/seating_plan.vue'
	import {ForceSetseat,SetseatPlantype,GetseatPlan,MaoyanseatPlan,GetseatPlanList,data_GetseatingPlan} from '@/request/api.js'
	export default{
		name:'navbar',
		components:{
			seatingplan
		},
		data() {
			
			return{
				abc:[],
				select_model_List: {
					'A': '模式A全开',
					'B': '模式B同步',
					'C': '模式C反锁',
					'D': '模式全部锁定'
				},
				select_model:'',//选择框 下拉菜单选择 
				is_show_modify_rendering_type:false,//对话框 渲染类型
				currentCell: {},
				state:'',
				seat_info: {},
				modal_is_show_seat_management:false,//对话框 查看座位图
				event_id:'',//场次编号 输入框
				is_show_cats_eye_for:false,//对话框 猫眼获取
				list:[],
				title:[]
			}
		},
		watch:{
			// 监听 查看 座位图
			async modal_is_show_seat_management(val){
				if(val){
					let pass_data = {
						'uid':this.currentCell.uid
					}
					let {data:res_data} = await GetseatPlan(pass_data)
					this.seat_info = res_data
					this.flash()
				}else{
					arr=[]
				}
			},
		},
		mounted() {
			this.flash_table()	
		},
		methods:{
			view(row=this.currentCell){
				this.currentCell=row
				this.modal_is_show_seat_management=true
				this.abc=[]
				this.state=''
			},
			// 设置座位图渲染模式 
			async to_SetseatPlantype(){
				let pass_data = {
					'uid':this.currentCell.uid,
					'type':this.select_model
				}
				let res_data = await SetseatPlantype(pass_data)
				if(res_data.code==200){
					this.$mytoast(res_data.msg)
					this.is_show_modify_rendering_type=false
					this.flash_table()
					this.select_model=''
				}
			},
			// 提交留座
			async submit_guest(){
				if(!this.state) return
				if(!this.abc.length) return
				let pass_data={
					"uid": this.currentCell.uid, //影厅uid
					"type": this.state, //['正常','坏座','真实坏座']选择一个 真实坏座在星影惠上无论什么渲染模式都会渲染坏座位
					"list": this.abc
					}
					let res =  await ForceSetseat(pass_data)
					if(res.code!=200) return
					this.modal_is_show_seat_management=false
					setTimeout(()=> {
						this.view()
					}, 1000);
			},
			// 标为留座
			standard_for_guest(value){
				this.abc=[]
				if(this.state==value){
					this.state=""
					return
				}
				this.state=value
			},
			// 刷新座位图
			flash(abc) {
				var canvas = document.getElementById('myCanvas');
				let ctx = canvas.getContext('2d');
			
				// 座位方法
				function rect(x, y, w, h, color, isfill, seat) {
					this.x = x
					this.y = y
					this.w = w
					this.h = h
					this.isfill = isfill
					this.seat = seat
					this.color = color ? color : 'black'
				}
				// 方法 画空心 和实心动画
				rect.prototype.render = function(abc) {
					
					switch (this.seat.seatStatus){
						case 1:
						this.isfill=false
						this.color = '#ccc'
							break;
						case 2:
						this.isfill=true
						this.color = 'red'
							break;
						case 4:
						this.isfill=true
						this.color = '#ccc'
							break;
						case -1:
						this.isfill=false
						this.color = 'green'
							break;
						case 5:
						this.isfill=true
						this.color = '#7c7cf8'
							break;
					}
					
					if(this.seat.seatStatus==2&&this.leave_seat){
						this.isfill=true
						this.color = 'orange'
					}
					if(abc.indexOf(this.seat.seatNo)==-1){
						
						this.seat.seatStatus = this.seat["_seatStatus"]||this.seat.seatStatus
					}
						
					if (this.isfill) {
						ctx.fillStyle = this.color
						
						ctx.fillRect(this.x, this.y, this.w, this.h)
						
					} else {
						ctx.strokeStyle = this.color
				
						ctx.strokeRect(this.x, this.y, this.w, this.h)
					
					}
			
					// 当前是座位索引区域
					ctx.font = ' 15px consolas';
					// 座位索引写入
					ctx.fillStyle = '#000';
					ctx.fillText(this.y/40+1,15,(this.y/40+1)*40-20)
					ctx.textAlign='center'
					ctx.fillText(this.seat.columnId,this.x+15,this.y+20)
						
						
				}
			
				// 成员数据获取
				rect.prototype.getXY = function() {
					return {
						x: this.x,
						y: this.y,
						w: this.w,
						h: this.h,
						color: this.color
					}
				}
			
				// 点击更新颜色
				rect.prototype.update = function() {
					this.color = 'red'
				}
				
					
					
				
				// 清除全部选中座位
				rect.prototype.clear_seat =function() {
					ctx.clearRect(0, 0, 1000, 1000)
					
				}
				
				// 不断修改数据 动画的帧数 
				setInterval(item => {
					ctx.clearRect(0, 0, 1000, 1000)
					for (let s of arr) {
						s.render(this.abc)
					}
				}, 3)
			
				for (let index in this.seat_info.list) {
					
					
					
					for (let indexin in this.seat_info.list[index]) {
						let isfill = true;
						if (this.seat_info.list[index][indexin].columnId == '') {
							continue;
						}
						
						// 可选座位 不填充
						if (this.seat_info.list[index][indexin].seatStatus == 1) {
							isfill = false
						}
					
						let asdf = new rect(indexin * 40+40, index * 40, 30, 30, '#ccc', isfill, this.seat_info.list[index][
							indexin
						])
						// 压入座位
						arr.push(asdf)
					}
			
				}
				
				// 鼠标按下将 点击的坐标和座位信息进行比对 通过方法 传递出去
				ctx.canvas.onmousedown = (e) => {
					// 通过窗口的绝对坐标 和 画布的绝对坐标获取 点击时候相对画布的绝对坐标
					let dx = e.pageX - parseInt(canvas.getBoundingClientRect().x)
					let dy = e.pageY - parseInt(canvas.getBoundingClientRect().y)
					
					// 通过枚举数组将命中的座位信息通过 函数的参数注入传递出去
					for (let s in arr) {
						
						// 获取所有预设置的座位信息数组
						let obj = arr[s].getXY()
						// 比对坐标数据位置是否在区间内
						
						// 横向区域判断
						// 将原点设置在座位的左上角
						// 如果进入的对象数据正确,就触发点击事件,传递座位信息出去
						if (0<(dx - obj.x)&&(dx - obj.x) <= obj.w) {
							// 纵向区域判断
							if (0<(dy - obj.y)&&(dy - obj.y) <= obj.h ) {
								this.test(arr[s], s)
							}
						}
			
					}
					
				}
			
			},
			
			test(data) {
				if(this.state!=""){
					if(this.abc.filter(item=>item==data.seat.seatNo).length){
						this.abc= this.abc.filter(item=>item!=data.seat.seatNo)
					}else{
						this.abc.push(data.seat.seatNo)
					}
					if(this.abc.indexOf(data.seat.seatNo)!=-1){
						data.seat["_seatStatus"]=data.seat.seatStatus 
						data.seat.seatStatus = -1
					}
					
				}
				console.log(data)
				
			},
				
			
			
			async flash_table(){
				let res_data = await GetseatPlanList()
				
				res_data.data.title.push({
					'title':'操作',
					'slot':'operation',
					'key':'operation',
					"width":200
				})
				
				if(res_data.code==200){
					this.list = res_data.data.data
					this.title = res_data.data.title
				}
				
			},
		
			async to_data_GetseatingPlan(){
				let res_data = await data_GetseatingPlan( )
				if(res_data.code==200){
					this.$mytoast(res_data.msg)
					this.flash_table()
				}
			},
		
			// 猫眼场次同步影厅底图
			async to_MaoyanseatPlan(){
				let pass_data = {
					'seqNo':this.event_id
				}
				let res_data = await MaoyanseatPlan(pass_data)
				if(res_data.code==200){
					this.is_show_cats_eye_for=false
					this.$mytoast(res_data.msg)
					this.flash_table()
				}
				
			},
		}
	}
</script>
