import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLogin: false,
		accountInfo: {},
		showPerson: false,
		mcode:'',
		cinemaList:[]
	},
	mutations: {
		changeState(state, {
			prop,
			value
		}) {
			state[prop] = value;
		},
		SET_SelectedCinema(state,obj){
			state.baseURL=obj.localurl
			localStorage.setItem(CINEMA,JSON.stringify(obj))
			
		},
		SET_CinemaList(state,arr){
			state.cinemaList=arr
		},
	},
	actions: {},
	modules: {}
})
