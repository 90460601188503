<template>
	<div>
		<Card>
			<div style="text-align: right;margin: 20px auto;">
				<Button @click="show_goods_detail_add_btn" type="primary">添加</Button>
			</div>
			<Table @on-cell-click='on_cell_click_goods' :columns="goosList_data.titlelist"
				:data="goosList_data.listdata">
				<!-- 表头操作 -->
				<template slot-scope="{ row, index }" slot="operation">
					<div style="display: flex;">
						<Button @click="edit_goods(row)" type="primary" size="small"
							style="margin-right: 5px">编辑</Button>
						<Button v-if="row.attribute!='电影套票'" @click="()=>Inventorychanges()" type="primary" size="small"
							style="margin-right: 5px">库存修改</Button>
						<Button v-if="row.attribute=='电影套票'"
							@click="()=>(is_show_kucunbangding=true)&&(to_GetActivityCoupons(row))" type="success"
							size="small" style="margin-right: 5px">库存绑定</Button>
						<Button v-if="row.attribute=='卖品套餐'" @click="()=>Packageoptions(row)" type="warning"
							size="small" style="margin-right: 5px">套餐选项</Button>
					</div>
				</template>
				<!-- 商品标签 -->
				<template slot-scope="{ row, index }" slot="tag">
					<div>
						<Tag v-for="(item, index) in row.tag" color="primary">{{item}}</Tag>
					</div>
				</template>
				<!-- 是否上架 v-if="$my_checkBtn('to_view')"-->
				<template slot-scope="{ row, index }" slot="onState">
					<div>
						<i-Switch :disabled="!$my_checkBtn('whether_shelf')" v-model="row.onState"
							@on-change="is_sale_change(row)" />
					</div>
				</template>
			</Table>
			<div style="margin: 10px ; text-align: right;">
				<Page @on-change="(index) => this.doGetgoosList('', index)" :page-size="goosList_data.size"
					:total="goosList_data.tall" />
			</div>
		</Card>
		<!-- 商品详情标签页抽屉 -->
		<Modal :title="`商品${drow_type}`" width='900' :closable='false' :mask-closable='false'
			v-model="is_show_type_goods_detail">
			<Alert v-show="pass_data_goods_obj.onState" show-icon type="warning">上架状态不允许修改 </Alert>
			<Tabs value="name1">
				<TabPane label="基本信息" name="name1">
					<!-- 商品操作区 -->
					<Form style="display: flex;flex-wrap: wrap;justify-content:space-between" :label-width="100">
						<FormItem label="商品名称">
							<Input :disabled="pass_data_goods_obj.onState" v-model="pass_data_goods_obj.name"
								style="width:260px" />
						</FormItem>
						<FormItem label="商品属性">
							<Select :disabled="drow_type!='添加'" v-model="pass_data_goods_obj.attribute"
								style="width:260px">
								<Option v-for="item in goosOneTow_data" :value="item._id" :key="item.value">
									{{ item.name }}
								</Option>
							</Select>
						</FormItem>
						<FormItem label="商品性质">
							<Select :disabled="drow_type!='添加'" v-model="pass_data_goods_obj.typeTag"
								style="width:260px">
								<Option v-for="item in commodity_nature" :value="item" :key="item"> {{ item }} </Option>
							</Select>
						</FormItem>
						<FormItem label="商品类型">
							<Select :disabled="pass_data_goods_obj.onState" v-model="pass_data_goods_obj.type"
								style="width:260px">
								<Option v-for="item in type_data" :value="item._id" :key="item.value"> {{ item.name }}
								</Option>
							</Select>
						</FormItem>
						<FormItem label="商品标签">
							<Select :disabled="pass_data_goods_obj.onState" v-model="pass_data_goods_obj.tag" multiple
								style="width:260px">
								<Option v-for="item in goosOneTow_data_tag" :value="item._id" :key="item.value">
									{{ item.name }}
								</Option>
							</Select>
						</FormItem>
						<FormItem label="商品排序">
							<Input :disabled="pass_data_goods_obj.onState" type="number"
								v-model="pass_data_goods_obj.sortnum" style="width:260px" />
						</FormItem>
						
						<FormItem label="商品原价">
							<Input :disabled="pass_data_goods_obj.onState" type="number"
								v-model="pass_data_goods_obj.price" style="width:260px" />
						</FormItem>
						<FormItem label="商品会员价">
							<Input :disabled="pass_data_goods_obj.onState" type="number"
								v-model="pass_data_goods_obj.memberPrice" style="width:260px" />
						</FormItem>
						<FormItem label="开始时间">
							<DatePicker transfer :disabled="pass_data_goods_obj.onState"
								:value='pass_data_goods_obj.timeSale' @on-change="DatePicker_change_bef" type="datetime"
								placeholder="选择日期和时间" style="width:260px"></DatePicker>
						</FormItem>
						<FormItem label="结束时间">
							<DatePicker transfer :disabled="pass_data_goods_obj.onState"
								:value='pass_data_goods_obj.timeEnd' @on-change="DatePicker_change_aft" type="datetime"
								placeholder="选择日期和时间" style="width:260px"></DatePicker>
						</FormItem>
						<FormItem label="商品描述">
							<Input :rows="4" type="textarea" :disabled="pass_data_goods_obj.onState" v-model="pass_data_goods_obj.describe"
								style="width:260px" />
						</FormItem>
						<FormItem label="选择图片">
							<div style="display: flex;width: 260px;">
								<div style="width: 100px;height: 100px;margin-right: 50px;">
									<img style="width: 100%;height: 100%;" :src="pass_data_goods_obj.iconurl" />
								</div>
								<qiniuUploadBtn :disabled="pass_data_goods_obj.onState" :qiniu_config="qiniu_config"
									@set_url="($)=>pass_data_goods_obj.iconurl=$" />
							</div>
						</FormItem>
					</Form>
				</TabPane>
				<TabPane label="商品详情" name="name2">
					<!-- //商品详情  details-->
					<div style="margin: 30px 0;">
						<div style="margin-top: 10px; background-color: #fff;">
							<!-- 图片上传组件辅助-->
							<span style="display: inline-block; padding: 10px 0 10px 0;">编辑内容：</span>
							<div style="display: flex;">
								<!-- 编辑显示 -->
								<quill-editor :disabled="pass_data_goods_obj.onState" class="editor" ref="myQuillEditor"
									:value="pass_data_goods_obj.details" :options="editorOption"
									@change="onEditorChange" @blur="onEditorBlur($event)"
									@focus="onEditorFocus($event)" />
							</div>
						</div>
					</div>
					<Upload v-show='false' :before-upload="(file)=>handleUpload(file)"
						:on-success='(response,file,fileList)=>upload_ok(response,file,fileList)' action="">
						<Button ref="uploadImg" @click="()=>upload()">上传图片</Button>
					</Upload>
				</TabPane>
				<TabPane label="营销" name="name3">
					<Form :label-width="130">
						<FormItem label="支付类型">
							<Select :disabled="pass_data_goods_obj.onState" v-model="pass_data_goods_obj.market.paytype"
								multiple style="width:260px">
								<Option v-for="item in cityList" :value="item.value" :key="item.value"> {{ item.label }}
								</Option>
							</Select>
						</FormItem>
						<FormItem label="兑换所需积分">
							<Input style="width:260px" :disabled="pass_data_goods_obj.onState" type="number"
								v-model="pass_data_goods_obj.market.pointsFor" />
						</FormItem>
						<FormItem label="只允许新用户购买">
							<i-Switch v-model="pass_data_goods_obj.market.aNewUser"
								@on-change="($)=>pass_data_goods_obj.market.aNewUser=$" />
						</FormItem>
						<FormItem label="商品限购">
							<Input :disabled="pass_data_goods_obj.onState" type="number" style="width:260px"
								v-model="pass_data_goods_obj.market.quota" />
						</FormItem>
						<FormItem label="虚拟销量">
							<Input :disabled="pass_data_goods_obj.onState" type="number" style="width:260px"
								v-model="pass_data_goods_obj.market.virtualSales" />
						</FormItem>
						<FormItem v-if="pass_data_goods_obj.typeTag!='赠品'" label="赠品">
							<div style="width:30vw;display: flex;align-items: center;">
								<Select style="width:260px" v-model="model19" filterable>
									<Option v-for="item in giv_obj" :value="item" :key="item.value">{{ item.label }}
									</Option>
								</Select>

								<Input style="width:100px;margin: 0 10px;" v-model="gov_num" type="number"></Input>
								<Button type='primary'
									@click="()=>model19&&pass_data_goods_obj.market.giv.push({'id':model19.value,'gov_num':gov_num,'name':model19.label})">添加</Button>
							</div>
						</FormItem>
						<div style="margin-top: 10px;">
							<Table :columns="gov_title" :data='pass_data_goods_obj.market.giv'>
								<template slot-scope="{row}" slot="operation">
									<Button size='small' @click="()=>deleteGiv(row)" type="error">删除</Button>
								</template>
							</Table>
						</div>
					</Form>
				</TabPane>
				<TabPane v-if="pass_data_goods_obj.typeTag=='分销商品'" label="分销" name="name4">
					<Form style="display: flex;flex-wrap: wrap;justify-content:space-between" :label-width="140">
						<FormItem label="一级分销商提成金额">
							<Input v-model="pass_data_goods_obj.placeObj.commission[0].val" style="width: 200px;" type='number' />
							<Select v-model="commission_0" style="width: 200px;margin-left: 10px;">
								<Option v-for="item in commission_" :value="item.type" :key="item.type">{{item.type}}
								</Option>
							</Select>
						</FormItem>
						<FormItem label="二级分销商提成金额">
							<Input v-model="pass_data_goods_obj.placeObj.commission[1].val" style="width: 200px;" type='number' />
							<Select v-model="commission_1" style="width: 200px;margin-left: 10px;">
								<Option v-for="item in commission_" :value="item.type" :key="item.type">{{item.type}}
								</Option>
							</Select>
						</FormItem>
					</Form>	
				</TabPane>
			</Tabs>
			<div slot="footer">
				<Button @click="()=>is_show_type_goods_detail=false">返回</Button>
				<Button :disabled="pass_data_goods_obj.onState" @click="goods_add"
					type="primary">{{ drow_type }}商品</Button>
			</div>
		</Modal>
		<!-- 库存绑定弹窗 -->
		<Modal :closable='false' :mask-closable='false' width='800' v-model="is_show_kucunbangding">
			<div v-if="goosbindingCoupon_infos">
				<div style="display: flex;position: relative;">
					<div>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">票券编号</span>
							<Input v-model="goosbindingCoupon_infos.apply" disabled style="width: 300px;"></Input>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">票券名称</span>
							<Input v-model="goosbindingCoupon_infos.applicationName" disabled
								style="width: 300px;"></Input>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">有效期-开始</span>
							<Input v-model="goosbindingCoupon_infos.satrt" disabled style="width: 300px;"></Input>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">有效期-结束</span>
							<Input v-model="goosbindingCoupon_infos.end" disabled style="width: 300px;"></Input>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">库存剩余张数</span>
							<Input v-model="goosbindingCoupon_infos.balanceTickets" disabled
								style="width: 300px;"></Input>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">规格</span>
							<Input v-model="goosbindingCoupon_infos.size" disabled style="width: 300px;"></Input>
						</p>
					</div>
					<div style="padding: 0 10px;">
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">模式</span>
							<Select v-model="goosbindingCoupon_infos.model" style="width: 200px;">
								<Option v-for="(item,index) in goosbindingCoupon_config" :value='index'>{{item}}
								</Option>
							</Select>
						</p>
						<p style="margin-bottom: 10px;">
							<span style="display: inline-block; width: 100px;">有效期天数</span>
							<Input v-model='goosbindingCoupon_infos.validity' style="width: 200px;"></Input>
						</p>
					</div>
					<!-- 按钮区域 -->
					<div style='position: absolute;bottom: 10px;right: 10px;'>
						<Button v-if="goosbindingCoupon_infos._rowKey&&goosbindingCoupon_infos.uid"
							@click="()=>to_goosbindingCoupon()" style="margin-left: 10px;" size='small'
							type="primary">绑定</Button>
						<Button v-if="!goosbindingCoupon_infos._rowKey&&goosbindingCoupon_infos.uid"
							@click="()=>to_disgoosbindingCoupon()" style="margin-left: 10px;" size='small'>解绑</Button>
					</div>
				</div>
				<!-- 表格区域 -->
				<div>
					<Table :data="inventory_bind_infos.list" :columns="inventory_bind_infos.title">
						<!-- 表格操作区域 -->
						<template slot-scope='{row}' slot="operation">
							<Button :disabled="goosbindingCoupon_infos._rowKey&&goosbindingCoupon_infos.uid"
								@click="()=>goosbindingCoupon_infos=row" size='small' type='primary'>选择</Button>
						</template>
					</Table>
					<div>
						<Card style='text-align: right;'>
							<Page @on-change="($)=>to_GetActivityCoupons({'id':current_id},$)"
								:total='inventory_bind_infos.total' :page-size='inventory_bind_infos.size'></Page>
						</Card>
					</div>
				</div>
			</div>
			<div slot='footer'>
				<Button @click="is_show_kucunbangding=false">返回</Button>
				<Button type='primary'>确定</Button>
			</div>
		</Modal>
		<!-- 套餐选项弹窗 -->
		<Modal :closable='false' :mask-closable='false' fullscreen v-model='is_show_package_options_window'>
			<div style="display: flex;justify-content: space-between;">
				<div style="min-width: 700px;">
					<p>
						<span style="display: inline-block;width: 200px;font-size: 30px;font-weight: 700;">套餐选项设置</span>
						<Input placeholder="请输入分组名称" v-model="group_name" style="width: 300px;"></Input>
						<Button @click="()=>do_addtap()" style="margin-left: 10px;" type='primary'>+添加分组</Button>
					</p>
					<div v-if="group_infos">
						<div v-for="(item,index) in group_infos.options">
							<p>
								<span
									style="display: inline-block;width: 200px;font-size: 20px;font-weight: 700;">|{{item.tapname}}</span>
								<Input placeholder="请输入选项名称" v-model="item.name" style="width: 200px;"></Input>
								<Button @click="()=>add_option(item)" style="margin-left: 10px;" type=''>+添加选项</Button>
								<Button @click="()=>delete_group(item)" style="margin-left: 10px;" type='error'
									ghost>删除分组</Button>
							</p>
							<p>
								<template v-for="(itemin,indexin) in  item.list">

									<span :class="current_select_tag==itemin.ouid?'pink':'grey'"
										@click="()=>current_select_tag=itemin.ouid"
										style='border-radius: 3px; margin-right: 10px; cursor: pointer;padding: 10px 20px;'>{{itemin.xname}}</span>
								</template>
							</p>
						</div>
					</div>
				</div>
				<!-- 标签点击的信息展示 -->
				<div style="padding: 80px 100px;">
					<Card>
						<div v-if="current_select_tag">
							<span v-show="false">
								{{compute_tag_info(group_infos.options).filter((item)=>item.ouid==current_select_tag)[0]?current_put_tag_info=compute_tag_info(group_infos.options).filter((item)=>item.ouid==current_select_tag)[0]:''}}
							</span>
							<!-- 命中list里面的对象的值  那粗来该对象 -->
							<p>
								<span style="display: inline-block;  width: 100px;">排序|</span>
								<Input style="width: 300px;" v-model="current_put_tag_info.xname"></Input>
							</p>
							<p>
								<span style="display: inline-block;  width: 100px;">原价加价|</span>
								<Input type='number' style="width: 300px;" v-model="current_put_tag_info.price"></Input>
							</p>
							<p>
								<span style="display: inline-block;  width: 100px;">会员加价|</span>
								<Input type='number' style="width: 300px;"
									v-model="current_put_tag_info.memberPrice"></Input>
							</p>
							<p>
								<span style="display: inline-block;  width: 100px;">默认|</span>
								<Tag v-if='current_put_tag_info.default'>{{current_put_tag_info.default?'默认':''}}
								</Tag>
							</p>
							<p>
								<span style="display: inline-block;  width: 100px;">排序|</span>
								<Input type='number' style="width: 300px;" v-model="current_put_tag_info.sort"></Input>
							</p>
							<div style="text-align: right;">
								<Button @click="()=>do_setoptionsdef()">默认</Button>
								<Button @click="()=>submit_options()" style="margin: 0 10px;" type='primary'>提交</Button>
								<Button @click="()=>to_deltapo('删除选项')" type='error'>删除</Button>
							</div>
						</div>
					</Card>
				</div>
			</div>
			<div slot='footer'>
				<Button @click="()=>is_show_package_options_window=false">返回</Button>
			</div>
		</Modal>
		<!-- 库存修改 -->
		<Modal :closable='false' :mask-closable='false' width="500" v-model="is_show_kuxiiugai" title="修改库存">
			<div>
				<div style="display: flex;">
					<div @click="kucun_select='加'" :class="kucun_select=='加'?'blue':''"
						style="padding: 5px 10px;border: 1px solid #ccc;border-radius: 5px;margin-right: 5px;">增加
					</div>
					<div @click="kucun_select='减'" :class="kucun_select=='减'?'blue':''"
						style="padding: 5px 10px;border: 1px solid #ccc;border-radius: 5px;">减少</div>
				</div>
				<div style="margin-top: 10px;width: 300px;">
					<Input type="number" v-model="kucun_add_del" size="large" placeholder="请选择增加/减少后输入数字" />
				</div>
			</div>
			<div slot="footer">
				<Button @click="do_count(1)">取消</Button>
				<Button type="primary" @click="do_count(2)">确定</Button>
			</div>
		</Modal>
		<!--【组件】 图片上传/选择组件  复用此组件只需要添加一个变量  和一个 点击事件  需要注意缩略图 和 ajax传参-->
		<Modal :closable='false' :mask-closable='false' width="800" v-model="modal_pic_show" title="选择 / 上传图片">
			<UploadImag @do_pick_pic="do_pick_pic" />
			<div slot="footer"></div>
		</Modal>
	</div>
</template>

<script>
	import dedent from 'dedent'
	import hljs from 'highlight.js'
	import {
		quillEditor
	} from 'vue-quill-editor'
	// highlight.js style
	import 'highlight.js/styles/tomorrow.css'
	// import theme style
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import debounce from 'lodash/debounce'
	import UploadImag from "@/components/UploadImag.vue";
	import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue'
	import {
		GetgoodsGiv,
		goosbindingCoupon,
		GetgoosbindingCoupon,
		GetActivityCoupons,
		setoptions,
		setoptionsdef,
		deltapo,
		gettap,
		addtap,
		Schedul,
		SetgoosType,
		GetgoosType,
		upimgfile,
		getimglist,
		GetgoosOneTow,
		AddgoosData,
		GetgoosList,
		GetgoosXData,
		SetgoosXData,
		UpgoosStock,
		UpgoosOnState,
		getupTK
	} from "@/request/api.js";
	import {
		getTimeFromZero
	} from "@/utils/index.js";
	import * as qiniu from 'qiniu-js'
	export default {
		name: "navbar",
		components: {
			UploadImag,
			quillEditor,
			qiniuUploadBtn
		},
		data() {
			return {
				gov_title: [{
						title: '套餐名称',
						key: 'name'
					},
					{
						title: 'id',
						key: 'id'
					},
					{
						title: '数量',
						key: 'gov_num'
					},
					{
						title: '操作',
						slot: 'operation'
					},
				],
				gov_num: 1, //赠品数量
				model19: '',
				options3: [],
				loading3: false,
				commodity_nature: ["常规商品", "分销商品", "赠品"], //商品性质配置
				file: '', //文件流,
				type: '', //
				img_type: ['image/png', 'image/bmp', 'image/jpeg', 'image/jpg', 'image/gif'], //上传准入格式
				qiniu_config: {}, //表单状态数据
				content: dedent``,
				editorOption: {
					modules: {
						toolbar: [
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{
								'header': 1
							}, {
								'header': 2
							}],
							[{
								'list': 'ordered'
							}, {
								'list': 'bullet'
							}],
							[{
								'script': 'sub'
							}, {
								'script': 'super'
							}],
							[{
								'indent': '-1'
							}, {
								'indent': '+1'
							}],
							[{
								'direction': 'rtl'
							}],
							[{
								'size': ['small', false, 'large', 'huge']
							}],
							[{
								'header': [1, 2, 3, 4, 5, 6, false]
							}],
							[{
								'font': []
							}],
							[{
								'color': []
							}, {
								'background': []
							}],
							[{
								'align': []
							}],
							['clean'],
							['link', 'image', 'video']
						],
						syntax: {
							highlight: text => hljs.highlightAuto(text).value
						}
					}
				},
				goosbindingCoupon_config: {
					"1": '按模板有效期生成',
					"2": '按绑定时长生成有效期'
				},
				goosbindingCoupon_infos: '', //库存绑定-票券信息
				inventory_bind_infos: '',
				is_show_kucunbangding: false, //库存绑定弹窗是否显示
				current_put_tag_info: {
					'xname': '',
					'price': '',
					'memberPrice': '',
					'default': '',
					'sort': ''
				},
				current_select_tag: '', //当前选中的标签的ouid
				group_infos: '', //分组数据
				group_name: '',
				current_row_info: '', //表格当前使用行的数据
				is_show_package_options_window: false, //套餐选项弹窗
				kucun_select: '',
				kucun_add_del: "", //跟库存有关
				is_show_kuxiiugai: false, //库存修改
				current_id: '', //当前选中的id
				modify: [], //许可编辑修改的表单
				goosList_data: {}, //
				imgurl: "", //用户展示图片
				goosOneTow_data_tag: [], //商品属性列表
				attribute_index: "", //商品属性索引下表
				goosOneTow_data: [], //获取商品属性组或标签组  商品属性数组
				giv_obj: [], //搜索
				cityList: [{
					value: 1,
					label: '微信支付'
				}, {
					value: 2,
					label: '会员支付'
				}, {
					value: 3,
					label: '优惠券支付'
				}, {
					value: 4,
					label: '积分支付'
				}],
				commission_0: '固定金额',
				commission_1: '固定金额',
				commission_: [{
						type: "百分比",
						val: 0
					},
					{
						type: "固定金额",
						val: 0
					},
				],
				pass_data_goods_obj: {
					placeObj: {
						commission: [{
								type: "固定金额",
								val: 0
							},
							{
								type: "固定金额",
								val: 0
							},
						], //提成金额
					},
					market: {
						virtualSales: 0, //虚拟销量
						paytype: [1, 2, 3], //支付类型
						pointsFor: 0, //积分对象 数字
						aNewUser: false, //新用户可购 bol
						quota: 0, //用户限购 》0限购
						giv: [], //赠品
						activity: [], //活动
					},
					typeTag: '',
					name: "", //
					attribute: "", //商品属性 通过GetgoosOneTow接口 type=1获取
					type: "", //商品类型
					tag: [], //商品标签组允许多个商品属性 通过GetgoosOneTow接口 type=2获取
					iconurl: "", //商品图片
					sortnum: "", //商品排序越大越靠前
					mcode: localStorage.getItem('mcode'), //影院编码
					describe: "", //商品描述
					details: dedent``, //商品详情
					price: "", //商品原价
					memberPrice: "", //商品会员价
					timeSale: "", //开始售卖时间
					timeEnd: "", //结束售卖时间
				}, //商品添加对象
				pass_data_goods_obj_copy: {},
				type_index: "", //商品类型索引下表
				modal_pic_show: false,
				drow_type: "", //抽屉动态显示文本  例子  点击添加按钮  xx添加；点击修改按钮 xx修改
				is_show_type_goods_detail: false,
				pass_data_type_obj: {
					id: "", //商品类型id，传此参数此接口为修改类型
					name: "", //类型名称
					iconurl: "", //图标路径
					mcode: localStorage.getItem('mcode'), //影院编码
					sortnum: "", //排序编号
				},
				columns_type: [{
						title: "类型id",
						key: "_id",
					},

					{
						title: "影院编码",
						key: "mcode",
					},
					{
						title: "类型名称",
						key: "name",
					},
					{
						title: "前台是否显示",
						key: "displayTags",
						slot: "is_font_show",
					},
					{
						title: "图标连接",
						key: "iconurl",
					},
					{
						title: "排序",
						key: "sortnum",
					},
					{
						title: "类型编号",
						key: "type",
					},

					{
						title: "操作",
						key: "operation",
						slot: "operation",
					},
				],
				type_data: [],
			};
		},
		computed: {
			gov_computed() {
				return function() {
					return JSON.parse(this.model19)
				}
			},
			compute_tag_info() {
				return function(row) {
					row.forEach(item => item.list.forEach(itemin => itemin['tapuid'] = item.tapuid))
					let arr = []
					row.forEach(item => arr.push(...item.list))
					return arr
				}
			}
		},
		watch: {
			commission_0(val) {

				let center = JSON.parse(JSON.stringify(this.pass_data_goods_obj.placeObj.commission[0]))
				center.type = val
				this.pass_data_goods_obj.placeObj.commission[0] = center

			},
			commission_1(val) {
				let center = JSON.parse(JSON.stringify(this.pass_data_goods_obj.placeObj.commission[1]))
				center.type = val
				this.pass_data_goods_obj.placeObj.commission[1] = center
			},
			current_select_tag() {
				this.to_gettap()
			},
			// 获取套餐选项组 
			async is_show_package_options_window(val) {
				this.group_infos = ''
				this.current_select_tag = ''
				if (val) {
					this.to_gettap()
				}
			},
			// 商品属性下拉菜单被选择时触发
			attribute_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.goosOneTow_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.attribute = _id;
			},
			// 用户点击添加按钮触发获取 【商品属性】 列表
			async is_show_type_goods_detail(is_show_drw) {
				if (is_show_drw) {
					let pass_data = {
						type: 1,
					};

					// 发动ajax 获取商品属性组或标签组
					let {
						data: res_data
					} = await GetgoosOneTow(pass_data);

					this.goosOneTow_data = res_data;
					// 获取商品标签组允许多个商品属性
					let pass_data1 = {
						type: 3,
					};

					// 发动ajax 获取商品属性组或标签组
					let {
						data: res_data1
					} = await GetgoosOneTow(pass_data1);

					this.goosOneTow_data_tag = res_data1;
					let pass_data2 = {
						'mcode': localStorage.getItem('mcode')
					}

					let {
						data: res_data2
					} = await GetgoosType(pass_data2);

					this.type_data = res_data2

				}

			},
			// 商品类型下拉菜单被选择时触发
			type_index(index) {
				// 找到该索引下表在type_data对应的_id值
				let _id = this.type_data[index]._id;
				// 将选中的值复制给传参对象
				this.pass_data_goods_obj.type = _id;
			},
		},
		async mounted() {
			this.to_getupTK()
			this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)
			this.pass_data_goods_obj_copy = JSON.parse(JSON.stringify(this.pass_data_goods_obj))
			this.doGetgoosList();
			// 编辑组件绑定到按钮里面
			// 获取赠品配置
			let pass_data = {
				"typeTag": '赠品',
				"mcode": localStorage.getItem('mcode')
			}
			let {
				data: res_data
			} = await GetgoodsGiv(pass_data)
			this.giv_obj = res_data
		},
		methods: {
			reset(){
				this.pass_data_goods_obj = JSON.parse(JSON.stringify(this.pass_data_goods_obj_copy))
			},
			deleteGiv(row) {
				this.pass_data_goods_obj.market.giv = this.pass_data_goods_obj.market.giv.filter(item => item.id != row.id)
			},
			Packageoptions(row) {
				this.group_name = ''
				this.current_row_info = row
				this.is_show_package_options_window = true
			},
			Inventorychanges() {
				this.kucun_select = ''
				this.kucun_add_del = ''
				this.is_show_kuxiiugai = true
			},
			async upload(file) {
				// 文件类型限制
				if ((this.img_type).indexOf(file.type) == -1) {
					this.$mytoast_err('只能上传图片类型')
					return false
				}
				// 文件大小限制
				if (file.size > 1024 * 1024) {
					this.$mytoast_err('上传文件不可大于1M')
					return false
				}
				var observable = qiniu.upload(file, 'cms_resources/' + new Date().getTime(), this.qiniu_config
					.token, {}, {})
				let res_qiniu = await new Promise((resolve, reject) => {
					observable.subscribe({
						next(res) {
						},
						error(err) {
						},
						complete(res) {
							resolve(res)
						}
					}) // 上传开始
				})
				let url = this.qiniu_config.downloadurl + res_qiniu.key
				this.addRange = this.$refs.myQuillEditor.quill.getSelection()
				this.$refs.myQuillEditor.quill.insertEmbed(this.addRange !== null ? this.addRange.index : 0, 'image',
					url) // 调用编辑器的 insertEmbed 方法，插入UR

			},
			// 手动上传
			handleUpload(file) {
				this.file = file;
				this.upload(file)
				return false;
			},
			// 获取七牛云token
			async to_getupTK() {
				let {
					data: res_data
				} = await getupTK()
				this.qiniu_config = res_data.data
			},
			//----------------------富文本------------------------
			// 点击图片ICON触发事件
			imgHandler(state) {
				this.addRange = this.$refs.myQuillEditor.quill.getSelection()
				if (state) {
					let fileInput = this.$refs.uploadImg
					fileInput.$el.click() // 加一个触发事件
				}
				this.uploadType = 'image'
			},
			// 上传成功回调函数 图片回显到编辑框
			upload_ok(response, file, fileList) {
				let url = response.msg
				this.addRange = this.$refs.myQuillEditor.quill.getSelection()
				this.$refs.myQuillEditor.quill.insertEmbed(this.addRange !== null ? this.addRange.index : 0, 'image', {
					url,
					controls: 'controls',
					width: '100%',
					height: '50px'
				}) // 调用编辑器的 insertEmbed 方法，插入UR					
				this.pass_data_goods_obj.details = this.pass_data_goods_obj.details.replace(/<img/g,
					'<img style=\"width:100%\"')

			},
			onEditorChange: debounce(function(value) {
				this.pass_data_goods_obj.details = value.html
			}, 466),
			//----------------------富文本------------------------
			// 库存绑定-绑定
			async to_goosbindingCoupon() {
				let pass_data = {
					"id": this.current_id, //商品id
					"type": "绑定", //操作类型 ’绑定‘  ’解除绑定‘
					"couponuid": this.goosbindingCoupon_infos.uid, //type为绑定时必须传   要绑定的票券模板uid 
					"model": this.goosbindingCoupon_infos.model, //type为绑定时必须传  选择绑定的生成模式 1按模板有效期生成  2按绑定时长生成有效期
					"validity": this.goosbindingCoupon_infos.validity //type为绑定时必须传  且 model=2 是必须传 有效期 单位天
				}
				let res_data = await goosbindingCoupon(pass_data)
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.to_GetgoosbindingCoupon(this.current_id)
				}
			},
			// 库存绑定-解绑
			async to_disgoosbindingCoupon() {
				let pass_data = {
					"id": this.current_id, //商品id
					"type": "解除绑定", //操作类型 ’绑定‘  ’解除绑定‘
				}
				let res_data = await goosbindingCoupon(pass_data)
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.to_GetgoosbindingCoupon(this.current_id)
				}
			},
			// 获取套票绑定模板数据
			async to_GetgoosbindingCoupon(id) {
				let pass_data = {
					'id': id
				}
				let {
					data: res_data
				} = await GetgoosbindingCoupon(pass_data)
				res_data['model'] += ''
				this.goosbindingCoupon_infos = res_data
			},
			// 获取库存绑定弹窗数据
			async to_GetActivityCoupons(row, page = 1) {
				this.inventory_bind_infos = ''
				let pass_data = {
					'page': page,
					'mcode': localStorage.getItem('mcode')
				}
				let {
					data: res_data
				} = await GetActivityCoupons(pass_data)
				res_data.title.push({
					'key': 'operation',
					'title': '操作',
					'slot': 'operation'
				})
				res_data.list.forEach(item => {
					item['model'] = '1'
					item['validity'] = 0
				})
				this.inventory_bind_infos = res_data
				// 调用获取套票绑定模板数据 接口
				await this.to_GetgoosbindingCoupon(row.id)

			},
			// 提交选项信息
			async submit_options() {
				let pass_data = {
					"tapuid": this.current_put_tag_info.tapuid, //分组uid
					"ouid": this.current_put_tag_info.ouid, //选项uid
					"xname": this.current_put_tag_info.xname, //选项名称
					"price": this.current_put_tag_info.price, //价格
					"memberPrice": this.current_put_tag_info.memberPrice, //会员价格
					"sort": this.current_put_tag_info.sort //排序0-100
				}
				let res_data = await setoptions(pass_data)
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.to_gettap()
				}
			},
			// 默认按钮点击事件
			async do_setoptionsdef() {
				let pass_data = {
					'tapuid': this.current_put_tag_info.tapuid,
					'ouid': this.current_put_tag_info.ouid
				}
				let res_data = await setoptionsdef(pass_data)
				if (res_data.code == 200) {
					this.to_gettap()
				}
			},
			// 删除分组
			async delete_group(row) {
				let pass_data = {
					'tapuid': row.tapuid,
					'type': '删除分组',
				}
				let res_data = await deltapo(pass_data)
				if (res_data.code == 200) {
					this.current_select_tag = false
					this.to_gettap()
				}
			},
			// 删除标签
			async to_deltapo(type) {
				let pass_data = {
					'tapuid': this.current_put_tag_info.tapuid,
					'type': type,
					'ouid': this.current_put_tag_info.ouid
				}
				let res_data = await deltapo(pass_data)
				if (res_data.code == 200) {
					this.current_select_tag = false
					this.to_gettap()
				}
			},
			// 获取弹窗套餐信息
			async to_gettap() {
				let pass_data = {
					'id': this.current_row_info.id,
					"mcode": localStorage.getItem('mcode')
				}
				let res_data = await gettap(pass_data)

				if (res_data.code = 200) {
					this.group_infos = ''
					res_data.data.options.forEach(item => {
						item['name'] = ''
					})
					this.group_infos = res_data.data
				}

			},
			// 添加选项按钮点击事件
			async add_option(row) {
				let pass_data = {
					'tapuid': row.tapuid,
					"id": this.current_row_info.id, //商品id
					"mcode": localStorage.getItem('mcode'), //营业mcode
					"type": "添加选项", //添加类型 添加分组  添加选项
					"name": row.name //添加的名称
				}
				let res_data = await addtap(pass_data)
				if (res_data.code == 200) {
					this.$mytoast('添加成功')
					this.to_gettap()
				}

			},
			// 添加分组按钮点击事件
			async do_addtap() {
				if (!this.group_name) {
					this.$mytoast_err('请输入分组名称')
					return
				}
				let pass_data = {
					"id": this.current_row_info.id, //商品id
					"mcode": localStorage.getItem('mcode'), //营业mcode
					"type": "添加分组", //添加类型 添加分组  添加选项
					"name": this.group_name //添加的名称
				}
				let res_data = await addtap(pass_data)
				if (res_data.code == 200) {
					this.$mytoast('添加成功')
					this.to_gettap()
				}
			},
			// 是否上架
			async is_sale_change(row) {
				let pass_data = {
					'id': row.id, //商品类型id
					'mcode': localStorage.getItem('mcode'),
					'onState': row.onState ? "1" : "-1", //1表示上架    -1表示下架
				};
				// // 设置商品类型可视状态  发送ajax
				let {
					data: res_data
				} = await UpgoosOnState(pass_data);
				if (res_data.id) {
					this.$Message.success("更新成功");
				} else {
					this.$Message.error("更新失败");
				}
			},
			on_cell_click_goods(payload) {
				this.current_id = payload.id
			},
			// 修改库存
			async do_count(type) {
				if (type == 1) {
					// 取消按钮
					this.is_show_kuxiiugai = false
					return
				}
				if (!this.kucun_select || !this.kucun_add_del) {
					this.$Message.error('请选择后正确输入')
					return
				}
				let num;
				if (this.kucun_select == '加') {
					num = '+' + this.kucun_add_del
				}
				if (this.kucun_select == '减') {
					num = '-' + this.kucun_add_del
				}
				// 凑全数据
				let pass_data = {
					"id": this.current_id, //修改商品id
					"mcode": localStorage.getItem('mcode'), //所属门店
					"num": num //操作数量 可正  可负 但是修改后如果库存量低于0将会修改失败
				}

				// 执行发送动作
				let res_data = await UpgoosStock(pass_data)

				//获取商品列表
				this.doGetgoosList();
				this.is_show_kuxiiugai = false

			},
			// 商品修改编辑
			async edit_goods(item) {
				this.reset()
				this.drow_type = '编辑修改'
				let pass_data = {
					id: item.id,
					mcode: localStorage.getItem('mcode'),
				};
				// 后台获取商品信息
				let {
					data: res_data
				} = await GetgoosXData(pass_data);
				// 服务器返回的是时间戳 需要处理成date类型 
				res_data.goosdata.timeEnd = new Date(res_data.goosdata.timeEnd * 1).Format("yyyy-MM-dd HH:mm:ss")
				res_data.goosdata.timeSale = new Date(res_data.goosdata.timeSale * 1).Format("yyyy-MM-dd HH:mm:ss")
				this.pass_data_goods_obj = res_data.goosdata
				// 重新赋值给抽屉  抽屉显示
				this.is_show_type_goods_detail = true
				//保存当前点击编辑修改的id
				this.current_id = item.id
				// 许可编辑修改的表单项
				this.modify = res_data.modify
			},
			//获取 商品详情标签页 列表
			async doGetgoosList(page = 1) {
				let pass_data = {
					mcode: localStorage.getItem('mcode'),
					page: page,
				};
				let {
					data: res_data
				} = await GetgoosList(pass_data);
				res_data.titlelist.forEach((item, index) => {
					if (item.title == "商品标签") {
						item.slot = "tag";
						item.width = "200";
					}
					if (item.title == "上架") {
						item.slot = "onState";
					}
				});
				// 添加操作列
				res_data.titlelist.push({
					title: "操作",
					slot: "operation",
					'width': '350'
				});
				this.goosList_data = res_data;
			},
			// 添加商品 发送ajax----------
			async goods_add() {
				if (this.drow_type == "编辑修改") {
					// 执行修改操作
					let pass_data = {
						'id': this.current_id,
						'mcode': localStorage.getItem('mcode')
					}
					let end_pass_data = {};
					end_pass_data = this.pass_data_goods_obj;
					end_pass_data.timeSale = new Date(end_pass_data.timeSale)
						.getTime()
						.toString();
					end_pass_data.timeEnd = new Date(end_pass_data.timeEnd)
						.getTime()
						.toString();
					delete end_pass_data.typeTag;
					delete end_pass_data.attribute;
					// 执行修改接口
					let {
						data: res_data1
					} = await SetgoosXData(pass_data, end_pass_data)

					if (res_data1.id) {
						// 修改成功抽屉消失
						this.is_show_type_goods_detail = false;
						// 刷新列表
						this.doGetgoosList();
					} else {

						this.$Message.error(res_data1.msg)
					}
					return
				}
				let pass_data = this.pass_data_goods_obj;
				pass_data.timeSale = new Date(pass_data.timeSale)
					.getTime()
					.toString();
				pass_data.timeEnd = new Date(pass_data.timeEnd)
					.getTime()
					.toString();

				let res_data = await AddgoosData(pass_data);

				console.log(res_data);
				if (res_data) {
					// 添加商品成功 抽屉消失
					this.is_show_type_goods_detail = false;

					// 刷新列表
					this.doGetgoosList();
				}

			},
			//end----------
			// 售卖开始时间和结束时间事件
			DatePicker_change_aft(paload_data) {
				this.pass_data_goods_obj.timeEnd = paload_data;
			},
			DatePicker_change_bef(paload_data) {
				this.pass_data_goods_obj.timeSale = paload_data;
			},
			// 商品详情标签页里的 抽屉- 弹窗子组件传到父组件的时间  点击选择图片
			do_pick_pic(item) {
				// 传参赋值初始化
				this.pass_data_goods_obj.iconurl = item.fileid;
				// 选中图片后的缩略图
				this.pass_data_goods_obj.imgurl = item.url;
				// 让弹窗消失
				this.modal_pic_show = false;
			},
			// 展示商品详情抽屉
			show_goods_detail_add_btn() {
				// 开放所有类型表单添加
				this.modify = [];
				this.reset()
				this.is_show_type_goods_detail = true;
				this.drow_type = "添加";
			},
			// 单元格点击里面的按钮按数据
			on_cell_click(row_data) {
				// 分支从修改排序按钮出来的
				this.drow_type = "修改";
				this.pass_data_type_obj = row_data;
			},
		},
	};
</script>

<style>
	.grey {
		background-color: #efefef;
	}

	.blue {
		background-color: #2d8cf0;
		color: #fff;
	}

	.pink {
		background-color: pink;
		color: #fff;
	}

	.show_type_add_class .ivu-drawer {
		width: 40vw !important;
	}

	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
</style>
