<template>
	<div>	
		ticket_setting
	</div>
</template>

<script>
	// import {} from '../../request/api.js'
	export default{
		name:'navbar',
		data(){
			
			return{
				
			}
			
		}
	}
</script>
