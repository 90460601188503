<template>
	<div>
		<div style="display: flex;justify-content: flex-end;">
			<Form inline>
				<FormItem>
					<Input v-model="searchValue" style="width: 200px;"></Input>
				</FormItem>
				<FormItem>
					<Button type='primary' style="margin: 0 10px;" @click="()=>HandleGetEmCodeList(1,searchValue)">搜索</Button>
				</FormItem>
				<FormItem>
					<Button @click="()=>addQrcode=true">添加员工码</Button>
				</FormItem>
			</Form>
		</div>
		<div>
			<div style="margin-top: 10px;">
				<Table :columns="EmCodeList.title" :data="EmCodeList.list">
					<template slot-scope="{row}" slot="promoCode">
						<img style="width: 100px;" :src="row.promoCode" alt="">
					</template>
					<template slot-scope="{row}" slot="state">
						<i-Switch @on-change="()=>handleEditEmployeeCode(row,'修改状态')" v-model="row.state"></i-Switch>
					</template>
					<template slot-scope="{row}" slot="operation">
						<Button size='small' style="margin-right: 10px;" @click="()=>qrcodeDownload(row)">下载</Button>
						 <Poptip
						 transfer
						        confirm
						        title="确认是否删除?"
						        @on-ok="()=>handleEditEmployeeCode(row,'删除')">
								<Button  size='small' type='error'>删除</Button>
						    </Poptip>
						
					</template>
				</Table>
				<Card style='text-align: right;'>
					<Page @on-change="(value)=>HandleGetEmCodeList(value,searchValue)" :page-size='EmCodeList.size'
						:total='EmCodeList.total'></Page>
				</Card>
			</div>
		</div>
		<Modal :closable='false' :mask-closable='false' v-model="addQrcode"   width="800">
			<h1>网售账户查找</h1>
			<Form ref="form" style="width: 300px;margin-top: 10px;" :model="formadd" :rules="ruleValidate"
				:label-width="80">
				<FormItem prop="keyword" label="查找条件">
					<div style="display: flex;">
						<Input v-model="formadd.keyword" style="margin-right: 10px;"></Input>
						<Button @click="()=>find()">查找</Button>
					</div>
				</FormItem>
			</Form>
			<div v-if="accountFindInfo">
				<div>ID：{{accountFindInfo.ID}} </div>
				<div><img style="width: 80px;" :src="accountFindInfo.imgURl" alt=""></div>
				<div>昵称：{{accountFindInfo.nickName}}</div>
				<div>真实姓名：{{accountFindInfo.realName}} <Button size='small' @click="()=>popEditMdal()"  type='primary'>修改</Button></div>
				<div>绑定电话：{{accountFindInfo.phone}}</div>
				<div>当前门店：{{accountFindInfo.mcode}}</div>
				<div>openid：{{accountFindInfo.openid}}</div>
				<div style="display: flex;justify-content: space-between;padding: 10px 0;">
					<h3>推广码信息</h3>
					<Button @click="()=>modalAddYgcode=true">添加员工码</Button>
				</div>
				<Table :columns="accountFindInfoTitle" :data="accountFindInfo.specialData">
					<template slot-scope="{row}" slot='state'>
						{{row.state?'有效':'无效'}}
					</template>
				</Table>
			</div>
			<div slot='footer'>
				<Button @click="()=>addQrcode=false">返回</Button>
			</div>
		</Modal>
		<Modal :closable='false' :mask-closable='false' v-model='editModal'>
			<Form ref="formedit" style="width: 300px;margin-top: 10px;" :model="formedit" :rules="ruleValidate"
				:label-width="80">
				<FormItem prop="realName" label="真实姓名">
					<div style="display: flex;">
						<Input v-model="formedit.realName" style="margin-right: 10px;"></Input>
					</div>
				</FormItem>
			</Form>
			<div slot='footer'>
				<Button @click="()=>editModal=false">返回</Button>
				<Button @click="()=>handleformedit()" type='primary'>确定</Button>
			</div>
		</Modal>
		<Modal :closable='false' :mask-closable='false' v-model="modalAddYgcode">
			<div>
				确认是否添加员工码信息？
			</div>
			<div slot='footer'>
				<Button @click="()=>modalAddYgcode=false">返回</Button>
				<Button @click="()=>handleAddYgcode()" type='primary'>确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import axios from 'axios'
	let mcode;
	import {
		GetEmCodeList,
		EditEmployeeCode,
		GenerateEmployeeCode,
		FindAppuser,
		EditAppuser
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {
			return {
				modalAddYgcode:false,
				formedit:{
					realName:""
				},
				currentEditInfo:"",
				editModal:false,
				accountFindInfoTitle: [{
						"key": "mcode",
						"title": "影院编码"
					}, {
						"key": "identity",
						"title": "类型"
					},
					{
						"key": "state",
						"title": "状态",
						'slot':'state'
					}


				],
				addQrcode: false,
				searchValue: "",
				title: [], //表头
				list: [], //数据集
				EmCodeList: "",
				formadd: {
					keyword: ""
				},
				formedit:{
					"appuserid": "", //小程序用户数据id
					"realName": "" 
				},
				ruleValidate: {
					"keyword": {
						required: true,
						trigger: 'blur'
					},
					'realName':{
						required: true,
						trigger: 'blur'
					}
				},
				accountFindInfo: "",
			}
		},
		watch:{
			addQrcode(value){
				if(value){
					this.accountFindInfo=''
					this.formadd.keyword=''
					this.formedit.realName=''
				}
			}
		},
		async mounted() {
			this.HandleGetEmCodeList()
		},
		methods: {
			async handleAddYgcode(){
				
				let res = await GenerateEmployeeCode({
					"appuserid":this.accountFindInfo._id //小程序用户数据id （后台查找小程序用户）接口中的_id
				})
				this.modalAddYgcode=false
				if (res.errcode != 0){
					 return
				}
				await this.HandleGetEmCodeList()
				this.addQrcode=false
			},
			popEditMdal(){
				this.editModal=true
				this.currentEditInfo=JSON.parse(JSON.stringify(this.accountFindInfo))
				this.formedit.realName=this.currentEditInfo.realName
			},
			async handleformedit(){
				this.$refs["form"].validate(async (valid) => {
					if (valid) {
						let res = await EditAppuser({
							"appuserid": this.currentEditInfo._id, //小程序用户数据id
							"type": "设置真实姓名", //编辑类型 ["设置真实姓名"]
							"realName": this.formedit.realName
						})
						if (res.errcode != 0) return
						this.editModal=false
						this.find()
					}
				})
				
			},
			find() {
				this.$refs["form"].validate(async (valid) => {
					if (valid) {
						let res = await FindAppuser({
							keyword: this.formadd.keyword
						})
						if (res.errcode != 0) return
						this.accountFindInfo = res.data
					}
				})
			},
			qrcodeDownload(row) {
				window.open(`${row.promoCode}?attname=${row.realName}.png`, '_blank');
			},
			async handleEditEmployeeCode(row,state) {
				await EditEmployeeCode({
					"appuserid": row._id, //小程序用户数据id
					"type": state
				})
				this.HandleGetEmCodeList()
			},
			async HandleGetEmCodeList(page = 1, name) {

				let {
					data: res_data
				} = await GetEmCodeList({
					page,
					name
				})

				res_data.title.forEach(item => {
					if (item.key == 'promoCode') {
						item.slot = 'promoCode'
					}
					if (item.key == 'state') {
						item.slot = 'state'
					}
				})

				res_data.title.push({
					title: '操作',
					key: 'operation',
					slot: 'operation',
					width: 200
				})
				this.EmCodeList = res_data


			}

		},

	}
</script>

<style>
	.dw_fx .ivu-btn {
		width: 200px !important;
	}

	.top,
	.bottom {
		text-align: center;
	}

	.center {
		width: 300px;
		margin: 10px auto;
		overflow: hidden;
	}

	.center-left {
		float: left;
	}

	.center-right {
		float: right;
	}

	/* 修改默认按钮样式 */
	.right_btn .ivu-btn {
		width: 100px !important;
	}

	.pre_info span {
		border-left: 3px solid #ccc;
		font-weight: 700;
	}
</style>
