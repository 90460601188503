<template>
    <div id="app">
        <!-- 全局加载动画 -->
        <div id="loading"></div>

        <!-- 应用路由 -->
        <div>
            <router-view />
        </div>
    </div>
</template>
<script>
export default {
    name: 'app',
    data() {
        return {};
    },
};
</script>

<style>
.shenlue3 {
    word-break: break-all;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.shenlue1 {
    word-break: break-all;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
/* ./your-css-folder/styles.css */
.ivu-poptip-body-content {
    overflow: hidden !important;
}
#menu .ivu-menu-item.ivu-menu-item-active.ivu-menu-item-selected {
    background: #333439 !important;
    color: rgb(246, 202, 157) !important;
}

#menu .ivu-menu {
    background-color: #000 !important;
}
#menu .ivu-menu-submenu-title {
    background-color: #000 !important;
}
#menu .ivu-menu-dark {
    background-color: #000 !important;
}
.ivu-menu {
    width: 190px !important;
}
.ivu-menu-item-group {
    width: 190px !important;
}
input {
    margin: unset !important;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
.ivu-drawer {
    width: 50vw !important;
}
.ivu-spin-fix {
    background-color: unset;
}
.demo-spin-container {
    position: relative;
    top: 10%;
    left: 50%;
    display: inline-block;
    position: relative;
    border: 1px solid #eee;
}
</style>
