<template>
	<div>
		<div style="text-align: right;">
			<Form inline>
				<FormItem>
					<DatePicker  @on-change='($)=>current_select_date=$'   type="month" placeholder="请选择拉新月份" style="width: 200px"></DatePicker>
				</FormItem>
				<FormItem>
					<Button @click="()=>do_GetpullNewData()" style="margin-left: 20px;" type='primary'>查询</Button>
				</FormItem>
				<FormItem>
					<Button @click="()=>exportData()" style="margin-left: 10px;" >导出全部数据</Button>
				</FormItem>
			</Form>
		</div>
		<div style="margin-top: 10px;">
			<Table stripe ref="table" :columns='title' :data='list'></Table>
		</div>
	</div>
</template>

<script>
	
	import { download } from '@/utils/table_download.js'
	let mcode;
	import {GetpullNewData} from '@/request/api.js'
	export default{
		name:'navbar',
		data() {
			
			return{
				current_select_date:'',//当前选中的查询日期
				title:[],//当前查询日期表头
				list:[],//当前查询日期当前页记录数
			}
			
		},
		mounted(){
			mcode = localStorage.getItem('mcode')
		},
		methods:{
			
			// 导出全部数据Ajax数据获取
			async exportData(){
				
				if(this.current_select_date==''){
					this.$mytoast_err('请选择日期')
					return
				}
				let pass_data = {
					'day':this.current_select_date,//当前查询日期拉新月份xxxx-xx
					'mcode':mcode
				}
				
				let {data:res_data} = await GetpullNewData(pass_data)
			
			    download({
					'worksheet':"拉新数据汇总",
					headers:res_data.title,
					data:res_data.list
				});
				
			},
			
			// 获取拉新数据AJax数据获取
			async do_GetpullNewData(){
				
				 // 表格数据初始化
				this.list = [];
				this.title = [];
				if(this.current_select_date==''){
					this.$mytoast_err('请选择日期')
					return
				}
				let pass_data = {
					'day':this.current_select_date,//当前查询日期拉新月份xxxx-xx
					'mcode':mcode
				}
				
				let {data:res_data} = await GetpullNewData(pass_data)
				
				this.title=res_data.title;//当前查询日期表头
				this.list = res_data.list;//当前查询日期单页数据集
				
			}
		
		}
	}
</script>

