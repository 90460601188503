<template>
    <div>
        <h1 class="mb-3">抖音团购</h1>
        <Card style="width: 1100px">
            <Tabs @on-click="changeTab" v-model="tabIndex" type="card">
                <TabPane name="抖音团购" label="抖音团购">
                    <Form style="display: flex; justify-content: flex-end" inline>
                        <FormItem>
                            <Checkbox v-model="isuseTime">只搜索使用时间内商品</Checkbox>
                        </FormItem>
                        <FormItem prop="">
                            <Input placeholder="商品名称搜索" v-model="name" />
                        </FormItem>
                        <Button class="mr-2" @click="() => fetTableShopping(tableShoppingData.page, name)">搜索</Button>
                        <Button type="error" @click="showSomeModal">同步数据</Button>
                    </Form>
                    <Table size="small" :columns="tableShoppingData.title" :data="tableShoppingData.list">
                        <template slot-scope="{ row }" slot="openCash">
                            <i-Switch @on-change="(value) => destoryCode(row._id, value)" :value="row.openCash"></i-Switch>
                        </template>
                        <template slot-scope="{ row }" slot="givList">
                            <Button @click="() => showSettingModel(row)" size="small">{{ row.givList }}</Button>
                        </template>
                        <template slot-scope="{ row }" slot="online_status">
                            <Tag v-if="row.online_status == '在线'" type="dot" color="success">在线</Tag>
                            <Tag v-if="row.online_status == '导入'" color="success" type="dot">导入</Tag>
                            <Tag v-if="row.online_status == '下线'" type="dot">下线</Tag>
                        </template>
                        <template slot-scope="{ row }" slot="Note">
                            <div class="text-red-500">{{ row.Note }}</div>
                        </template>
                    </Table>
                    <div class="text-right">
                        <Page :page-size="tableShoppingData.siez" :total="tableShoppingData.total" @on-change="fetTableShopping"></Page>
                    </div>
                </TabPane>
                <TabPane name="核销查询" label="核销查询">
                    <div class="flex justify-end">
                        <Form ref="formPayCode" :model="formPayCode" :rules="ruleFormPayCode" inline>
                            <FormItem prop="startTime">
                                <DatePicker
                                    transfer
                                    v-model="formPayCode.startTime"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    placeholder="核销开始时间"
                                    style="width: 200px"
                                ></DatePicker>
                            </FormItem>
                            <FormItem prop="endTime">
                                <DatePicker
                                    transfer
                                    v-model="formPayCode.endTime"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    placeholder="核销结束时间"
                                    style="width: 200px"
                                ></DatePicker>
                            </FormItem>
                            <FormItem prop="dycode">
                                <Input v-model="formPayCode.dycode" placeholder="团购码" />
                            </FormItem>
                            <FormItem prop="orderid">
                                <Input v-model="formPayCode.orderid" placeholder="团购订单号" />
                            </FormItem>
                            <FormItem>
                                <Button @click="() => fetchPayCodeTableData()">查询</Button>
                            </FormItem>
                            <FormItem>
                                <Button @click="exportPayCodeTable" type="primary">导出</Button>
                            </FormItem>
                        </Form>
                    </div>
                    <Table size="small" :columns="payCodeTableData.title" :data="payCodeTableData.list">
                        <template slot-scope="{ row }" slot="revoke">
                            <Tag v-if="row.revoke == '已撤销'">{{ row.revoke }}</Tag>
                            <Tag v-if="row.revoke == '已核销'" color="cyan">{{ row.revoke }}</Tag>
                        </template>
                        <template slot-scope="{ row }" slot="isRelieve_res">
                            <div style="font-size: 3px">{{ row.isRelieve_res }}</div>
                        </template>
                        <template slot-scope="{ row }" slot="opreate">
                            <Button v-if="row.revoke == '已核销' && row.isRevoke" type="error" size="small" @click="() => handleBackupBtn(row)">撤销</Button>
                            <Button @click="() => openWindow(row._id)" size="small">详情</Button>
                        </template>
                    </Table>
                    <div class="text-right">
                        <Page :page-size="payCodeTableData.size" :total="payCodeTableData.total" @on-change="fetchPayCodeTableData"></Page>
                    </div>
                </TabPane>

                <TabPane name="手动导入" label="手动导入">
                    <div class="flex justify-end mb-3">
                        <Form :disabled="xlslTableList.length > 0" inline>
                            <FormItem>
                                <Upload :show-upload-list="false" :before-upload="handleUpload" multiple action="//jsonplaceholder.typicode.com/posts/">
                                    <Button>本地导入</Button>
                                </Upload>
                            </FormItem>
                        </Form>
                        <Button class="mr-2" @click="() => downTemplate()">下载模版</Button>
                        <Button class="mr-2" :disabled="xlslTableList.length >= 20" @click="showdyModalAdd">添加</Button>
                        <Button @click="xlslTableList = []">清空导入表格</Button>
                    </div>
                    <Table :columns="xlslTableHeader" :data="xlslTableList">
                        <template slot-scope="{ row }" slot="exeType">
                            <div v-if="row.exeType == '导入异常'" class="text-red-500">{{ row.exeType }}</div>
                            <div v-else>{{ row.exeType }}</div>
                        </template>
                        <template slot-scope="{ row }" slot="result">
                            <div v-if="row.result == '成功'" class="text-green-400">{{ row.result }}</div>
                            <div v-else class="text-red-500">{{ row.result }}</div>
                        </template>
                    </Table>
                    <div class="mt-3 text-right">
                        <Button @click="uploadTableData" v-if="xlslTableList.length > 0">确认上传</Button>
                    </div>
                </TabPane>
            </Tabs>
        </Card>
        <Modal title="兑换表编辑" width="700" :mask="false" v-model="settingModel" :closable="false">
            <Form :label-width="100">
                <FormItem label="商品ID">
                    <Input disabled v-model="currentTableRow.sku_id" />
                </FormItem>
                <FormItem label="商品名称">
                    <Input disabled v-model="currentTableRow.sku_name" />
                </FormItem>
                <Row>
                    <Col :span="12">
                        <FormItem label="状态">
                            <Input disabled v-model="currentTableRow.online_status" />
                        </FormItem>
                    </Col>
                    <Col :span="12">
                        <FormItem label="类型">
                            <Input disabled v-model="currentTableRow.product_type" />
                        </FormItem>
                    </Col>
                </Row>
                <FormItem label="销售时间">
                    <Input disabled :value="`${currentTableRow.start_time}至${currentTableRow.end_time}`" />
                </FormItem>
                <FormItem label="使用时间">
                    <Input disabled :value="currentTableRow.use_time_str" />
                </FormItem>
            </Form>
            <Form :label-width="10" ref="formPreset" :model="formPreset" :rules="ruleFormPreset">
                <Row>
                    <Col :span="7">
                        <FormItem prop="value1" :label-width="30">
                            <Select placeholder="请选择票券" v-model="formPreset.value1">
                                <Option v-for="(item, index) in presetValue" :key="index" :value="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col :span="7">
                        <FormItem prop="value2">
                            <Input type="number" placeholder="兑换量" v-model.number="formPreset.value2" />
                        </FormItem>
                    </Col>
                    <Col :span="7">
                        <FormItem prop="value3">
                            <Input type="number" placeholder="有效期" v-model.number="formPreset.value3" />
                        </FormItem>
                    </Col>
                    <Col :span="3">
                        <FormItem>
                            <Button @click="insetTableData" type="primary">插入</Button>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <Alert show-icon>有效期填写:抖音商品使用时间(-1);票券申请单有效期(0);绑定时间+有效期动态生成(大于0)</Alert>
            <Table :columns="title" :data="currentTableRow.givList" size="small">
                <template slot-scope="{ row, index }" slot="operater">
                    <Button type="error" size="small" @click="() => removeInsetTableRow(index)">删除</Button>
                </template>
            </Table>

            <div slot="footer">
                <Button @click="() => cancelSettingModal()">取消</Button>
                <Button v-if="$my_checkBtn('settingchange')" type="primary" @click="confimSettingModal">确定</Button>
            </div>
        </Modal>
        <Modal :mask="false" :closable="false" v-model="backupModel">
            <h1>撤销原因</h1>
            <Alert show-icon>撤销核销并非团购退款，只是将团购码撤销为未核销状态，退款请到抖音来客后台操作</Alert>
            <Form ref="backupForm" :model="backupForm" :rules="ruleBackupForm">
                <FormItem prop="note">
                    <Input :rows="3" type="textarea" v-model="backupForm.note" />
                </FormItem>
                <FormItem label="" prop="isRelieve"> <Checkbox v-model="backupForm.isRelieve"></Checkbox>回收销毁 已发放票券 </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="cancelBackUpModal">返回</Button>
                <Button type="primary" @click="confirmBackUp">确认</Button>
            </div>
        </Modal>
        <Modal title="同步数据" :mask="false" :closable="false" v-model="someModal">
            <Form ref="someModalref" :label-width="100" class="w-80" :model="someModalForm" :rules="someModalRules">
                <FormItem prop="select" label="选择同操作">
                    <Select v-model="someModalForm.select">
                        <Option value="同步全部上线商品">同步全部上线商品</Option>
                        <Option value="同步单个商品">同步单个商品</Option>
                    </Select>
                </FormItem>
                <FormItem prop="product_id" label="抖音商品ID" v-if="someModalForm.select == '同步单个商品'">
                    <Input v-model="someModalForm.product_id" />
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="() => cancelSomeModal()">取消</Button>
                <Button type="primary" @click="() => confirmSomeModal()">确定</Button>
            </div>
        </Modal>
        <Modal title="添加" :mask="false" v-model="dyTableUploadModal" :closable="false">
            <Form class="w-96" ref="uploadForm" :model="uploadForm" :rules="uploadFormRules" :label-width="120">
                <FormItem prop="sku_id" label="商品ID">
                    <Input v-model="uploadForm.sku_id" />
                </FormItem>
                <FormItem prop="sku_name" label="商品名称">
                    <Input v-model="uploadForm.sku_name" />
                </FormItem>
                <FormItem prop="start_time" label="售卖时间(开始)">
                    <Input v-model="uploadForm.start_time" />
                </FormItem>
                <FormItem prop="end_time" label="售卖时间(结束)">
                    <Input v-model="uploadForm.end_time" />
                </FormItem>
                <FormItem prop="use_end_time" label="使用时间(开始)">
                    <Input v-model="uploadForm.use_end_time" />
                </FormItem>
                <FormItem prop="use_start_time" label="使用时间(结束)	">
                    <Input v-model="uploadForm.use_start_time" />
                </FormItem>
                <FormItem prop="exeType"></FormItem>
            </Form>
            <div slot="footer">
                <Button @click="dyTableUploadModal = false">取消</Button>
                <Button @click="confirmAddTableRow" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import {
    GetTiktokShopList,
    GetDyGoosList,
    ExeDyGoos,
    GetPresetValue,
    GetGoosCashRecordList,
    GetGoosCashRecordInfo,
    RevokeCash,
    GetTiktokShop,
    CheckmanualEdit,
    ManualEdit,
} from '@/request/api';
import { CellGroup, Checkbox, FormItem, Input, Time } from 'view-design';
import { download } from '@/utils/table_download.js';
import { readXls2Json } from '../../../utils/table_download';
export default {
    name: 'dyShopping',
    data() {
        return {
            tabIndex: '抖音团购',
            tableShoppingData: '',
            name: '',
            settingModel: false,
            currentTableRow: '',
            title: [
                { key: 'type', title: '类型' },
                { key: 'Name', title: '名称' },
                { key: 'Num', title: '数量' },
                { key: 'Time', title: '有效期' },
                { key: 'operater', title: '操作', slot: 'operater' },
            ],
            presetValue: [],
            formPreset: {
                value1: '',
                value2: '',
                value3: '',
            },
            ruleFormPreset: {
                value1: { required: 'true', trigger: 'blur' },
                value2: { required: 'true', trigger: 'blur', type: 'number' },
                value3: { required: 'true', trigger: 'blur', type: 'number' },
            },
            formPayCode: {
                startTime: new Date().Format('yyyy-MM-dd') + ' 06:00',
                endTime: new Date(new Date(Date.now() + 1000 * 24 * 60 * 60)).Format('yyyy-MM-dd') + ' 06:00',
                dycode: '',
                orderid: '',
            },
            ruleFormPayCode: {},
            payCodeTableData: {},
            backupForm: {
                isRelieve: true,
                note: '',
            },
            ruleBackupForm: {
                note: { required: true, trigger: 'blur', message: ' ' },
                isRelieve: { required: true, trigger: 'blur', message: ' ', type: 'boolean' },
            },
            backupModel: false,
            isuseTime: true,
            someModalSelectBind: '',
            someModalForm: {
                product_id: '',
                select: '同步全部上线商品',
            },
            someModalRules: {
                select: { require: true, trigger: 'blur', message: ' ' },
                product_id: { require: true, trigger: 'blur', message: ' ' },
            },
            someModal: false,
            xlslTableHeader: [
                {
                    title: '商品ID',
                    key: 'sku_id',
                },
                {
                    title: '商品名称',
                    key: 'sku_name',
                },
                {
                    title: '售卖时间(开始)',
                    key: 'start_time',
                },
                {
                    title: '售卖时间(结束)',
                    key: 'end_time',
                },
                {
                    title: '使用时间(开始)',
                    key: 'use_start_time',
                },
                {
                    title: '使用时间(结束)',
                    key: 'use_end_time',
                },
                {
                    title: '导入类型',
                    key: 'exeType',
                    slot: 'exeType',
                },
                {
                    title: '执行结果',
                    key: 'result',
                    slot: 'result',
                },
            ],
            xlslTableList: [],
            dyTableUploadModal: false,
            uploadForm: {
                exeType: '',
                sku_id: '',
                sku_name: '',
                start_time: '',
                end_time: '',
                use_end_time: '',
                use_start_time: '',
            },
            uploadFormRules: {
                sku_id: { required: 'true', trigger: 'blur', message: ' ' },
                sku_name: { required: 'true', trigger: 'blur', message: ' ' },
                start_time: { required: 'true', trigger: 'blur', message: ' ' },
                end_time: { required: 'true', trigger: 'blur', message: ' ' },
                use_end_time: { required: 'true', trigger: 'blur', message: ' ' },
                use_start_time: { required: 'true', trigger: 'blur', message: ' ' },
            },
        };
    },
    created() {
        this.fetTableShopping();
    },
    methods: {
        /**
         * 显示抖音表格添加弹窗
         */
        showdyModalAdd() {
            this.dyTableUploadModal = true;
            this.$refs['uploadForm'].resetFields();
        },
        /**
         * 抖音导入添加一行表格数据
         */
        async confirmAddTableRow() {
            this.$refs['uploadForm'].validate(async (valid) => {
                if (valid) {
                    //预校验数据
                    await this.preUpload(this.uploadForm);
                    // 数据下方到表格下
                    if (this.uploadForm['exeType'] != '导入异常') {
                        this.xlslTableList.unshift(this.uploadForm);
                        this.dyTableUploadModal = false;
                    }
                }
            });
        },
        async downTemplate() {
            let res = await GetPresetValue({ type: '抖音导入模板' });
            if (res.errcode != 0) return;
            var a = document.createElement('a'); //创建一个a标签 用来下载
            a.download = '抖音导入模板'; //设置下载的图片名称
            var event = new MouseEvent('click'); //增加一个点击事件
            //如果需要下载的话就加上这两句
            a.href = res.data; //此处的url为base64格式的图片资源
            a.dispatchEvent(event); //触发a的单击事件 即可完成下载
        },
        /**
         * 刷新表格
         */
        reloadTableUpload() {
            this.xlslTableList = JSON.parse(JSON.stringify(this.xlslTableList));
        },
        /**
         * 预校验单挑
         */
        async preUpload(data) {
            let { sku_id } = data;
            let res = await CheckmanualEdit({ sku_id });
            if (res.errcode == 0) {
                //正常返回
                if (res.data == 1) {
                    data['exeType'] = '创建';
                }
                if (res.data == 2) {
                    data['exeType'] = '编辑';
                }
            } else {
                //  报错返回
                data['exeType'] = '导入异常';
            }
        },
        /**
         * 欲校验确认上传
         */
        async preUploadTableData() {
            let list = this.xlslTableList;
            for (let i = 0; i < list.length; i++) {
                await this.preUpload(list[i]);
            }
            this.reloadTableUpload();
        },
        /**
         *  确认上传按钮
         */
        async uploadTableData() {
            let list = this.xlslTableList;
            for (let i = 0; i < list.length; i++) {
                let data = list[i];
                if (data['exeType'] != '导入异常') {
                    let passdata = JSON.parse(JSON.stringify(data));
                    try {
                        passdata['start_time'] = passdata['start_time'].split('.').join('-').replaceAll('/', '-');
                        passdata['end_time'] = passdata['end_time'].split('.').join('-').replaceAll('/', '-');
                        passdata['use_end_time'] = passdata['use_end_time'].split('.').join('-').replaceAll('/', '-');
                        passdata['use_start_time'] = passdata['use_start_time'].split('.').join('-').replaceAll('/', '-');
                    } catch (error) {
                        this.$Message.error(error.message);
                    }
                    let res = await ManualEdit(passdata);
                    if (res.errcode == 0) {
                        data['result'] = '成功';
                    } else {
                        data['result'] = '失败';
                        console.log(data);
                    }
                }
            }
            this.reloadTableUpload();
        },
        async handleUpload(file) {
            //构建映射表
            let tableMap = {
                '使用时间(开始)': 'use_start_time',
                '使用时间(结束)': 'use_end_time',
                '售卖时间(开始)': 'start_time',
                '售卖时间(结束)': 'end_time',
                商品ID: 'sku_id',
                商品名称: 'sku_name',
            };
            let res = await readXls2Json(file);
            let tableList = [];
            //处理表格数据
            res.forEach((item, index) => {
                let tabelRow = {};
                for (let key in item) {
                    let realKey = tableMap[key];
                    let value = item[key];
                    //过滤undefined
                    if (realKey) {
                        tabelRow[realKey] = value;

                        //处理当前是日期格式的数据
                        if (typeof value == 'object') {
                            tabelRow[realKey] = new Date(value).Format('yyyy.MM.dd');
                        }
                    }
                    //追加表头数据 字段
                }
                console.log(12312);
                tableList.push(tabelRow);
            });
            console.log(tableList);
            this.xlslTableList = tableList.slice(0, 20);
            this.preUploadTableData();
            return false;
        },
        /**
         * 提交同步数据弹窗内容
         */
        async confirmSomeModal() {
            this.$refs['someModalref'].validate(async (valid) => {
                if (valid) {
                    const { select, product_id } = this.someModalForm;
                    //同步全部上线商品  选中 调用 tobesome
                    if (select == '同步全部上线商品') {
                        try {
                            await this.toBeSome();
                            this.someModal = false;
                        } catch (error) {}
                    }
                    if (select == '同步单个商品') {
                        let res = await GetTiktokShop({ product_id });
                        if (res.errcode != 0) return;
                    }

                    this.fetTableShopping();
                }
            });
        },
        /**
         * 取消同步数据弹窗
         */
        cancelSomeModal() {
            this.someModal = false;
        },
        /**
         * 显示同步数据弹窗
         */
        showSomeModal() {
            this.someModal = true;
            this.$refs['someModalref'].resetFields();
        },
        /**
         * 撤销按钮 点击
         */
        async handleBackupBtn(value) {
            this.currentTableRow = value;
            this.backupModel = true;
            this.$refs['backupForm'].resetFields();
        },
        /**
         * 撤销记录撤销 提交
         */
        async confirmBackUp() {
            this.$refs['backupForm'].validate(async (valid) => {
                console.log(valid);
                if (valid) {
                    const { _id } = this.currentTableRow;
                    let res = await RevokeCash({
                        id: _id,
                        ...this.backupForm,
                    });
                    if (res.errcode != 0) return;
                    this.backupModel = false;
                    this.fetchPayCodeTableData();
                }
            });
        },
        /**
         * 关闭撤销弹窗
         */
        cancelBackUpModal() {
            this.backupModel = false;
        },
        /**
         * 跳转核销详情页面
         */
        openWindow(id) {
            window.open('/payCodeDetail?id=' + id);
        },
        /**
         * 切换tabs
         */
        changeTab(value) {
            if (value == '抖音团购') {
                this.fetTableShopping();
            }
            if (value == '核销查询') {
                this.fetchPayCodeTableData();
            }
        },
        /**
         * 导出所有核销的表格数据
         */
        async exportPayCodeTable() {
            let data = Object.assign({}, this.formPayCode);
            data['page'] = 1;
            data['all'] = true;
            let res = await GetGoosCashRecordList(data);
            if (res.errcode != 0) return;
            const { list, title } = res.data;
            data = list;
            download({
                worksheet: '核销',
                headers: title,
                data,
            });
        },
        /**
         * 查询核销的表格数据
         */
        async fetchPayCodeTableData(page = 1) {
            let data = Object.assign({}, this.formPayCode);
            data['page'] = page;
            data['all'] = false;
            let res = await GetGoosCashRecordList(data);
            if (res.errcode != 0) return;
            res.data.title.push({
                slot: 'opreate',
                title: '操作',
                key: 'opreate',
            });
            res.data.title.forEach((item, index) => {
                if (item.key == 'isRelieve_res') {
                    item['slot'] = 'isRelieve_res';
                }
                if (item.key == 'revoke') {
                    item['slot'] = 'revoke';
                }
            });
            this.payCodeTableData = res.data;
        },
        /**
         * 删除插入行的数据
         */
        removeInsetTableRow(index) {
            this.currentTableRow.givList.splice(index, 1);
        },
        /**
         * 执行插入数据到表格动作
         */
        insetTableData() {
            const { value1, value2, value3 } = this.formPreset;
            const { value: id, label: Name } = this.presetValue.filter((item, index) => item.value == value1)[0];
            let data = {
                type: '票券',
                id: id,
                Num: value2, //兑换量1-99
                Time: value3, //有效期 0使用模板 1为绑定后起1天
                Name: Name, //票券名称
            };
            this.currentTableRow.givList.push(data);
            this.$refs['formPreset'].resetFields();
        },
        /**
         * 插入表格预设值 获取
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({ type: '获取非赠券' });
            if (res.errcode != 0) return false;
            this.presetValue = res.data;
            return true;
        },
        /**
         * 取消 设置 弹窗
         */
        cancelSettingModal() {
            this.settingModel = false;
        },
        /**
         * 提交 设置 弹窗
         */
        async confimSettingModal() {
            const { _id, givList } = this.currentTableRow;
            let data = {
                exeType: '设置兑换表',
                id: _id,
                givList: givList,
            };
            let res = await ExeDyGoos(data);
            if (res.errcode != 0) return;
            this.fetTableShopping(this.tableShoppingData.page, this.name);
            this.settingModel = false;
        },
        /**
         * 获取表格行详情数据
         */
        async getTableRowData(id) {
            let res = await ExeDyGoos({ exeType: '获取详情', id });
            if (res.errcode != 0) return false;
            this.currentTableRow = res.data;
        },
        /**
         * 弹窗 当前表格行数据
         */
        showSettingModel(value) {
            const { _id } = value;
            this.handleGetPresetValue();
            this.$refs['formPreset'].resetFields();
            let access = this.getTableRowData(_id);
            if (access == false) return;
            this.settingModel = true;
        },
        /**
         * 核销 切换
         * @param {*} id 商品id
         * @param {*} value 切换的值 boll
         */
        async destoryCode(id, value) {
            await ExeDyGoos({
                exeType: '允许核销',
                id: id,
                openCash: value,
            });
            this.fetTableShopping(this.tableShoppingData.page, this.name);
        },
        /**
         * 同步商品
         */
        async toBeSome() {
            let res = await GetTiktokShopList({});
            if (res.errcode != 0) return;
            this.$Message.success('同步成功');
            this.fetTableShopping(this.tableShoppingData.page, this.name);
        },
        /**
         * 表格获取 团购商品
         */
        async fetTableShopping(page = 1, name = '') {
            let res = await GetDyGoosList({ name, page, isuseTime: this.isuseTime });
            if (res.errcode != 0) return;
            res.data.title.forEach((item, index) => {
                if (item.key == 'openCash') {
                    item['slot'] = 'openCash';
                }
                if (item.key == 'givList') {
                    item['slot'] = 'givList';
                }
                if (item.key == 'online_status') {
                    item['slot'] = 'online_status';
                    item['width'] = 150;
                }
                if (item.key == 'Note') {
                    item['slot'] = 'Note';
                }
            });
            this.tableShoppingData = res.data;
        },
    },
    components: { FormItem, Input, Checkbox },
};
</script>
