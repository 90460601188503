<template>
	<div>
		<!-- 第一层 -->
		<div>
			<!-- 订单信息展示 -->
			<div style="margin: 60px 0; height: 100px;display: flex;align-items: center;justify-content: space-between;">
				<Card v-for="(item,index) in res_salesOrderSummary" :shadow='true' style="width:18vw;height: 100%;">
					<div style="text-align:center;display: flex;justify-content: space-around;">
						<div>
							<h3 style="position: relative;color: #ccc;">
								<span
									:style="`background-color: ${statistics[index].type};left: -10px;top: 50%;transform: translate(-50%,-50%); position: absolute; display: inline-block;width: 10px;height: 10px;border-radius: 100px;`">
								</span>{{statistics[index].leftTitle}}
							</h3>
							<div style="font-weight: 700;">
								<!-- 订单总数 -->
								<div v-if="index=='order'" style="margin-top: 20px;">{{item.totalOrders}}</div>
								 <!-- 总人次  -->
								<div v-if="index=='sTime'" style="margin-top: 20px;">{{item.total}}</div>
								 <!-- 票券核销 -->
								<div v-if="index=='consTime'" style="margin-top: 20px;">{{item.total}}</div>
								<!-- 会员充值 -->
								<div v-if="index=='members'" style="margin-top: 20px;">{{item.amount}}</div>
							</div>
						</div>
						<div>
							<h3 style="color: #ccc;">{{statistics[index].rightTitle}}</h3>
							<div style="font-weight: 700;">
								<!-- 退款订单 -->
								<div v-if="index=='order'" style="margin-top: 20px;">{{item.aRefundOrders}}</div>
								<!-- 总票房 -->
								<div  v-if="index=='sTime'"  style="margin-top: 20px;">{{item.amount}}</div>
								<!-- 票房 -->
								<div v-if="index=='consTime'"  style="margin-top: 20px;">{{item.amount}}</div>
								<!-- 会员消费 -->
								<div v-if="index=='members'"  style="margin-top: 20px;">{{item.gst}}</div>
							</div>
						</div>
					</div>
				</Card>

			</div>
			<!-- 表格顶上的数据 -->
			<div>
				<div style="display: flex;">
					<!-- 时间选择 -->
					<div>
						<DatePicker v-model='value'  type="datetimerange"
							placeholder="Select date and time" style="width: 300px"></DatePicker>
					</div>
					<!-- 下拉菜单选择 -->
					<div style="margin: 0 20px;">
						<Dropdown @on-click='dropdown_model_select' style="margin-left: 20px">
							<Button type="primary">
								{{select_state?select_state:state[12]}}
								<Icon type="ios-arrow-down"></Icon>
							</Button>
							<DropdownMenu slot="list">
								<DropdownItem v-for="(item,index) in state" :name='index'>{{item}}</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
					<!-- 获取数据按钮   -->
					<div style="margin:0 30px ; ">
						<Button @click='order_get(1)' type="dashed">获取</Button>
					</div>
					<Button type="primary" size="mini" @click="exportData()">
						<Icon type="ios-download-outline"></Icon> 导出全部数据
					</Button>
				</div>
			</div>
			<!-- 表格部分 -->
			<div>
				<!-- 表格主体部分 -->
				<div>
					<Table stripe ref="table" stripe style="margin-top: 50px;" :columns="columns1" :data="order_data">
						<!-- 订单类型 标签显示 -->
						<template slot-scope="{ row, index }" slot="order_type">
							<!-- 判断标签逻辑显示部分 -->
							<div>
								<div v-if="row.state == 1">
									<Tag color="warning">{{state[row.state]}}</Tag>
								</div>
								<div v-if="row.state == 2">
									<Tag color="primary">{{state[row.state]}}</Tag>
								</div>
								<div v-if="row.state == 4">
									<Tag color="error">{{state[row.state]}}</Tag>
								</div>
								<div v-if="row.state == 5">
									<Tag color="default">{{state[row.state]}}</Tag>
								</div>
							</div>
						</template>
						<!-- 订单描述 插槽部分 -->
						<template slot-scope="{ row, index }" slot="orderAbout">
							<div>
								{{deal_order_detail(row)}}
							</div>
						</template>
						<!-- 查看详情 -->
						<template slot-scope="{ row, index }" slot="operation">
							<div>
								<Button @click="get_detail(row)" type="primary">查看详情</Button>
							</div>
						</template>
						<!-- 支付类型 -->
						<template slot-scope="{ row, index }" slot="paytype">
							<!-- 2为会员支付 0 为微信支付 -->
							{{row.paytype}}
						</template>
					</Table>
				</div>
				<!-- 表格分页部分 -->
				<div
					style="background-color: white;height: 50px;display: flex;justify-content: flex-end;align-items: center;padding-right: 50px;">
					<div></div>
					<Page ref='page' :page-size='size' :total="total" @on-change='on_change_page' />
				</div>
			</div>
		</div>
		<!-- 负一层 显示订单详情 -->
		<div>
			<Drawer title="订单详情" :closable="false" v-model="show_detail_Drawer">
				<!-- 电话号码 -->
				<div>
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">电话号码
					</p>
					<div style="padding-left: 20px;">
						{{mobilePhone}}
					</div>
				</div>
				<!-- 订单信息 -->
				<div>
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单信息
					</p>
					<div style="padding-left: 20px;position: relative;">

						订单号 {{order.orderid}}
						<br> 订单描述
						{{order.orderName}}
						<br />
						{{order.orderAbout}}

						<br>

						<!-- 解决报错问题  数据未到达时候无长度报错问题 -->
						<!-- 支付类型 {{order.paytype.length>0?'会员':'微信'}} -->
						<span v-if="order.paytype">
							支付类型 {{order.paytype.length>0?'会员':'微信'}}
						</span>

						<br>
						内部ID {{order._id}}
						<br>
						订单状态 {{state[order.state]}}
						<br>

						订单创建时间 {{order.createTime}}

						<br>
						订单结束时间 {{order.time_end}}
						<br>
						订单价格 {{order.orderPrice/100}}元
						<br>
						实付金额 {{order.cash_fee/100}}元
						<br>
						优惠价格 {{order.privilege/100}}元
						<br>
						退款金额 {{order.refund}}
						<br>
						退款时间 {{order.retime}}
						<br>
						退款说明 {{order.retNote}}
						<br>
						订单说明 {{order.Note}}

						<br />
						用户串码 {{order.openid}}
						<br>
						影院编号 {{order.mcode}}
						<br>
						退款单号 {{order.refereesid}}
						<br>
						<div style="width: 200px ;height: 300px;position: absolute;top: 0;right: 0;">
							<img style="width: 100%;height: 100%;" :src="order.imgurl">
						</div>
					</div>
				</div>


				<!-- 商品 票券信息 -->
				<div>
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">商品/票券信息</p>
					<div style="padding-left: 20px;">

						<div v-if="goos_couponlist_data">
							<Table stripe style="margin-top: 50px;" :columns="columns2" :data="goos_couponlist_data">

								<!-- 座位 -->
								<template slot-scope="{ row, index }" slot="seat">

									<div>
										<pre>{{row.data.seat.str}}</pre>
									</div>

								</template>

								<!-- 影厅名称 -->
								<template slot-scope="{ row, index }" slot="hallName">

									<div>
										<pre>{{row.data.movie.hallName}}</pre>
									</div>

								</template>

								<!-- 日期-->
								<template slot-scope="{ row, index }" slot="showDate">

									<div>
										<pre>{{row.data.movie.showDate}} {{row.data.movie.showTime }}</pre>
									</div>

								</template>


								<!-- 取票码-->
								<template slot-scope="{ row, index }" slot="qrCode">

									<div>
										<pre>{{row.data.qrCode}}</pre>
									</div>

								</template>

								<!-- 券名-->
								<template slot-scope="{ row, index }" slot="couponName">

									<div>
										<pre>{{row.couponId[0].asname?row.couponId[0].asname:row.couponId[0].name}}
										</pre>
									</div>

								</template>


								<!-- 券码-->
								<template slot-scope="{ row, index }" slot="couponCode">

									<div>
										<pre>{{row.couponId[0].couponCode}}</pre>
									</div>

								</template>


								<!-- 是否取票-->
								<template slot-scope="{ row, index }" slot="isprint">

									<div>
										<pre>{{row.data.isprint?'是':'否'}}</pre>
									</div>

								</template>

								<!-- 是否取票-->
								<template slot-scope="{ row, index }" slot="operation">

									<div>

										<div v-if="row.allowRefund">
											<Button type="error">退款</Button>
										</div>

										<div v-else>

										</div>

									</div>

								</template>

							</Table>
						</div>



					</div>
				</div>

			</Drawer>
		</div>

	</div>
</template>

<script>
	
	import { find_order, order_detail, salesOrderSummary } from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {
			
			return {
				res_salesOrderSummary: '',
				total: '',
				goos_couponlist_data: '',
				show_detail_Drawer: false,
				currentPage: 1,
				page: 1,
				size: 30,
				order_data: [],
				columns2: [],
				columns1: [],
				value: [new Date(new Date().setHours(6, 0, 0)), new Date(new Date().setHours(30, 0, 0))],
				state: {
					12: "全部", //
					1: '未支付', //黄色    <Tag color="warning">warning</Tag>
					2: "已支付", //蓝色<Tag color="primary">primary</Tag>
					4: "已退款", //红色  <Tag color="error">error</Tag>
					5: "已关闭", //灰色 <Tag color="default">default</Tag>
				},
				select_state: '',
				select_state_key: '',
				couponslist: '',
				gooslist: [],
				mobilePhone: '',
				order: {},
				statistics:{
					'order':{
						'leftTitle':'订单总数',
						'rightTitle':'退款订单',
						'type':'blue'
					},
					'sTime':{
						'leftTitle':'总人次',
						'rightTitle':'总票房',
						'type':'orange'
					},
					'consTime':{
						'leftTitle':'票券核销',
						'rightTitle':'票房',
						'type':'green'
					},
					'members':{
						'leftTitle':'会员充值',
						'rightTitle':'会员消费',
						'type':'red'
					},
				}
			}
			
		},
		mounted() {
			this.order_get()

		},
		computed: {
			
			// 计算属性处理内部订单数组描述信息
			deal_order_detail() {
				return function(item) {

					return JSON.stringify(item.orderAbout).split("\"").join("").split(",").join("").split("[").join("")
						.split(']').join("")
				}
			}

		},
		methods: {
			
			// 导出全部数据
			async exportData() {
				
				// 加载数据
				let sizecp = this.size;
				this.size = this.total

				let res = await this.order_get(2)
				

	
				let order_data = res.list
				let title = this.columns1
				// 复原size
				this.size = sizecp
				//处理导出数据
				order_data.forEach(i => {
					i.state = this.state[i.state]
					i.orderAbout = i.orderAbout.join(" ")
				})
				this.$refs.table.exportCsv({

					filename: 'order_data',
					columns: title,
					data: order_data
				});
				
			},
			
			// 查看详情点击事件
			async get_detail(row_data) {
			
				this.goos_couponlist_data = []

				let orderid = row_data.orderid
		
				let res = await order_detail({
					orderid
				})
				
				this.couponslist = res.couponslist
				this.gooslist = res.gooslist
				this.mobilePhone = res.mobilePhone
				this.order = res.order
				// 处理商品票券信息 生成新的数据 初始化goos_couponlist_data
				let goos_couponlist_data = this.gooslist;
				this.gooslist.forEach((itemout, indexout) => {
					itemout.couponId.forEach((itemin, indexin) => {
						console.log(111111111, this.couponslist)
						// couponId数组内
						// console.log(goos_couponlist_data,'{}{{}{}}')
						// itemin 为couponslist数组内的一个 对象

						// 如果得到了couponslist 的id,  那么使用couponslist数组 使用过滤器命中 
						let push_data = this.couponslist.filter(item => {
							if (item[itemin]) {
								return item[itemin]
							}
						})
						goos_couponlist_data[indexout].couponId.pop()
						goos_couponlist_data[indexout].couponId.push(push_data[0][itemin])
					})
				})
				this.goos_couponlist_data = goos_couponlist_data
				this.show_detail_Drawer = true
				
			},

			on_change_page(down_page) {
				
				// 初始化page页数  为1
				// this.page = 1
				// this.$refs.page.currentPage =1
				let page = down_page
				// 再次复制页码数字
				this.page = page
				this.order_get()
				
			},

			// 下拉菜单选中事件
			dropdown_model_select(payload) {

				// 初始化page页数  为1
				// this.page = 1
				// 	this.$refs.page.currentPage =1
				this.select_state = this.state[payload]
				this.select_state_key = payload

			},

			// 获取订单信息
			async order_get(type) {

				if (type !== 2) {
					this.order_data = ''
				}
				let pass_data = {
					'mcode': '',
					'startTime': '',
					'endTime': '',
					'state': '',
					'page': 1,
					'size': 30
				}
				pass_data.mcode = localStorage.getItem('mcode')
				pass_data.startTime = Date.parse(this.value[0])
				pass_data.endTime = Date.parse(this.value[1])
				// 下拉菜单初始化下面参数  默认是1 为已支付
				pass_data.state = this.select_state_key ? this.select_state_key : 12
				pass_data.page = this.page
				pass_data.size = this.size
				
				let {data:res} = await find_order(pass_data)
	
				// 打印文本操作
				if (type == 2) {
					return res
				}
				let pass_data_salesOrderSummary = {
					'mcode': localStorage.getItem('mcode'),
					'startTime': Date.parse(this.value[0]),
					'endTime': Date.parse(this.value[1])
				}
				
				// 发送查询获取订单汇总
				let  salesOrderSummarys = await salesOrderSummary(pass_data_salesOrderSummary)
			
				this.res_salesOrderSummary = salesOrderSummarys.data
				let center = JSON.parse(JSON.stringify(this.res_salesOrderSummary))
				this.order_data = res.list
				this.total = res.total
				// 设置表格插槽
				res.title.forEach(item=>{
					// 订单状态
					if(item.key=='state'){
						item.slot='order_type'
					}
					// 支付类型
					if(item.key=='paytype'){
						item.slot='paytype'
					}
					//订单描述
					if(item.key=='orderAbout'){
						item.slot='orderAbout'
						item.width=300
					}
				})
				// 设置页面表头
				this.columns1 =res.title
				
			},
			

		}

	}
</script>

<style scoped>
	
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}
	
</style>
