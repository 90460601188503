<template>
    <div>
        <Upload :before-upload="handleUpload" multiple type="drag" action="//jsonplaceholder.typicode.com/posts/">
            <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                <p>Click or drag files here to upload</p>
            </div>
        </Upload>
        <Table :header="title" :data="list"></Table>
        <div v-html="asdf"></div>
    </div>
</template>
<script>
import { readCsv2json } from '@/utils/table_download.js';
import { read, writeFileXLSX, utils } from 'xlsx';
export default {
    nmame: 'test_csv_parse',
    data() {
        return {
            records: [],
            title: [],
            list: [],
            asdf: '',
        };
    },
    methods: {
        /**
         * csv装json
         */
        // async handleUpload (file) {
        //  	let res = await readCsv2json(file)
        // 	console.log(res)
        // },
        /**
         * xls转json
         * @param {*} file 上传的文件
         */
        async handleUpload(file) {
            const data = await file.arrayBuffer();
            /* data is an ArrayBufw b */
            const wb = read(data, { codepage: 0 });
            const ws = wb.Sheets[wb.SheetNames[0]];
            console.log(utils.sheet_to_json(ws));
        },
    },
};
</script>
