<template>
    <div>
        <div class="mb-5 text-right">
            <Form inline>
                <FormItem label="NS码:" :label-width="50">
                    <Input v-model="form.NS" />
                </FormItem>
                <FormItem>
                    <Button @click="() => addTermira()">添加终端</Button>
                </FormItem>
                <FormItem>
                    <Button @click="() => fetchTableData()" type="primary"> 查找 </Button>
                </FormItem>
            </Form>
        </div>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="operate">
                <Poptip transfer confirm title="确定是否释放?" @on-ok="() => beFree(row)">
                    <Button size="small" class="mr-5">释放</Button>
                </Poptip>
                <Button @click="() => edit(row)" type="primary" size="small">编辑</Button>
            </template>
        </Table>
        <Modal :closable="false" :maskClosable="false" v-model="modelTermira" title="">
            <Form :showMessage="false" ref="formValidate" :model="formValidate" :rules="ruleValidate" class="w-96" :label-width="120">
                <FormItem prop="NS" label="NS">
                    <Input disabled v-model="formValidate.NS" />
                </FormItem>
                <FormItem prop="url_a" label="url_a">
                    <Input v-model="formValidate.url_a" />
                </FormItem>
                <FormItem prop="url_b" label="url_b">
                    <Input v-model="formValidate.url_b" />
                </FormItem>
                <FormItem prop="openDevTools" label="openDevTools">
                    <i-Switch v-model="formValidate.openDevTools"></i-Switch>
                </FormItem>
                <FormItem prop="diyCharge" label="diyCharge">
                    <i-Switch v-model="formValidate.diyCharge"></i-Switch>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="() => cancel('formValidate')">取消</Button>
                <Button @click="() => submit()" type="primary">确定添加</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetAddSetTerm, NetPayTermGetList, SetTermExe } from '@/request/api';
export default {
    name: 'endPointMgr',
    data() {
        return {
            modleType: '',
            modelTermira: false,
            formValidate: {
                NS: '',
                url_a: '',
                url_b: '',
                openDevTools: '',
                diyCharge: '',
            },
            tableData: '',
            ruleValidate: {
                NS: { required: true, trigger: 'blur' },
                url_a: { required: true, trigger: 'blur' },
                url_b: { required: true, trigger: 'blur' },
                openDevTools: { required: true, trigger: 'blur', type: 'boolean' },
                diyCharge: { required: true, trigger: 'blur', type: 'boolean' },
            },
            form: {
                NS: '',
            },
        };
    },
    created() {
        this.fetchTableData();
    },
    methods: {
        /**
         * 释放 按钮
         */
        async beFree(value) {
            const { _id } = value;
            let passdata = {
                id: _id,
                type: '释放',
            };
            let res = await SetTermExe(passdata);
            if (res.errcode != 0) return;
            this.fetchTableData();
        },
        /**
         * 编辑 按钮
         */
        async edit(value) {
            const { _id } = value;
            let passdata = {
                id: _id,
                type: '获取',
            };
            let res = await SetTermExe(passdata);
            if (res.errcode != 0) return;
            this.formValidate = res.data;
            this.modelTermira = true;
            this.modleType = '编辑';
        },
        cancel(name) {
            this.$refs[name].resetFields();
            this.modelTermira = false;
        },
        /**
         * 确认添加 按钮
         */
        submit() {
            this.$refs['formValidate'].validate(async (valid) => {
                if (valid) {
                    const { _id, url_a, url_b, openDevTools, diyCharge } = this.formValidate;
                    let passdata = {
                        id: _id,
                        url_a,
                        url_b,
                        openDevTools,
                        type: this.modleType,
                        diyCharge,
                    };
                    let res = await SetTermExe(passdata);
                    if (res.errcode != 0) return;
                    this.modelTermira = false;
                    this.fetchTableData();
                }
            });
        },
        /**
         * 终端添加 按钮
         */
        async addTermira() {
            this.modleType = '添加';
            const { NS } = this.form;
            let passdata = { NS };
            if (this.form.NS == '') {
                this.$Message.warning('请输入NS码');
                return;
            }
            let res = await GetAddSetTerm(passdata);
            if (res.errcode != 0) return;
            this.formValidate = res.data;
            this.modelTermira = true;
        },
        /**
         * 表格数据获取 按钮
         */
        async fetchTableData() {
            const { NS } = this.form;
            let passdata = { NS };
            if (this.form.NS == '') {
                passdata.NS = null;
            }
            let res = await NetPayTermGetList(passdata);
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operate',
                slot: 'operate',
            });
            this.tableData = res.data;
        },
    },
};
</script>

