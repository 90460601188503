<template>
	<div>
		<div v-if="tableData&&tableData.sumMary" style="margin: 60px 0; height: 100px;display: flex;align-items: center">
			<Card class=" mr-10" :shadow='true' style="width:18vw;height: 100%;">
				<div style="text-align:center;display: flex;justify-content: space-around;">
					<div>
						<h3 style="position: relative;color: #ccc;">
							<span
								:style="`background-color:red;left: -10px;top: 50%;transform: translate(-50%,-50%); position: absolute; display: inline-block;width: 10px;height: 10px;border-radius: 100px;`">
							</span>订单总数
						</h3>
						<div style="font-weight: 700;">
							<div style="margin-top: 20px;">{{tableData.sumMary.orderNum}}</div>
						</div>
					</div>
					<div>
						<h3 style="color: #ccc;">退款订单</h3>
						<div style="font-weight: 700;">
							<div  style="margin-top: 20px;">{{tableData.sumMary.orderRNum}}</div>
						</div>
					</div>
				</div>
			</Card>
			<Card class="mr-10" :shadow='true' style="width:18vw;height: 100%;">
				<div style="text-align:center;display: flex;justify-content: space-around;">
					<div>
						<h3 style="position: relative;color: #ccc;">
							<span
								:style="`background-color:green;left: -10px;top: 50%;transform: translate(-50%,-50%); position: absolute; display: inline-block;width: 10px;height: 10px;border-radius: 100px;`">
							</span>总人次
						</h3>
						<div style="font-weight: 700;">
							<div style="margin-top: 20px;">{{tableData.sumMary.moviegoersTime}}</div>
						</div>
					</div>
					<div>
						<h3 style="color: #ccc;">总票房</h3>
						<div style="font-weight: 700;">
							<div  style="margin-top: 20px;">{{tableData.sumMary.movieBox}}</div>
						</div>
					</div>
				</div>
			</Card>
			<Card class="mr-10" :shadow='true' style="width:18vw;height: 100%;">
				<div style="text-align:center;display: flex;justify-content: space-around;">
					<div>
						<h3 style="position: relative;color: #ccc;">
							<span
								:style="`background-color:orange;left: -10px;top: 50%;transform: translate(-50%,-50%); position: absolute; display: inline-block;width: 10px;height: 10px;border-radius: 100px;`">
							</span>票券核销
						</h3>
						<div style="font-weight: 700;">
							<div style="margin-top: 20px;">{{tableData.sumMary.moviegoersTime_T}}</div>
						</div>
					</div>
					<div>
						<h3 style="color: #ccc;">票房</h3>
						<div style="font-weight: 700;">
							<div  style="margin-top: 20px;">{{tableData.sumMary.movieBox_T}}</div>
						</div>
					</div>
				</div>
			</Card>
			<Card :shadow='true' style="width:18vw;height: 100%;">
				<div style="text-align:center;display: flex;justify-content: space-around;">
					<div>
						<h3 style="position: relative;color: #ccc;">
							<span
								:style="`background-color:blue;left: -10px;top: 50%;transform: translate(-50%,-50%); position: absolute; display: inline-block;width: 10px;height: 10px;border-radius: 100px;`">
							</span>会员充值
						</h3>
						<div style="font-weight: 700;">
							<div style="margin-top: 20px;">{{tableData.sumMary.memberUp}}</div>
						</div>
					</div>
					<div>
						<h3 style="color: #ccc;">会员消费</h3>
						<div style="font-weight: 700;">
							<div  style="margin-top: 20px;">{{tableData.sumMary.memberCon}}</div>
						</div>
					</div>
				</div>
			</Card>
		</div>
		<div class=" flex justify-end">
			<Form inline>
				<div class=" flex justify-end">
					<FormItem :label-width="80" prop="" label="精确查找:">
						<Input style="width: 318px;" v-model="inlineForm.orderid" placeholder="请输入订单号" />
					</FormItem>
					<FormItem>
						<Button @click="()=>fetchData()" type="primary">查询</Button>
					</FormItem>
				</div>
				<div>
					<FormItem :label-width="80" prop="" label="范围查找:">
						<Select multiple placeholder="请选择商品类型" v-model="inlineForm.goodsType">
							<Option value="电影票">电影票</Option>
							<Option value="卖品套餐">卖品套餐</Option>
							<Option value="会员充值">会员充值</Option>
							<Option value="会员开卡">会员开卡</Option>
						</Select>
						</FormItem>
						<FormItem prop="">
							<DatePicker 
								@on-change="(value)=>inlineForm.start=value"
								:value="inlineForm.start"
								type="datetime" 
								format="yyyy-MM-ddTHH:mm" 
								placeholder="请输入开始时间" 
								style="width: 200px"></DatePicker>
						</FormItem>
						<FormItem prop="">
							<DatePicker 
								@on-change="(value)=>inlineForm.end=value"
								:value="inlineForm.end"
								type="datetime" 
								format="yyyy-MM-ddTHH:mm" 
								placeholder="请输入结束时间" 
								style="width: 200px"></DatePicker>
						</FormItem>
						<FormItem prop="">
							<Select multiple placeholder="请选择状态" v-model="inlineForm.state">
								<Option value="已支付">已支付</Option>
								<Option value="未支付">未支付</Option>
								<Option value="已退款">已退款</Option>
								<Option value="已关闭">已关闭</Option>
							</Select>
						</FormItem>
						<FormItem>
							<Button @click="()=>fetchData()" type="primary">查询</Button>
						</FormItem>
						<FormItem>
							<Button @click="()=>tableDownload()" type="primary">导出</Button>
						</FormItem>
				</div>
			</Form>
		</div>
		<Table
			class=" mt-5"
			:columns="tableData.title"
			:data="tableData.list">
			<template slot-scope="{row,index}" slot="orderAbout">
				{{row.orderAbout.join(" ")}}
			</template>
			<template slot-scope="{row,index}" slot="state">
				<Tag style="transform: scale(.8);" v-if="row.state=='已支付'" color="success">{{row.state}}</Tag>
				<Tag style="transform: scale(.8);" v-if="row.state=='未支付'" color="primary">{{row.state}}</Tag>
				<Tag style="transform: scale(.8);" v-if="row.state=='已退款'" color="error">{{row.state}}</Tag>
				<Tag style="transform: scale(.8);" v-if="row.state=='已关闭'" color="default">{{row.state}}</Tag>
				<Tag style="transform: scale(.8);" v-if="row.state=='退款中'" color="warning">{{row.state}}</Tag>
			</template>
			<template slot-scope="{row,index}" slot="Goodstype">
				<div>
					{{row.Goodstype.join(" | ")}}
				</div>
			</template>
			<template slot-scope="{row,index}" slot="paytype">
				<div>
					{{row.paytype.join(" | ")}}
				</div>
			</template>
			<template slot-scope="{row,index}" slot="markInRefund">
				<div>
					<div v-if="row.markInRefund" class=" text-red-500">退款</div>
				</div>
			</template>
			<template slot-scope="{row,index}" slot="opreate">
				<Button @click="()=>routeTo('orderDetail?id='+row._id)" size="small">详情</Button>
			</template>
		</Table>
		<Card class=" text-right">
			<Page @on-change="(value)=>fetchData(value)" :total="tableData.total" :page-size="tableData.size"></Page>
		</Card>
</div>
</template>
<script>
	import {OrderList} from "@/request/api"
	import { download } from '@/utils/table_download.js'
	export default{
		name:"orderSelect",
		data(){
			return{
				tableData:"",
				inlineForm:{
					goodsType:["电影票","卖品套餐","会员充值","会员开卡"],
					state:["已支付"],
					start:new Date().Format("yyyy-MM-dd")+"T06:00",
					end:new Date(new Date().getTime()+24*60*60*1000).Format("yyyy-MM-dd")+"T06:00",
				},
				inlineFormRule:{},
			}
		},
		created(){
			this.fetchData()
		},
		methods:{
			/**
			 * 路由跳转
			 */
			routeTo(value){
				window.open(value)
			},
			/**
			 * 导出表格数据
			 */
			async tableDownload(){
				let passdata = this.inlineForm
				passdata["all"] = true
				let res = await OrderList(passdata)
				if(res.errcode!=0) return
				let headers = res.data.title
				res.data.list.forEach((item,index)=>{
					item.paytype = item.paytype.join(" | ")
					item.Goodstype = item.Goodstype.join(" | ")
					item.orderAbout = item.orderAbout.join(" ")
					item.markInRefund = item.markInRefund?"退款":""
				})
				let data  = res.data.list
				download({
				  'worksheet':'订单查询',
				  headers,
				  data
				})
			},
			/**
			 * 获取表格列表数据
			 */
			async fetchData(page=1){
				let passdata = this.inlineForm
				passdata["page"] = page
				passdata["all"] = false
				let res = await OrderList(passdata)
				if(res.errcode!=0) return
				res.data.title.push({
					key:"opreate",
					slot:"opreate",
					title:"操作"
				})
				res.data.title.forEach((item,index)=>{
					if(item.key=="state"){
						item["slot"] = "state"
					}
					if(item.key=='Goodstype'){
						item["slot"] = "Goodstype"
					}
					if(item.key=='paytype'){
						item["slot"] = "paytype"
					}
					if(item.key=='markInRefund'){
						item["slot"] = "markInRefund"
					}
					if(item.key=='orderAbout'){
						item["slot"] = "orderAbout"
						item["width"] = 200
					}
				})
				this.tableData = res.data
			},
		}
	}
</script>