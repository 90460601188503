<template>
    <div>
        <Card>
            <Tabs v-model="acitveTab" type="card">
                <TabPane label="竖版海报">
                    <div class=" flex" style="width: 1400px;">
                        <div @mouseout="mouseLeave(-1)"  :class="['p-4', 'text-white', currentBgColor]" ref="poster-v" style="width: 375px;">
                            <span class="text-white pl-2 pr-2 pt-1 pb-1 rounded-3xl commonBgColor">{{ chooseDate }}
                                {{ chooseWeek }}</span>
                            <div class="flex mt-5">
                                <div class=" pr-10 flex-1">
                                    <h1>{{ storeData.stores.asName }}</h1>
                                    <div>
                                        <div class="flex">
                                            <div>
                                                <Icon type="md-pin" />
                                            </div>
                                            <div>{{ storeData.stores.detailed_address }}</div>
                                        </div>
                                    </div>
                                </div>
                                <img style="width: 120px;height: 120px;" :src="storeData.stores.address" />
                            </div>
                            <div @mouseenter="mouseEnter(index)" @mouseout="mouseLeave(index)"  v-for="(item, index) in filmPlanList" @mouseover="mouseOver(index)"  :key="index" class=" bg-white text-black mt-5 p-3 relative out">
                                <Icon @mouseenter="mouseEnter(index)" @mouseout="mouseLeave(index)" @mouseover="mouseOver(index)" v-show="(hoverIndex==index)&&filmPlanList.length>1" @click="removeFilm(item)"  class=" cursor-pointer absolute top-2 right-2 text-red-500 " size="20" type="ios-close-circle" />
                                <div class=" flex pointer-events-none" style="height: 80px;">
                                    <img style="width: 50px;height: 73px;"
                                        :src="((item.Movie.Film[0].img&&item.Movie.Film[0].img.indexOf('p0.pipi.cn')) == -1 ? corsHost : '') + item.Movie.Film[0].img" />
                                    <div class=" flex-1 pl-3 pr-3 flex items-start flex-col">
                                        <div>{{ item.Movie.movieName }}</div>
                                        <div class=" text-xs text-gray-300 flex">
                                            <div>{{ item.Movie.Film[0].type }} 版本:</div>
                                            <div class=" text-yellow-300 font-bold"> {{ item.Movie.Film[0].version }}</div>
                                        </div>
                                        <div>
                                            <div v-if="infoShowType == '不显示'" class="flex">
                                            </div>
                                            <div v-if="infoShowType == '方案一'" class="flex">
                                                <div>售价:</div>
                                                <div>¥{{ item.ShowList[0].showlist[0].basePrice }} </div>
                                                <div class="ml-2 text-red-500"> 会员:</div>
                                                <div class=" text-red-500">¥{{ item.ShowList[0].showlist[0].memberPrice }}
                                                </div>
                                            </div>
                                            <div v-if="infoShowType == '方案二'">
                                                <div class="flex">
                                                    <div>售价:</div>
                                                    <div>¥{{ item.ShowList[0].showlist[0].basePrice }} </div>
                                                </div>
                                                <div class="flex">
                                                    <div class="text-red-500"> 会员价:</div>
                                                    <div class=" text-red-500">¥{{ item.ShowList[0].showlist[0].memberPrice
                                                    }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="infoShowType == '方案三'" class="flex text-red-500 ">
                                                <div>售价:</div>
                                                <div>¥{{ item.ShowList[0].showlist[0].memberPrice }}起 </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class=" mt-2 pointer-events-none" style="flex-wrap: wrap;display: flex;">
                                    <template v-for="(item1, index1) in item.ShowList">
                                        <div v-if="item1.showDate == chooseDate" v-for="(item2, index2) in item1.showlist.filter(itemlist=>itemlist.status)"
                                            class=" flex" style="flex: 20%;">
                                            <div class="mb-2">
                                                <div class=" font-bold  pl-2 pr-2"
                                                    style="border-top: 1px solid #cccccc;border-left: 1px solid #cccccc;border-right: 1px solid #cccccc;">
                                                    {{ item2.showTime }}</div>
                                                <div class="pl-2 pr-2 text-xs"
                                                    style="border: 1px solid #cccccc;color: #cccccc;">普通厅</div>
                                            </div>
                                        </div>
                                        <!-- 填充空白位置 -->
                                        <div v-if="item1.showDate == chooseDate" class=" flex" style="flex: 20%;"
                                            v-for="item in 5 - item1.showTime.length % 5"></div>
                                    </template>
                                </div>
                            </div>
                            <div class=" bg-white text-black mt-5 p-3">
                                <div class=" flex">
                                    <div class=" flex-1 p-3 flex items-start flex-col mr-5">
                                        <div class=" text-md">{{ filmChangeInfo.tips }}</div>
                                    </div>
                                    <img style="width: 100px;height: 100px;" :src="filmChangeInfo.qrcode" />
                                </div>
                            </div>
                        </div>
                        <div style="width: 600px;" class=" ml-60">
                            <div class=" text-left">
                                <Button @click="()=>downloadImg('poster-v')">下载海报</Button>
                            </div>
                            <Alert show-icon class=" mt-5">可将鼠标移动到对应影片进行删除,竖版删除后，横版同步删除，刷新页面可还原</Alert>
                            <h2 class=" mt-5 text-left mb-2">选择日期</h2>
                            <div class=" flex flex-wrap">
                                <div @click="() => changeTab(item)" :class="['mb-5', 'mr-3']"
                                    v-for="(item, index) in timeTagList" :key="index">
                                    <span
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', chooseDate == item.showDate ? 'active_tab' : 'inactive_tab']">{{
                                            item.showDate }}
                                        {{ item.week }}</span>
                                </div>
                            </div>
                            <h2 class=" text-left mb-3">主题颜色</h2>
                            <div class="flex flex-wrap">
                                <div @click="() => changeTabColor('blue')" :class="['mb-5', 'mr-3']">
                                    <span
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'blue' ? 'blue' : '']">蓝色</span>
                                </div>
                                <div @click="() => changeTabColor('yellow')" :class="['mb-5', 'mr-3']">
                                    <span
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'yellow' ? 'yellow' : '']">黄色</span>
                                </div>
                                <div :class="['mb-5', 'mr-3']">
                                    <span @click="() => changeTabColor('red')"
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'red' ? 'red' : '']">红色</span>
                                </div>
                                <div :class="['mb-5', 'mr-3']">
                                    <span @click="() => changeTabColor('purple')"
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'purple' ? 'purple' : '']">紫色</span>
                                </div>
                                <div :class="['mb-5', 'mr-3']">
                                    <span @click="() => changeTabColor('darkBlue')"
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'darkBlue' ? 'darkBlue' : '']">深蓝色</span>
                                </div>
                            </div>
                            <h2 class="text-left">顶部信息</h2>
                            <div>
                                <Form :label-width="100">
                                    <FormItem label="门店:">
                                        <Input v-model="storeData.stores.asName" />
                                    </FormItem>
                                    <FormItem label="地址">
                                        <Input type="textarea" v-model="storeData.stores.detailed_address" />
                                    </FormItem>
                                    <FormItem label="logo">
                                        <div class="flex items-center">
                                            <img class=" w-10 h-10 mr-10" :src="storeData.stores.address" />
                                            <div class=" w-52">
                                                <Upload :before-upload="(e) => handleUpload(e, 'logo')" multiple type="drag"
                                                    action="//jsonplaceholder.typicode.com/posts/">
                                                    <div style="padding: 20px 0">
                                                        <p>修改显示图片</p>
                                                    </div>
                                                </Upload>
                                            </div>
                                        </div>
                                    </FormItem>
                                </Form>
                            </div>
                            <h2 class=" text-left">底部信息</h2>
                            <div>
                                <Form :label-width="100">
                                    <FormItem label="提示信息">
                                        <Input v-model="filmChangeInfo.tips" />
                                    </FormItem>
                                    <FormItem label="二维码">
                                        <div class="flex items-center">
                                            <div class=" w-10 h-10">
                                                <img class=" w-10 h-10" :src="filmChangeInfo.qrcode" />
                                            </div>
                                            <div class=" w-52 ml-10">
                                                <Upload :before-upload="(e) => handleUpload(e, 'qrcode')" multiple
                                                    type="drag" action="//jsonplaceholder.typicode.com/posts/">
                                                    <div style="padding: 20px 0">
                                                        <p>修改显示图片</p>
                                                    </div>
                                                </Upload>
                                            </div>
                                        </div>
                                    </FormItem>
                                </Form>
                            </div>
                            <h2 class=" text-left mb-3">内容信息</h2>

                            <div class="flex flex-wrap">
                                <div class=" mr-2">价格显示</div>
                                <div @click="() => infoShowType = '不显示'" :class="['mb-5', 'mr-3']">
                                    <span
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', infoShowType == '不显示' ? 'commonBgColor' : '']">不显示</span>
                                </div>
                                <div @click="() => infoShowType = '方案一'" :class="['mb-5', 'mr-3']">
                                    <span
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', infoShowType == '方案一' ? 'commonBgColor' : '']">方案一</span>
                                </div>
                                <div :class="['mb-5', 'mr-3']">
                                    <span @click="() => infoShowType = '方案二'"
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', infoShowType == '方案二' ? 'commonBgColor' : '']">方案二</span>
                                </div>
                                <div :class="['mb-5', 'mr-3']">
                                    <span @click="() => infoShowType = '方案三'"
                                        :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', infoShowType == '方案三' ? 'commonBgColor' : '']">方案三</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane label="横版海报">
                    <div style="width: 1246px;" >
                        <h2 class=" mt-10 text-left mb-2">选择日期</h2>
                        <div class=" flex flex-wrap">
                            <div @click="() => changeTab(item)" :class="['mb-5', 'mr-3']"
                                v-for="(item, index) in timeTagList" :key="index">
                                <span
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', chooseDate == item.showDate ? 'active_tab' : 'inactive_tab']">{{
                                        item.showDate }}
                                    {{ item.week }}</span>
                            </div>
                        </div>
                        <h2 class=" text-left mb-3">主题颜色</h2>
                        <div class="flex flex-wrap">
                            <div @click="() => changeTabColor('blue')" :class="['mb-5', 'mr-3']">
                                <span
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'blue' ? 'blue' : '']">蓝色</span>
                            </div>
                            <div @click="() => changeTabColor('yellow')" :class="['mb-5', 'mr-3']">
                                <span
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'yellow' ? 'yellow' : '']">黄色</span>
                            </div>
                            <div :class="['mb-5', 'mr-3']">
                                <span @click="() => changeTabColor('red')"
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'red' ? 'red' : '']">红色</span>
                            </div>
                            <div :class="['mb-5', 'mr-3']">
                                <span @click="() => changeTabColor('purple')"
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'purple' ? 'purple' : '']">紫色</span>
                            </div>
                            <div :class="['mb-5', 'mr-3']">
                                <span @click="() => changeTabColor('darkBlue')"
                                    :class="['pl-2 pr-2 pt-1 pb-1 rounded-3xl cursor-pointer', 'inactive_tab', currentBgColor == 'darkBlue' ? 'darkBlue' : '']">深蓝色</span>
                            </div>
                        </div>
                        <h2 class="text-left">顶部信息</h2>
                        <div>
                            <Form :label-width="100">
                                <FormItem label="标题:">
                                    <Input v-model="filmChangeInfo.title" />
                                </FormItem>
                                <FormItem label="顶部提示">
                                    <Input type="textarea" v-model="filmChangeInfo.tipsTop" />
                                </FormItem>
                                <FormItem label="底部提示">
                                    <Input type="textarea" v-model="filmChangeInfo.tipsBottom" />
                                </FormItem>
                                <FormItem v-show="false" label="logo">
                                    <div class="flex items-center">
                                        <img class=" w-10 h-10 mr-10" :src="filmChangeInfo.address" />
                                        <div class=" w-52">
                                            <Upload :before-upload="(e) => handleUpload(e, 'logo')" multiple type="drag"
                                                action="//jsonplaceholder.typicode.com/posts/">
                                                <div style="padding: 20px 0">
                                                    <p>修改显示图片</p>
                                                </div>
                                            </Upload>
                                        </div>
                                    </div>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                    <div  :class="['flex-col flex',currentBgColor]" ref="poster-h" style="width: 1246px;height: 720px;">
                        <div class=" flex p-5 justify-center text-white">
                            <div class="flex items-center w-full">
                                <div class="text-3xl font-bold flex-1 flex justify-end"
                                    style="-webkit-text-stroke: #5b9bd5;-webkit-text-stroke-width: thin;">
                                    <div class=" flex-col items-center flex" style="padding-right:90px;">
                                        <div>{{ filmPlanListAuto[0].ShowList[0].showDate }}</div>
                                        <div>{{ filmPlanListAuto[0].ShowList[0].week }}</div>
                                    </div>
                                </div>
                                <div class="text-5xl">{{filmChangeInfo.title}}</div>
                                <div class=" text-xl font-bold flex-1 flex justify-end">
                                    <div class="shenlue3" style="width: 360px; color: #fec000;">{{ filmChangeInfo.tipsTop }}</div>
                                </div>
                            </div>
                        </div>
                        <div class=" flex justify-between flex-1 pl-10 pr-10">
                            <div v-for="(item, index) in rowPostList" style="width: 17%;">
                                <div class="relative h-full">
                                    <div style="box-shadow: 0 0 10px #fff;filter: blur(2px);"
                                        class=" absolute left-0 right-0 bottom-0 top-0 bg-black z-10 opacity-80"></div>
                                    <div class=" z-20 left-0 right-0 bottom-0 top-0 absolute">
                                        <!-- 图片 -->
                                        <div class=" w-full">
                                            <img v-if="item.Movie.Film[0].img" class=" w-full h-72"  :src="(item.Movie.Film[0].img.indexOf('p0.pipi.cn') == -1 ? corsHost : '') + item.Movie.Film[0].img" />
                                            <img v-else  class=" w-full h-72" alt="没有图片了"  src="" />
                                        </div>
                                        <!-- 标题块 -->
                                        <div style="background-color: #fdf516;"
                                            class=" pt-2 pb-2 w-full text-black font-bold text-xl text-center">
                                            <div class=" shenlue1">{{ item.Movie.Film[0].movieName }}</div>
                                            <Row>
                                                <Col :span="12">
                                                <div>{{ item.ShowList[0].showlist[0].lang }}{{item.Movie.Film[0].version}}</div>
                                                </Col>
                                                <Col :span="12">
                                                <div>{{ item.Movie.Film[0].len }}分钟</div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <!-- 价格显示块 -->
                                        <div>
                                            <Row style="color: #fcff00;" class="font-bold text-lg text-center pt-3 pb-3">
                                                <Col :span="12">
                                                <div>会员价</div>
                                                </Col>
                                                <Col :span="12">
                                                <div class="flex justify-center">
                                                    <div>¥</div>
                                                    <div>{{item.ShowList[0].showlist[0].memberPrice}}</div>
                                                    <div style="transform: scale(.7) translateY(5%);">起</div>
                                                </div>
                                                </Col>
                                            </Row>
                                            <Row class=" text-xl font-bold text-white text-center">
                                                <Col v-for="(item1, index1) in item.ShowList[0].showlist.filter(itemlist=>itemlist.status)" :span="12">
                                                <div>{{ item1.showTime }}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 17%;" v-for="(iasf, index) in 5 - rowPostList.length">
                            </div>
                        </div>
                        <div class=" font-bold text-center pt-3 pb-5  text-3xl" style="color: #fece4a;">{{filmChangeInfo.tipsBottom  }}</div>
                    </div>
                    <div class=" text-right p-4 flex justify-end">
                        <Button class=" mr-3" @click="()=>downloadImg('poster-h')" type="primary">下载海报</Button>
                        <Page @on-change="changePage" :total="filmPlanListAuto.length" :page-size="5"></Page>
                    </div>
                </TabPane>
            </Tabs>
        </Card>
    </div>
</template>
<script>
import html2canvas from "html2canvas"
import { GetPlan, Get_StoreDate } from "../../../request/api"
export default {
    name: "postBorn",
    data() {
        return {
            acitveTab:0,
            dataURL: "",
            filmPlanList: [],
            filmPlanListCopy: [],
            filmPlanListAuto: [],
            storeData: "",
            corsHost: 'https://proxy.ruiziyou.com/crossOriginImg?refurl=',
            timeTagList: [],
            chooseDate: "",
            chooseWeek: "",
            filmChangeInfo: {
                asName: "",
                detailed_address: "",
                address: "",
                tips: "看热门大片",
                qrcode: "",
                title:"影院今日排期",
                tipsTop:"温馨提示：1名成人可带1名1.3米以下儿童免票无座观影，超1.3米需购票入场",
                tipsBottom:"观看3D电影需自备3D镜或前往影院前台购买",
            },
            currentBgColor: "blue",
            infoShowType: "方案一",
            pageIndex: 1,
            hoverIndex:-1
        }
    },
    created() {
        this.handleGetPlan()
        this.handleGetStoreData()
    },
    computed: {
        /**
         * 统计出当前页码的横版海报数据列表
         */
        rowPostList() {
            let data = this.filmPlanListAuto.slice((this.pageIndex - 1) * 5, 5 * (this.pageIndex));
            //处理当天没有排片的问题
            data = data.filter(item=>item.ShowList[0].showlist.length>0); 
            console.log(data,'======data')
            return data
        }
    },
    methods: {
        mouseLeave(value){
            this.hoverIndex = value
        },
        mouseEnter(value){
            this.hoverIndex = value
        },
        mouseOver(value){
            this.hoverIndex = value
        },
        /**
         * 移出当前code在当前日期的影片
         * @param {} value 
         */
        removeFilm(value){
            const movieCode = value.Movie.movieCode
            let data = this.filmPlanListCopy.filter((item,index)=>item.Movie.movieCode!=movieCode) 
            this.filmPlanListCopy = data
            this.filmSort(this.chooseDate) 
            
        },
        /** 
         * 横版海报切换页面
        */
        changePage(value) {
            this.pageIndex = value
        },
        /**
         * 切换主题颜色
         */
        changeTabColor(value) {
            this.currentBgColor = value
        },
        /**
         * 上传文件修改logo
         * @param {*} file 
         */
        handleUpload(file, type) {

            let fr = new FileReader()
            fr.readAsDataURL(file)
            fr.onload = (e) => {
                if (type == 'logo') {
                    this.storeData.stores.address = e.target.result
                }
                if (type == 'qrcode') {
                    this.filmChangeInfo.qrcode = e.target.result
                }
            }
            return false;
        },
        /**
         * 切换标签页
         */
        changeTab(value) {
            const { showDate, week } = value
            this.chooseDate = showDate
            this.chooseWeek = week
            this.pageIndex = 1
            this.filmSort(showDate)
        },
        /**
         * 获取门店信息
         */
        async handleGetStoreData() {
            let passdata = {
                mcode: localStorage.getItem('mcode')
            }
            let res = await Get_StoreDate(passdata)
            if (res.code != 200) return
            this.storeData = res.data[0]
            this.filmChangeInfo = {
                address: res.data[0].stores.address,
                asName: res.data[0].stores.asName,
                detailed_address: res.data[0].stores.detailed_address,
                tips: "看热门大片",
                qrcode: "",
                title:"影院今日排期",
                tipsTop:"温馨提示：1名成人可带1名1.3米以下儿童免票无座观影，超1.3米需购票入场",
                tipsBottom:"观看3D电影需自备3D镜或前往影院前台购买",
            }
        },
        /**
         * 处理某一天影片排序的问题
         */
        filmSort(showDate) {
            //获取获取只有当前时间的数据 当前存放的数据有 filmPlanListCopy filmPlanList
            //需要在filmPlanList里删除不是当天的数据
            let data = JSON.parse(JSON.stringify(this.filmPlanListCopy))
            //保存一条数据在showlist里面
            data.forEach((item, index) => {
                let arr = item.ShowList.filter((item1, index1) => item1.showDate == showDate)
                item.ShowList = arr
            })
            //去掉没有一个厅的电影
            data = data.filter((item, index) => item.ShowList.length > 0)
            //排序 排时间最多的在上面
            let sortdata = data.sort((a, b) => b.ShowList[0].showTime.length - a.ShowList[0].showTime.length)
            //竖版排期
            this.filmPlanList = sortdata
            //横版排期
            this.filmPlanListAuto = JSON.parse(JSON.stringify(sortdata))
            this.deal8TimePost(this.filmPlanListAuto)
            this.deal8TimePost(this.filmPlanListAuto)
        },
        /**
         * 处理横版数个超过8个日期时间换行的问题
         */
        deal8TimePost(dateList) {
            //找到对应的下标 
            let spownPostList = []
            dateList.forEach((item, index) => {
               return item.ShowList[0].showTime.length > 8 && spownPostList.push(index)
            })
 
            //对下表进行排序
            spownPostList.sort((a, b) => b - a)
 
            //将下表的8个往后 用splice从右向左插入数据 用spilce索引得加1，前置插入
            for (let i = 0; i < spownPostList.length; i++) {
                //游标前置 删除 取到8个往后的数据
                let dataShowTime = dateList[spownPostList[i]].ShowList[0].showTime.splice(8, 1000)
                
                let dateShowlist = dateList[spownPostList[i]].ShowList[0].showlist.splice(8, 1000)
                //组数据 副本
                let copyDataRow = JSON.parse(JSON.stringify(dateList[spownPostList[i]]))
                copyDataRow.ShowList[0].showTime = dataShowTime
                copyDataRow.ShowList[0].showlist = dateShowlist
                //插入到原始数据中 dateList
                dateList.splice(spownPostList[i] + 1, 0, copyDataRow)
            }
        },
        /**
         * 删除下架的排期 同一数据源
         */
        deleteInshowFilm(data){
            let hiddenFilms = [];
            data.forEach((item,index)=>{
                item.ShowList.forEach((item1,index1)=>{
                    let realFilms = item1.showlist.filter((item2,index2)=>item2.status)
                    if(realFilms.length>0){
                        console.log(realFilms)
                        item1.showlist = realFilms 
                    }else{
                        hiddenFilms.push([index,index1])
                    }
                })
            })
            hiddenFilms.forEach((item,index)=>{
                // 删除的索引
                let filmIndex = item[1] 
                data[item[0]].ShowList.splice(filmIndex,1)
            })
        },
        /**
         * 获取排期数据
         */
        async handleGetPlan() {
            let res = await GetPlan({ uptype: "后台获取" })
            if (res.errcode != 0) return
            // res.data = JSON.parse(JSON.stringify(res.data).replaceAll("%3E",">"))
            //删除下架的排期
            this.deleteInshowFilm(res.data) 
            this.filmPlanList = JSON.parse(JSON.stringify(res.data))
            this.filmPlanListCopy = JSON.parse(JSON.stringify(res.data))

            let timeTagList = []
            for (let i = 0; i < res.data.length; i++) {
                let showList = res.data[i].ShowList
                for (let j = 0; j < showList.length; j++) {
                    let showDate = showList[j].showDate
                    let data = {
                        showDate: showDate,
                        week: showList[j].week
                    }
                    let taglist = timeTagList.map((item, index) => JSON.stringify(item))
                    if (taglist.indexOf(JSON.stringify(data)) == -1) {
                        timeTagList.push({
                            showDate: showDate,
                            week: showList[j].week
                        })
                    }
                }
            }
            this.timeTagList = timeTagList
            this.changeTab({
                showDate: res.data[0].ShowList[0].showDate,
                week: res.data[0].ShowList[0].week
            })
        },
        /**
         * 下载海报
         * @param {*} url 海报的地址
         */
        downloadImg(value) {
            html2canvas(this.$refs[value], { allowTaint: true, useCORS: true,scale:this.acitveTab==1?2:5 })
                .then(canvas => {
                    var url = canvas.toDataURL(); //这里上面不设值cors会报错
                    this.dataURL = url
                    var a = document.createElement("a"); //创建一个a标签 用来下载
                    a.download = "海报排期"; //设置下载的图片名称
                    var event = new MouseEvent("click"); //增加一个点击事件
                    //如果需要下载的话就加上这两句
                    a.href = url;//此处的url为base64格式的图片资源
                    a.dispatchEvent(event); //触发a的单击事件 即可完成下载
                })

        },
        getBase64(imgUrl) {
            window.URL = window.URL || window.webkitURL;
            var xhr = new XMLHttpRequest();
            xhr.open("get", imgUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
                if (this.status == 200) {
                    //得到一个blob对象
                    var blob = this.response;
                    // 至关重要
                    let oFileReader = new FileReader();
                    oFileReader.onloadend = function (e) {
                        // 此处拿到的已经是base64的图片了,可以赋值做相应的处理
                        return e.target.result
                    }
                    oFileReader.readAsDataURL(blob);
                }
            }
            xhr.send();
        },

    }
}
</script>
<style>
.active_tab {
    background-color: #6eb4f9;
    color: #fff;
}

.inactive_tab {
    background-color: #fff;
    color: #000;
}

.blue {
    color: #fff;
    background: linear-gradient(90deg, #4b69d3, #6dbaf6)
}

.red {
    color: #fff;
    background: linear-gradient(90deg, red, pink);
}

.yellow {
    color: #fff;
    background: linear-gradient(90deg, orange, yellow);
}

.purple {
    color: #fff;
    background: linear-gradient(90deg, purple, black);
}

.commonBgColor {
    background-color: #6eb4f9;
    color: #fff;
}
.darkBlue{
    background-color: #042237;
    color: #fff;
}
</style>


