<template>
	<div style="position: relative;">
		<div  style="height: 300px;">
			<Alert show-icon type="warning"> 未设置最低发行价，影片场次无法上架，请到片库设置！</Alert>
			<div  style="text-align: right; margin: 10px 0;display: flex;justify-content: space-between;" >
				<h1>片名：{{currentItem.name}}</h1>
				<div>
					<Button  @click="()=>forced_to_update()"  type="error">强制更新</Button>
					<Button v-if="$my_checkBtn('synchronized_schedul')" @click="()=>synchronized_schedul()" style="margin-left: 10px;" type='primary'>同步排期</Button>
				</div>
			</div>
			<!-- 选片 -->
			<div style="display: flex;align-items: center; overflow-x: scroll;height: 180px;overflow-y: hidden;">
				<div @click="film_choose(item,index)" v-for="(item,index)  in movieslist"
					style="background-color: #fff; color: #fff;  text-align: center; cursor: pointer; height: 150px;padding: 5px;margin: 10px;border: 1px solid gold;">
						<img alt="无数据" style="display: block; height: 100%;width: 100px;" :src="item.img">
				</div>
			</div>
		</div>
		<div  v-if="currentItem" style="display: flex;height: 30px;">
			<div @click="()=>current_showDate_select_index=index" style=" height: 30px; margin-right: 10px;cursor: pointer;" v-for="(item,index) in currentItem.show" :class="index==current_showDate_select_index?'select':'un_select'">{{item.showDate}}</div>
		</div>
		<!-- 内容主体 -->
		<div> 
			<!-- 表格 -->
			<div  class="filmPrice" >
				<!-- 点击选中当前单元格触发的事件 保存当前行信息到本地 -->
				<Table v-if="currentItem"  @on-row-click='(payload)=>currentCell=payload' style="margin-top: 18px;height: 100%;" :height="$my_height()-460"     :columns="columns1" :data="currentItem.show[current_showDate_select_index].list">
					<!-- 命中策略 -->
					<template slot-scope='{row,column}' slot='hit'>
						<span style="color: orange;" v-for="item in row.hit">{{item}}<br/></span>
					</template>
					<!-- showTime -->
					<template slot-scope="{ row, index }" slot="showTime">
						<div>
							{{row.dt+' '+row.tm}}
						</div>
					</template>
					<!-- 上架  下架 -->
					<template slot-scope="{ row, index }" slot="slotTicketStatus">
						<div>
							<i-Switch @on-change="()=>tag_click(row,-1,index)" v-model="currentItem.show[current_showDate_select_index].list[index].ticketStatus"></i-Switch>
						</div>
					</template>
					<!-- 座位图渲染模式 -->
					<template slot-scope="{ row, index }" slot="slotSeatingPlanModel">
						<div>
							<Dropdown transfer @on-click='(payload)=>dropdown_model_select(row,payload)'>
								<Button type="primary">
									{{dList[row.seatingPlanModel]}}
									<Icon type="ios-arrow-down"></Icon>
								</Button>
								<DropdownMenu slot="list">
									<DropdownItem v-for="(item,index) in dList" :name='index'>{{item}}</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</div>
					</template>
					<!-- 表头操作 -->
					<template slot-scope="{ row, index }" slot="action"> 
						<div v-if="isEdit&&currentIndex==index">
							<Button @click="save_edit(row)" type="primary" size="small"
								style="margin-right: 5px">保存</Button>
							<Button @click="cancel(index)" type="error" size="small">取消</Button>
						</div>
						<div v-else>
							<Button type="error" size="small" @click="edit(row,index)"
								style="text-align: right;">编辑</Button>
							<Button @click="()=>Seatmanagement(row)" v-if="$my_checkBtn('session_seat')" type="primary" size="small" style="margin-left: 5px" >座位管理</Button>
						</div>
					</template>
					<!-- 会员价格 -->
					<template slot-scope="{ row, index }" slot="mPrice">
						<div>
							<div v-if="isEdit&&currentIndex==index">
								<Input v-model="currentItem.show[current_showDate_select_index].list[index].MemberPrice" placeholder="default size" />
							</div>
							<div v-else>
								<div>
									{{currentItem.show[current_showDate_select_index].list[index].MemberPrice}}
								</div>
							</div>
						</div>
					</template>
					<!-- 非会员价格 -->
					<template slot-scope="{ row, index }" slot="nomPrice">
						<div>
							<div v-if="isEdit&&currentIndex==index">
								<Input v-model="currentItem.show[current_showDate_select_index].list[index].baseSellPrice" placeholder="default size" />
							</div>
							<div v-else>
								<div>
									{{currentItem.show[current_showDate_select_index].list[index].baseSellPrice}}
								</div>
							</div>
						</div>
					</template>
				</Table>
			</div>
		</div>
		<Modal :closable='false' :mask-closable='false' fullscreen  v-model='modal_is_show_seat_management'>
			<div>
				<div style="position: relative;width: 80vw;height: 80vh;">
					<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
						<seatingplan :state='state' :seat_info='seat_info' :modal_is_show_seat_management='modal_is_show_seat_management' />
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: left;">
					<Button :type="state=='标记留座'?'primary':'default'" @click="()=>standard_for_guest()">标为留座</Button>
					<Button @click="()=>submit_guest()" style="margin-left: 10px;" >提交留座</Button>
				</div>
			</div>
			<div slot='footer'>
				<Button @click="()=>modal_is_show_seat_management=false">返回</Button>
			</div>
		</Modal>
	</div>
</template>
<!-- seatingPlanModel 0 为默认的模式 1变更座位图渲染模式为A全开  2变更座位图渲染模式为B同步  3变更座位图渲染模式为C反锁  4变更座位渲染图模式全部锁定 -->
<!--ticketStatus  为上 下架 -2下架 -1上架 -->
<script>
	
	import seatingplan from '@/components/seating_plan.vue'
	let arr =[];
	import {
		PosSeatPlan,
		filmPrice2,
		setFilmPrices,
		filmPrice,
		setSeat_render
	} from '@/request/api.js'
	import {
		getTimeFromZero
	} from '@/utils/index.js'
	
	export default {
		name: 'navbar',
		components:{
			seatingplan
		},
		data() {
			return {
				localCurrentPrice:"",
				state:'',
				seat_info: {},
				modal_is_show_seat_management:false,//点击座位管理对话框显示
				current_showDate_select_index:0,//当前选中的日期
				switch1: false, //上架下架开关
				dList: {
					"0": "默认模式",
					'A': '模式A全开',
					'B': '模式B同步',
					'C': '模式C反锁',
					'D': '模式全部锁定'

				},
				currentCell: {},
				spinShow: false, //加载动画控制
				currentItem: '',
				movieslist: [], //顶部影片列表
				mPriceVal: '', //会员价格值
				nomPriceVal: '', //非会员价格值
				isEdit: false, //是否编辑
				currentIndex: '', //当前点击的表格影片
				currentFilmIndex: '',
				columns1: [{
						title: '场次编码',
						key: 'seqNo',
						width: 180
					},

					{
						title: '场次',
						key: 'dt',
						slot: 'showTime',
						width: 160
					},
					{
						title: '影厅',
						key: 'th',
						width: 200
					},
					{
						title: '开售',
						key: 'ticketStatus',
						slot: 'slotTicketStatus',
						width: 150
					},
					{
						title: '座位图渲染模式',
						key: 'seatingPlanModel',
						slot: 'slotSeatingPlanModel',
						width: 150
					},
					{
						title: '发行价',
						key: 'min_price',
						width: 100,
						
					},
					{
						title: '命中策略',
						key: 'hit',
						width: 150,
						slot:'hit'
					},

					{
						title: '会员价格',
						key: 'MemberPrice',
						width: 100
					},
					{
						title: '非会员价',
						key: 'baseSellPrice',
						width: 100
					},


					{
						title: '操作',
						key: 'operation',
						slot: 'action',
						width:200
					}
				],
				data1: [],
			}
			
		},
		watch:{
			 async modal_is_show_seat_management(val){
				if(val){
					let pass_data = {
						'seqNo':this.currentCell.seqNo
					}
					let {data:res_data} = await PosSeatPlan(pass_data)
					this.seat_info = res_data
					this.flash()
				}else{
					this.currentCell=''
					arr=[]
				}
			 }
		},
		mounted() {
			// 刷新页面影片列表信息
			this.flash_film_list();
		},
		methods: {
			Seatmanagement(row){
				this.currentCell=row
				this.modal_is_show_seat_management=true
			},
			async synchronized_schedul() {
				let pass_data = {
					"type": "同步排期" //'强制更新','获取排期','同步排期'  三选一
					}
				let {data:res_data} = await filmPrice(pass_data)
				this.movieslist=''
				this.movieslist = res_data.movieslist
			},
			// 提交留座
			async submit_guest(){

				let list = arr.filter(item=>{
					// 选中状态
					if(item.seat.seatStatus==5){
						return true
					}
				}).map(item=>item.seat)
				let pass_data = {
					'type':this.state,
					'seqNo':this.currentCell.seqNo,
					'list':list,
					'username':localStorage.getItem('username')
				}
				
			},
			// 标为留座
			standard_for_guest(){
				this.state='标记留座'
			},
			// 刷新座位图
			flash() {
				var canvas = document.getElementById('myCanvas');
				let ctx = canvas.getContext('2d');
				// 座位方法
				function rect(x, y, w, h, color, isfill, seat) {
					this.x = x
					this.y = y
					this.w = w
					this.h = h
					this.isfill = isfill
					this.seat = seat
					this.color = color ? color : 'black'
				}
				
				// 方法 画空心 和实心动画
				rect.prototype.render = function() {
					
					switch (this.seat.seatStatus){
						case 1:
						this.isfill=false
						this.color = '#ccc'
							break;
						case 2:
						this.isfill=true
						this.color = 'red'
							break;
						case 3:
						this.isfill=true
						this.color = 'orange'
						break;
						case 4:
						this.isfill=true
						this.color = '#ccc'
							break;
						case 5:
						this.isfill=false
						this.color = 'green'
							break;
					}
					
					if(this.seat.seatStatus==2&&this.leave_seat){
						this.isfill=true
						this.color = 'orange'
					}
					
						
					if (this.isfill) {
						ctx.fillStyle = this.color
						
						ctx.fillRect(this.x, this.y, this.w, this.h)
						
					} else {
						ctx.strokeStyle = this.color
				
						ctx.strokeRect(this.x, this.y, this.w, this.h)
					
					}
			
					// 当前是座位索引区域
					ctx.font = ' 15px consolas';
					// 座位索引写入
					ctx.fillStyle = '#000';
					ctx.fillText(this.y/40+1,15,(this.y/40+1)*40-20)
					ctx.textAlign='center'
					ctx.fillText(this.seat.columnId,this.x+15,this.y+20)
						
						
				}
			
				// 成员数据获取
				rect.prototype.getXY = function() {
					return {
						x: this.x,
						y: this.y,
						w: this.w,
						h: this.h,
						color: this.color
					}
				}
			
				// 点击更新颜色
				rect.prototype.update = function() {
					this.color = 'red'
				}
				
				// 清除全部选中座位
				rect.prototype.clear_seat =function() {
					ctx.clearRect(0, 0, 1000, 1000)
					
				}
				
				// 不断修改数据 动画的帧数 
				setInterval(item => {
					ctx.clearRect(0, 0, 1000, 1000)
					for (let s of arr) {
						s.render()
					}
				}, 3)
			
				for (let index in this.seat_info.list) {
					
					for (let indexin in this.seat_info.list[index]) {
						let isfill = true;
						if (this.seat_info.list[index][indexin].columnId == '') {
							continue;
						}
						
						// 可选座位 不填充
						if (this.seat_info.list[index][indexin].seatStatus == 1) {
							isfill = false
						}
						
						let asdf = new rect(indexin * 40+40, index * 40, 30, 30, '#ccc', isfill, this.seat_info.list[index][
							indexin
						])
						// 压入座位
						arr.push(asdf)
					}
			
				}
				
				// 鼠标按下将 点击的坐标和座位信息进行比对 通过方法 传递出去
				ctx.canvas.onmousedown = (e) => {
					// 通过窗口的绝对坐标 和 画布的绝对坐标获取 点击时候相对画布的绝对坐标
					let dx = e.pageX - parseInt(canvas.getBoundingClientRect().x)
					let dy = e.pageY - parseInt(canvas.getBoundingClientRect().y)
					
					// 通过枚举数组将命中的座位信息通过 函数的参数注入传递出去
					for (let s in arr) {
						
						// 获取所有预设置的座位信息数组
						let obj = arr[s].getXY()
						// 比对坐标数据位置是否在区间内
						
						// 横向区域判断
						// 将原点设置在座位的左上角
						// 如果进入的对象数据正确,就触发点击事件,传递座位信息出去
						if (0<(dx - obj.x)&&(dx - obj.x) <= obj.w) {
							// 纵向区域判断
							if (0<(dy - obj.y)&&(dy - obj.y) <= obj.h ) {
								this.test(arr[s], s)
							}
						}
			
					}
					
				}
			
			},
			test(data) {
				// 当前是否标为留座
				if(data.seat.seatStatus==1||data.seat.seatStatus==5){
					if(this.state=="标记留座"){
						if(data.seat.seatStatus==5){
							console.log(1)
							data.seat.seatStatus=1
						}else{
							console.log(5)
							data.seat.seatStatus=5
						}
					}
				}
			},
			// 强制更新按钮
			async forced_to_update(){
				let pass_data = {
					"type": "强制更新" //'强制更新','获取排期','同步排期'  三选一
					}
				let res_data =  await filmPrice2(pass_data)
				if(res_data.msg=='ok'){
					this.$mytoast('更新成功')
				}else{
					this.$mytoast_err('更新失败')
				}
			},
			// 上架下架切换
			async tag_click(row, index, rowIndex) {
				let seqNo = row.seqNo
				let state = index
				let res = await setSeat_render({
					seqNo,
					type:"开售停售"
				})
				if(res.code!=200){
					console.log(row.ticketStatus)
					row.ticketStatus=!row.ticketStatus
				}else{
					this.$Message.success("操作成功")
					row.ticketStatus=res.data.up.ticketStatus
				}
				console.log('asdfasdfasdfad')
				this.film_choose(this.currentItem, this.currentIndex,"不重置时间")
			},
			async dropdown_model_select(row,payload) {
				let seqNo = this.currentCell.seqNo
				let res = await setSeat_render({
					seqNo,
					state:payload,
					type:"渲染模式"
				})
				if(res.code!=200){
					row.seatingPlanModel=row.seatingPlanModel
				}else{
					this.$Message.success("操作成功")
					row.seatingPlanModel=payload
				}
			},
			film_choose(item, inde,move_to_other_time) {
				if(move_to_other_time=='不重置时间'){
				}else{
					this.current_showDate_select_index=0
				}
				this.currentFilmIndex = inde
				let {
					show
				} = item;
				let filmList = [];
				show.map(movies => {
					filmList.push(...movies.list)
				})
				this.data1=[]
				this.currentItem=""
				this.data1 = filmList
				this.currentItem = item
			},
			// 刷新页面影片列表信息
			async flash_film_list() {
				let pass_data = {
					"type": "获取排期" //'强制更新','获取排期','同步排期'  三选一
					}
				let {data:res_data} = await filmPrice(pass_data)
				this.movieslist=''
				this.movieslist = res_data.movieslist
			},
			// 点击表格编辑按钮
			edit(row,index) {
				console.log(row,'==')
				// 停售隐藏    编辑按钮编程取消  停售按钮变成保存
				this.isEdit = true;
				this.currentIndex = index;
				// 将表头会员价格 的项目变成  input框 非会员价格变成INPUT框
				// 这里这样写是为了修改对象同时可以不影响响应式展示数据
				this.$set(this.columns1[7], 'slot', 'mPrice')
				this.$set(this.columns1[8], 'slot', 'nomPrice')
				this.localCurrentPrice={
					baseSellPrice:row.baseSellPrice,
					MemberPrice:row.MemberPrice,
					thuid:row.thuid,
					seqNo:row.seqNo
				}
			},
			//保存编辑
			async save_edit(item) {
				console.log(item)
				if (!item.baseSellPrice || item.baseSellPrice == 0) {
					this.$Message.error('非会员价不能为空');
					return
				}
				// 会员价格值
				let mPriceVal = this.mPriceVal;
				// 非会员价格值
				let nomPriceVal = this.nomPriceVal;
				console.log(item, 1111)
				let obj1;
				let obj2;
				let baseURL = this.$store.state.baseURL
				obj1=	{
					"seqNo": item.seqNo, //影片编号
					"Member": item.MemberPrice, //会员价格
					"baseSell":  item.baseSellPrice, //普通价格
					"type":"自定义价格"
					}
				
				let res = await setSeat_render(obj1)
				if(res.code!=200){
					item.baseSellPrice=this.localCurrentPrice.baseSellPrice
					item.MemberPrice=this.localCurrentPrice.MemberPrice
					this.data1.forEach(item=>{
						if(this.localCurrentPrice.thuid==item.thuid){
							item.baseSellPrice=this.localCurrentPrice.baseSellPrice
							item.MemberPrice=this.localCurrentPrice.MemberPrice
						}
					})
				}else{
					this.$Message.success("操作成功")
					this.data1.forEach(item=>{
						if(this.localCurrentPrice.seqNo==item.seqNo){
							console.log(res)
							item.baseSellPrice=res.data.up.baseSell
							item.MemberPrice=res.data.up.Member
						}
					})
				}
				// localCurrentPrice
				this.film_choose(this.currentItem, this.currentIndex,'不重置时间')
				this.currentIndex = -1

			},
			//取消编辑
			cancel() {
				
				this.isEdit = false;
				this.$set(this.columns1[7], 'slot', '')
				this.$set(this.columns1[8], 'slot', '')
				
			}
		}
	}
</script>

<style >
	
	.un_select{
		border: 1px solid #000; padding: 5px 10px;border-radius: 100px;
	}
	.select{
		background-color: #333439;padding: 5px 10px;color: rgb(246, 202, 157);border-radius: 100px;
	}
	
/* 	.ivu-table-wrapper{
		height: 47vh;

	} */
	.out_line {
		border: 3px solid orange;
	}
	
</style>
